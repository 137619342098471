import React, { useState } from 'react'
import './styles.css'
import useEditObjectiveRequirement from '../../../../../../api/mutation/editObjectiveRequirement'
import ErrorModal from '../../../../../../components/ErrorModal'

const F2WCard = ({ tradeoff, btnClickHandler, teamLeaders, setTeamLeaders }) => {
  const [capacityCounter, setCapacityCounter] = useState(tradeoff.allocatedFte)

  const [selectValue, setSelectValue] = useState('')
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [isDisabledBtn, setIsDisabledBtn] = useState(true)

  const workPoolSelectChangeHandler = (e) => {
    const newTeamLeaders = [...teamLeaders]

    const previousLeader = newTeamLeaders.find((el) => el.f2wTlId === Number(selectValue))
    const newLeader = newTeamLeaders.find((el) => el.f2wTlId === Number(e.target.value))

    tradeoff.needToTransfer = tradeoff.requiredFte - tradeoff.allocatedFte
    tradeoff.updatedAlloc = Math.min(
      tradeoff.requiredFte - tradeoff.allocatedFte,
      newLeader.remainingFte,
    )

    if (e.target.value === '') {
      setSelectValue('')
      setCapacityCounter(tradeoff.allocatedFte)
      setHasUnsavedChanges(false)
      setIsDisabledBtn(true)
      // } else if (newLeader.remainingFte >= tradeoff.teamCapacityNeeds) {
    } else if (newLeader.remainingFte >= tradeoff.needToTransfer) {
      // setCapacityCounter(tradeoff.teamCapacityNeeds)
      setCapacityCounter(tradeoff.requiredFte)
      newLeader.remainingFte -= tradeoff.needToTransfer // teamCapacityNeeds

      setHasUnsavedChanges(true)
      setIsDisabledBtn(false)
      setSelectValue(e.target.value)
    } else {
      setCapacityCounter(newLeader.remainingFte)
      newLeader.remainingFte = 0

      setHasUnsavedChanges(true)
      setIsDisabledBtn(false)
      setSelectValue(e.target.value)
    }

    if (previousLeader) {
      previousLeader.remainingFte += capacityCounter
    }

    setTeamLeaders(...[newTeamLeaders])
  }

  const [displaySaveButtonTeamCapacityNeeds, setDisplaySaveButtonTeamCapacityNeeds] =
    React.useState(false)
  const [teamCapacityNeedsInputValue, setTeamCapacityNeedsInputValue] = React.useState(null)
  const [teamCapacityNeedsInputError, setTeamCapacityNeedsInputError] = React.useState(null)

  const handleTeamCapacityNeedsChange = (input) => {
    setTeamCapacityNeedsInputError(null)
    if (input < 0 || input === null || input === undefined || isNaN(input) === true) {
      setTeamCapacityNeedsInputError('The Team Capacity input has to be greater or equal to 0 ')
      setTeamCapacityNeedsInputValue(null)
      return
    }
    setTeamCapacityNeedsInputValue(input)
  }

  const mutationEditObjectiveRequirement = useEditObjectiveRequirement()

  const handleTeamCapacityNeedsSave = async (objId) => {
    if (teamCapacityNeedsInputValue !== null) {
      const submitObject = {
        objId: objId,
        newRequiredFte: teamCapacityNeedsInputValue,
      }

      await mutationEditObjectiveRequirement.mutateAsync(
        { ...submitObject },
        {
          onSuccess: (response) => {
            setTeamCapacityNeedsInputValue(null)
          },
          onError: () => {
            openErrorModal()
          },
        },
      )
    }
  }

  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const [errorModalMessage, setErrorModalMessage] = React.useState(null)
  const openErrorModal = () => {
    setIsErrorModalOpen(true)
  }

  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
    setErrorModalMessage(null)
  }

  return (
    <>
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={closeErrorModal}
        errorMessage={errorModalMessage}
      />
      <div className="f2w-card shadow">
        <div className="f2w-bullet-container">
          <div className="f2w-card-bullet">
            <b>Objective</b>
            <span>{tradeoff.orginalStatement}</span>
          </div>
          <div className="f2w-card-bullet">
            <b>Team Leader</b>
            <span>{tradeoff.ownerName}</span>
          </div>

          <div className="f2w-card-bullet">
            <b>Required Capacity</b>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <div style={{ paddingRight: '2rem' }}>
                <input
                  style={{ width: '5vw' }}
                  className="fte-input"
                  defaultValue={tradeoff.requiredFte.toFixed(2)}
                  name="requiredFte"
                  type="number"
                  min="0"
                  onChange={(e) => {
                    setDisplaySaveButtonTeamCapacityNeeds(true)
                    handleTeamCapacityNeedsChange(parseFloat(e.target.value).toFixed(2))
                  }}
                />
              </div>
              {displaySaveButtonTeamCapacityNeeds === true && (
                <button
                  style={{
                    width: '5vw',
                    padding: '0.3rem 0',
                    fontSize: '14px',
                    border: 'none',
                    backgroundColor: 'var(--red)',
                    color: '#fff',
                    borderRadius: '2.5px',
                    cursor: 'pointer',
                    bottom: '4vh',
                  }}
                  onClick={(e) => {
                    handleTeamCapacityNeedsSave(tradeoff.orginalObjId)
                  }}
                  disabled={
                    parseFloat(teamCapacityNeedsInputValue) < parseFloat(tradeoff.allocatedFte)
                  }
                >
                  Save
                </button>
              )}
            </div>
            <div className="validation-error">{teamCapacityNeedsInputError}</div>
            {parseFloat(teamCapacityNeedsInputValue) < parseFloat(tradeoff.allocatedFte) && (
              <span className="save-warning-text">
                You cannot decrease the requirement to less than the allocation.
              </span>
            )}
            {parseFloat(teamCapacityNeedsInputValue) === parseFloat(tradeoff.allocatedFte) && (
              <span className="save-warning-text">
                This objective will no longer be considered over capacity.
              </span>
            )}
          </div>

          <div className="f2w-card-bullet">
            <b>Allocated Capacity</b>
            <span>{capacityCounter}</span>
          </div>

          <div className="f2w-card-bullet">
            <b>Flow-to-Work Pool</b>

            <select
              className="work-pool-select"
              defaultValue=""
              onChange={(e) => workPoolSelectChangeHandler(e)}
            >
              <option value="">Assign F2W Team Leader</option>
              {teamLeaders.map((leader, index) => {
                return (
                  <option
                    disabled={leader.remainingFte <= 0 ? true : false}
                    key={index}
                    value={leader.f2wTlId}
                  >
                    Leader: {leader.f2wTlName}, Capacity: {leader.remainingFte}
                  </option>
                )
              })}
            </select>
          </div>

          <div className="f2w-card-bullet" style={{ alignItems: 'center', width: '15rem' }}>
            <button
              onClick={(e) => btnClickHandler(e, tradeoff)}
              className={`f2w-action-assignment-btn${isDisabledBtn ? ' disabled-f2w-btn' : ''}`}
            >
              Action
            </button>

            <div>
              {!hasUnsavedChanges ? (
                <div></div>
              ) : (
                <div className="save-warning-text-container">
                  <span className="save-warning-text">
                    Unsaved changes. Click the button to save.
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default F2WCard
