import React, { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import './styles.css'
import { Fragment } from 'react'
import next from '../../../../assets/next-white.svg'
import team from '../../../../assets/team.svg'
import plus from '../../../../assets/plus.svg'
import useEditObjectiveRequirement from '../../../../api/mutation/editObjectiveRequirement'
import ErrorModal from '../../../../components/ErrorModal'
import AddOptionModal from './components/AddOptionModal'
import Modal from '../../../../components/Modal'

const TradeOffs = () => {}

export default TradeOffs
