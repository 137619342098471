import React from 'react'
// will be removed after first successful upload sourcemaps
// via github actions
// import {
//   Routes,
//   Route,
//   BrowserRouter,
//   useNavigationType,
//   createRoutesFromChildren,
//   matchRoutes,
//   Navigate
// } from 'react-router-dom';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import {
  governanceSubRoutes,
  routes,
  teamLeaderSubRoutes,
  areaLeaderSubRoutes,
  meetingViewSubRoutes,
  teamMeetingViewSubRoutes,
  meetingMultiViewSubRoutes,
  setupWizardSubRoutes,
  updateSubRoutes,
  demandIntakeSubRoutes,
  capacitySubRoutes,
  excoExerciseSubRoutes,
  kpiSubRoutes,
  myNotebookSubRoutes,
  changesReportSubRoutes,
} from './utils/routes'
import * as Sentry from '@sentry/react'
import SentryRouting from './interSentryRouteHack'

import TeamLeaderWizardPage from './pages/TeamLeader'
import Intro from './pages/TeamLeader/components/Intro'
import TeamStep from './pages/TeamLeader/components/TeamStep'
import ExternalMembers from './pages/TeamLeader/components/ExternalMembers'
import ObjectivesStep from './pages/TeamLeader/components/ObjectivesStep'
import ActivitiesStep from './pages/TeamLeader/components/ActivitiesStep'
import EnterpriseStep from './pages/TeamLeader/components/EnterpriseStep'
import CapacityStep from './pages/TeamLeader/components/CapacityStep'
import MeetingTopicsStep from './pages/TeamLeader/components/MeetingTopicsStep'
import ObjectivesTeamMapping from './pages/TeamLeader/components/ObjectivesTeamMapping'
import TeamCapacityAllocation from './pages/TeamLeader/components/TeamCapacityAllocation'
import AdditionalCapacity from './pages/TeamLeader/components/AdditionalCapacity'

import HomePage from './pages/Home'
import TempHome from './pages/TempHome'
import GovernancePage from './pages/Governance'
import MeetingViewPage from './pages/MeetingView'
import MeetingMultiViewPage from './pages/MeetingMultiView'
import TeamMeetingViewPage from './pages/TeamMeetingView'
import GovernanceStatus from './components/GovernanceStatus'
import GovernanceAreaLeaderStatus from './components/AreaLeaderGovernanceStatus'
import ManageCadences from './pages/Governance/components/ManageCadences'
import ManageLeaders from './pages/Governance/components/ManageLeaders'
import ManageObjectives from './pages/Governance/components/ManageObjectives'
import PeopleManagment from './pages/Governance/components/PeopleManagment'
import GTeamFlow from './pages/Governance/components/GTeamFlow'
import Leadership from './pages/Leadership'
import ManageInitiative from './pages/Governance/components/ManageInitiative'
import AccessConfig from './pages/Governance/components/AccessConfig'
import ClassifyTopics from './pages/Governance/components/Classification'
import LogMei from './pages/Governance/components/LogMei'
import Support from './pages/Governance/components/Support'
import MeiReports from './pages/Governance/components/MeiReports'
import AgedItemsRanking from './pages/AgedItemsRanking'
import CompanyInfo from './pages/Governance/components/CompanyInfo'

// Meeting View Pages
import DependencyResolution from './pages/MeetingView/components/DependencyResolution'
import TradeOffs from './pages/MeetingView/components/TradeOffs'
import F2WAssignment from './pages/MeetingView/components/F2WAssignment'
import MilestonesOverview from './pages/MeetingView/components/MilestonesOverview'
import OverAllocation from './pages/MeetingView/components/OverAllocation'
import TrendsAnalysis from './pages/MeetingView/components/TrendsAnalysis'

// Team Meeting View Pages
import TeamMvTradeOffs from './pages/TeamMeetingView/components/TradeOffs'
import TeamMvF2WAssignment from './pages/TeamMeetingView/components/F2WAssignment'
import TeamMvMilestonesOverview from './pages/TeamMeetingView/components/MilestonesOverview'
import TeamMvOverAllocation from './pages/TeamMeetingView/components/OverAllocation'
import TeamMvTakeaways from './pages/TeamMeetingView/components/Takeways'
import TeamMvTasks from './pages/TeamMeetingView/components/Tasks'
import TeamMvMeetingTopics from './pages/MeetingMultiView/components/MeetingTopics'
import TeamMvLeadershipDecisions from './pages/MeetingMultiView/components/DecisionLog'
import TeamMvMyDecisions from './pages/TeamMeetingView/components/MyDecisions'

// Meeting Multi View Pages
import MultiMvTradeOffs from './pages/MeetingMultiView/components/TradeOffs'
import MultiMvF2WAssignment from './pages/MeetingMultiView/components/F2WAssignment'
import MultiMvMilestonesOverview from './pages/MeetingMultiView/components/MilestonesOverview'
import MultiMvOverAllocation from './pages/MeetingMultiView/components/OverAllocation'
import MultiMvMeetingTopics from './pages/MeetingMultiView/components/MeetingTopics'
import MultiMvTrendsAnalysis from './pages/MeetingView/components/TrendsAnalysis'
import DecisionLog from './pages/MeetingMultiView/components/DecisionLog'
import MultiMvMyDecisions from './pages/TeamMeetingView/components/MyDecisions'
import MultiKpiReportByLeader from './pages/MeetingMultiView/components/MeetingHubKpiReport'
import MeetingSummary from './pages/MeetingMultiView/components/MeetingSummary'

// Setup Wizard Pages
import SetupWizardPage from './pages/SetupWizard'
import SetupWizardIntro from './pages/SetupWizard/components/Intro'
import EnterpriseLeaderStep from './pages/SetupWizard/components/EnterpriseLeaderStep'
import AreaLeaderStep from './pages/SetupWizard/components/AreaLeaderStep'
import TeamLeaderStep from './pages/SetupWizard/components/TeamLeaderStep'
import IndividualContStep from './pages/SetupWizard/components/IndividualContStep'
import ImpactMeasures from './pages/SetupWizard/components/ImpactMeasures'
import SetUpWizardEnd from './pages/SetupWizard/components/SetUpWizardEnd'
import CaseMapping from './pages/SetupWizard/components/CaseMapping'

//Demand Intake pages
import DemandIntakePage from './pages/DemandIntake'
import ReviewAssessObjective from './pages/DemandIntake/components/Intro'
import ChooseBauObjective from './pages/DemandIntake/components/ChooseBauObjective'
import F2wAvailability from './pages/DemandIntake/components/F2wAvailability'
import DemandIntakeObjectivesTeamMapping from './pages/DemandIntake/components/ObjectivesTeamMapping'
import DemandIntakeTeamCapacityAllocation from './pages/DemandIntake/components/TeamCapacityAllocation'
import DemandIntakeAdditionalCapacity from './pages/DemandIntake/components/AdditionalCapacity'

//Capacity pages
import CapacityPage from './pages/Capacity'
import CapacityF2wAvailability from './pages/Capacity/components/F2wAvailability'
import CapacityObjectivesTeamMapping from './pages/Capacity/components/ObjectivesTeamMapping'
import CapacityTeamCapacityAllocation from './pages/Capacity/components/TeamCapacityAllocation'
import CapacityAdditionalCapacity from './pages/Capacity/components/AdditionalCapacity'

// NEW PAGES
import GovernanceConfigStep from './pages/SetupWizard/components/GovernanceConfigStep'
import TopLevelObjectiveStep from './pages/SetupWizard/components/TopLevelObjectiveStep'
import AreaLevelObjectiveStep from './pages/SetupWizard/components/AreaLevelObjectiveStep'
import EnterpriseMetrics from './pages/Governance/components/EnterpriseMetrics'

// Update Wizard pages
import Update from './pages/Update'
import UpdateIntro from './pages/Update/components/Intro'
import UpdateActivities from './pages/Update/components/Activities'
import UpdateObjectivesTeamMapping from './pages/Update/components/ObjectivesTeamMapping'
import UpdateTeamCapacityAllocation from './pages/Update/components/TeamCapacityAllocation'
import UpdateAdditionalCapacity from './pages/Update/components/AdditionalCapacity'
import UpdateBlockers from './pages/Update/components/Blockers'
import MeetingTopics from './pages/Update/components/MeetingTopics'
import UpdateAllMyActions from './pages/Update/components/AllMyActions'
import KPIUpdatePage from './pages/Update/components/KPI/index'

//Area Level Flow
import AreaLevelFlow from './pages/AreaLevelFlow'

// import EnterpriseLeaderStep from './pages/SetupWizard/components/EnterpriseLeaderStep'
import OrganizationStrategy from './pages/OrganizationStrategy'
import ObjectiveMap from './pages/objectiveFlow'
// Login-related pages
import Landing from './pages/Landing'
import CLogin from './pages/CLogin'

import KanbanView from './pages/kanban'
import CreateCustomTemplate from './pages/createTemplate/index'
import SpreadsheetsView from './pages/spreadsheets'
import ImportSpreadSheet from './pages/spreadsheets/importSpreadSheet'

//Area Leader Wizard
import AreaLeaderWizardPage from './pages/AreaLeader'
import IntroAreaLeader from './pages/AreaLeader/components/Intro'
import ObjectivesStepAreaLeader from './pages/AreaLeader/components/ObjectivesStep'
import EnterpriseStepAreaLeader from './pages/AreaLeader/components/EnterpriseStep'
import TypeStepAreaLeader from './pages/AreaLeader/components/TypeStep'
import MeetingTopicsStepAreaLeader from './pages/AreaLeader/components/MeetingTopicsStep'

import MeetingDeck from './pages/MeetingMultiView/components/PopupPage'
import PresentationDeck from './pages/MeetingMultiView/components/PopupPage/presentation'

import { getCompanyConfigSettings } from './utils/auth'
import ObjectivesTab from './pages/ObjectivesTab'
import EmailReminderTab from './pages/EmailReminderTab'

import SankeyPageOnly from './pages/SankeyPageOnly'

import DecisionLogDigest from './pages/EmailDigests/DecisionLog'

import CxoMgmtScore from './pages/CxoMgmtScore'
import CxoMgmtScoreL2Beta from './pages/CxoMgmtScoreL2Beta'
import SearchTest from './pages/SearchTest'
import PrintMode from './pages/PrintMode'
import CompanyConfigOptions from './pages/Governance/components/CompanyConfigOptions'
import Changelog from './pages/Changelog'
import OdinInbox from './pages/OdinInbox'

import GovernanceRouteGuard from './route-guards/governanceRouteGuard'

import { KpiReportByLeaderWithContext } from './pages/KpiReportByLeader'

//Notifications
import Notifications from './pages/Notifications'

//AI Exercise
import ExcoExerciseWizardPage from './pages/ExcoExercise'
import AIExerciseIntro from './pages/ExcoExercise/components/Intro'
import AIExerciseQuestion1 from './pages/ExcoExercise/components/Question1'
import AIExerciseQuestion2 from './pages/ExcoExercise/components/Question2'
import AIExerciseQuestion3 from './pages/ExcoExercise/components/Question3'
import AIExerciseResult from './pages/ExcoExercise/components/Result'

import WorkshopAspirations from './pages/ExcoExercise/components/WorkshopAspirations'
import WorkshopFinalAspirations from './pages/ExcoExercise/components/WorkshopFinalAspirations'
import WorkshopAdminAspirationsView from './pages/ExcoExercise/components/WorkshopAdminAspirationsView'
import InternalSupport from './pages/Governance/components/InternalSupport'

//KPI Wizard
import KPIWizardPage from './pages/KPI'
import KPIIntro from './pages/KPI/components/Intro'
import KPITeamOverview from './pages/KPI/components/TeamOverview'
import KPIListOfObjectives from './pages/KPI/components/ListOfObjectives'
import KPITimeframeQuestion from './pages/KPI/components/TimeframeQuestion'
import KPILongTermQuestion from './pages/KPI/components/LongTermQuestion'
import KPIShortTermQuestion from './pages/KPI/components/ShortTermQuestion'
import KPISuggestedKPIs from './pages/KPI/components/SuggestedKPIs'
import CompanySetupAiAgent from './pages/CompanySetupAiAgent'
import TeamLeaderSetupAgent from './pages/CompanySetupAiAgent/TeamLeaderSetupAgent'
import CompanySetupIntroScreen from './components/CompanySetupIntroScreen'
import MyNotebookNotes from './pages/MyNotebook/Notes'
import MyNotebookPage from './pages/MyNotebook'
import MyNotebookTasks from './pages/MyNotebook/Tasks'

import ChangesReportPage from './pages/ChangesReport'
import ChangesReportActions from './pages/ChangesReport/Actions'
import ChangesReportMeetingTopics from './pages/ChangesReport/MeetingTopics'
import ChangesReportKPIs from './pages/ChangesReport/KPIs'

// Sentry.init({
//   dsn: 'https://a7b0b68e936b2d638a728293f0886217@o4507267061841920.ingest.us.sentry.io/4507267095134208',
//   integrations: [
//     Sentry.reactRouterV6BrowserTracingIntegration({
//       useEffect: React.useEffect,
//       useLocation,
//       useNavigationType,
//       createRoutesFromChildren,
//       matchRoutes,
//     }),
//   ],
//   tracesSampleRate: 1.0,
// });

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const Routing = () => {
  return (
    <BrowserRouter>
      <SentryRouting />
      <SentryRoutes>
        <Route exact path={routes.teamleader} element={<TeamLeaderWizardPage />}>
          <Route path={teamLeaderSubRoutes.intro} element={<Intro />} />
          <Route path={teamLeaderSubRoutes.team} element={<TeamStep />} />
          <Route path={teamLeaderSubRoutes.objectives} element={<ObjectivesStep />} />
          <Route path={teamLeaderSubRoutes.activities} element={<ActivitiesStep />} />
          <Route path={teamLeaderSubRoutes.enterprise} element={<EnterpriseStep />} />
          <Route path={teamLeaderSubRoutes.capacity} element={<CapacityStep />} />
          <Route path={teamLeaderSubRoutes.externalMembers} element={<ExternalMembers />} />
          <Route path={teamLeaderSubRoutes.meetingTopics} element={<MeetingTopicsStep />} />

          <Route
            path={teamLeaderSubRoutes.objectivesTeamMapping}
            element={<ObjectivesTeamMapping />}
          />
          <Route
            path={teamLeaderSubRoutes.teamCapacityAllocation}
            element={<TeamCapacityAllocation />}
          />
          <Route path={teamLeaderSubRoutes.additionalCapacity} element={<AdditionalCapacity />} />

          <Route index element={<Navigate to={teamLeaderSubRoutes.intro} />} />
        </Route>

        <Route exact path={routes.areaLeader} element={<AreaLeaderWizardPage />}>
          <Route path={areaLeaderSubRoutes.intro} element={<IntroAreaLeader />} />
          <Route path={areaLeaderSubRoutes.objectives} element={<ObjectivesStepAreaLeader />} />
          <Route path={areaLeaderSubRoutes.type} element={<TypeStepAreaLeader />} />
          <Route path={areaLeaderSubRoutes.linkage} element={<EnterpriseStepAreaLeader />} />
          <Route
            path={areaLeaderSubRoutes.meetingTopics}
            element={<MeetingTopicsStepAreaLeader />}
          />
          <Route index element={<Navigate to={areaLeaderSubRoutes.intro} />} />
        </Route>

        <Route
          exact
          path={routes.governance}
          element={<GovernanceRouteGuard WrappedComponent={GovernancePage} />}
        >
          <Route path={governanceSubRoutes.manageTeamLeader} element={<ManageLeaders />} />
          <Route path={governanceSubRoutes.manageObjectives} element={<ManageObjectives />} />
          <Route path={governanceSubRoutes.manageCadences} element={<ManageCadences />} />
          <Route path={governanceSubRoutes.peopleManagment} element={<PeopleManagment />} />
          <Route path={governanceSubRoutes.manageTeamConfiguration} element={<GTeamFlow />} />
          <Route path={governanceSubRoutes.initiativeMangement} element={<ManageInitiative />} />
          <Route path={governanceSubRoutes.classifyMeetingTopics} element={<ClassifyTopics />} />
          <Route path={governanceSubRoutes.logMei} element={<LogMei />} />
          <Route path={governanceSubRoutes.support} element={<Support />} />
          <Route path={governanceSubRoutes.seeOthersProfile} element={<InternalSupport />} />
          <Route path={governanceSubRoutes.companyConfig} element={<CompanyConfigOptions />} />
          <Route path={governanceSubRoutes.meiReports} element={<MeiReports />} />
          <Route path={governanceSubRoutes.companyInfo} element={<CompanyInfo />} />
          <Route
            path={governanceSubRoutes.governanceStatus}
            element={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '2rem',
                  marginBottom: '2rem',
                }}
              >
                <GovernanceStatus location={'Governance'} />
                <GovernanceAreaLeaderStatus location={'Governance'} />
              </div>
            }
          />
          <Route path={governanceSubRoutes.enterpriseMetrics} element={<EnterpriseMetrics />} />
          <Route path={governanceSubRoutes.accessConfig} element={<AccessConfig />} />
          <Route index element={<ManageLeaders />} />
        </Route>
        <Route exact path={routes.setupwizard} element={<SetupWizardPage />}>
          <Route path={setupWizardSubRoutes.intro} element={<SetupWizardIntro />} />
          <Route path={setupWizardSubRoutes.enterpriseLeaders} element={<EnterpriseLeaderStep />} />
          <Route path={setupWizardSubRoutes.areaLeaders} element={<AreaLeaderStep />} />
          <Route path={setupWizardSubRoutes.teamLeaders} element={<TeamLeaderStep />} />
          <Route path={setupWizardSubRoutes.caseMapping} element={<CaseMapping />} />
          <Route
            path={setupWizardSubRoutes.individualContributor}
            element={<IndividualContStep />}
          />
          <Route path={setupWizardSubRoutes.governanceConfig} element={<GovernanceConfigStep />} />
          <Route
            path={setupWizardSubRoutes.topLevelObjectives}
            element={<TopLevelObjectiveStep />}
          />
          <Route
            path={setupWizardSubRoutes.areaLevelObjectives}
            element={<AreaLevelObjectiveStep />}
          />
          <Route path={setupWizardSubRoutes.impactMeasures} element={<ImpactMeasures />} />
          <Route index element={<Navigate to={setupWizardSubRoutes.intro} />} />
        </Route>

        <Route exact path={routes.meetingview} element={<MeetingViewPage />}>
          <Route path={meetingViewSubRoutes.tradeOffs} element={<TradeOffs />} />
          <Route
            path={meetingViewSubRoutes.dependencyResolution}
            element={<DependencyResolution />}
          />
          <Route path={meetingViewSubRoutes.F2WAssignment} element={<F2WAssignment />} />
          <Route path={meetingViewSubRoutes.overview} element={<MilestonesOverview />} />
          <Route path={meetingViewSubRoutes.overAllocation} element={<OverAllocation />} />
          <Route path={meetingViewSubRoutes.trendsAnalysis} element={<TrendsAnalysis />} />
        </Route>

        <Route exact path={routes.meetingMultiView} element={<MeetingMultiViewPage />}>
          <Route path={meetingMultiViewSubRoutes.tradeOffs} element={<MultiMvTradeOffs />} />
          <Route
            path={meetingMultiViewSubRoutes.F2WAssignment}
            element={<MultiMvF2WAssignment />}
          />
          <Route
            path={meetingMultiViewSubRoutes.overview}
            element={<MultiMvMilestonesOverview />}
          />
          <Route
            path={meetingMultiViewSubRoutes.overAllocation}
            element={<MultiMvOverAllocation />}
          />
          <Route
            path={meetingMultiViewSubRoutes.trendsAnalysis}
            element={<MultiMvTrendsAnalysis />}
          />
          <Route path={meetingMultiViewSubRoutes.takeaways} element={<TeamMvTakeaways />} />
          <Route
            path={meetingMultiViewSubRoutes.meetingTopics}
            element={<MultiMvMeetingTopics />}
          />
          <Route
            path={meetingMultiViewSubRoutes.decisionLog}
            element={<DecisionLog isTeamMeetingView={false} />}
          />
          <Route path={meetingMultiViewSubRoutes.myDecisions} element={<MultiMvMyDecisions />} />
          <Route
            path={meetingMultiViewSubRoutes.kpiReportByLeader}
            element={<MultiKpiReportByLeader />}
          />
          <Route path={meetingMultiViewSubRoutes.meetingSummaries} element={<MeetingSummary />} />
          <Route index element={<MultiMvMeetingTopics />} />
        </Route>

        <Route exact path={routes.teamMeetingview} element={<TeamMeetingViewPage />}>
          <Route path={teamMeetingViewSubRoutes.tradeOffs} element={<TeamMvTradeOffs />} />
          <Route path={teamMeetingViewSubRoutes.F2WAssignment} element={<TeamMvF2WAssignment />} />
          <Route path={teamMeetingViewSubRoutes.overview} element={<TeamMvMilestonesOverview />} />
          <Route
            path={teamMeetingViewSubRoutes.overAllocation}
            element={<TeamMvOverAllocation />}
          />
          <Route path={teamMeetingViewSubRoutes.takeaways} element={<TeamMvTakeaways />} />
          <Route path={teamMeetingViewSubRoutes.tasks} element={<TeamMvTasks />} />
          <Route path={teamMeetingViewSubRoutes.meetingTopics} element={<TeamMvMeetingTopics />} />
          <Route
            path={teamMeetingViewSubRoutes.leadershipDecisions}
            element={<TeamMvLeadershipDecisions isTeamMeetingView={true} />}
          />
          <Route path={teamMeetingViewSubRoutes.myDecisions} element={<TeamMvMyDecisions />} />
          <Route
            path={teamMeetingViewSubRoutes.kpiReportByLeader}
            element={<MultiKpiReportByLeader />}
          />
          <Route path={teamMeetingViewSubRoutes.meetingSummaries} element={<MeetingSummary />} />
          <Route index element={<TeamMvMeetingTopics />} />
        </Route>

        <Route exact path={routes.update} element={<Update />}>
          <Route path={updateSubRoutes.intro} element={<UpdateIntro />} />
          <Route path={updateSubRoutes.activities} element={<UpdateActivities />} />
          <Route
            path={updateSubRoutes.objectivesTeamMapping}
            element={<UpdateObjectivesTeamMapping />}
          />
          <Route
            path={updateSubRoutes.teamCapacityAllocation}
            element={<UpdateTeamCapacityAllocation />}
          />
          <Route path={updateSubRoutes.additionalCapacity} element={<UpdateAdditionalCapacity />} />
          <Route path={updateSubRoutes.blockers} element={<UpdateBlockers />} />
          <Route path={updateSubRoutes.meetingTopics} element={<MeetingTopics />} />
          <Route path={updateSubRoutes.allMyActions} element={<UpdateAllMyActions />} />
          <Route path={updateSubRoutes.overdueKPIs} element={<KPIUpdatePage />} />

          <Route index element={<Navigate to={updateSubRoutes.intro} />} />
        </Route>

        <Route exact path={routes.kpi} element={<KPIWizardPage />}>
          <Route path={kpiSubRoutes.intro} element={<KPIIntro />} />
          <Route path={kpiSubRoutes.teamOverview} element={<KPITeamOverview />} />
          <Route path={kpiSubRoutes.listOfObjectives} element={<KPIListOfObjectives />} />
          <Route path={kpiSubRoutes.timeframe} element={<KPITimeframeQuestion />} />
          <Route path={kpiSubRoutes.longtermQuestion} element={<KPILongTermQuestion />} />
          <Route path={kpiSubRoutes.shorttermQuestion} element={<KPIShortTermQuestion />} />
          <Route path={kpiSubRoutes.suggestedKpis} element={<KPISuggestedKPIs />} />

          <Route index element={<Navigate to={kpiSubRoutes.intro} />} />
        </Route>
        <Route exact path={routes.leadership} element={<Leadership />} />
        <Route exact path={routes.setupcomplete} element={<SetUpWizardEnd />} />
        <Route exact path="/DecisionLogDigest" element={<DecisionLogDigest />} />
        <Route exact path={routes.objectivemap} element={<ObjectiveMap />} />
        <Route exact path={routes.organizationstrategy} element={<OrganizationStrategy />} />
        <Route exact path={routes.sankey} element={<SankeyPageOnly />} />
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/Home" element={<TempHome />} />
        <Route exact path="/CLogin" element={<CLogin />} />
        <Route exact path="/AreaLevelFlow" element={<AreaLevelFlow />} />
        <Route exact path={routes.cxomgmtscore} element={<CxoMgmtScore />} />
        <Route exact path={routes.cxomgmtscoreL2Beta} element={<CxoMgmtScoreL2Beta />} />
        <Route exact path={routes.searchTest} element={<SearchTest />} />
        <Route exact path={routes.kpiReportByLeader} element={<KpiReportByLeaderWithContext />} />
        <Route exact path={routes.agedItemsRanking} element={<AgedItemsRanking />} />

        <Route exact path={routes.demandintake} element={<DemandIntakePage />}>
          <Route path={demandIntakeSubRoutes.intro} element={<ReviewAssessObjective />} />
          <Route path={demandIntakeSubRoutes.chooseBauObjective} element={<ChooseBauObjective />} />
          <Route path={demandIntakeSubRoutes.f2wAvailability} element={<F2wAvailability />} />
          <Route
            path={demandIntakeSubRoutes.objectivesTeamMapping}
            element={<DemandIntakeObjectivesTeamMapping />}
          />
          <Route
            path={demandIntakeSubRoutes.teamCapacityAllocation}
            element={<DemandIntakeTeamCapacityAllocation />}
          />
          <Route
            path={demandIntakeSubRoutes.additionalCapacity}
            element={<DemandIntakeAdditionalCapacity />}
          />
        </Route>

        <Route exact path={routes.capacity} element={<CapacityPage />}>
          <Route path={capacitySubRoutes.f2wAvailability} element={<CapacityF2wAvailability />} />
          <Route
            path={capacitySubRoutes.objectivesTeamMapping}
            element={<CapacityObjectivesTeamMapping />}
          />
          <Route
            path={capacitySubRoutes.teamCapacityAllocation}
            element={<CapacityTeamCapacityAllocation />}
          />
          <Route
            path={capacitySubRoutes.additionalCapacity}
            element={<CapacityAdditionalCapacity />}
          />
        </Route>

        <Route exact path={routes.excoExercise} element={<ExcoExerciseWizardPage />}>
          <Route path={excoExerciseSubRoutes.intro} element={<AIExerciseIntro />} />
          <Route path={excoExerciseSubRoutes.question1} element={<AIExerciseQuestion1 />} />
          <Route path={excoExerciseSubRoutes.question2} element={<AIExerciseQuestion2 />} />
          <Route path={excoExerciseSubRoutes.question3} element={<AIExerciseQuestion3 />} />
          <Route path={excoExerciseSubRoutes.results} element={<AIExerciseResult />} />
          <Route index element={<Navigate to={areaLeaderSubRoutes.intro} />} />
        </Route>

        <Route exact path={routes.myNotebook} element={<MyNotebookPage />}>
          <Route path={myNotebookSubRoutes.notes} element={<MyNotebookNotes />} />
          <Route path={myNotebookSubRoutes.tasks} element={<MyNotebookTasks />} />
          <Route index element={<Navigate to={myNotebookSubRoutes.tasks} />} />
        </Route>

        <Route exact path={routes.changesReport} element={<ChangesReportPage />}>
          <Route path={changesReportSubRoutes.actions} element={<ChangesReportActions />} />
          <Route
            path={changesReportSubRoutes.meetingTopics}
            element={<ChangesReportMeetingTopics />}
          />
          <Route
            path={changesReportSubRoutes.meetingTopics}
            element={<ChangesReportMeetingTopics />}
          />
          <Route path={changesReportSubRoutes.kpis} element={<ChangesReportKPIs />} />
          <Route index element={<Navigate to={changesReportSubRoutes.actions} />} />
        </Route>

        <Route exact path={routes.workshopAspirations} element={<WorkshopAspirations />} />
        <Route
          exact
          path={routes.workshopFinalAspirations}
          element={<WorkshopFinalAspirations />}
        />
        <Route
          exact
          path={routes.workshopAdminAspirationsView}
          element={<WorkshopAdminAspirationsView />}
        />

        <Route exact path={routes.kanban} element={<KanbanView />} />
        <Route exact path={routes.objectives} element={<ObjectivesTab />} />
        <Route exact path={routes.emailReminder} element={<EmailReminderTab />} />
        <Route exact path={routes.printMode} element={<PrintMode />} />
        <Route exact path="/createCustomTemplate" element={<CreateCustomTemplate />} />
        <Route exact path={routes.changeLog} element={<Changelog />} />
        <Route exact path={routes.odinInbox} element={<OdinInbox />} />

        <Route exact path="/spreadsheets" element={<SpreadsheetsView />} />
        <Route exact path="/import-spreadsheets" element={<ImportSpreadSheet />} />

        <Route path={routes.meetingDeck} element={<MeetingDeck />} />
        <Route path={governanceSubRoutes.manageTeamConfiguration} element={<GTeamFlow />} />
        <Route exact path={routes.notifications} element={<Notifications />} />

        <Route path={routes.presentation} element={<PresentationDeck />} />

        <Route exact path={routes.companySetUpAgent} element={<CompanySetupAiAgent />} />
        <Route exact path={routes.teamLeaderSetupAgent} element={<TeamLeaderSetupAgent />} />
        <Route exact path={routes.setupIntroScreen} element={<CompanySetupIntroScreen />} />

        {/* Default path for missing pages or incorrect URL */}
        <Route path="*" element={<TempHome />} />
      </SentryRoutes>
    </BrowserRouter>
  )
}

export default Routing
