import React from 'react'
import PercentageRender from './percentageRender'
import './styles.css'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList,
  ComposedChart,
} from 'recharts'

const ScoreCard = ({ scoreCardData, selectedCardIndexHook }) => {
  scoreCardData.stratWorkObjectives = scoreCardData.stratWorkObjectives || []
  scoreCardData.stratWorkMeasures = scoreCardData.stratWorkMeasures || []
  const [strategyBreakdown, setStrategyBreakdown] = React.useState([])
  const [workObjectives, setWorkObjectives] = React.useState(scoreCardData.stratWorkObjectives)
  const [strategicMeasures, setStrategicMeasures] = React.useState(scoreCardData.stratWorkMeasures)

  const roundTo100 = (objArray) => {
    let truncStratSum = 0
    for (let obj of objArray) {
      truncStratSum += obj.realPercent
    }
    let remainder = 100 - truncStratSum
    for (let obj of objArray) {
      if (remainder === 0) break
      if (obj.realPercent == 0) continue
      obj.realPercent += 1
      remainder--
    }
  }

  React.useEffect(() => {
    // Percentages for type of work.
    const tempBreakdown = [
      {
        measureName: 'Enterprise strategic priorities',
        fte: scoreCardData.strategicWorkFte,
      },
      {
        measureName: 'Area strategic priorities',
        fte: scoreCardData.nonStrategicWorkFte,
      },
      {
        measureName: 'Day-To-Day Operations',
        fte: scoreCardData.dayToDayWorkFte,
      },
    ]
    const tempWorkObj = [...scoreCardData.stratWorkObjectives]
    const tempMeasures = [...scoreCardData.stratWorkMeasures]

    let stratSum = 0
    for (let strat of tempBreakdown) {
      if (strat.fte === undefined) strat.fte = 0
      stratSum += strat.fte
    }
    for (let strat of tempBreakdown) {
      if (stratSum > 0) strat.realPercent = Math.trunc((strat.fte / stratSum) * 100, 0)
      else strat.realPercent = 0
    }

    if (stratSum > 0) roundTo100(tempBreakdown)

    // Percentages for objectives.
    let topObjSum = 0
    for (let obj of tempWorkObj) {
      if (obj.fte === undefined) obj.fte = 0
      topObjSum += obj.fte
    }

    for (let obj of tempWorkObj) {
      if (topObjSum > 0) {
        obj.realPercent = Math.trunc((obj.fte / topObjSum) * 100, 0)
      } else obj.realPercent = 0
    }

    if (topObjSum > 0) roundTo100(tempWorkObj)

    // Percentages for measures.
    let measureSum = 0
    for (let measure of tempMeasures) {
      measureSum += measure.fte
    }
    for (let measure of tempMeasures) {
      if (measureSum > 0) measure.realPercent = Math.trunc((measure.fte / measureSum) * 100, 0)
      else measure.realPercent = 0
    }
    if (measureSum > 0) roundTo100(tempMeasures)

    setStrategyBreakdown(tempBreakdown)
    setWorkObjectives(tempWorkObj)
    setStrategicMeasures(tempMeasures)
  }, [scoreCardData])

  const renderCustomizedLabel = (props) => {
    const { content, ...rest } = props

    return <Label {...rest} fontSize="12" fontWeight="Bold" fill={'black'} />
  }

  ///////// strategyBreakdown set chart
  const [workType, setWorkType] = React.useState(null)

  React.useEffect(() => {
    if (selectedCardIndexHook === 0) {
      let current = { name: 'Current' }
      let acc = 0
      for (let str of strategyBreakdown) {
        acc += str.realPercent
        if (str.realPercent > 0) {
          if (str.measureName === 'Enterprise strategic priorities') {
            current['Enterprise'] = str.realPercent
          } else if (str.measureName === 'Area strategic priorities') {
            current['Area'] = str.realPercent
          } else if (str.measureName === 'Day-To-Day Operations') {
            current['BAU'] = str.realPercent
          }
        }
      }
      if (acc !== 0) {
        setWorkType([current])
      } else {
        setWorkType(null)
      }
    }

    if (selectedCardIndexHook > 0) {
      let acc = 0
      let curr = workType[0]
      let projected = { name: 'Projected' }
      for (let str of strategyBreakdown) {
        acc += str.realPercent
        if (str.realPercent > 0) {
          if (str.measureName === 'Enterprise strategic priorities') {
            projected['Enterprise'] = str.realPercent
          } else if (str.measureName === 'Area strategic priorities') {
            projected['Area'] = str.realPercent
          } else if (str.measureName === 'Day-To-Day Operations') {
            projected['BAU'] = str.realPercent
          }
        }
      }
      if (acc !== 0) {
        setWorkType([curr, projected])
      } else {
        setWorkType([curr])
      }
    }
  }, [strategyBreakdown])

  ///////// workObjectives set chart
  const [strategicObj, setStrategicObj] = React.useState(null)
  React.useEffect(() => {
    if (selectedCardIndexHook === 0) {
      let current = { name: 'Current' }
      let acc = 0
      for (let obj of workObjectives) {
        acc += obj.realPercent
        if (obj.realPercent > 0) {
          current[obj.statement] = obj.realPercent
        }
      }
      if (acc !== 0) {
        setStrategicObj([current])
      } else {
        setStrategicObj(null)
      }
    }
    if (selectedCardIndexHook > 0) {
      let curr = {}
      // if (workObjectives.length > 0) {
      //   curr = workObjectives[0]
      // }
      if (strategicObj.length > 0) {
        curr = strategicObj[0]
      }
      let projected = { name: 'Projected' }
      let acc = 0
      for (let obj of workObjectives) {
        acc += obj.realPercent
        if (obj.realPercent > 0) {
          projected[obj.statement] = obj.realPercent
        }
      }
      if (acc !== 0) {
        setStrategicObj([curr, projected])
      } else {
        setStrategicObj([curr])
      }
    }
  }, [workObjectives])

  ///////// strategicMeasures set chart
  const [strategicMsrs, setStrategicMsrs] = React.useState(null)
  React.useEffect(() => {
    if (selectedCardIndexHook === 0) {
      let current = { name: 'Current' }
      let acc = 0
      for (let measure of strategicMeasures) {
        acc += measure.realPercent
        if (measure.realPercent > 0) {
          current[measure.measureName] = measure.realPercent
        }
      }
      if (acc !== 0) {
        setStrategicMsrs([current])
      } else {
        setStrategicMsrs(null)
      }
    }
    if (selectedCardIndexHook > 0) {
      let curr = {}
      if (strategicMsrs.length > 0) {
        curr = strategicMsrs[0]
      }
      let projected = { name: 'Projected' }
      let acc = 0
      for (let measure of strategicMeasures) {
        acc += measure.realPercent
        if (measure.realPercent > 0) {
          projected[measure.measureName] = measure.realPercent
        }
      }
      if (acc !== 0) {
        setStrategicMsrs([curr, projected])
      } else {
        setStrategicMsrs([curr])
      }
    }
  }, [strategicMeasures])

  //Note: Since workObjectives & strategicMeasures have an infinite amount of objectives
  //they can display this array is being used under a circular array logic to repeat colors in
  //case the list of objectives is greater than this list of colors
  let colorArray = ['#D85959', '#fe7f2d', '#fcca46', '#E38888', '#FEA56C', '#FFF7BB']

  return (
    <div className="scorecard-main-container">
      <div className="scorecard-container shadow">
        <h3 className="scorecard-title">Live Impact Scorecard</h3>

        <h3 style={{ fontSize: '14px', width: 'fit-content', paddingbottom: '0.5rem' }}>
          Work by type
        </h3>

        {workType ? (
          <>
            <ResponsiveContainer height={150} width="100%">
              <ComposedChart layout="vertical" data={workType} margin={{ left: 5, right: 10 }}>
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  align="left"
                  formatter={(value, entry, index) => (
                    <span style={{ color: 'black', fontSize: '0.8rem' }}>{value}</span>
                  )}
                />
                <XAxis
                  hide
                  axisLine={false}
                  tickLine={false}
                  ticks={[0, 100]}
                  tickFormatter={(tick) => `${tick}%`}
                  type="number"
                  domain={[0, 100]}
                />
                <YAxis
                  type="category"
                  dataKey="name"
                  stroke="#44444"
                  style={{
                    fontSize: '0.8rem',
                  }}
                />
                <Tooltip />
                <Bar barSize={50} dataKey="Enterprise" stackId="a" fill="#D85959">
                  <LabelList
                    dataKey="Enterprise"
                    position="center"
                    content={renderCustomizedLabel}
                  />
                </Bar>

                <Bar barSize={50} dataKey="Area" stackId="a" fill="#fe7f2d">
                  <LabelList dataKey="Area" position="center" content={renderCustomizedLabel} />
                </Bar>
                <Bar barSize={50} dataKey="BAU" stackId="a" fill="#fcca46">
                  <LabelList dataKey="BAU" position="center" content={renderCustomizedLabel} />
                </Bar>
              </ComposedChart>
            </ResponsiveContainer>
          </>
        ) : (
          <span> There are currently no objectives </span>
        )}

        <div className="divider"></div>

        <h3 style={{ fontSize: '14px', width: 'fit-content', paddingbottom: '0.5rem' }}>
          Work by strategic objective
        </h3>

        {strategicObj ? (
          <>
            <ResponsiveContainer height={230} width={'100%'}>
              <ComposedChart layout="vertical" data={strategicObj} margin={{ left: 5, right: 10 }}>
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  align="left"
                  formatter={(value, entry, index) => (
                    <span style={{ color: 'black', fontSize: '0.8rem' }}>{value}</span>
                  )}
                />
                <XAxis
                  hide
                  axisLine={false}
                  tickLine={false}
                  ticks={[0, 100]}
                  tickFormatter={(tick) => `${tick}%`}
                  type="number"
                  domain={[0, 100]}
                />
                <YAxis
                  type="category"
                  dataKey="name"
                  stroke="#44444"
                  style={{
                    fontSize: '0.8rem',
                  }}
                />
                <Tooltip />
                {workObjectives.map((obj, objIndex) => (
                  <Bar
                    barSize={50}
                    dataKey={obj.statement}
                    stackId="a"
                    fill={
                      colorArray[
                        ((objIndex % colorArray.length) + colorArray.length) % colorArray.length
                      ]
                    }
                  >
                    <LabelList
                      dataKey={obj.statement}
                      position="center"
                      content={renderCustomizedLabel}
                    />
                  </Bar>
                ))}
              </ComposedChart>
            </ResponsiveContainer>
          </>
        ) : (
          <span>
            {' '}
            There is no strategic work at this time. The chart will show as strategic work gets
            populated.
          </span>
        )}

        <div className="divider"></div>

        <h3 style={{ fontSize: '14px', width: 'fit-content', paddingbottom: '0.5rem' }}>
          Work by impact measure
        </h3>
        {strategicMsrs ? (
          <>
            <ResponsiveContainer height={180} width={'100%'}>
              <ComposedChart layout="vertical" data={strategicMsrs} margin={{ left: 5, right: 10 }}>
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  align="left"
                  formatter={(value, entry, index) => (
                    <span style={{ color: 'black', fontSize: '0.8rem' }}>{value}</span>
                  )}
                />
                <XAxis
                  hide
                  axisLine={false}
                  tickLine={false}
                  ticks={[0, 100]}
                  tickFormatter={(tick) => `${tick}%`}
                  type="number"
                  domain={[0, 100]}
                />
                <YAxis
                  type="category"
                  dataKey="name"
                  stroke="#44444"
                  style={{
                    fontSize: '0.8rem',
                  }}
                />
                <Tooltip />
                {strategicMeasures.map((measure, measureIndex) => (
                  <Bar
                    barSize={50}
                    dataKey={measure.measureName}
                    stackId="a"
                    fill={
                      colorArray[
                        ((measureIndex % colorArray.length) + colorArray.length) % colorArray.length
                      ]
                    }
                  >
                    <LabelList
                      dataKey={measure.measureName}
                      position="center"
                      content={renderCustomizedLabel}
                    />
                  </Bar>
                ))}
              </ComposedChart>
            </ResponsiveContainer>
          </>
        ) : (
          <span>
            {' '}
            So far, all work is BAU and not associated with an impact measure. The chart will show
            as non-BAU work is populated.
          </span>
        )}
      </div>
    </div>
  )
}

export default ScoreCard
