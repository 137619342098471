import React, { useState } from 'react'
import '../../../MeetingView/components/OverAllocation/styles.css'
import useOverAllocatedEmployeeDataForArea from '../../../../api/query/useOverAllocatedEmployeeDataForArea'
import useOverAllocatedEmployeeDataForNonArea from '../../../../api/query/useOverAllocatedEmployeeDataForNonArea'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components'
import edit from '../../../../assets/edit.svg'
import EditOverAllocationModal from './components/EditOverAllocationModal'
import InPageLoader from '../../../../components/InPageLoader'

const Grid = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 0.3fr 0.4fr 1.5fr;
  row-gap: 0.5em;
  padding-top: 1rem;
  width: 100%;
`

const GridTwoColumns = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 0.3fr 1.5fr;
  row-gap: 0.5em;
  padding-top: 1rem;
  width: 100%;
`
const GridHeader = styled.div`
  font-weight: bold;
  justify-content: center;
  padding: 0.2rem 0.5rem;
  border-bottom: 1px solid rgb(238, 238, 238);
`

const GridContent = styled.div`
  justify-content: center;
  padding: 0.2rem 0.5rem;
  border-bottom: 2px solid rgb(238, 238, 238);
`

const OverAllocation = () => {
  const { meetingData, isLoading } = useOutletContext()
  const [isEditOverAllocationModalOpen, setIsEditOverAllocationModalOpen] = React.useState(false)
  const [icOtherManagerMappings, setIcOtherManagerMappings] = React.useState(null)
  const [teamAlloc, setTeamAlloc] = React.useState(null)
  const [tlId, setTlId] = React.useState(null)
  const [allocEditElId, setAllocEditElId] = React.useState(null)
  const [allocBigProto, setAllocBigProto] = React.useState(null)
  const { data: overAllocData, isLoading: isOverAllocDataLoading } =
    useOverAllocatedEmployeeDataForArea()
  const { data: overAllocNonAreaData, isLoading: isOverAllocNonAreaDataLoading } =
    useOverAllocatedEmployeeDataForNonArea()

  if (isOverAllocDataLoading || isOverAllocNonAreaDataLoading) {
    return <InPageLoader />
  }

  const icTotalAllocs = overAllocData?.icTotalAllocs ?? []
  const icTotalAllocsNonArea = overAllocNonAreaData?.icTotalAllocs ?? []

  return (
    <>
      <EditOverAllocationModal
        isEditOverAllocationModalOpen={isEditOverAllocationModalOpen}
        setIsEditOverAllocationModalOpen={setIsEditOverAllocationModalOpen}
        icOtherManagerMappings={icOtherManagerMappings}
        teamAlloc={teamAlloc}
        tlId={tlId}
        eId={allocEditElId}
        bigproto={allocBigProto}
      />

      <div className="table-wrapper" style={{ padding: '0 5rem' }}>
        <Grid>
          <GridHeader>Employee</GridHeader>
          <GridHeader>Total allocation</GridHeader>
          <GridHeader style={{}}>Allocation Distribution</GridHeader>

          {icTotalAllocs.map(
            (empAlloc, index) =>
              empAlloc.icOtherManagerMappings.length > 0 && (
                <>
                  <React.Fragment key={`empAlloc-${index}`}>
                    <GridContent style={{ alignContent: 'center', alignItems: 'center' }}>
                      {empAlloc.icOtherManagerMappings[0].eName}
                    </GridContent>
                    <GridContent style={{}}>
                      {empAlloc.icOtherManagerMappings.reduce(
                        (acc, curr) => acc + curr.allocation,
                        0,
                      ) + empAlloc.teamAlloc}
                      %
                    </GridContent>
                    <GridContent
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        <div style={{ padding: '0' }}>
                          <b>Requested allocation to other teams:</b>
                        </div>
                        <br />
                        {empAlloc.icOtherManagerMappings.map((empObj, eoIndx) => (
                          <>
                            <div
                              style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}
                            >
                              <div style={{ maxWidth: '25rem' }}>
                                <li>
                                  {empObj.objDesc} under <u>{empObj.tlName}</u>:{' '}
                                  {/* <b>{empObj.allocation} </b> */}
                                  <br />
                                  <br />
                                </li>
                              </div>
                              <div>{empObj.allocation}</div>
                            </div>
                          </>
                        ))}
                        <div>
                          <b style={{ color: '#0926D5' }}>Current allocation to own team:</b> {'  '}
                          {empAlloc.teamAlloc}
                        </div>
                      </div>

                      <div
                        className="edit-button"
                        onClick={() => {
                          setIcOtherManagerMappings(empAlloc?.icOtherManagerMappings)
                          setTeamAlloc(empAlloc?.teamAlloc)
                          setTlId(empAlloc?.icOtherManagerMappings[0]?.tlId)
                          setAllocEditElId(empAlloc?.eId)
                          setAllocBigProto(empAlloc)
                          setIsEditOverAllocationModalOpen(true)
                        }}
                      >
                        <img className="edit-btn" src={edit} alt="Click to edit symbol" />
                      </div>
                    </GridContent>
                  </React.Fragment>
                </>
              ),
          )}
        </Grid>
      </div>

      {icTotalAllocsNonArea && icTotalAllocsNonArea.length > 0 ? (
        <>
          <div style={{ textAlign: 'center', paddingTop: '45px' }}>
            <h3>Other team members (not in Decision+)</h3>
          </div>

          <div className="table-wrapper" style={{ padding: '0 5rem' }}>
            <GridTwoColumns>
              <GridHeader>Employee</GridHeader>

              <GridHeader style={{}}>Allocation Distribution</GridHeader>

              {icTotalAllocsNonArea.map(
                (empAlloc, index) =>
                  empAlloc.icOtherManagerMappings.length > 0 && (
                    <>
                      <React.Fragment key={`empAlloc-${index}`}>
                        <GridContent style={{ alignContent: 'center', alignItems: 'center' }}>
                          {empAlloc.icOtherManagerMappings[0].eName}
                        </GridContent>

                        <GridContent
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div>
                            {empAlloc.icOtherManagerMappings.map((empObj, eoIndx) => (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    columnGap: '1rem',
                                  }}
                                >
                                  <div style={{ maxWidth: '25rem' }}>
                                    <li>
                                      {empObj.objDesc} under <u>{empObj.tlName}</u>:{' '}
                                      <b>{empObj.allocation} </b>
                                      <br />
                                      <br />
                                    </li>
                                  </div>
                                  <div></div>
                                </div>
                              </>
                            ))}
                          </div>
                        </GridContent>
                      </React.Fragment>
                    </>
                  ),
              )}
            </GridTwoColumns>
          </div>
        </>
      ) : null}
    </>
  )
}

export default OverAllocation
