import React from 'react'
import { postHttpRequest } from '../../../../../../api/query/dynamicAPI'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Modal from '../../../../../../components/Modal'
import { dayMonthFilter } from '../../../../../../utils/time'
import InPageLoader from '../../../../../../components/InPageLoader'
import { convertToTitleCase, linkifyString } from '../../../../../../utils/general'
import CommentsModal2 from '../../../../../../components/CommentsModal2'
import { removeMentionMarkupFromText } from '../../../../../../utils/parseMarkup'
import Select from 'react-select'

const ObjectiveStatement = styled.div`
  font-size: 16px;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 0.5rem;
  text-align: left;
`
const ObjectiveDetailsModal = ({ isModalOpen, closeModal, objIdIn, showInitiativesId }) => {
  const [data, setData] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)
  const [cardButtonTypes, setCardButtonTypes] = React.useState([])
  const { t } = useTranslation(['MeetingHub'])

  const [isCommentModalOpen, setIsCommentModalOpen] = React.useState(false)
  const [objectiveIdSelected, setObjectiveIdSelected] = React.useState(null)
  const [taskIdSelected, setTaskIdSelected] = React.useState(null)
  const [topicNames, setTopicNames] = React.useState([])
  const [topicFilter, setTopicFilterValue] = React.useState([])
  const [appplyFilerts, setApplyFilters] = React.useState(false)
  const [combinedList, setCombinedList] = React.useState([])
  // const applyFilters = () => {
  //   setApplyFilters(true)
  // }

  const handleChangeTopicFilter = (value) => {
    setApplyFilters(true)
    let valueArray = value.map((obj) => obj.value)
    console.log(valueArray)
    setTopicFilterValue(valueArray)
    if (value.length == 0) {
      setApplyFilters(false)
    }
  }

  React.useEffect(() => {
    loadHistory()
  }, [])

  React.useEffect(() => {
    if (data != null && data?.objectives && data?.objectives?.[0]) {
      const { blockers, decisions, updates, admins } = data?.objectives[0]
      const topics = []

      if (blockers && Array.isArray(blockers)) {
        blockers.forEach((blocker) => {
          blocker?.comments?.forEach((comment) => {
            topics.push({
              ...comment,
              description: blocker.description,
              topicType: blocker.topicType,
            })
          })

          blocker?.milestones?.forEach((milestone) => {
            topics.push({
              ...milestone,
              description: blocker.description,
              topicType: blocker.topicType,
              objId: blocker.objId,
            })
          })
        })
      }
      // Iterate over decisions
      if (decisions && Array.isArray(decisions)) {
        decisions.forEach((decision) => {
          decision?.comments?.forEach((comment) => {
            topics.push({
              ...comment,
              description: decision.description,
              topicType: decision.topicType,
            })
          })

          decision?.milestones?.forEach((milestone) => {
            topics.push({
              ...milestone,
              description: decision.description,
              topicType: decision.topicType,
              objId: decision.objId,
            })
          })
        })
      }

      // Iterate over updates
      if (updates && Array.isArray(updates)) {
        updates.forEach((update) => {
          update?.comments?.forEach((comment) => {
            topics.push({
              ...comment,
              description: update.description,
              topicType: update.topicType,
            })
          })

          update?.milestones?.forEach((milestone) => {
            topics.push({
              ...milestone,
              description: update.description,
              topicType: update.topicType,
              update: update.objId,
            })
          })
        })
      }

      // Iterate over admin
      if (admins && Array.isArray(admins)) {
        admins.forEach((admin) => {
          admin?.comments?.forEach((comment) => {
            topics.push({
              ...comment,
              description: admin.description,
              topicType: admin.topicType,
            })
          })

          admin?.milestones?.forEach((milestone) => {
            topics.push({
              ...milestone,
              description: admin.description,
              topicType: admin.topicType,
            })
          })
        })
      }

      // Sort the combined list based on creation date in descending order
      topics.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

      if (blockers && Array.isArray(blockers)) {
        blockers.forEach((blocker) => {
          if (!blocker.comments && !blocker.milestones) {
            topics.push({
              description: blocker.description,
              topicType: blocker.topicType,
            })
          }
        })
      }
      if (decisions && Array.isArray(decisions)) {
        decisions.forEach((decision) => {
          if (!decision.comments && !decision.milestones) {
            topics.push({
              description: decision.description,
              topicType: decision.topicType,
            })
          }
        })
      }
      if (updates && Array.isArray(updates)) {
        updates.forEach((update) => {
          if (!update.comments && !update.milestones) {
            topics.push({
              description: update.description,
              topicType: update.topicType,
            })
          }
        })
      }
      if (admins && Array.isArray(admins)) {
        admins.forEach((admin) => {
          if (!admin.comments && !admin.milestones) {
            topics.push({
              description: admin.description,
              topicType: admin.topicType,
            })
          }
        })
      }
      console.log(data?.objectives[0])
      console.log(topics)

      // Update state with the combined list of topics
      setCombinedList(topics)

      // Extract unique topic descriptions
      const descriptionsSet = new Set()
      topics.forEach((topic) => descriptionsSet.add(topic.description))
      const nameArr = Array.from(descriptionsSet).map((item) => ({ value: item, label: item }))
      setTopicNames(nameArr)
    }
  }, [data])

  const loadHistory = async () => {
    setIsLoading(true)
    const result = await postHttpRequest(`/get_team_decision_log`, { objId: objIdIn })
    setData(result)
    setIsLoading(false)
  }

  const comments = (commentsList, topicDescription) => {
    return (
      <>
        {commentsList
          .filter((comment) => !cardButtonTypes.includes('OpenOnly'))
          .map((comment) => (
            <div className="my-decisions-inner-grid" key={comment?.commentId}>
              <div className="meeting-topic-blocker" style={{ textAlign: 'left' }}>
                {getActionBox(comment.topicType)}
                {topicDescription}
              </div>
              <div
                className="my-decisions-comment"
                style={{ textAlign: 'left', wordBreak: 'break-word' }}
              >
                {comment &&
                  (comment.isDecision ? (
                    <b>{t('myDecisions.decision')}:&nbsp;</b>
                  ) : (
                    <b>{t('myDecisions.comment')}:&nbsp;</b>
                  ))}
                {linkifyString(removeMentionMarkupFromText(comment?.commentText))}{' '}
              </div>
              <div className="my-decisions-cell">{comment?.commentOwnerName?.match(/\b(\w)/g)}</div>
              <div className="my-decisions-cell">{dayMonthFilter(comment?.createdAt)}</div>
              <div className="my-decisions-cell"></div>
              <div className="my-decisions-cell"></div>
              <div className="my-decisions-cell"></div>
            </div>
          ))}
      </>
    )
  }

  const history = (commentsList, topicDescription) => {
    return (
      <>
        {commentsList
          .filter((comment) => !cardButtonTypes.includes('OpenOnly'))
          .map((comment) => (
            <div className="my-decisions-inner-grid" key={comment?.commentId}>
              <div className="meeting-topic-blocker" style={{ textAlign: 'left' }}>
                {getActionBox(comment.topicType)}
                {topicDescription}
              </div>
              <div className="my-decisions-comment"></div>
              <div className="my-decisions-cell"></div>
              <div className="my-decisions-cell"></div>
              <div className="my-decisions-cell"></div>
              <div className="my-decisions-cell"></div>
              <div className="my-decisions-cell"></div>
            </div>
          ))}
      </>
    )
  }

  const milestones = (milestonesList, objId, topicDescription) => {
    return (
      <>
        {milestonesList
          .filter((milestone) => {
            // Filter out completed milestones only if cardButtonTypes includes 'OpenOnly'
            return !(cardButtonTypes.includes('OpenOnly') && milestone.progress === 'COMPLETED')
          })
          .map((milestone) => (
            <div className="my-decisions-inner-grid" key={milestone.milestoneId}>
              <div className="meeting-topic-blocker" style={{ textAlign: 'left' }}>
                {getActionBox(milestone.topicType)}
                {topicDescription}
              </div>
              <div
                className="my-decisions-cell"
                style={{ textAlign: 'left', wordBreak: 'break-word' }}
              >
                <b>{t('myDecisions.action')}:</b>{' '}
                {linkifyString(removeMentionMarkupFromText(milestone.milestoneDescription))}
              </div>
              <div className="my-decisions-cell">
                {milestone?.assignees?.map((assignee, ind) => (
                  <>
                    <span key={assignee.eId}>{assignee.name.match(/\b(\w)/g)}</span>
                    {ind !== milestone?.assignees?.length - 1 && <span key={assignee.eId}>, </span>}
                  </>
                ))}
              </div>
              <div className="my-decisions-cell">{dayMonthFilter(milestone.createdAt)}</div>
              {milestone.dueDate === 0 ? (
                <div className="my-decisions-cell">--</div>
              ) : (
                <div className="my-decisions-cell">{dayMonthFilter(milestone.dueDate)}</div>
              )}
              <div className="my-decisions-cell">{convertToTitleCase(milestone.progress)}</div>
              {milestone.commentsCount && milestone.commentsCount > 0 ? (
                <div
                  style={{ marginLeft: '0.6rem' }}
                  className={'fitted-button blue tooltip'}
                  onClick={(e) => {
                    setObjectiveIdSelected(objId)
                    setTaskIdSelected(milestone.milestoneId)
                    setIsCommentModalOpen(true)
                  }}
                >
                  <span class="material-symbols-outlined icon-size" style={{ fontSize: '15px' }}>
                    comment
                  </span>
                  <span class="tooltiptext" style={{ fontSize: '16px' }}>
                    {' '}
                    {t('Common:tooltip.comments')}{' '}
                  </span>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          ))}
      </>
    )
  }

  const getActionBox = (topicType) => {
    let type
    let color
    if (topicType === 1) {
      type = 'blocker'
      color = 'rgb(237, 82, 46)'
    }
    if (topicType === 2) {
      type = 'decision'
      color = 'blue'
    }
    if (topicType === 3) {
      type = 'update'
      color = 'gray'
    }
    if (topicType === 4) {
      type = 'admin'
      color = '#9933FF'
    }
    return (
      <div className="action-box-new-format" style={{ backgroundColor: color }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '8px',
          }}
        >
          {t(`Common:filter.${type}`)}
        </div>
      </div>
    )
  }

  async function filterOpenOnly() {
    // if user clicks on same button twice, remove it from the array (so not underline)
    if (cardButtonTypes.includes('OpenOnly')) {
      setCardButtonTypes((prev) => prev.filter((item) => item !== 'OpenOnly'))
    } else {
      setCardButtonTypes((prev) => [...prev, 'OpenOnly'])
    }
  }
  function renderItemContent(item) {
    if (item.commentId) {
      return comments([item], item.description)
    } else if (item.milestoneId) {
      return milestones([item], item.objId, item.description)
    } else {
      return history([item], item.description)
    }
  }

  return (
    <>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal} width="88%">
        {isLoading && <InPageLoader inComponent={true} />}

        {data?.objectives?.length === 0 ||
          (!data?.objectives?.length && isLoading == false && (
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              {t('myDecisions.noMeetingTopics')}
            </div>
          ))}
        {data?.objectives?.length > 0 && isLoading == false && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              wordBreak: 'break-word',
            }}
          >
            <div
              style={{
                paddingTop: '0',
                justifyContent: 'flex-start',
                width: '100%',
                paddingBottom: '1rem',
                position: 'absolute',
                left: '2.5rem',
                top: '2rem',
              }}
              className="card_footer"
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: 'fit-content' }}>
                <button
                  type="button"
                  className="btn-card tonedown-blue-btn"
                  onClick={(e) => filterOpenOnly()}
                  style={{ width: 'fit-content' }}
                >
                  {t('meetingTopics.openActionsOnly')}
                </button>
                {cardButtonTypes.includes('OpenOnly') && <div className="underline-style"></div>}
                {!cardButtonTypes.includes('OpenOnly') && (
                  <div className="underline-style" style={{ opacity: '0' }}></div>
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: '1rem',
                marginBottom: '1rem',
                float: 'left',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '0.5rem',
                  textAlign: 'left',
                }}
              >
                <label for="teamLeadersFilter">
                  <b>{t('Common:filter.topic')}:</b>
                </label>
                <div data-testid="multi-options-1" style={{ width: '25rem' }}>
                  <Select
                    // value={peopleFilterValue}
                    isMulti
                    onChange={handleChangeTopicFilter}
                    // maxMenuHeight={100}
                    // getOptionLabel={(option) => option?.name}
                    // getOptionValue={(option) => option?.eId}
                    options={topicNames}
                  />
                </div>
              </div>
              {/* <div
                class="fitted-button blue"
                onClick={() => {
                  applyFilters()
                }}
                onKeyDown={() => {
                  applyFilters()
                }}
                style={{ alignSelf: 'end' }}
              >
                <span>{t('Common:filter.applyFilter')}</span>
              </div> */}
            </div>

            <div
              className="my-decisions-container"
              style={{ width: '100%', marginBottom: '3rem', padding: '1.5rem' }}
            >
              <div className="my-decisions-meeting-table-new-format">
                <div className="my-decisions-inner-grid">
                  <div
                    className="my-decisions-meeting-header-new-format"
                    style={{ textAlign: 'left' }}
                  >
                    {t('myDecisions.topic')}
                  </div>
                  <div
                    className="my-decisions-meeting-header-new-format"
                    style={{ paddingLeft: '0rem', textAlign: 'left' }}
                  >
                    {t('myDecisions.outcome')}
                  </div>
                  <div
                    className="my-decisions-meeting-header-new-format"
                    style={{ paddingLeft: '0rem', textAlign: 'left' }}
                  >
                    {t('myDecisions.owner')}
                  </div>
                  <div
                    className="my-decisions-meeting-header-new-format"
                    style={{ paddingLeft: '0rem', textAlign: 'left' }}
                  >
                    {t('myDecisions.createdOn')}
                  </div>
                  <div
                    className="my-decisions-meeting-header-new-format"
                    style={{ paddingLeft: '0rem', textAlign: 'left' }}
                  >
                    {t('myDecisions.dueDate')}
                  </div>
                  <div
                    className="my-decisions-meeting-header-new-format"
                    style={{ paddingLeft: '0rem', textAlign: 'left' }}
                  >
                    {t('myDecisions.status')}
                  </div>
                  <div
                    className="my-decisions-meeting-header-new-format"
                    style={{ paddingLeft: '0rem', textAlign: 'left' }}
                  ></div>
                </div>
                {console.log(combinedList)}
                {console.log(topicFilter)}

                {combinedList
                  .filter((item) => {
                    if (appplyFilerts) {
                      return topicFilter.includes(item.description)
                    } else {
                      return true
                    }
                  })
                  .map((item, index) => (
                    <React.Fragment>
                      <div
                        style={{
                          backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5',
                        }}
                      >
                        {renderItemContent(item)}
                      </div>
                    </React.Fragment>
                  ))}

                {/* {data?.objectives?.map((objective, index) => {
                  const { blockers, decisions, updates, decisionCommentsGoalOnly, admins } =
                    objective
                  const totalRows =
                    (blockers?.length ?? 0) +
                    (decisions?.length ?? 0) +
                    (updates?.length ?? 0) +
                    (decisionCommentsGoalOnly?.length ?? 0) +
                    (admins?.length ?? 0)

                  return (
                    <React.Fragment key={`objective-${index}`}>
                      {totalRows > 0 && (
                        <React.Fragment>
                          {/* <div className="my-decisions-meeting-description">
                            <b>
                              {showInitiativesId && !objective.isGeneralObjective ? (
                                <ObjectiveStatement>
                                  {objective?.initiativeId.toString()?.padStart(5, '0')} -{' '}
                                  {objective.objDescription}
                                </ObjectiveStatement>
                              ) : (
                                <ObjectiveStatement>{objective.objDescription}</ObjectiveStatement>
                              )}
                            </b>
                            <div className="divider"></div>
                          </div> */}
              </div>
            </div>
          </div>
        )}
      </Modal>
      {isCommentModalOpen && (
        <CommentsModal2
          objective={{ id: objectiveIdSelected }}
          taskId={taskIdSelected}
          handleCancel={() => {
            setIsCommentModalOpen(false)
            loadHistory()
          }}
        />
      )}
    </>
  )
}

export default ObjectiveDetailsModal
