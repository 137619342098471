import React from 'react'
import useEmployeeData from '../../../api/query/useEmployeeData'
import useMeetingData from '../../../api/query/useMeetingData'
// import useF2WData from '../../../api/query/useF2WData'

export default function useMeetingViewData(refreshIndex) {
  const { data: meetingData, isLoading: isMeetingViewLoading } = useMeetingData(refreshIndex)
  const { data: employeeData, isLoading: isEmployeeLoading } = useEmployeeData()
  // const { data: F2WData, isLoading: isF2WLoading } = useF2WData()
  let F2WData = null

  const [scoreCard, setScoreCard] = React.useState(null)
  const [selectedCardIndexHook, setSelectedCardIndexHook] = React.useState(0)

  let origScoreCard = null

  React.useEffect(() => {
    if (!isMeetingViewLoading) {
      setScoreCard(meetingData.liveScoreCard)
      setScoreCard(JSON.parse(JSON.stringify(meetingData.liveScoreCard)))
    }
  }, [isMeetingViewLoading, meetingData?.liveScoreCard])

  const updateScoreCard = (e, tradeOffIndex) => {
    if (e === null || e === undefined) {
      setScoreCard(JSON.parse(JSON.stringify(meetingData.liveScoreCard)))
      return
    }
    const thisCard = e.target.closest('.trade-off-card') || e.target

    if (
      e.target.closest('#tradeoff-btn-container') ||
      thisCard.classList.contains('selected-trade-off')
    ) {
      return
    }

    if (e.target.closest('.show-team-btn') || e.target.classList.contains('show-team-btn')) {
      return
    }

    const newScoreCard = {
      ...scoreCard,
    }

    // TODO(ali): Do not assume this order, instead get it from the response.
    // let typeOfWorkIndexMap = {
    //   STRATEGIC: 0,
    //   'NON-STRATEGIC': 1,
    //   BAU: 2,
    // }

    let stratWorkMeasuresMap = {}
    let stratWorkMeasuresIdx = 0
    for (let measure of newScoreCard.stratWorkMeasures) {
      stratWorkMeasuresMap[measure.measureId] = stratWorkMeasuresIdx++
    }

    let selectCardIndex = 0
    let i = 0
    for (const card of thisCard
      .closest('.trade-off-container')
      .querySelectorAll('.trade-off-card')) {
      if (card === thisCard) {
        card.classList.add('selected-trade-off')
        selectCardIndex = i
        setSelectedCardIndexHook(i)
      } else {
        card.classList.remove('selected-trade-off')
      }
      i++
    }

    let unstaffedGoalRequirement = meetingData.tradeoffs[tradeOffIndex].teamCapacityNeeds
    let unstaffedGoalId = meetingData.tradeoffs[tradeOffIndex].orginalObjId
    let selectedGoalId = 0
    let selectedGoalAlloc = 0

    if (selectCardIndex === 0) {
      selectedGoalId = meetingData.tradeoffs[tradeOffIndex].orginalObjId
    } else {
      selectedGoalId = meetingData.tradeoffs[tradeOffIndex].options[selectCardIndex - 1].objId
      selectedGoalAlloc = meetingData.tradeoffs[tradeOffIndex].options[selectCardIndex - 1].fteLost
    }

    // dump.mId
    // dump.type_id = leaf_data.type_id
    // dump.goal_allocation = leaf_data.goal_allocation
    // dump.is_exceeding_capacity = leaf_data.is_exceeding_capacity == 1
    // dump.deprioritized = leaf_data.deprioritized == 1
    // dump.top_obj_id = leaf_data.top_obj_id

    let impactMeasureScore = {}
    let workTypeScore = {}
    let topObjScore = {}

    for (let d of newScoreCard.bigDump.objDumps) {
      // if (d.goalId == selectedGoalId) continue;
      if (d.isDeprioritzed) continue
      // If the selected goal is hte unstaffed (left) one, then we have are removing its goal
      // alloc from the total.
      if (selectCardIndex === 0) {
        if (d.goalId === selectedGoalId) continue

        if (d.mId in impactMeasureScore) impactMeasureScore[d.mId] += d.goalAllocation
        else impactMeasureScore[d.mId] = d.goalAllocation

        if (d.typeId in workTypeScore) workTypeScore[d.typeId] += d.goalAllocation
        else workTypeScore[d.typeId] = d.goalAllocation

        if (d.topObjId === undefined) continue
        if (d.topObjId in topObjScore) {
          topObjScore[d.topObjId] += d.goalAllocation
        } else {
          topObjScore[d.topObjId] = d.goalAllocation
        }
      } else {
        // If the selected goal is a candidate (one of the right) one, then we are removing
        // only those FTE that need to go to the left one.
        // i.e., removed = unstaffed.alloc
        // so when totaling, we can still keep some FTE from the candidate if candidate.alloc > unstaffed.alloc
        //
        // 1. unstaffed.requirement == candidate.alloc
        //        give `candidate.alloc` to unstaffed, keep 0 for candidate
        // 2. unstaffed.requirement >  candidate.alloc
        //        give `candidate.alloc` to unstaffed, keep 0 for candidate
        // 3. unstaffed.requirement <  candidate.alloc
        //        give `unstaffed.requirement` to unstaffed, keep the rest for `candidate`
        //
        // keep_for_candidate = max(candidate.alloc - unstaffed.requirement, 0)
        // give_to_unstaffed = candidate.alloc - max(candidate.alloc - unstaffed.requirement, 0)

        let thisGoalAlloc = 0
        // If this is the candidate objective.
        if (d.goalId === selectedGoalId) {
          // This is the allocation left after we do the transfer.
          thisGoalAlloc = Math.max(d.goalAllocation - unstaffedGoalRequirement, 0)
        } else if (d.goalId === unstaffedGoalId) {
          // If this is the unstaffed goal, then give it:
          thisGoalAlloc =
            selectedGoalAlloc - Math.max(selectedGoalAlloc - unstaffedGoalRequirement, 0)
        } else {
          thisGoalAlloc = d.goalAllocation
        }

        if (d.mId in impactMeasureScore) impactMeasureScore[d.mId] += thisGoalAlloc
        else impactMeasureScore[d.mId] = thisGoalAlloc

        if (d.typeId in workTypeScore) workTypeScore[d.typeId] += thisGoalAlloc
        else workTypeScore[d.typeId] = thisGoalAlloc

        if (d.topObjId === undefined) continue
        if (d.topObjId in topObjScore) {
          topObjScore[d.topObjId] += thisGoalAlloc
        } else {
          topObjScore[d.topObjId] = thisGoalAlloc
        }
      }
    }

    newScoreCard.strategicWorkFte = workTypeScore['STRATEGIC']
    newScoreCard.nonStrategicWorkFte = workTypeScore['NON_STRATEGIC']
    newScoreCard.dayToDayWorkFte = workTypeScore['BAU']

    for (let i = 0; i < newScoreCard.stratWorkMeasures.length; i++) {
      newScoreCard.stratWorkMeasures[i].fte =
        impactMeasureScore[newScoreCard.stratWorkMeasures[i].measureId]
    }

    for (let i = 0; i < newScoreCard.stratWorkObjectives.length; i++) {
      newScoreCard.stratWorkObjectives[i].fte =
        topObjScore[newScoreCard.stratWorkObjectives[i].objectiveId]
    }

    setScoreCard(newScoreCard)
  }

  const addOption = (e) => {}

  return {
    isLoading: isMeetingViewLoading || isEmployeeLoading,
    tradeoffs: meetingData?.tradeoffs ?? [],
    meetingData,
    scoreCard,
    employees: employeeData?.employees ?? [],
    F2WData,
    updateScoreCard,
    addOption,
    selectedCardIndexHook,
    setSelectedCardIndexHook,
  }
}
