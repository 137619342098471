import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import NextPreviousActions from '../NextPreviousActions'
import useKPIWizardSteps, { stepsLabelsGenerator } from '../../hooks/useKPIWizardSteps'
import { useKPIContext } from '../../context/kpiContext'
import {
  setTeamOverview,
  setKpiWizardTimeframe,
  setKpiWizardLongtermAnswer,
  setKpiWizardShorttermAnswer,
  saveManyKpis,
} from '../../../../api/services/kpi.services'
import { useTranslation } from 'react-i18next'
import Toast from '../../../../common/toast'
import Swal from 'sweetalert2'

const KPIWizard = ({ existingStep }) => {
  const { t } = useTranslation(['KPIWizard', 'Common'])
  const [errorMessage, setErrorMessage] = useState(null)
  const { state, dispatch } = useKPIContext()
  const [isLoading, setIsLoading] = React.useState(false)

  const checkValidityOfSuggestedKpisToSave = () => {
    if (state.suggestedKpisToSave === null || state.suggestedKpisToSave.length === 0) {
      return false
    }

    let tempSuggestedKpisToSave = [...state.suggestedKpisToSave]
    let atLeastOneError = false
    let suggestedKpisToSaveWithErrors = []

    for (let kpi of tempSuggestedKpisToSave) {
      let kpiWithErrors = { ...kpi, errorFields: {} } // Object to store fields with errors

      if (kpi?.name) {
        kpiWithErrors.errorFields.name = false
      } else {
        kpiWithErrors.errorFields.name = true
        atLeastOneError = true
      }

      if (kpi?.kpiType != null) {
        kpiWithErrors.errorFields.kpiType = false
      } else {
        kpiWithErrors.errorFields.kpiType = true
        atLeastOneError = true
      }

      if (kpi?.kpiType !== 3) {
        if (kpi?.unit) {
          kpiWithErrors.errorFields.unit = false
        } else {
          kpiWithErrors.errorFields.unit = true
          atLeastOneError = true
        }
        debugger
        if (kpi?.target) {
          kpiWithErrors.errorFields.targetDate = false
        } else {
          kpiWithErrors.errorFields.target = true
          atLeastOneError = true
        }
      }

      if (kpi?.frequency != null && kpi?.frequency !== 0) {
        kpiWithErrors.errorFields.frequency = false
      } else {
        kpiWithErrors.errorFields.frequency = true
        atLeastOneError = true
      }

      if (kpi?.targetDate) {
        kpiWithErrors.errorFields.targetDate = false
      } else {
        kpiWithErrors.errorFields.targetDate = true
        atLeastOneError = true
      }

      if (kpi?.startDate && kpi?.targetDate) {
        if (new Date(kpi?.startDate) >= new Date(kpi?.targetDate)) {
          kpiWithErrors.errorFields.startDate = true
          atLeastOneError = true
        } else {
          kpiWithErrors.errorFields.startDate = false
        }
      }

      if (atLeastOneError) {
        suggestedKpisToSaveWithErrors.push(kpiWithErrors)
      }
    }

    if (atLeastOneError) {
      dispatch({
        type: 'SET_SUGGESTED_KPIS_TO_SAVE_WITH_ERROR',
        value: suggestedKpisToSaveWithErrors,
      })
      return false
    } else {
      return true
    }
  }

  async function handleSubmit() {
    if (checkValidityOfSuggestedKpisToSave(state.suggestedKpisToSave) === false) {
      if (state.suggestedKpisToSave === null || state.suggestedKpisToSave.length === 0) {
        Swal.fire({
          title: t('Common:modalMessages.pleaseReviewAndTryAgain'),
          text: t('Common:modalMessages.selectAtLeastOneKpiToSave'),
          confirmButtonText: 'Ok',
        })
      } else {
        Swal.fire({
          title: t('Common:modalMessages.pleaseReviewAndTryAgain'),
          text: t('Common:modalMessages.suggestedKpiTableMandatoryFields'),
          confirmButtonText: 'Ok',
        })
      }
      return
    }
    //save suggested kpis
    setIsLoading(true)
    let args = { suggestedKpisToSave: state.suggestedKpisToSave, goalId: parseInt(state.obj.id) }
    let result = await saveManyKpis(args)
    setIsLoading(false)
    if (result?.success) {
      Toast.fire({
        icon: 'success',
        title: t('Common:modalMessages.updatedSuccessfully'),
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }

    //navigate back to list of objectives
    redirectToListOfObjPage()

    //clean obj saved in state
    dispatch({ type: 'SET_OBJ', obj: null })

    //obj related answers stored
    dispatch({ type: 'SET_TIMEFRAME', value: null })
    dispatch({ type: 'SET_LONGTERM_ANSWER', value: null })
    dispatch({ type: 'SET_SHORTTERM_ANSWER', value: null })
  }

  const {
    currentStepIndex,
    steps,
    handleNext,
    handlePrevious,
    kpiwArray,
    isLastStep,
    redirectToIntroPage,
    redirectToListOfObjPage,
  } = useKPIWizardSteps(existingStep, state, dispatch)

  const stepsLabels = stepsLabelsGenerator()

  async function saveAnswer(saveFunction) {
    setIsLoading(true)
    let result = await saveFunction()
    setIsLoading(false)
    if (result?.success) {
      // Toast.fire({
      //   icon: 'success',
      //   title: t('Common:modalMessages.updatedSuccessfully'),
      // })
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const sendArgumentsAndSend = (direction) => {
    //set error messages
    if (
      (currentStepIndex === stepsLabels.TIMEFRAME ||
        currentStepIndex === stepsLabels.LONGTERM_QUESTION ||
        currentStepIndex === stepsLabels.SHORTTERM_QUESTION) &&
      (state?.obj === undefined || state?.obj === null)
    ) {
      setErrorMessage('Internal error')
      //Send user back to first page in wizard
      redirectToIntroPage()
      return false
    }

    if (currentStepIndex === stepsLabels.TEAM_OVERVIEW) {
      if (
        (state.answers.teamOverview === null ||
          state.answers.teamOverview === undefined ||
          state.answers.teamOverview.trim().length === 0) &&
        direction === 'foward'
      ) {
        setErrorMessage(t('pleaseAnswerTheQuestion'))
        return false
      }
      if (state?.answers?.teamOverview?.trim().length > 0) {
        saveAnswer(() => setTeamOverview({ teamOverview: state.answers.teamOverview }))
        setErrorMessage(null)
      }
    } else if (currentStepIndex === stepsLabels.TIMEFRAME) {
      if (
        (state.answers.timeframe === null ||
          state.answers.timeframe === undefined ||
          state.answers.timeframe.trim().length === 0) &&
        direction === 'foward'
      ) {
        setErrorMessage(t('pleaseAnswerTheQuestion'))
        return false
      }
      if (state?.answers?.timeframe?.trim().length > 0) {
        saveAnswer(() =>
          setKpiWizardTimeframe({
            timeframe: state.answers.timeframe,
            goalId: parseInt(state.obj.id),
          }),
        )
        setErrorMessage(null)
      }
    } else if (currentStepIndex === stepsLabels.LONGTERM_QUESTION) {
      if (
        (state.answers.longtermAnswer === null ||
          state.answers.longtermAnswer === undefined ||
          state.answers.longtermAnswer.trim().length === 0) &&
        direction === 'foward'
      ) {
        setErrorMessage(t('pleaseAnswerTheQuestion'))
        return false
      }
      if (state?.answers?.longtermAnswer?.trim().length > 0) {
        saveAnswer(() =>
          setKpiWizardLongtermAnswer({
            longtermAnswer: state.answers.longtermAnswer,
            goalId: parseInt(state.obj.id),
          }),
        )
        setErrorMessage(null)
      }
    } else if (currentStepIndex === stepsLabels.SHORTTERM_QUESTION) {
      if (
        (state.answers.shorttermAnswer === null ||
          state.answers.shorttermAnswer === undefined ||
          state.answers.shorttermAnswer.trim().length === 0) &&
        direction === 'foward'
      ) {
        setErrorMessage(t('pleaseAnswerTheQuestion'))
        return false
      }
      if (state?.answers?.shorttermAnswer?.trim().length > 0) {
        saveAnswer(() =>
          setKpiWizardShorttermAnswer({
            shorttermAnswer: state.answers.shorttermAnswer,
            goalId: parseInt(state.obj.id),
          }),
        )
        setErrorMessage(null)
      }
    }
    return true
  }

  return (
    <>
      <Outlet
        context={{
          setErrorMessage,
          handlePrevious,
          handleNext,
        }}
      />
      <NextPreviousActions
        hideBackButton={false}
        submitIsLoading={false}
        isLastStep={isLastStep()}
        currentStep={currentStepIndex}
        handleSubmit={handleSubmit}
        handleNext={() => {
          let success = sendArgumentsAndSend('foward')
          if (errorMessage || success === false) {
            return
          }
          handleNext()
        }}
        handlePrevious={() => {
          let success = sendArgumentsAndSend('back')
          if (errorMessage || success === false) {
            return
          }
          handlePrevious()
        }}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        spaceBetween={true}
      />
    </>
  )
}

export default KPIWizard
