import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getOverAllocatedEmployeeDataForNonArea = async () => {
  const queryString = '/allocation_for_non_area_ics'

  const { data } = await axios.get(`${BASE_URL}${queryString}`)
  return data
}

export default function useOverAllocatedEmployeeDataForNonArea() {
  return useQuery(
    ['over-allocated-employees-non-area'],
    () => getOverAllocatedEmployeeDataForNonArea(),
    {
      refetchOnWindowFocus: false,
    },
  )
}
