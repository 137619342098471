import logo from '../../assets/logo_new.png'
const Header = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
      <div>
        <img className="logo-large-new" src={logo} alt="TransforML Logo" />
      </div>
    </div>
  )
}

export default Header
