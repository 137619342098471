import React, { useState } from 'react'
import '../../../MeetingView/components/F2WAssignment/styles.css'
import F2WCard from './components/f2w-card'
import next from '../../../../assets/next-white.svg'
import { useOutletContext } from 'react-router-dom'
import useEditActionF2w from '../../../../api/mutation/editActionF2w'
import InPageLoader from '../../../../components/InPageLoader'
import { useTranslation } from 'react-i18next'

const F2WAssignment = () => {
  const { t } = useTranslation(['MeetingHub'])
  const { meetingData, F2WData, isLoading, updateRefreshIndex } = useOutletContext()

  const [tradeoffs, setTradeoffs] = useState(meetingData.tradeoffs)
  const [teamLeaders, setTeamLeaders] = useState([...F2WData.f2wTeamCapacities])

  React.useEffect(() => {
    let tempTradeoffs = JSON.parse(JSON.stringify(meetingData.tradeoffs))
    for (let t of tempTradeoffs) {
      t.allocatedFteModified = t.allocatedFte
    }
    setTradeoffs(tempTradeoffs)
  }, [meetingData])

  React.useEffect(() => {
    setTeamLeaders([...F2WData.f2wTeamCapacities])
  }, [F2WData])

  const mutationEditActionF2w = useEditActionF2w()

  const actionAssignmentHandler = async (f2wId, tradeoff, resetCard) => {
    //***NOTE: After substituting this for f2wId, teamLeader allocation is not coming updated for the next cards
    //const thisSelect = e.target.closest('.f2w-card').querySelector('.work-pool-select') what is this?

    if (f2wId.value === 'null') {
      return
    }

    const submitObject = {
      f2wManager: f2wId,
      needToTransfer: tradeoff.needToTransfer,
      newAllocatedFte: tradeoff.allocatedFteModified,
      toObjId: tradeoff.orginalObjId,
    }

    await mutationEditActionF2w.mutateAsync(
      { ...submitObject },
      {
        onSuccess: (response) => {
          resetCard()
          workPoolSelectChangeHandler('null')
          updateRefreshIndex()
        },
        onError: () => {},
      },
    )
  }

  const [tradeoffIndex, setTradeoffIndex] = React.useState(0)
  const [teamLeadersCopy, setTeamLeadersCopy] = React.useState(
    JSON.parse(JSON.stringify(teamLeaders)),
  )

  const workPoolSelectChangeHandler = (f2wTlId) => {
    if (f2wTlId !== 'null') {
      //1. if value comes with an f2wTlId, we reset values for all team leaders and allocatedFte
      const tempTeamLeaders = JSON.parse(JSON.stringify(teamLeadersCopy))
      const tempTradeoffs = JSON.parse(JSON.stringify(tradeoffs))

      for (let i = 0; i < tempTeamLeaders.length; i++) {
        if (parseInt(tempTeamLeaders[i].f2wTlId) === parseInt(f2wTlId)) {
          //2. First we calculate value needed to fill in required capacity
          let valueNeeded =
            tempTradeoffs[tradeoffIndex].requiredFte - tempTradeoffs[tradeoffIndex].allocatedFte

          //3. we check if the tl has all of the capacity needed to finishing fulfilling the required capacity
          if (teamLeadersCopy[i].remainingFte >= valueNeeded) {
            tempTradeoffs[tradeoffIndex].allocatedFteModified =
              tempTradeoffs[tradeoffIndex].allocatedFte + valueNeeded
            tempTeamLeaders[i].remainingFte -= valueNeeded
            tempTradeoffs[tradeoffIndex].needToTransfer = valueNeeded

            tempTradeoffs[tradeoffIndex].insuficientFte = false
          } else {
            tempTradeoffs[tradeoffIndex].allocatedFteModified =
              tempTradeoffs[tradeoffIndex].allocatedFte + tempTeamLeaders[i].remainingFte
            tempTradeoffs[tradeoffIndex].needToTransfer = tempTeamLeaders[i].remainingFte

            tempTradeoffs[tradeoffIndex].insuficientFte = true
            tempTeamLeaders[i].remainingFte = 0
          }
        }
      }
      setTradeoffs([...tempTradeoffs])
      setTeamLeaders([...tempTeamLeaders])
    } else {
      // if value comes as null we reset values for all team leaders and allocatedFte
      const tempTradeoffs = JSON.parse(JSON.stringify(tradeoffs))
      tempTradeoffs[tradeoffIndex].allocatedFteModified = tempTradeoffs[tradeoffIndex].allocatedFte

      setTradeoffs([...tempTradeoffs])
      setTeamLeaders([...teamLeadersCopy])
    }
  }

  return (
    <div className="f2w-assignment-component" style={{ marginRight: '8px' }}>
      <h2>{t('f2wAssignment.flowToWorkAssignment')}</h2>

      {tradeoffs.length > 0 ? (
        <>
          <div className="trade-offs">
            <div
              className="tradeoff-btn-container"
              id="tradeoff-btn-container"
              style={{ width: 'auto' }}
            >
              <button
                className={`dual-back-btn dual-btn${tradeoffIndex === 0 ? ' disabledBtn' : ''}`}
                onClick={() => {
                  if (tradeoffIndex > 0) {
                    workPoolSelectChangeHandler('null')
                    setTradeoffIndex(tradeoffIndex - 1)
                  }
                }}
              >
                <img
                  className="tradeoff-nav-arrow tradeoff-previous"
                  src={next}
                  alt="Arrow indication a navigation backwards."
                />
              </button>

              <p className="tradeoff-counter">
                <b className="tradeoff-counter-title">
                  {t('f2wAssignment.flowToWorkAllocations')} (
                  <b className="current-tradeoff">{tradeoffIndex + 1}</b>
                  <b>/</b>
                  <b className="total-tradeoffs">{tradeoffs.length}</b>)
                </b>
              </p>

              <button
                className={`dual-next-btn dual-btn${
                  tradeoffIndex === tradeoffs.length - 1 ? ' disabledBtn' : ''
                }`}
                onClick={() => {
                  if (tradeoffIndex < tradeoffs.length - 1) {
                    workPoolSelectChangeHandler('null')
                    setTradeoffIndex(tradeoffIndex + 1)
                  }
                }}
              >
                <img
                  className="tradeoff-nav-arrow tradeoff-next"
                  src={next}
                  alt="Arrow indication a navigation forward."
                />
              </button>
            </div>
          </div>

          <div className="f2w-card-container">
            {isLoading ? (
              <InPageLoader />
            ) : (
              <F2WCard
                key={tradeoffIndex}
                tradeoff={tradeoffs[tradeoffIndex]}
                btnClickHandler={actionAssignmentHandler}
                teamLeaders={teamLeaders}
                workPoolSelectChangeHandler={workPoolSelectChangeHandler}
                mutationEditActionF2w={mutationEditActionF2w}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <center>{t('f2wAssignment.noObjectivesMessage')}</center>
        </>
      )}
    </div>
  )
}

export default F2WAssignment
