import React from 'react'
// import Loading from '../../components/Loading'
import { useLocation } from 'react-router-dom'
import { getHttpRequest, postHttpRequest } from '../../../api/query/dynamicAPI'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { linkifyString, shortenName } from '../../../utils/general'
import InPageLoader from '../../../components/InPageLoader'
import ApplicationLayout from '../../../components/ApplicationLayout'

const DecisionLogDigest = () => {
  const ObjectiveStatement = styled.div`
    font-size: 16px;
    width: -moz-fit-content;
    width: fit-content;
    padding-bottom: 1.5rem;
  `

  const { t } = useTranslation(['MeetingHub', 'Common'])

  async function fetchDecisionLogViaEmail(emailId) {
    try {
      const data = await postHttpRequest(`/digest/decision_log/data/${emailId}`, {})
      return data
    } catch (e) {
      // Toast.fire({
      //   icon: 'error',
      //   title: API_ERROR_MESSAGE,
      // });
    }
  }

  async function getDecisionLogViaEmail(emailId) {
    setLoading(true)
    const result = await fetchDecisionLogViaEmail(emailId)
    setLoading(false)

    if (result) {
      if (result.error && result.error === 403) {
        setAuthError(true)
        return
      }

      setDecisionLogData(result.log)
      setForUser(result.user)
    }
  }

  const [decisionLogData, setDecisionLogData] = useState(null)
  const [forUser, setForUser] = useState(null)
  const [authError, setAuthError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const emailId = queryParams.get('digest_id')
    getDecisionLogViaEmail(emailId)
  }, [])

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const emailId = queryParams.get('digest_id')

  const isLogEditable = false
  const isTeamMeetingView = false

  const renderComments = (comment, objective) => {
    return (
      <div key={comment.commentId} className="decision-log-inner-grid-area-view">
        <div style={{ display: 'grid', gridTemplateColumns: '0.9fr 0.1fr' }}>
          <div className="decision-log-comment">
            <b>{t('decisionLog.comment')}:</b> {linkifyString(comment.commentText)}
          </div>
        </div>
        <div class="owner">{comment.commentOwnerName.match(/\b(\w)/g)}</div>
      </div>
    )
  }

  const renderMilestones = (milestone, objective) => {
    return (
      <div key={milestone.milestoneId} className="decision-log-inner-grid-area-view">
        <div
          key={milestone.milestoneId}
          style={{ display: 'grid', gridTemplateColumns: '0.9fr 0.1fr' }}
        >
          <div className="decision-log-milestone">
            <b>{t('decisionLog.action')}:</b> {linkifyString(milestone.milestoneDescription)}
          </div>
        </div>
        <div className="owner" style={{ display: 'grid', marginBottom: '1rem', gridGap: '0.5rem' }}>
          {milestone.assignees.map((assignee, index) => (
            <>
              <div style={{ display: 'grid', gridTemplateColumns: '0.9fr 0.1fr' }}>
                <span key={index}>
                  {index > 0}
                  {shortenName(assignee.name).slice(0, -1)}{' '}
                </span>
                {assignee.eId === forUser && (
                  <>
                    <input
                      type="checkbox"
                      name="action_id"
                      value={objective.objId + '_' + milestone.milestoneId}
                    />
                  </>
                )}
              </div>
            </>
          ))}
        </div>
      </div>
    )
  }

  const countActionsForThisUserInTopicArray = (topicArray) => {
    let count = 0
    topicArray?.forEach((topic) => {
      topic.milestones?.forEach((milestone) => {
        milestone.assignees?.forEach((assignee) => {
          if (assignee.eId === forUser) {
            count++
          }
        })
      })
    })
    return count
  }

  const countActionsForThisUser = () => {
    if (!decisionLogData?.objectives) return 0

    let count = 0
    for (let objective of decisionLogData.objectives) {
      count += countActionsForThisUserInTopicArray(objective.blockers)
      count += countActionsForThisUserInTopicArray(objective.decisions)
      count += countActionsForThisUserInTopicArray(objective.updates)
    }
    return count
  }

  if (authError) {
    return (
      <ApplicationLayout>
        <b>Unauthorized Access</b>
      </ApplicationLayout>
    )
  }

  if (loading) {
    return (
      <ApplicationLayout>
        <InPageLoader />
      </ApplicationLayout>
    )
  }

  return (
    <>
      <ApplicationLayout>
        <>
          <div style={{ margin: '2.5%' }}>
            <p>{t('decisionLog.digestIntro')}</p>
            {countActionsForThisUser() > 0 ? (
              <p>{t('decisionLog.digestYouHaveActions')}</p>
            ) : (
              <p>{t('decisionLog.digestNoActions')}</p>
            )}
          </div>
          <form action="/resolve_email_action" method="POST">
            {decisionLogData?.objectives?.length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div className="" style={{ width: '95%', marginBottom: '3rem', padding: '2rem' }}>
                  <div className="decision-log-meeting-table">
                    <div className="decision-log-meeting-header">{t('decisionLog.topic')}</div>
                    <div className="decision-log-inner-grid-area-view">
                      <div className="decision-log-meeting-header">{t('decisionLog.outcome2')}</div>
                      <div className="decision-log-meeting-header" style={{ paddingLeft: '0rem' }}>
                        {t('decisionLog.owner')}
                      </div>
                    </div>

                    <div
                      className="divider decision-log-meeting-description"
                      style={{ gridColumn: '1 / 3' }}
                    ></div>

                    {decisionLogData?.objectives?.map((objective, index) => {
                      const { blockers, decisions, updates } = objective
                      const totalRows =
                        (blockers?.length ?? 0) + (decisions?.length ?? 0) + (updates?.length ?? 0)
                      return (
                        <React.Fragment key={`objective-${index}`}>
                          {totalRows > 0 && (
                            <React.Fragment>
                              <div
                                className="decision-log-meeting-description"
                                style={{ gridColumn: '1 / 3' }}
                              >
                                <b>
                                  <ObjectiveStatement>
                                    <b>{t('decisionLog.objective')}:</b> {objective.objDescription}
                                  </ObjectiveStatement>
                                </b>
                                <div className="divider"></div>
                              </div>
                              {blockers?.length > 0 && (
                                <>
                                  {blockers.map((blocker) => (
                                    <>
                                      {/* <div></div> */}
                                      <div className="decision-log-blocker">
                                        {linkifyString(blocker.description)}
                                      </div>
                                      <div>
                                        {blocker?.comments?.length > 0 && (
                                          <>
                                            {blocker.comments.map((comment) => (
                                              <>
                                                {false ? (
                                                  renderComments(comment, objective)
                                                ) : (
                                                  <>
                                                    {comment.showInLog ? (
                                                      renderComments(comment, objective)
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ))}
                                          </>
                                        )}
                                        {blocker?.milestones?.length > 0 && (
                                          <>
                                            {blocker.milestones.map((milestone) => (
                                              <>
                                                {false ? (
                                                  renderMilestones(milestone, objective)
                                                ) : (
                                                  <>
                                                    {milestone.showInLog ? (
                                                      renderMilestones(milestone, objective)
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ))}
                                          </>
                                        )}
                                      </div>
                                    </>
                                  ))}
                                </>
                              )}

                              {decisions?.length > 0 && (
                                <>
                                  {/* {(blockers && blockers.length > 0) ? <div>p</div> : <></>} */}
                                  {decisions.map((decision) => (
                                    <>
                                      {/* <div></div> */}
                                      <div className="decision-log-decision">
                                        {linkifyString(decision.description)}
                                      </div>
                                      <div>
                                        {decision?.comments?.length > 0 && (
                                          <>
                                            {decision.comments.map((comment) => (
                                              <>
                                                {false ? (
                                                  renderComments(comment, objective)
                                                ) : (
                                                  <>
                                                    {comment.showInLog ? (
                                                      renderComments(comment, objective)
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ))}
                                          </>
                                        )}
                                        {decision?.milestones?.length > 0 && (
                                          <>
                                            {decision.milestones.map((milestone) => (
                                              <>
                                                {false ? (
                                                  renderMilestones(milestone, objective)
                                                ) : (
                                                  <>
                                                    {milestone.showInLog ? (
                                                      renderMilestones(milestone, objective)
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ))}
                                          </>
                                        )}
                                      </div>
                                    </>
                                  ))}
                                </>
                              )}
                              {updates?.length > 0 && (
                                <>
                                  {/* {(blockers && blockers.length > 0) || (decisions && decisions.length > 0) ? <div></div> : <></>} */}
                                  {updates.map((update) => (
                                    <>
                                      {/* <div></div> */}
                                      <div className="decision-log-update">
                                        {linkifyString(update.description)}
                                      </div>
                                      <div>
                                        {update?.comments?.length > 0 && (
                                          <>
                                            {update.comments.map((comment) => (
                                              <>
                                                {false ? (
                                                  renderComments(comment, objective)
                                                ) : (
                                                  <>
                                                    {comment.showInLog ? (
                                                      renderComments(comment, objective)
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ))}
                                          </>
                                        )}
                                        {update?.milestones?.length > 0 && (
                                          <>
                                            {update.milestones.map((milestone) => (
                                              <>
                                                {false ? (
                                                  renderMilestones(milestone, objective)
                                                ) : (
                                                  <>
                                                    {milestone.showInLog ? (
                                                      renderMilestones(milestone, objective)
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ))}
                                          </>
                                        )}
                                      </div>
                                    </>
                                  ))}
                                </>
                              )}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ padding: '2rem 0rem 2rem 10rem' }}>
                {t('decisionLog.thereAreNoLogsAtThisTime')}
              </div>
            )}

            {countActionsForThisUser() > 0 && (
              <>
                <center>
                  <input type="hidden" name="digest_id" value={emailId} />
                  <input
                    class="fitted-button blue"
                    type="submit"
                    value={t('decisionLog.resolveSelectedActions')}
                  />
                </center>
              </>
            )}
          </form>
          <p>&nbsp;</p>
        </>
      </ApplicationLayout>
    </>
  )
}

export default DecisionLogDigest
