import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getF2WData = async () => {
  const { data } = await axios.get(`${BASE_URL}/f2w_teams`)
  return data
}

export default function useF2WData() {
  return useQuery(['f2w'], () => getF2WData(), {
    refetchOnWindowFocus: false,
  })
}
