import React, { useState } from 'react'
import '../../../../../MeetingView/components/F2WAssignment/components/f2w-card/styles.css'
import useEditObjectiveRequirement from '../../../../../../api/mutation/editObjectiveRequirement'
import ErrorModal from '../../../../../../components/ErrorModal'
import { Formik, Field, Form } from 'formik'
import F2wCardSchema from '../../../../../../utils/validationSchemas/F2wCardSchema'
import ModalSaveButton from '../../../../../../components/ModalSubmitButton'
import EditCapacityNeeded from '../../../../../../components/CapacityNeededModal/capacityNeededModal'
import useEditRequiredFte from '../../../../../../api/mutation/useEditRequiredFte'
import { useTranslation } from 'react-i18next'

const F2WCard = ({
  tradeoff,
  btnClickHandler,
  teamLeaders,
  workPoolSelectChangeHandler,
  mutationEditActionF2w,
}) => {
  const { t } = useTranslation(['MeetingHub'])
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [isDisabledBtn, setIsDisabledBtn] = useState(true)
  const [displaySaveButtonTeamCapacityNeeds, setDisplaySaveButtonTeamCapacityNeeds] =
    React.useState(false)

  const resetCard = () => {
    setHasUnsavedChanges(false)
    setIsDisabledBtn(true)
    setDisplaySaveButtonTeamCapacityNeeds(false)
  }

  const mutationEditObjectiveFte = useEditRequiredFte()

  const handleTeamCapacityNeedsSave = async (objId, additionalCapacity) => {
    const submitObject = {
      objId: objId,
      additionalCapacity: additionalCapacity,
    }

    await mutationEditObjectiveFte.mutateAsync(
      { ...submitObject },
      {
        onSuccess: (response) => {
          closeCapacityNeededModal()
        },
        onError: () => {
          closeCapacityNeededModal()
          openErrorModal()
        },
      },
    )
  }

  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const [errorModalMessage, setErrorModalMessage] = React.useState(null)
  const openErrorModal = () => {
    setIsErrorModalOpen(true)
  }
  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
    setErrorModalMessage(null)
  }

  const [isCapacityNeededModalOpen, setIsCapacityNeededModalOpen] = React.useState(false)

  const closeCapacityNeededModal = () => {
    setIsCapacityNeededModalOpen(false)
  }
  const openCapacityNeededModal = () => {
    setIsCapacityNeededModalOpen(true)
  }

  return (
    <>
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={closeErrorModal}
        errorMessage={errorModalMessage}
      />

      {isCapacityNeededModalOpen && tradeoff && (
        <EditCapacityNeeded
          tradeoff={tradeoff}
          closeModal={() => {
            closeCapacityNeededModal()
          }}
          handleSubmit={handleTeamCapacityNeedsSave}
        />
      )}
      <div className="f2w-card shadow">
        <div
          className="f2w-bullet-container"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              requiredCapacityInput: parseInt(tradeoff?.requiredFte * 100) ?? '',
              additionalCapacityNeededInput:
                parseInt(tradeoff?.requiredFte * 100 - tradeoff?.allocatedFte * 100) ?? '',
              f2wId: 'null',
            }}
            validationSchema={F2wCardSchema}
            onSubmit={(values, { resetForm }) => {
              resetForm()
            }}
          >
            {({
              errors,
              touched,
              resetForm,
              values,
              setFieldValue,
              validateForm,
              handleChange,
            }) => (
              <Form
                className="f2w-assignment-modal-form"
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <div className="f2w-card-bullet">
                    <b>{t('f2wAssignment.objective')}</b>
                    <span>{tradeoff?.orginalStatement}</span>
                  </div>
                  <div className="f2w-card-bullet">
                    <b>{t('f2wAssignment.teamLeader')}</b>
                    <span>{tradeoff?.ownerName}</span>
                  </div>

                  {/* <div className="f2w-card-bullet">
                    <b>Required Capacity</b>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        width: '100%',
                      }}
                    >
                      <div style={{ paddingRight: '2rem' }}>
                        <Field
                          style={{ width: '5vw' }}
                          className="fte-input"
                          type="number"
                          id="requiredCapacityInput"
                          name="requiredCapacityInput"
                          autoComplete="off"
                          onChange={(e) => {
                            if (e.target.value){
                              setFieldValue('requiredCapacityInput', parseFloat(e.target.value))
                            }else{
                              setFieldValue('requiredCapacityInput', e.target.value)
                            }
                            
                            setDisplaySaveButtonTeamCapacityNeeds(true)
                          }}
                        /> %
                      </div>
                      {displaySaveButtonTeamCapacityNeeds === true && (
                        <button
                          style={{
                            width: '5vw',
                            padding: '0.3rem 0',
                            fontSize: '14px',
                            border: 'none',
                            backgroundColor: 'var(--red)',
                            color: '#fff',
                            borderRadius: '2.5px',
                            cursor: 'pointer',
                            bottom: '4vh',
                          }}
                          onClick={() => {
                            handleTeamCapacityNeedsSave(values)
                          }}
                        >
                          Save
                        </button>
                      )}
                    </div>
                    {errors.requiredCapacityInput && (
                      <div className="validation-error">{errors.requiredCapacityInput}</div>
                    )}
                    {values.requiredCapacityInput <= tradeoff?.allocatedFte.toFixed(2)*100 &&
                      values.requiredCapacityInput > 0 &&
                      values.requiredCapacityInput !== null &&
                      displaySaveButtonTeamCapacityNeeds === true && (
                        <span className="validation-error">
                          This objective will no longer be considered over capacity.
                        </span>
                      )}
                  </div> */}

                  <div className="f2w-card-bullet">
                    <b>{t('f2wAssignment.additionalCapacityNeeded')}</b>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <span style={{ paddingRight: '2rem' }} data-testid="capacityNeeded">
                        {parseInt((tradeoff.requiredFte - tradeoff.allocatedFte) * 100)} %
                      </span>

                      <button
                        style={{
                          width: '5vw',
                          padding: '0.3rem 0',
                          fontSize: '14px',
                          border: 'none',
                          backgroundColor: 'var(--red)',
                          color: '#fff',
                          borderRadius: '2.5px',
                          cursor: 'pointer',
                          bottom: '4vh',
                        }}
                        onClick={(e) => {
                          openCapacityNeededModal()
                        }}
                      >
                        {t('Common:commonButtons.edit')}
                      </button>
                    </div>
                  </div>

                  <div className="f2w-card-bullet">
                    <b>{t('f2wAssignment.originalAllocatedCapacity')}</b>
                    {/* <span>{parseInt(tradeoff?.allocatedFteModified)*100} %</span> */}
                    <span>{parseInt(tradeoff?.allocatedFte * 100)} %</span>
                  </div>

                  <div className="f2w-card-bullet">
                    <b>{t('f2wAssignment.flowToWorkPool')}</b>

                    {/* ***Note:(Ceci) Leaving this for now for reference if something breaks */}
                    {/* <select
                      id="requiredFteInput"
                      className="work-pool-select"
                      defaultValue="null"
                      onChange={(e) => {
                        workPoolSelectChangeHandler(e.target.value)
                        setRequiredCapacityInput(tradeoff.requiredFte.toFixed(2))
                        if (e.target.value !== 'null') {
                          setHasUnsavedChanges(true)
                          setIsDisabledBtn(false)
                        } else {
                          setHasUnsavedChanges(false)
                          setIsDisabledBtn(true)
                        }
                      }}
                    >
                      <option value="null">Assign F2W Team Leader</option>
                      {teamLeaders.map((leader, index) => {
                        return (
                          <option
                            disabled={leader.remainingFte <= 0 ? true : false}
                            key={index}
                            value={leader.f2wTlId}
                          >
                            Leader: {leader.f2wTlName}, Capacity: {leader.remainingFte}
                          </option>
                        )
                      })}
                    </select> */}
                    <Field
                      type="number"
                      as="select"
                      id="f2wId"
                      name="f2wId"
                      onChange={(e) => {
                        setFieldValue('f2wId', e.target.value)

                        workPoolSelectChangeHandler(e.target.value)

                        if (e.target.value !== 'null') {
                          setHasUnsavedChanges(true)
                          setIsDisabledBtn(false)
                        } else {
                          setHasUnsavedChanges(false)
                          setIsDisabledBtn(true)
                        }
                        if (displaySaveButtonTeamCapacityNeeds === true) {
                          setFieldValue('requiredCapacityInput', parseFloat(tradeoff?.requiredFte))
                          setDisplaySaveButtonTeamCapacityNeeds(false)
                        }
                      }}
                    >
                      <option value="null">{t('f2wAssignment.assignF2WTeamLeader')}</option>
                      {teamLeaders.map((leader, index) => {
                        return (
                          <option
                            disabled={leader.remainingFte <= 0 ? true : false}
                            key={index}
                            value={leader.f2wTlId}
                          >
                            {t('f2wAssignment.leader')}: {leader.f2wTlName},{' '}
                            {t('f2wAssignment.capacity')}: {leader.remainingFte * 100}
                          </option>
                        )
                      })}
                    </Field>
                  </div>
                </div>

                <div className="f2w-card-bullet" style={{ alignItems: 'center' }}>
                  {/* ***Note:(Ceci) Fix the situation with css of this button */}
                  {/* <button
                    onClick={(e) => btnClickHandler(e, tradeoff)}
                    className={`f2w-action-assignment-btn${
                      isDisabledBtn ? ' disabled-f2w-btn' : ''
                    }`}
                  >
                    Action
                  </button> */}

                  {isDisabledBtn === true || displaySaveButtonTeamCapacityNeeds === true ? (
                    <button
                      onClick={(e) => btnClickHandler(e, tradeoff)}
                      className={`f2w-action-assignment-btn${
                        isDisabledBtn || displaySaveButtonTeamCapacityNeeds === true
                          ? ' disabled-f2w-btn'
                          : ''
                      }`}
                    >
                      {t('f2wAssignment.action')}
                    </button>
                  ) : (
                    <div className="modal-dual-btn">
                      <ModalSaveButton
                        isLoading={
                          mutationEditActionF2w.isLoading || mutationEditActionF2w.isFetching
                        }
                        tag={'Action'}
                        onClick={() => {
                          btnClickHandler(parseInt(values.f2wId), tradeoff, resetCard)
                        }}
                      />
                    </div>
                  )}

                  <div>
                    {!hasUnsavedChanges ? (
                      <div></div>
                    ) : (
                      <div className="save-warning-text-container">
                        <span className="save-warning-text" style={{ textAlign: 'left' }}>
                          {t('f2wAssignment.unsavedChangesMessage')}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default F2WCard
