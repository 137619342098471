import React, { useState } from 'react'
import '../../../MeetingView/components/F2WAssignment/styles.css'
import F2WCard from './components/f2w-card'

import { useOutletContext } from 'react-router-dom'

const F2WAssignment = () => {
  const { meetingData, F2WData } = useOutletContext()

  const [tradeoffs, setTradeoffs] = useState(meetingData.tradeoffs)

  const [teamLeaders, setTeamLeaders] = useState(F2WData.f2wTeamCapacities)

  const actionAssignmentHandler = async (e, tradeoff) => {
    const thisSelect = e.target.closest('.f2w-card').querySelector('.work-pool-select')

    if (thisSelect.value === '') {
      return
    }

    const postConfig = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        f2wManager: parseInt(thisSelect.value),
        needToTransfer: tradeoff.needToTransfer,
        newAllocatedFte: tradeoff.needToTransfer + tradeoff.allocatedFte,
        toObjId: tradeoff.orginalObjId,
      }),
    }

    const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
    const response = await fetch(`${BASE_URL}/action_f2w`, postConfig)

    const thisTradeOffIndex = meetingData.tradeoffs.indexOf(tradeoff)
    meetingData.tradeoffs.splice(thisTradeOffIndex, 1)
    setTradeoffs([...meetingData.tradeoffs])
  }

  return (
    <div className="f2w-assignment-component">
      <h2>Flow-to-Work Assignment</h2>

      <div className="f2w-card-container">
        {tradeoffs.map((tradeoff, index) => {
          return (
            <F2WCard
              key={index}
              tradeoff={tradeoff}
              btnClickHandler={actionAssignmentHandler}
              teamLeaders={teamLeaders}
              setTeamLeaders={setTeamLeaders}
            />
          )
        })}
      </div>
    </div>
  )
}

export default F2WAssignment
