import React from 'react'
import Modal from '../../Modal'
import { shortenName } from '../../../utils/general'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../utils/routes'
import { meetingMultiViewSubRoutes } from '../../../utils/routes'
import InPageLoader from '../../InPageLoader'

const TeamPerformanceModal = ({
  seeMore,
  setSeeMore = () => {},
  teamPerformanceTable = [],
  setData = () => {},
  setIsModalOpen = () => {},
  name = '',
  isCxo = false,
  isDataLoading = false,
}) => {
  const navigate = useNavigate()
  return (
    <Modal
      title="How is my team doing?"
      isModalOpen={seeMore}
      closeModal={() => {
        setSeeMore(false)
        setData(null)
      }}
      showCard={true}
      width={'75%'}
      titleStyle={{ marginRight: 'auto', color: '#5780ca' }}
      modalContainerStyle={{ width: '90%' }}
    >
      {isDataLoading && <InPageLoader inComponent={true} />}
      {!isDataLoading && (
        <>
          <div style={{ display: 'flex', marginRight: 'auto', fontSize: '25px' }}>{name}</div>
          <div className="chatbot-team-performance-table">
            <div
              className={
                isCxo ? 'chatbot-team-performance-row-cxo' : 'chatbot-team-performance-row'
              }
            >
              <div className="chatbot-team-performance-header">Name</div>
              <div className="chatbot-team-performance-header">
                MEI Status <br />
                <span>(Click for Details)</span>
              </div>
              <div className="chatbot-team-performance-header">Topics # In Last 4 Weeks</div>
              <div className="chatbot-team-performance-header">
                Current Open Topics <br />
                <span>(Click for Details)</span>
              </div>
              <div className="chatbot-team-performance-header">
                Actions Closed vs Added <br />
                <span>(4 weeks)</span>
              </div>
              {isCxo && (
                <div className="chatbot-team-performance-header">
                  Team's MEI
                  <br />
                  <span>(Click for Details)</span>
                </div>
              )}
              <div className="chatbot-team-performance-header">
                Up-to-Date KPIs
                <br />
                <span>(Click for Details)</span>
              </div>
            </div>
            {/* <div className="chatbot-team-performance-header"></div> */}

            {teamPerformanceTable.map((row, index) => {
              let statusColor =
                row?.meiData?.length > 0
                  ? row.meiData.every((e) => e?.mei_status?.is_good)
                    ? '#3bc13b'
                    : 'red'
                  : 'grey'
              if (row?.meiData?.[0]?.mei_status?.is_on_vacation) {
                statusColor = 'grey'
              }
              return (
                <div
                  className={
                    isCxo ? 'chatbot-team-performance-row-cxo' : 'chatbot-team-performance-row'
                  }
                  key={row.leaderId}
                  style={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white' }}
                >
                  <div className="chatbot-team-performance-item">{shortenName(row.leaderName)}</div>
                  <div
                    style={{
                      backgroundColor: statusColor,
                      width: '2rem',
                      height: '2rem',
                      // marginLeft:"1rem"
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setIsModalOpen(true)
                      setData(row.meiData[0])
                    }}
                  ></div>
                  <div className="chatbot-team-performance-item">
                    {row.meiData[0]?.raw_data?.topics_added}
                  </div>
                  <div
                    className="chatbot-team-performance-item"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSeeMore(false)
                      navigate(
                        `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.meetingTopics}`,
                        {
                          state: {
                            id: row.leaderId,
                            name: row.leaderName,
                            redirToTeamTopics: true,
                          },
                        },
                      )
                      navigate(0)
                    }}
                  >
                    {row.topicsAdded}
                  </div>
                  <div className="chatbot-team-performance-item">{`${row.actionsClosed}/${row.actionsAdded}`}</div>
                  {isCxo && (
                    <div
                      className="chatbot-team-performance-item"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        navigate(`/${routes.cxomgmtscore}`)
                        navigate(0)
                      }}
                    >
                      {row.tlsPerformance}
                    </div>
                  )}
                  <div
                    className="chatbot-team-performance-item"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSeeMore(false)
                      let url = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.kpiReportByLeader}`
                      navigate(url, {
                        state: { leaderId: row.leaderId, level: row.isCxo ? 2 : 3 },
                      })
                      navigate(0)
                    }}
                  >{`${row.totalKpiNumber - row.overdueKpiNumber}/${row.totalKpiNumber}`}</div>

                  {/* <div class="row-border"  style={{gridColumn: '1 / 6'}}></div> */}
                </div>
              )
            })}
          </div>
        </>
      )}
    </Modal>
  )
}

export default TeamPerformanceModal
