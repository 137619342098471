import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getEmployeeData = async (reportingTo) => {
  const queryString =
    reportingTo != null ? `/get_employees?reporting_to=${reportingTo}` : '/get_employees?all=1'

  const { data } = await axios.get(`${BASE_URL}${queryString}`)
  return data
}

export default function useEmployeeData(reportingTo = null) {
  return useQuery(['employees', reportingTo], () => getEmployeeData(reportingTo), {
    refetchOnWindowFocus: false,
  })
}
