import React from 'react'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'
import Toast from '../../common/toast'
import { hasRole } from '../../utils/auth'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../utils/routes'

const ChangesReport = ({
  setIsNotficationModalOpen,
  setReports,
  reports,
  buildMutatedText = () => {},
}) => {
  // const [reports, setReports] = React.useState([])

  const { t } = useTranslation(['Common'])
  const navigate = useNavigate()

  React.useEffect(() => {
    getChangeReports()
  }, [])

  const getChangeReports = async () => {
    try {
      let response = await getHttpRequest('/collect_change_reports')
      setReports(response.changesReportItems)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  return (
    <>
      <div>
        {reports.length > 0 && (
          <>
            <div
              className="divider"
              style={{ backgroundColor: '#4472c4', width: '92%', margin: 'auto' }}
            ></div>
            <div style={{ marginLeft: '0.5rem' }}>{t('clickToSeeChanges')}</div>
            {/* {!hasRole('team') && <div style={{display:'flex', justifyContent:'center'}}><span>{t('clickToSeeMore')}</span></div>} */}
          </>
        )}
        {reports.slice(0, 3).map((report, index) => {
          const mutatedText = buildMutatedText(
            report.action,
            report.highlightStartIndex,
            report.highlightEndIndex,
          )
          return (
            <div
              key={report.actionId}
              style={{
                backgroundColor: report.isNew ? '#c6d4ed' : 'rgb(242, 242, 242)',
                color: 'black',
                borderRadius: '0.3rem',
                cursor: 'pointer',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '0.5rem',
                width: '85%',
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
              }}
              onClick={() => {
                console.log(report.type)
                if (!hasRole('team')) {
                  console.log(report.type)
                  let subroute =
                    report.type === 'ACTION_REPORT'
                      ? 'Actions'
                      : report.type === 'KPI_REPORT'
                        ? 'KPIs'
                        : 'MeetingTopics'
                  navigate(`/${routes.changesReport}/${subroute}`)
                }
              }}
            >
              <span
                style={{
                  textAlign: 'left',
                  display: 'block',
                }}
              >
                {mutatedText}
              </span>
              <span
                style={{
                  textAlign: 'right',
                  display: 'block',
                }}
              >
                {report.date}
              </span>
            </div>
          )
        })}
        {reports.length > 3 && (
          <div
            style={{
              cursor: 'pointer',
              backgroundColor: '#c6d4ed',
              color: '#324adc',

              margin: '0.5rem auto 0 auto',
              padding: '0.5rem 0.5rem 0.5rem 0.5rem',
              borderRadius: '0.3rem',
              width: '85%',
            }}
            onClick={() => {
              setIsNotficationModalOpen(true)
            }}
          >
            <span
              style={{
                textAlign: 'center',
                display: 'block',
              }}
            >
              {t('Common:commonButtons.seeMore')}
            </span>
          </div>
        )}
      </div>
    </>
  )
}

export default ChangesReport
