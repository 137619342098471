import React, { useState } from 'react'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList,
  ComposedChart,
  LineChart,
  PieChart,
  Pie,
} from 'recharts'

const TrendsAnalysis = () => {
  const dataTypeOfWorkBeingDone = [
    { name: 'Q1', Enterprise: 36.58, Area: 22.76, BAU: 40.66 },
    { name: 'Q2', Enterprise: 42.17, Area: 21.69, BAU: 36.14 },
    { name: 'Q3', Enterprise: 28.09, Area: 49.44, BAU: 22.47 },
    { name: 'Q4', Enterprise: 49.42, Area: 27.59, BAU: 22.99 },
  ]
  const dataWorkByStrategicObjective = [
    {
      name: 'Q1',
      'Increase Sales Effectiveness': 42.86,
      'Become a Digital Insurer': 28.57,
      'Become an Agile Organization': 28.57,
    },
    {
      name: 'Q2',
      'Increase Sales Effectiveness': 26.67,
      'Become a Digital Insurer': 33.33,
      'Become an Agile Organization': 40,
    },
    {
      name: 'Q3',
      'Increase Sales Effectiveness': 15.39,
      'Become a Digital Insurer': 46.14,
      'Become an Agile Organization': 28.47,
    },
    {
      name: 'Q4',
      'Increase Sales Effectiveness': 49.42,
      'Become a Digital Insurer': 27.59,
      'Become an Agile Organization': 22.99,
    },
  ]

  const dataWorkByImpactMeasure = [
    {
      name: 'Q1',
      'Average New Policies Per Agent': 36.58,
      'Average Quote Rate': 22.76,
      'Average Bind Rate': 40.66,
    },
    {
      name: 'Q2',
      'Average New Policies Per Agent': 42.17,
      'Average Quote Rate': 21.69,
      'Average Bind Rate': 36.14,
    },
    {
      name: 'Q3',
      'Average New Policies Per Agent': 28.09,
      'Average Quote Rate': 49.44,
      'Average Bind Rate': 22.47,
    },
    {
      name: 'Q4',
      'Average New Policies Per Agent': 49.42,
      'Average Quote Rate': 27.59,
      'Average Bind Rate': 22.99,
    },
  ]

  const dataAreWeAllocatingOptimally = [
    { name: 'May', 'Number of People Overallocated': 4 },
    { name: 'June', 'Number of People Overallocated': 2 },
    { name: 'July', 'Number of People Overallocated': 2 },
    { name: 'August', 'Number of People Overallocated': 1 },
  ]

  const dataHowWellAreWeRemovingRoadblocks = [
    { name: 'May', 'Average Time to Resolve a Block (days)': 4 },
    { name: 'June', 'Average Time to Resolve a Block (days)': 7 },
    { name: 'July', 'Average Time to Resolve a Block (days)': 8 },
    { name: 'August', 'Average Time to Resolve a Block (days)': 9 },
  ]

  const dataWhichObjAreImpBlockers = [
    { name: 'Become a digital insurer', 'Number of Blockers': 5 },
    { name: 'Increase sales effectiveness', 'Number of Blockers': 4 },
    { name: 'Transform operating model to be more agile', 'Number of Blockers': 2 },
  ]

  const dataHowMuchWorkIsOnTrack = [
    { name: 'On-track', 'Number of Blockers': 60 },
    { name: 'Off-track', 'Number of Blockers': 40 },
  ]

  const renderCustomizedLabel = (props) => {
    const { content, ...rest } = props
    return (
      <Label
        {...rest}
        fontSize="12"
        fontWeight="Bold"
        fill={'black'}
        color="black"
        textShadow={'black'}
      />
    )
  }

  //Note: Since workObjectives & strategicMeasures have an infinite amount of objectives
  //they can display this array is being used under a circular array logic to repeat colors in
  //case the list of objectives is greater than this list of colors
  let colorArray = ['#D85959', '#fe7f2d', '#fcca46', '#E38888', '#FEA56C', '#FFF7BB']

  return (
    <div style={{ margin: '1rem 3rem', display: 'flex', flexDirection: 'row', columnGap: '4rem' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '50%',
          rowGap: '2rem',
          justifyContent: 'flex-start',
        }}
      >
        <div style={{ alignSelf: 'center' }}>
          <h3 className="scorecard-title">What are we working on?</h3>
          <hr class="solid"></hr>
        </div>

        <div>
          <h3 style={{ fontSize: '14px', width: 'fit-content', paddingbottom: '0.5rem' }}>
            Type of work being done
          </h3>
          <ResponsiveContainer height={200} width="100%">
            <ComposedChart
              layout="vertical"
              data={dataTypeOfWorkBeingDone}
              margin={{ left: 5, right: 10 }}
              barCategoryGap="20%"
              stackOffset="expand"
            >
              <Legend
                layout="horizontal"
                verticalAlign="bottom"
                align="left"
                formatter={(value, entry, index) => (
                  <span style={{ color: 'black', fontSize: '0.8rem' }}>{value}</span>
                )}
              />
              <XAxis
                hide
                axisLine={false}
                tickLine={false}
                //   ticks={[0, 100]}
                tickFormatter={(tick) => `${tick}%`}
                type="number"
                domain={['dataMin', 'dataMax']}
              />
              <YAxis
                type="category"
                dataKey="name"
                stroke="#44444"
                style={{
                  fontSize: '0.8rem',
                }}
              />
              <Tooltip />
              <Bar dataKey="Enterprise" stackId="a" fill="#D85959">
                <LabelList dataKey="Enterprise" position="center" content={renderCustomizedLabel} />
              </Bar>

              <Bar dataKey="Area" stackId="a" fill="#fe7f2d">
                <LabelList dataKey="Area" position="center" content={renderCustomizedLabel} />
              </Bar>
              <Bar dataKey="BAU" stackId="a" fill="#fcca46">
                <LabelList dataKey="BAU" position="center" content={renderCustomizedLabel} />
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
        </div>

        <div>
          <h3 style={{ fontSize: '14px', width: 'fit-content', paddingbottom: '0.5rem' }}>
            Work by strategic objective
          </h3>
          <ResponsiveContainer height={200} width="100%">
            <ComposedChart
              layout="vertical"
              data={dataWorkByStrategicObjective}
              margin={{ left: 5, right: 10 }}
              barCategoryGap="20%"
              stackOffset="expand"
            >
              <Legend
                layout="horizontal"
                verticalAlign="bottom"
                align="left"
                formatter={(value, entry, index) => (
                  <span style={{ color: 'black', fontSize: '0.8rem' }}>{value}</span>
                )}
              />
              <XAxis
                hide
                axisLine={false}
                tickLine={false}
                //   ticks={[0, 100]}
                tickFormatter={(tick) => `${tick}%`}
                type="number"
                domain={['dataMin', 'dataMax']}
              />
              <YAxis
                type="category"
                dataKey="name"
                stroke="#44444"
                style={{
                  fontSize: '0.8rem',
                }}
              />
              <Tooltip />
              <Bar dataKey="Increase Sales Effectiveness" stackId="a" fill="#D85959">
                <LabelList
                  dataKey="Increase Sales Effectiveness"
                  position="center"
                  content={renderCustomizedLabel}
                />
              </Bar>

              <Bar dataKey="Become a Digital Insurer" stackId="a" fill="#fe7f2d">
                <LabelList
                  dataKey="Become a Digital Insurer"
                  position="center"
                  content={renderCustomizedLabel}
                />
              </Bar>
              <Bar dataKey="Become an Agile Organization" stackId="a" fill="#fcca46">
                <LabelList
                  dataKey="Become an Agile Organization"
                  position="center"
                  content={renderCustomizedLabel}
                />
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
        </div>

        <div>
          <h3 style={{ fontSize: '14px', width: 'fit-content', paddingbottom: '0.5rem' }}>
            Work by impact measure
          </h3>
          <ResponsiveContainer height={200} width="100%">
            <ComposedChart
              layout="vertical"
              data={dataWorkByImpactMeasure}
              margin={{ left: 5, right: 10 }}
              barCategoryGap="20%"
              stackOffset="expand"
            >
              <Legend
                layout="horizontal"
                verticalAlign="bottom"
                align="left"
                formatter={(value, entry, index) => (
                  <span style={{ color: 'black', fontSize: '0.8rem' }}>{value}</span>
                )}
              />
              <XAxis
                hide
                axisLine={false}
                tickLine={false}
                //   ticks={[0, 100]}
                tickFormatter={(tick) => `${tick}%`}
                type="number"
                domain={['dataMin', 'dataMax']}
              />
              <YAxis
                type="category"
                dataKey="name"
                stroke="#44444"
                style={{
                  fontSize: '0.8rem',
                }}
              />
              <Tooltip />
              <Bar dataKey="Average New Policies Per Agent" stackId="a" fill="#D85959">
                <LabelList
                  dataKey="Average New Policies Per Agent"
                  position="center"
                  content={renderCustomizedLabel}
                />
              </Bar>

              <Bar dataKey="Average Quote Rate" stackId="a" fill="#fe7f2d">
                <LabelList
                  dataKey="Average Quote Rate"
                  position="center"
                  content={renderCustomizedLabel}
                />
              </Bar>
              <Bar dataKey="Average Bind Rate" stackId="a" fill="#fcca46">
                <LabelList
                  dataKey="Average Bind Rate"
                  position="center"
                  content={renderCustomizedLabel}
                />
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '50%',
          rowGap: '2rem',
          justifyContent: 'flex-start',
        }}
      >
        <div style={{ alignSelf: 'center' }}>
          <h3 className="scorecard-title">How well are we executing?</h3>
          <hr class="solid"></hr>
        </div>

        <div>
          <h3 style={{ fontSize: '14px', width: 'fit-content', paddingbottom: '0.5rem' }}>
            Are we allocating optimally?
          </h3>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart
              width={500}
              height={300}
              data={dataAreWeAllocatingOptimally}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="Number of People Overallocated"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div>
          <h3 style={{ fontSize: '14px', width: 'fit-content', paddingbottom: '0.5rem' }}>
            How well are we removing roadblocks?
          </h3>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart
              width={500}
              height={300}
              data={dataHowWellAreWeRemovingRoadblocks}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="Average Time to Resolve a Block (days)"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3 style={{ fontSize: '14px', width: 'fit-content', paddingbottom: '0.5rem' }}>
              How well are we removing roadblocks?
            </h3>
            <ResponsiveContainer width="100%" height={250}>
              <PieChart width={50} height={50}>
                <Pie
                  data={dataWhichObjAreImpBlockers}
                  dataKey="Number of Blockers"
                  //   outerRadius={250}
                  fill="green"
                >
                  <LabelList dataKey="Number of Blockers" content={renderCustomizedLabel} />
                  {dataWhichObjAreImpBlockers.map((entry, index) => (
                    <Cell fill={colorArray[index % colorArray.length]} />
                  ))}
                </Pie>

                <Tooltip />
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  align="left"
                  formatter={(value, entry, index) => (
                    <span style={{ color: 'black', fontSize: '0.8rem' }}>{value}</span>
                  )}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3 style={{ fontSize: '14px', width: 'fit-content', paddingbottom: '0.5rem' }}>
              How well are we removing roadblocks?
            </h3>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart width={50} height={50}>
                <Pie
                  data={dataHowMuchWorkIsOnTrack}
                  dataKey="Number of Blockers"
                  //   outerRadius={250}
                  fill="green"
                >
                  <LabelList dataKey="Number of Blockers" content={renderCustomizedLabel} />

                  {dataHowMuchWorkIsOnTrack.map((entry, index) => (
                    <Cell fill={colorArray[index % colorArray.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  align="left"
                  formatter={(value, entry, index) => (
                    <span style={{ color: 'black', fontSize: '0.8rem' }}>{value}</span>
                  )}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrendsAnalysis
