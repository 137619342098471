import React from 'react'
import './styles.scss'

const Modal = ({
  children,
  title,
  closeModal,
  width = null,
  height = null,
  includeCloseIcon = true,
  style = {},
  icon = null,
  hasTitle = true,
  showCard = false,
  fixHorizontalScroll = false,
  modalContainerStyle = {},
  titleStyle = {},
  modalTitleContainerStyle = {},
}) => {
  const modalRef = React.useRef()
  const closeRef = React.useRef(null)
  React.useEffect(() => {
    if (modalRef.current && modalRef.current.scrollTo) {
      modalRef.current.scrollTo({ top: 0, left: 0, animated: false })
    }

    // when scroll horizontally, close icon position should be fixed
    const handleCloseIconScroll = () => {
      if (closeRef.current && fixHorizontalScroll) {
        closeRef.current.style.left = '0px'
      }
    }
    const modelDiv = modalRef.current
    modelDiv.addEventListener('scroll', handleCloseIconScroll)
    return () => {
      modelDiv.removeEventListener('scroll', handleCloseIconScroll)
    }
  }, [])
  return (
    <>
      <div className={`modal-overlay`} style={style}>
        <div
          className="modal-container"
          style={{
            width: width === null ? 'auto' : width,
            height: height === null ? 'auto' : height,
          }}
          ref={modalRef}
        >
          <div className="modal-close">
            {includeCloseIcon === false ? null : (
              <span
                class="material-symbols-outlined"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  closeModal()
                }}
                onKeyDown={() => {
                  closeModal()
                }}
                ref={closeRef}
              >
                close
              </span>
            )}
          </div>
          <div
            className={showCard ? 'modal-container-less-padding' : 'modal-container-padding'}
            style={modalContainerStyle}
          >
            {hasTitle && (
              <div className="modal-title-container" style={modalTitleContainerStyle}>
                <h3 style={titleStyle}>
                  <b className="modal-title">{title}</b>
                </h3>
                {icon && (
                  <>
                    {icon === 'warning' && (
                      <span
                        class="material-symbols-outlined icon-size yellow-notification "
                        style={{ backgroundColor: '#fff', border: '0', fontSize: '30px' }}
                      >
                        {icon}
                      </span>
                    )}

                    {icon === 'notifications' && (
                      <span
                        class="material-symbols-outlined icon-size green-notification "
                        style={{ backgroundColor: '#fff', border: '0', fontSize: '30px' }}
                      >
                        {icon}
                      </span>
                    )}
                  </>
                )}
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal
