import React, { useState } from 'react'
import Modal from '../../../components/Modal'
import { getHttpRequest } from '../../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'
import { dateFilter } from '../../../utils/time'
import InPageLoader from '../../../components/InPageLoader'
import Textarea from 'rc-textarea'
import useSaveSpreadsheetData from '../../../api/mutation/useSaveSpreadsheetData'
import AddEditActivityModal from '../../../components/AddEditActivityModal'
import { shortenName } from '../../../utils/general'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import { isDateInPast } from '../../../utils/time'
import { markActivityAsPriority } from '../../EmailReminderTab/components/utils.js'
import CardModal from '../../../components/CardModal'
import { removeMentionMarkupFromText } from '../../../utils/parseMarkup'

const ObjMapActivitiesModal = ({ closeModal, objectiveId, mode, activityId }) => {
  const { t } = useTranslation(['MeetingHub', 'ObjectiveMap', 'Common'])

  const [isLoading, setIsLoading] = React.useState(false)
  const [activityData, setActivityData] = React.useState(null)
  const [inputDescription, setInputDescription] = useState('')
  const [isRefreshObjective, setIsRefreshObjective] = useState('')
  const [isSingleActivityModalOpen, setIsSingleActivityModalOpen] = useState(false)
  const [editActivityId, setEditActivityId] = useState(false)
  const [isTopicModalOpen, setIsTopicModalOpen] = React.useState(false)
  const [topicId, setTopicId] = React.useState(null)
  const [topicType, setTopicType] = React.useState(null)
  const [milestoneStatusList, setMilestoneStatusList] = useState([])

  function getObjectiveTasks(objectiveId) {
    setIsLoading(true)

    // TODO double check if we should use 999999 as limit.
    getHttpRequest(`/kanban_board_activity_data_with_limit/${objectiveId}/${999999}`, {
      params: {
        filter: null,
        is_shared: false,
        show_assigned_activities_only: false,
      },
    })
      .then((response) => {
        const data = response
        if (data && data.milestones) {
          const tasks = Object.values(data.milestones)
          const progressOrder = [
            'NOT_STARTED',
            'ON_TRACK',
            'FOR_REVIEW',
            'COMPLETED',
            'DEPRIORITIZED',
          ]

          tasks.sort((a, b) => {
            // Sort by priority (true comes first)
            if (a.isPriority !== b.isPriority) {
              return a.isPriority ? -1 : 1
            }

            // If priority is the same, sort by progress
            const progressComparison =
              progressOrder.indexOf(a.progress) - progressOrder.indexOf(b.progress)

            // If progress is the same or not found in the predefined order, maintain the current order
            if (progressComparison !== 0) {
              return progressComparison
            }

            // If both priority and progress are the same, sort by createdAt
            return new Date(b.createdAt) - new Date(a.createdAt)
          })

          setActivityData(tasks)
          setMilestoneStatusList(data.milestoneStatusList)
        }
        setIsLoading(false)
      })
      .catch((error) => {})
  }

  function statusEnumStrToVal(status) {
    return milestoneStatusList[status].title
  }

  const mutationSaveSpreadsheetData = useSaveSpreadsheetData()

  const submitForm = async (description) => {
    let actdata = [
      {
        mId__: null,
        description: description,
        progress: 5, // tlActivityProgress(props.column.statusId),
      },
    ]

    let setChangesObj = {
      createdRowIds: Array.from([]),
      updatedRowIds: Array.from([]),
      deletedRowIds: Array.from([]),
      activityData: [],
      idMapping: {},
      columnMetadata: null, // activityData?.columnMetadata,
      objectiveId: objectiveId,
    }
    setChangesObj.createdRowIds = [0]
    actdata[0].id = 0
    setChangesObj.activityData = actdata

    await mutationSaveSpreadsheetData.mutateAsync([objectiveId, setChangesObj], {
      onSuccess: (response) => {
        setInputDescription('')
      },
      onError: (e) => {},
    })

    setIsRefreshObjective((old) => !old)
  }

  const updateDatasetWithFlagVal = (newFlagVal, milestoneId) => {
    // setChangedActivitiesIds([...changedActivitiesIds, milestoneId])
    let newData = activityData.map((activity) => {
      if (activity.milestoneId == milestoneId) {
        activity.isPriority = newFlagVal
      }
      return activity
    })
    setActivityData(newData)
  }

  React.useEffect(() => {
    getObjectiveTasks(objectiveId)
  }, [objectiveId, isRefreshObjective])

  if (isLoading) {
    return (
      <Modal
        isModalOpen={true}
        closeModal={() => {
          closeModal()
        }}
        width={'90%'}
        style={{ padding: '0rem 0rem 1rem 0rem' }}
        title={
          mode === 'activities'
            ? t('ObjectiveMap:modalTitle.activities')
            : t('ObjectiveMap:modalTitle.overdue')
        }
        icon={mode === 'overdue' ? 'warning' : null}
      >
        <InPageLoader inComponent={true} />
      </Modal>
    )
  }

  const topicTypeToStyle = (topicType) => {
    if (topicType == 1) {
      return 'chart-log-blocker'
    } else if (topicType == 2) {
      return 'chart-log-decision'
    } else if (topicType == 3) {
      return 'chart-log-update'
    } else if (topicType == 4) {
      return 'chart-log-admin-topic'
    }
  }

  return (
    <>
      <Modal
        isModalOpen={true}
        closeModal={() => {
          closeModal()
        }}
        width={'90%'}
        style={{ padding: '0rem 0rem 1rem 0rem' }}
        title={
          mode === 'activities'
            ? t('ObjectiveMap:modalTitle.activities')
            : t('ObjectiveMap:modalTitle.overdue')
        }
        icon={mode === 'overdue' ? 'warning' : null}
      >
        <>
          {isSingleActivityModalOpen && (
            <AddEditActivityModal
              isModalOpen={isSingleActivityModalOpen}
              closeModal={() => {
                setIsSingleActivityModalOpen(false)
              }}
              objectiveId={objectiveId}
              activityId={editActivityId}
              setIsRefreshObjective={() => {
                setIsRefreshObjective((old) => !old)
              }}
            />
          )}
          {isTopicModalOpen === true && topicId && topicType && (
            <CardModal
              isModalOpen={isTopicModalOpen}
              closeModal={() => {
                setIsTopicModalOpen(false)
                setTopicId(null)
                setTopicType(null)
              }}
              umtId={topicId}
              topicType={topicType}
              hasTopicType={true}
              fromHomePage={true}
            />
          )}
          {mode === 'activities' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '10px',
                boxShadow: 'rgb(204, 204, 204) 0px 0px 6px',
                borderRadius: '4px',
                backgroundColor: 'white',
                alignItems: 'center',
              }}
            >
              <Textarea
                autoSize
                //rows={2}
                type="text"
                name="activity"
                placeholder={t('Common:formPlaceHolder.typeToAdd')}
                style={{
                  minHeight: '2.5rem',
                  width: '80%',
                  resize: 'none',
                  border: '1px solid rgb(238, 238, 238)',
                  backgroundColor: 'rgba(238, 238, 238, 0.2)',
                  borderRadius: '2.5px',
                  fontFamily: "'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif",
                }}
                onChange={(e) => {
                  setInputDescription(e.target.value)
                }}
                value={inputDescription}
              />
              <div
                class="fitted-button blue"
                onClick={() => {
                  if (inputDescription !== '') {
                    submitForm(inputDescription)
                  }
                }}
              >
                <span class="material-symbols-outlined">add</span>
              </div>
            </div>
          )}
          <br />
          {isLoading && <InPageLoader inComponent={true} />}
          {isLoading === false && activityData && activityData?.length > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                wordBreak: 'break-word',
              }}
            >
              <div style={{ width: '100%', marginBottom: '3rem' }}>
                <div className="activities-table">
                  {/* <div className="chart-log-meeting-header"></div> */}
                  <div
                    style={{ display: 'flex', flexDirection: 'row', fontWeight: 'normal' }}
                    className="chart-log-meeting-header title-border"
                  >
                    <b>{t('scorecard.activityStatement')}</b> &nbsp; &#40;{' '}
                    <div
                      className="meeting-topic-blocker line_break_div"
                      style={{
                        borderLeft: '0.2rem solid rgb(188, 112, 99)',
                        display: 'flex',
                        flexDirection: 'row',
                        textAlign: 'left',
                        padding: '0',
                        margin: '0 .5rem 0 .2rem',
                        height: '1.5rem',
                      }}
                    ></div>
                    {t('Common:modalMessages.fromMeetingTopic')}&#41;{' '}
                  </div>
                  <div className="chart-log-meeting-header title-border">
                    {t('scorecard.assignees')}
                  </div>
                  <div className="chart-log-meeting-header title-border">
                    {t('scorecard.dueDate')}
                  </div>
                  <div className="chart-log-meeting-header title-border">
                    {t('Common:status.status')}
                  </div>
                  <div className="chart-log-meeting-header title-border">
                    {t('scorecard.priority')}
                  </div>
                  <div className="chart-log-meeting-header title-border">More</div>

                  {activityData
                    ?.filter((obj) => {
                      if (mode === 'overdue') {
                        return (
                          isDateInPast(obj.dueDate) === true &&
                          obj.progress !== 'COMPLETED' &&
                          obj.progress !== 'DEPRIORITIZED'
                        )
                      } else {
                        return true // Keep all objects when mode is not "overdue"
                      }
                    })
                    .map((item, index) => {
                      return (
                        <React.Fragment key={`activity-${item?.milestoneId}`}>
                          <div className="chart-log-meeting-description"></div>
                          <>
                            <>
                              {item.umtId === 0 ? (
                                <div
                                  style={{
                                    textAlign: 'left',
                                    paddingLeft: '0.5rem',
                                    backgroundColor:
                                      item.milestoneId === activityId ? '#FBE5D6' : '',
                                  }}
                                >
                                  {removeMentionMarkupFromText(item?.description)}
                                </div>
                              ) : (
                                <div
                                  className={topicTypeToStyle(item.topicType)}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    textAlign: 'left',
                                  }}
                                >
                                  <div>{removeMentionMarkupFromText(item?.description)}</div>
                                  &nbsp;
                                  <div class="fitted-button blue tooltip" type="button">
                                    <span
                                      class="material-symbols-outlined"
                                      onClick={() => {
                                        setTopicType(item.topicType)
                                        setTopicId(item.umtId)

                                        setIsTopicModalOpen(true)
                                      }}
                                    >
                                      info
                                    </span>
                                    <span class="tooltiptext">{item?.meetingTopicDescription}</span>
                                  </div>
                                </div>
                              )}
                              <div style={{ textAlign: 'left' }}>
                                {item?.assigneeList?.length > 1 ? (
                                  item?.assigneeList?.map((assignee, ind) => (
                                    <>
                                      <span key={assignee.name}>
                                        {assignee.name.replace(/(\b\w+\b)\s(\b\w)\w*\b/, '$1 $2')}
                                      </span>
                                      {ind !== item?.assigneeList?.length - 1 && <span>, </span>}
                                    </>
                                  ))
                                ) : item?.assigneeList?.length > 0 ? (
                                  <span>{shortenName(item?.assigneeList[0].name)}</span>
                                ) : (
                                  <span>--</span>
                                )}
                              </div>
                              <div style={{ textAlign: 'left' }}>
                                {item?.dueDate == 0 ? '--' : dateFilter(item?.dueDate)}
                              </div>
                              <div style={{ textAlign: 'left' }}>
                                {statusEnumStrToVal(item?.progress)}
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                {!item.isPriority && (
                                  <button
                                    class="icon-button-no-border"
                                    style={{ display: 'inline-block' }}
                                  >
                                    <span
                                      class="material-symbols-outlined icon-size"
                                      onClick={() => {
                                        markActivityAsPriority(true, item.milestoneId)
                                        updateDatasetWithFlagVal(true, item.milestoneId)
                                      }}
                                    >
                                      flag
                                    </span>
                                  </button>
                                )}
                                {item.isPriority && (
                                  <button
                                    class="icon-button-no-border"
                                    style={{ display: 'inline-block' }}
                                  >
                                    <span
                                      class="material-symbols-outlined icon-size"
                                      style={{ color: 'red' }}
                                      onClick={() => {
                                        markActivityAsPriority(false, item.milestoneId)
                                        updateDatasetWithFlagVal(false, item.milestoneId)
                                      }}
                                    >
                                      flag
                                    </span>
                                  </button>
                                )}
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                <div>
                                  <Menu
                                    menuButton={
                                      <MenuButton
                                        style={{
                                          backgroundColor: 'white',
                                          border: 'none',
                                          padding: '0',
                                        }}
                                      >
                                        <span
                                          style={{ alignSelf: 'center' }}
                                          class="material-symbols-outlined"
                                        >
                                          more_vert
                                        </span>
                                      </MenuButton>
                                    }
                                  >
                                    <>
                                      <MenuItem
                                        style={{ textAlign: 'left' }}
                                        onClick={() => {
                                          setEditActivityId(item.milestoneId)
                                          setIsSingleActivityModalOpen(true)
                                        }}
                                      >
                                        {t('summaryOfObjectives.edit')}
                                      </MenuItem>
                                    </>
                                  </Menu>
                                </div>
                              </div>
                              <div class="row-border" style={{ gridColumn: '1 / 7' }}></div>
                              {/* <div>
                            </div> */}
                            </>
                          </>{' '}
                        </React.Fragment>
                      )
                    })}
                </div>
              </div>
            </div>
          ) : (
            <div style={{ padding: '2rem' }}>{t('ObjectiveMap:legend.noActivities')}</div>
          )}
        </>
      </Modal>
    </>
  )
}

export default ObjMapActivitiesModal
