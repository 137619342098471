import React from 'react'

const TranscriptSummary = ({ meetingSummary }) => {
  return (
    <div className="meeting-transcription-body">
      <ul>
        {meetingSummary.map((transcript, index) => {
          return (
            <>
              <li key={index} style={{ marginTop: '0.5rem' }}>
                <span style={{ fontSize: '14px', marginLeft: '-3px' }}>{transcript.summary}</span>
              </li>
            </>
          )
        })}
      </ul>
    </div>
  )
}

export default TranscriptSummary
