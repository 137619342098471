import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const editObjectiveRequirement = async (objectiveData) => {
  const { data } = await axios.post(`${BASE_URL}/update_obj_requirement`, objectiveData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useEditObjectiveRequirement() {
  const client = useQueryClient()

  return useMutation((data) => editObjectiveRequirement(data), {
    onSuccess: (response) => {
      if (response) {
        void client.invalidateQueries('multi-meeting')
        void client.invalidateQueries('f2w')
      }
    },
  })
}
