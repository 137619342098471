import React, { useState } from 'react'
import Modal from '../Modal'
import InPageLoader from '../InPageLoader'
import { useTranslation } from 'react-i18next'
import Card from '../../pages/MeetingMultiView/components/MeetingTopics/common/Card'
import Toast from '../../common/toast'
import {
  meetingCommentAdd,
  meetingMilestonAdd,
  getSingleMeetingTopicDetails,
  useUpdateMeetingLogs,
} from '../../api/services/meetingTopic.services'
import { getCompanyConfigSettings } from '../../utils/auth'
import { getObjectivUserList } from '../../api/services/employee.services'
import { useMeetingRecording } from '../../utils/meetingRecordingContext/meetingRecordingContext'

const CardModal = ({
  isModalOpen,
  closeModal,
  umtId,
  topicType,
  hasTopicType = false,
  fromHomePage = false,
}) => {
  const { t } = useTranslation(['Common'])
  const [objData, setObjData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [flag, setFlag] = React.useState(false)
  const [resolved, setResolved] = useState(false)
  const isProductSettingsEnabled = getCompanyConfigSettings('productSettings')
  const [userList, setUserList] = React.useState([])
  const { state, dispatch, updateTopicFocusFn } = useMeetingRecording()
  const [currentSelection, setCurrentSelection] = useState([])

  async function getScreenData() {
    const postObj = { umtId: umtId, topicType: topicType }
    const result = await getSingleMeetingTopicDetails(postObj)
    setIsLoading(false)
    if (result) {
      if (result?.blockedObjectives?.length > 0) {
        setObjData(result?.blockedObjectives[0])
        setCurrentSelection(result?.blockedObjectives)
        setFlag(result?.blockedObjectives[0]?.isPriority)
        setResolved(!result?.blockedObjectives[0]?.status)
      } else if (result?.decisionObjectives?.length > 0) {
        setObjData(result?.decisionObjectives[0])
        setCurrentSelection(result?.decisionObjectives)
        setFlag(result?.decisionObjectives[0]?.isPriority)
        setResolved(!result?.decisionObjectives[0]?.status)
      } else if (result?.updateObjectives?.length > 0) {
        setObjData(result?.updateObjectives[0])
        setCurrentSelection(result?.updateObjectives)
        setFlag(result?.updateObjectives[0]?.isPriority)
        setResolved(!result?.updateObjectives[0]?.status)
      } else if (result?.adminTopicObjectives?.length > 0) {
        setObjData(result?.adminTopicObjectives[0])
        setCurrentSelection(result?.adminTopicObjectives)
        setFlag(result?.adminTopicObjectives[0]?.isPriority)
        setResolved(!result?.adminTopicObjectives[0]?.status)
      }
      setIsLoading(false)
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const getAllEmployees = async () => {
    const response = await getObjectivUserList(0)
    if (response?.employees)
      setUserList(response.employees.map((emp) => ({ display: emp.name, id: emp.eId })))
  }

  React.useEffect(() => {
    getScreenData()
    getAllEmployees()
  }, [])

  async function createComment(data) {
    setIsLoading(true)
    const result = await meetingCommentAdd(data)
    setIsLoading(false)
    if (result) {
      getScreenData()
      Toast.fire({
        icon: 'success',
        title: t('Common:modalMessages.commentUpdatedSuccessfully'),
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  async function createActivity(data, comment, index, mentions = []) {
    const formData = {
      milestoneDesc: comment,
      goalId: data?.objectiveId,
      mentions: mentions.map((mention) => mention.id),
    }

    formData.umtId = data.umtId

    setIsLoading(true)
    const result = await meetingMilestonAdd(formData)
    setIsLoading(false)
    if (result) {
      getScreenData()
      Toast.fire({
        icon: 'success',
        title: t('Common:modalMessages.actionUpdatedSuccesfully'),
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const mutationUpdateMeetingLogs = useUpdateMeetingLogs()

  async function createFlagStatus(data, type) {
    const formData = {}
    if (type == 'flag') {
      formData.isPriority = !data?.isPriority
    } else if (type == 'status') {
      formData.status = !data?.status
    }

    formData.umtId = data?.umtId

    await mutationUpdateMeetingLogs.mutateAsync(formData, {
      onSuccess: () => {
        getScreenData()
      },
      onError: () => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      },
    })
  }

  const updateCardRecordingState = async (umtId, objId, newValue, newState) => {
    dispatch({
      type: 'SET_CARD_RECORDING_STARTED',
      umtId: umtId,
      newValue: newValue,
    })
    console.log(state)
    await updateTopicFocusFn(umtId, objId, newState, currentSelection, getScreenData)
  }

  return (
    <Modal
      style={{ zIndex: 104 }}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      hasTitle={false}
      showCard={true}
      width={'50%'}
    >
      {isLoading && <InPageLoader inComponent={true} />}
      {!isLoading && objData && (
        <div style={{ textAlign: 'left', wordBreak: 'normal' }}>
          <Card
            data={objData}
            createFlagStatus={createFlagStatus}
            flag={flag}
            createComment={createComment}
            createActivity={createActivity}
            showFull={false}
            loadAgain={getScreenData}
            refreshBGUScorecardFn={() => {}}
            resolved={resolved}
            viewOnlyMode={false}
            flagStatusLoading={
              mutationUpdateMeetingLogs.isLoading || mutationUpdateMeetingLogs.isFetching
            }
            hasTopicType={hasTopicType}
            showInitiativesId={isProductSettingsEnabled}
            fromHomePage={fromHomePage}
            showDropDownMenu={false}
            userList={userList}
            updateCardRecordingState={updateCardRecordingState}
          />
        </div>
      )}
    </Modal>
  )
}
export default CardModal
