import React, { useEffect, useState } from 'react'
import Modal from '../../../components/Modal'
import { useTranslation } from 'react-i18next'
import { getHttpRequest, postHttpRequest } from '../../../api/query/dynamicAPI'
import Toast from '../../../common/toast'
import InPageLoader from '../../../components/InPageLoader'
import { ModalSaveButtonFitted } from '../../../components/ModalSubmitButton'

const SelectTasksModal = ({ noteId, noteDescription, noteTitle, closeModal }) => {
  const { t } = useTranslation(['MyNotebook', 'Common'])
  const [isLoading, setIsLoading] = useState(false)
  const [suggestedTasks, setSuggestedTasks] = useState(null)
  const [selectedIndexTaskArray, setSelectedIndexTaskArray] = useState([])
  console.log('isLoading: ', isLoading)
  React.useEffect(() => {
    getSuggestedTasks()
  }, [])

  const getSuggestedTasks = async () => {
    setIsLoading(true)
    let postObj = {
      note_id: noteId ?? null,
      note_description: noteDescription ?? null,
      note_title: noteTitle ?? null,
    }

    postHttpRequest(`/convert_my_notebook_note_to_tasks`, postObj)
      .then((response) => {
        if (response.task_list) {
          setSuggestedTasks(response.task_list)
          setSelectedIndexTaskArray(new Array(response.task_list.length).fill(false))
        } else {
          Toast.fire({
            icon: 'error',
          })
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false)
      })
  }

  const saveSelectedTasks = async () => {
    let postObj = {
      task_list: [],
    }

    for (let i = 0; i < suggestedTasks.length; i++) {
      if (selectedIndexTaskArray[i]) {
        postObj.task_list.push(suggestedTasks[i])
      }
    }

    postHttpRequest(`/save_many_my_notebook_tasks`, postObj)
      .then((response) => {
        if (response.success === true) {
          Toast.fire({
            icon: 'success',
            title: t('Common:modalMessages.tasksSavedSuccessfully'),
          })
        } else {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.somethingWentWrongPleaseTryAgain'),
          })
        }
      })
      .catch((error) => {})
  }

  return (
    <>
      <Modal
        title={t('selectTasks')}
        closeModal={() => {
          closeModal()
        }}
      >
        {isLoading && <InPageLoader isLoading={isLoading} inComponent={true} />}
        {!isLoading && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {suggestedTasks &&
              suggestedTasks.map((task, index) => {
                return (
                  <div key={index} style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                    <input
                      style={{ marginLeft: '10px' }}
                      type="checkbox"
                      name="type"
                      onChange={(e) => {
                        let tempArray = selectedIndexTaskArray
                        tempArray[index] = e.target.checked
                        setSelectedIndexTaskArray(tempArray)
                      }}
                      defaultChecked={false}
                    />
                    <div>{task}</div>
                  </div>
                )
              })}
          </div>
        )}
        {!isLoading && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyItems: 'center',
              justifyContent: 'center',
              paddingTop: '2rem',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
              <ModalSaveButtonFitted
                tag={t('Common:commonButtons.cancel')}
                onClick={() => {
                  closeModal()
                }}
              />

              <ModalSaveButtonFitted
                tag={t('Common:commonButtons.save')}
                onClick={() => {
                  saveSelectedTasks()
                  closeModal()
                }}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}
export default SelectTasksModal
