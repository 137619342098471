import React from 'react'

import { useTranslation } from 'react-i18next'
import 'react-tooltip/dist/react-tooltip.css'
import useEditKanbanBoardChangeActivityStatus from '../../../api/mutation/useEditKanbanBoardChangeActivityStatus'
import './style.css'
import { markActivityAsPriority } from './utils.js'
import { linkifyString } from '../../../utils/general'
import { dateFilter, dayMonthFilter } from '../../../utils/time'
import AddEditActivityModal from '../../../components/AddEditActivityModal'
import SooDropdownMenu from '../../../components/SooDropdownMenu'
import CommentsModal2 from '../../../components/CommentsModal2'
import { removeMentionMarkupFromText } from '../../../utils/parseMarkup'

const OtherActivities = ({
  data,
  refreshBGUScorecardFn,
  refreshPendingListData = () => {},
  printMode,
  showFlaggedOnly = false,
  searchFilterInput = '',
}) => {
  const { t } = useTranslation(['MeetingHub', 'Common'])
  const [dataToShow, setDataToShow] = React.useState(data?.activities)
  const [isCompletedBtns, setIsCompletedBtns] = React.useState({})
  const [changedActivitiesIds, setChangedActivitiesIds] = React.useState([])
  const changeActivityStatusMutation = useEditKanbanBoardChangeActivityStatus()

  const [isActivityEditModalOpen, setIsActivityEditModalOpen] = React.useState(false)
  const [objectiveIdSelected, setObjectiveIdSelected] = React.useState(null)
  const [taskIdSelected, setTaskIdSelected] = React.useState(null)
  const [isCommentModalOpen, setIsCommentModalOpen] = React.useState(false)
  const [objective, setObjective] = React.useState({})

  React.useEffect(() => {
    if (data) {
      let filteredData = data.activities

      // Apply showFlaggedOnly filter if enabled
      if (showFlaggedOnly) {
        filteredData = filteredData.filter((activity) => activity.isPriority)
      }

      // Apply search filter if filterInput is not empty
      if (searchFilterInput.trim() !== '') {
        filteredData = filteredData.filter(
          (activity) =>
            activity?.objDescription.toLowerCase().includes(searchFilterInput.toLowerCase()) ||
            activity?.meetingTopicDescription
              .toLowerCase()
              .includes(searchFilterInput.toLowerCase()) ||
            activity?.description.toLowerCase().includes(searchFilterInput.toLowerCase()) ||
            activity?.createdByAssignee?.name
              .toLowerCase()
              .includes(searchFilterInput.toLowerCase()),
        )
      }

      setDataToShow(filteredData)
    }
  }, [data, showFlaggedOnly, searchFilterInput])

  const handleCompleteClick = (objectiveId, milestoneId, index, isOpen) => async (e) => {
    if (isCompletedBtns[e.target.id] === undefined || isCompletedBtns[e.target.id] === false) {
      if (!isOpen) {
        await changeActivityStatus(objectiveId, milestoneId, 'ON_TRACK', index, isOpen)
      } else {
        await changeActivityStatus(objectiveId, milestoneId, 'COMPLETED', index, isOpen)
      }
    } else {
      if (isCompletedBtns[e.target.id] && !isOpen) {
        await changeActivityStatus(objectiveId, milestoneId, 'ON_TRACK', index, isOpen)
      } else {
        await changeActivityStatus(objectiveId, milestoneId, 'COMPLETED', index, isOpen)
      }
    }
  }

  const changeActivityStatus = async (objectiveId, milestoneId, newStatus, index, isOpen) => {
    const postObject = {
      objectiveId,
      milestoneId,
      newStatus,
    }

    await changeActivityStatusMutation.mutateAsync(postObject, {
      onSuccess: (response) => {
        setIsCompletedBtns((prev) => ({
          ...prev,
          [milestoneId]: prev[milestoneId] === undefined ? true : !prev[milestoneId],
        }))
        if (!isOpen) {
          dataToShow[index].isOpen = true
        } else {
          dataToShow[index].isOpen = false
        }
        refreshBGUScorecardFn()
      },
      onError: () => {},
    })
  }

  // Sort activities by date. We need to extract the activity from the objective first.
  const cmpSortDataByDuedate = (action1, action2) => {
    if (action1.dueDate == action2.dueDate) return 0
    return action1.dueDate > action2.dueDate ? -1 : 1 // Reverse sort.
  }

  const updateDatasetWithFlagVal = (newFlagVal, milestoneId) => {
    setChangedActivitiesIds([...changedActivitiesIds, milestoneId])
    let newData = dataToShow.map((action) => {
      if (action.milestoneId == milestoneId) {
        action.isPriority = newFlagVal
      }
      return action
    })

    setDataToShow(newData)
  }

  return (
    <>
      {isActivityEditModalOpen === true && objectiveIdSelected && taskIdSelected && (
        <AddEditActivityModal
          isModalOpen={isActivityEditModalOpen}
          closeModal={() => {
            setIsActivityEditModalOpen(false)
            setObjectiveIdSelected(null)
            setTaskIdSelected(null)
          }}
          objectiveId={objectiveIdSelected}
          activityId={taskIdSelected}
          refreshBGUScorecardFn={refreshBGUScorecardFn}
          setIsRefreshObjective={refreshPendingListData}
          fromModal={true}
          hasTopicType={true}
        />
      )}
      {isCommentModalOpen && (
        <CommentsModal2
          objective={{ id: objectiveIdSelected, ...objective }}
          taskId={taskIdSelected}
          handleCancel={() => {
            setIsCommentModalOpen(false)
          }}
          setIsRefreshObjective={() => {}}
        />
      )}
      <div
        style={{
          margin: '2rem 0 0 1rem',
          display: 'flex',
          fontWeight: 'bold',
          marginBottom: '1rem',
        }}
      >
        <span>{t('reminderEmailOtherActivities')}</span>
      </div>
      <div>
        <div style={{ marginLeft: '1.5rem' }}>
          {dataToShow && dataToShow.length > 0 ? (
            <div
              className={
                printMode === false
                  ? 'reminder-email-other-activities-table'
                  : 'reminder-email-other-activities-table-print'
              }
            >
              <div className="chart-log-meeting-header title-border">
                {t('scorecard.objectiveTitle')}
              </div>
              <div className="chart-log-meeting-header title-border">{t('scorecard.action')}</div>
              <div className="chart-log-meeting-header title-border">
                {t('scorecard.createdBy')}
              </div>
              <div className="chart-log-meeting-header title-border">
                {t('scorecard.createdOn')}{' '}
                <span style={{ fontWeight: 'normal' }}> &#40;mm/dd&#41; </span>
              </div>
              <div className="chart-log-meeting-header title-border">
                {t('scorecard.dueDate')}{' '}
                <span style={{ fontWeight: 'normal' }}> &#40;mm/dd&#41; </span>
              </div>
              {printMode === false && (
                <div className="chart-log-meeting-header title-border">
                  {t('scorecard.complete')}
                </div>
              )}
              {printMode === false && (
                <div className="chart-log-meeting-header title-border">
                  {t('scorecard.priority')}
                </div>
              )}
              {printMode === false && (
                <div className="chart-log-meeting-header title-border">{t('scorecard.more')}</div>
              )}
              {printMode === true && <div></div>}

              {dataToShow?.map((action, actionIndex) => {
                return (
                  <>
                    <React.Fragment key={`objective-${action.milestoneId}`}>
                      {/* <div></div> */}
                      <div className="chart-log-meeting-description"></div>
                      <>
                        {/* <div></div> */}
                        <>
                          <div
                            style={{ textAlign: 'left', wordWrap: 'break-word' }}
                            data-testid="action-item"
                          >
                            {action.isObjCompleted ? (
                              <div
                                style={{ color: 'grey' }}
                                data-testid={`completed-${action.milestoneId}`}
                              >
                                <b>{t('Common:completedCaps')}</b>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div>
                              <span data-testid={`objDescription-${action.milestoneId}`}>
                                {action.objDescription}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{ textAlign: 'left', wordWrap: 'break-word' }}
                            data-testid={`actionDescription-${action.milestoneId}`}
                          >
                            {linkifyString(removeMentionMarkupFromText(action.description))}
                          </div>
                          <div style={{ textAlign: 'left', wordWrap: 'break-word' }}>
                            <span key={action?.createdByAssignee?.name}>
                              {action?.createdByAssignee?.name ? (
                                action.createdByAssignee.name.replace(
                                  /(\b\w+\b)\s(\b\w)\w*\b/,
                                  '$1 $2',
                                )
                              ) : (
                                <>---</>
                              )}
                            </span>
                          </div>
                          <div style={{ textAlign: 'left' }}>
                            {dayMonthFilter(action.createdAt)}
                          </div>
                          {/* <div>{activity.assigneeList?.map(assignee => <b key={assignee.name}>{assignee.name}</b>)}</div> */}
                          <div style={{ textAlign: 'left' }}>
                            {action.dueDate == '0' ? '--' : dayMonthFilter(action.dueDate)}
                          </div>
                          {printMode === false && (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <div
                                data-testid={`complete-${action.milestoneId}`}
                                class="fitted-button checklist"
                                onClick={(e) => {
                                  handleCompleteClick(
                                    action.objectiveId,
                                    action.milestoneId,
                                    actionIndex,
                                    action.isOpen,
                                  )(e)
                                }}
                              >
                                <span
                                  data-testid={`completeBtn-${action.milestoneId}`}
                                  class={
                                    action.isOpen
                                      ? 'material-symbols-outlined grey-check'
                                      : 'material-symbols-outlined green-check '
                                  }
                                >
                                  {' '}
                                  done
                                </span>
                              </div>
                            </div>
                          )}
                          {printMode === false && (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              {!action.isPriority && (
                                <button class="icon-button-no-border">
                                  <span
                                    data-testid={`priority-${action.milestoneId}`}
                                    class="material-symbols-outlined icon-size"
                                    onClick={() => {
                                      markActivityAsPriority(true, action.milestoneId)
                                      updateDatasetWithFlagVal(true, action.milestoneId)
                                    }}
                                  >
                                    flag
                                  </span>
                                </button>
                              )}
                              {action.isPriority && (
                                <button class="icon-button-no-border">
                                  <span
                                    data-testid={`priority-${action.milestoneId}`}
                                    class="material-symbols-outlined icon-size"
                                    style={{ color: 'red' }}
                                    onClick={() => {
                                      markActivityAsPriority(false, action.milestoneId)
                                      updateDatasetWithFlagVal(false, action.milestoneId)
                                    }}
                                  >
                                    flag
                                  </span>
                                </button>
                              )}
                            </div>
                          )}
                          {printMode === false && (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <SooDropdownMenu
                                fromModal={true}
                                onClickEdit={() => {
                                  setObjectiveIdSelected(action.objectiveId)
                                  setTaskIdSelected(action.milestoneId)
                                  setIsActivityEditModalOpen(true)
                                }}
                                onClickComment={() => {
                                  //setObjective({})
                                  setObjectiveIdSelected(action.objectiveId)
                                  setTaskIdSelected(action.milestoneId)
                                  setIsCommentModalOpen(true)
                                }}
                              />
                            </div>
                          )}
                          {printMode === true && (
                            <>
                              {action.isPriority ? (
                                <button class="icon-button-no-border">
                                  <span
                                    class="material-symbols-outlined icon-size"
                                    style={{ color: 'red' }}
                                  >
                                    flag
                                  </span>
                                </button>
                              ) : (
                                <div></div>
                              )}
                            </>
                          )}
                        </>
                      </>
                    </React.Fragment>
                  </>
                )
              })}
            </div>
          ) : (
            <div style={{ display: 'flex', marginBottom: '1rem' }}>
              {t('Common:emailReminder.noItemsMsg')}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default OtherActivities
