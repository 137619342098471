import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const deleteTeamLeaderActivity = async (activityId) => {
  const { data } = await axios.post(`${BASE_URL}/tl_activity_delete`, activityId, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return data
}

export default function useDeleteTeamLeaderActivity() {
  const client = useQueryClient()
  return useMutation((activityId) => deleteTeamLeaderActivity(activityId), {
    onSuccess: (response) => {
      if (response.ok) {
        void client.invalidateQueries('team-leader-activities')
      }
    },
  })
}
