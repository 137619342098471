import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import './style.css'
import CreatableSelect from 'react-select/creatable'
import { useEffect, useState } from 'react'
import { getHttpRequest, postHttpRequest } from '../../api/query/dynamicAPI'
import Toast from '../../common/toast'
import SelectField from '../CustomMultipleSelect/singleOption'

const TagsManagementModal = ({
  closeModal,
  objectiveId,
  setEditedObj,
  tags,
  objective,
  meetingtopic,
  meetingtopicId,
}) => {
  const { t } = useTranslation(['Kanban'])
  const [tagsList, setTagsList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [id, setId] = useState(0)
  const [passedTags, setPassedTags] = useState([])
  const [selectedOption, setSelectedOption] = useState([])

  const [companyMeetings, setCompanyMeetings] = useState([])
  const [topicMeetings, setTopicMeetings] = useState([])

  useEffect(() => {
    getTags()
  }, [])
  const getTags = () => {
    if (objective) {
      return getObjectiveTags()
    }
    if (meetingtopic) {
      getMeetingTopicTags()
      // if(isFromMeetingHub){
      getMeetings()
      getMeetingTopicMeetings()
      // }
    }
  }
  const handleChangeTags = () => {
    if (objective) {
      return handleChangeObjTags()
    }
    if (meetingtopic) {
      return handleChangeMeetingTopicTags()
    }
  }

  const getMeetingTopicMeetings = async () => {
    try {
      let result = await getHttpRequest(`/get_meeting_topic_meetings/${meetingtopicId}`)
      setTopicMeetings(result.meetings)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const getMeetings = async () => {
    try {
      let result = await getHttpRequest('/get_company_meetings')
      setCompanyMeetings(result.data)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const getMeetingTopicTags = async () => {
    let result
    try {
      result = await getHttpRequest(`/get_meeting_topic_tags?topic_id=${meetingtopicId}`)
      let existingtagsList = result.success.map((item) => ({
        id: item[0],
        name: item[1],
        topic_id: item[2],
      }))
      setPassedTags(existingtagsList)
      setSelectedOption(result.success.map((item) => ({ value: item[0], label: item[1] })))
      let companyTags = await getHttpRequest(`/get_company_tags`)
      if (companyTags.success.length > 0) {
        const comtags = companyTags.success.map((item) => ({
          id: item[0],
          name: item[1],
          topic_id: item[2],
        }))
        setTagsList(comtags)
      }
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }
  const getObjectiveTags = async () => {
    let result
    try {
      result = await getHttpRequest(`/get_objective_tags?obj_id=${objectiveId}`)
      let existingtagsList = result.success.map((item) => ({
        id: item[0],
        name: item[1],
      }))
      setPassedTags(existingtagsList)
      setSelectedOption(result.success.map((item) => ({ value: item[0], label: item[1] })))
      let companyTags = await getHttpRequest(`/get_company_tags`)

      if (companyTags.success.length > 0) {
        const comtags = companyTags.success.map((item) => ({
          id: item[0],
          name: item[1],
        }))
        setTagsList(comtags)
      }
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption)
  }

  const handleChangeObjTags = async () => {
    try {
      let postData = {}
      //set deleted tags
      let delete_list = passedTags.filter(
        (item) => !selectedOption.some((option) => option.label === item.name),
      )
      delete_list.forEach((item) => {
        item['tagId'] = item.id
        item['isDeleted'] = true
      })
      postData['tags'] = delete_list
      postData['obj_id'] = objectiveId
      //set added tags
      let notInPassedTags = selectedOption.filter(
        (item) => !passedTags.some((option) => option.name === item.label),
      )
      let add_list = notInPassedTags
        .filter((item) => !item.hasOwnProperty('isDeleted'))
        .map((item) => ({ tagName: item.label }))
      postData['tags'] = postData['tags'].concat(add_list)
      let result = await postHttpRequest(`/save_objective_tags`, postData)
      setEditedObj({
        objId: objectiveId,
        tags: selectedOption.map((item) => {
          if (item.value < 1 && result.success.length > 0) {
            return {
              tagId: result.success.filter((tag) => tag[1] === item.label)[0][0],
              tagName: item.label,
            }
          }
          return { tagId: item.value, tagName: item.label }
        }),
      })
      closeModal()
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }
  const handleChangeMeetingTopicTags = async () => {
    try {
      let postData = {}
      //set deleted tags
      let delete_list = passedTags.filter(
        (item) => !selectedOption.some((option) => option.label === item.name),
      )
      delete_list.forEach((item) => {
        item['tagId'] = item.id
        item['isDeleted'] = true
      })
      postData['tags'] = delete_list
      postData['umt_id'] = meetingtopicId
      //set added tags
      let notInPassedTags = selectedOption.filter(
        (item) => !passedTags.some((option) => option.name === item.label),
      )
      let add_list = notInPassedTags
        .filter((item) => !item.hasOwnProperty('isDeleted'))
        .map((item) => ({ tagName: item.label }))
      postData['tags'] = postData['tags'].concat(add_list)

      if (meetingtopic) {
        postData['meetings'] = topicMeetings.map((item) => item.meetingId)
      }

      let result = await postHttpRequest(`/save_meetingtopic_tags`, postData)
      closeModal()
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }
  const handleCreate = (inputValue) => {
    setIsLoading(true)
    setTimeout(() => {
      const newOption = createOption(inputValue)
      setIsLoading(false)
      setTagsList((tagsList) => [...tagsList, newOption])
      setSelectedOption((selectedOption) => [
        ...selectedOption,
        { value: newOption.id, label: newOption.name },
      ])
    }, 1000)
  }

  const createOption = (label) => {
    setId(id - 1)
    if (objective) {
      return {
        id: id,
        name: label,
        objId: id,
      }
    }
    if (meetingtopic) {
      return {
        id: id,
        name: label,
        topicId: id,
      }
    }
  }

  const updateTopicMeetings = (selectedOption) => {
    setTopicMeetings(selectedOption)
  }

  return (
    <div className="tags-management-modal">
      <Modal
        width={'50%'}
        // height={'20rem'}
        closeModal={closeModal}
      >
        <div className="modal-gray-box" style={{ width: '100%' }}>
          <div className="modal-inner-content">
            <div className="tutorial-management-modal-title" style={{ marginBottom: '10px' }}>
              {t('Kanban:manageTags')}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '0.5rem',
                width: '18rem',
              }}
            >
              <label>{t('Kanban:tags')}</label>
              <CreatableSelect
                id="selectOption"
                value={selectedOption}
                onChange={handleSelectChange}
                options={tagsList
                  .filter((item) => !selectedOption.some((option) => option.label === item.name))
                  .map(({ id, name }) => ({ value: id, label: name }))}
                isLoading={isLoading}
                // getOptionLabel={(option) => option?.name}
                // getOptionValue={(option) => option?.id}
                onCreateOption={handleCreate}
                isMulti
                isClearable
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
              <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}></div>
            </div>

            {meetingtopic && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '0.5rem',
                  width: '18rem',
                }}
              >
                <label>{t('Kanban:addToMeeting')}</label>
                <SelectField
                  name="selectMeeting"
                  value={topicMeetings}
                  options={companyMeetings}
                  onChange={updateTopicMeetings}
                  getOptionLabel={(option) => option.meetingName}
                  getOptionValue={(option) => option.meetingId}
                  width={'100%'}
                  isMulti={true}
                  menuPosition="fixed"
                  isClearable={true}
                />
              </div>
            )}

            <div
              className="sleek-button sleek-blue"
              onClick={handleChangeTags}
              style={{ marginLeft: 'auto' }}
            >
              <span>{t('Common:commonButtons.saveSmall')}</span>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default TagsManagementModal
