import React, { useState } from 'react'

import { Handle, Position } from 'reactflow'
import './customNode.scss'
import ActivityIcon from '../../../assets/activity.svg'
import MessageIcon from '../../../assets/message.svg'
import ZoomIcon from '../../../assets/zoom.svg'
import ZoomOutIcon from '../../../assets/zoomOut.svg'
import ObjMapDropdownMenu from './objMapDropdownMenu'
import { shortenName } from '../../../utils/general'

const handleStyle = { left: 10 }

function CustomNodeObjectiveMode({ data, isConnectable }) {
  const SelectFirstCharacter = (name) => {
    return name
  }

  const renderListOfUserNames = (data) => {
    return (
      <>
        {data.slice(0, 4).map((item, key) => (
          <div>
            <span key={item.eId + key}>{SelectFirstCharacter(item.name)}</span>
            {key !== data.length - 1 && <span>,</span>}
          </div>
        ))}
        {data.length > 4 ? (
          <span className="d-flex align-items-center">{data.length - 4} more</span>
        ) : null}
      </>
    )
  }

  function findCardIndexWithId(list, id) {
    if (!list) return -1

    for (let i = 0; i < list.length; i++) {
      if (list[i].id == id) {
        return i
      }
    }
    return -1
  }

  let zoomIn = findCardIndexWithId(data?.zoomIndexLevel, data.id) === -1

  return (
    <div className="nodrag text-updater-node">
      {data.main === 1 || data.main === 2 || data.main === 3 ? (
        <>
          {' '}
          <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
        </>
      ) : (
        ''
      )}
      <div
        className={`custom-box-node box-level-${data.main} custom-box-node-assignee ${
          data.isSearchedObjective ? 'searched-border' : ''
        }`}
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      >
        <ul className="node-text-list">
          <li>
            <div style={{ display: 'flex', gap: '1.5rem', marginBottom: '0.2rem' }}>
              <div className="owner">{shortenName(data.owner)}</div>
            </div>
          </li>
          <li className="single-node">
            {/* <h2>Objective:</h2> */}
            <p className="goal-desc">{data.goal}</p>
          </li>
        </ul>
        <div className="icons-list-node-box">
          <div
            style={{
              width: '100%',
              alignSelf: 'flex-end',
              gap: '0.313rem',
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '0 .313rem .313rem 0',
              padding: '0',
            }}
          >
            <div>
              {data.cardPermissions.canSeeObjStatistics === true &&
                data.hasOverdueActivities === true && (
                  <div class="fitted-button yellow-notification">
                    <span
                      class="material-symbols-outlined icon-size-sm"
                      style={{ backgroundColor: '#fff' }}
                      onClick={() => {
                        data.ShowOverdueModal(data?.id)
                      }}
                    >
                      warning
                    </span>
                  </div>
                )}
            </div>
            {data.cardPermissions.canAdd && (
              <div class="fitted-button blue">
                <span
                  class="material-symbols-outlined icon-size-sm"
                  style={{ backgroundColor: '#fff' }}
                  onClick={() => {
                    data.ShowAddModal(data, data.main)
                  }}
                >
                  add
                </span>
              </div>
            )}

            {data.cardPermissions.canSeeObjStatistics === true &&
              data.hasAnyProgressLastTwoWeeks === true && (
                <div class="fitted-button green-notification">
                  <span
                    class="material-symbols-outlined icon-size-sm"
                    style={{ backgroundColor: '#fff' }}
                    onClick={() => {
                      data.ShowNotificationsModal(data?.id)
                    }}
                  >
                    notifications
                  </span>
                </div>
              )}
            {data.cardPermissions.canSeeObjStatistics === true &&
              data.hasAnyProgressLastTwoWeeks === false && (
                <div class="fitted-button red-notification" style={{ cursor: 'grab' }}>
                  <span
                    class="material-symbols-outlined icon-size-sm"
                    style={{ backgroundColor: '#fff' }}
                  >
                    hourglass_disabled
                  </span>
                </div>
              )}
            {data.rag && data.main == 3 && data.rag !== 'UNKNOWN_RAG' && !data.fromSetUpAgent && (
              <div
                className={`rag-letter-box-sm ${
                  data.rag === 'RED'
                    ? 'red-rag-box'
                    : data.rag === 'YELLOW'
                      ? 'amber-rag-box'
                      : data.rag === 'GREEN'
                        ? 'green-rag-box'
                        : ''
                }`}
                style={{ cursor: 'grab' }}
                onClick={() => {
                  data.ShowKPIModalSpecificKpi(data?.id)
                }}
              >
                <span class="icon-size-sm">
                  {data.rag === 'RED'
                    ? 'R'
                    : data.rag === 'YELLOW'
                      ? 'A'
                      : data.rag === 'GREEN'
                        ? 'G'
                        : ''}
                </span>
              </div>
            )}

            {data.cardPermissions.canSeeActivities && (
              <li className="fitted-button blue" style={{ backgroundColor: '#fff' }}>
                <span
                  className="material-symbols-outlined icon-size-sm"
                  style={{ transform: 'rotate(270deg) scaleX(-1)', marginLeft: '0.1rem' }}
                  onClick={() => data.ShowActivitesModal(data?.id)}
                >
                  account_tree
                </span>
              </li>
            )}

            {data.cardPermissions.canSeeHistory && (
              <li>
                <div
                  class="fitted-button blue"
                  style={{ backgroundColor: '#fff' }}
                  onClick={() => {
                    data.ShowHistoryModal(data?.id)
                  }}
                >
                  <span class="material-symbols-outlined icon-size-sm">history</span>
                </div>
              </li>
            )}

            {data.main !== 3 && data.hasChildren === true && (
              <>
                {data.cardPermissions.canZoom &&
                  data.main !== 3 &&
                  zoomIn && (
                    <li
                      className="fitted-button blue"
                      style={{ backgroundColor: '#fff' }}
                      onClick={() => data.setZoomIndex(data, data.main, zoomIn)}
                    >
                      <span class="material-symbols-outlined icon-size-sm">zoom_in</span>
                    </li>
                  )}

                {data.cardPermissions.canZoom &&
                  !zoomIn &&
                  data.main !== 3 &&
                  findCardIndexWithId(data?.zoomIndexLevel, data.id) >= 0 && (
                    <li
                      className="fitted-button blue"
                      style={{ backgroundColor: '#e4e7fd' }}
                      onClick={() => data.setZoomIndex(data, data.main, zoomIn)}
                    >
                      <span class="material-symbols-outlined icon-size-sm">zoom_out</span>
                    </li>
                  )}
              </>
            )}

            {data.cardPermissions.canEdit && (
              <ObjMapDropdownMenu
                level={data.main}
                canEdit={data.cardPermissions.canEdit}
                canDeleteComplete={data.cardPermissions.canDeleteComplete}
                canSeeFiles={data.cardPermissions.canSeeFiles}
                isDeactivated={data.isDeactivated}
                canKpi={data.cardPermissions.canKpi}
                onClickEdit={() => {
                  data.ShowEditModal(data?.id, data.main)
                }}
                onClickRequestUpdate={() => {
                  data.ShowRequestUpdateModal(data?.id)
                }}
                onClickViewFiles={() => {
                  data.ShowFilesModal(data?.id)
                }}
                onClickKPIs={() => {
                  data.ShowKPIModal(data?.id)
                }}
                onClickChangelog={() => {
                  data.ShowChangelogPage(data?.id)
                }}
                onClickCompleteObj={() => {
                  data.ShowCompleteObjectiveModal(data?.id, data.main, data?.numOfChildrenActive)
                }}
                onClickDeleteObj={() => {
                  data.ShowDeleteObjectiveModal(data?.id, data.main, data?.numOfChildrenNotDeleted)
                }}
                onClickShowGanttChart={() => {
                  data.ShowGanttChartModal(data?.id)
                }}
              />
            )}
          </div>
        </div>
      </div>

      {data.main != 3 ? (
        <>
          <Handle type="source" position={Position.Bottom} id="b" isConnectable={isConnectable} />
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default CustomNodeObjectiveMode
