import React, { useState, useEffect } from 'react'
import Modal from '../../../../../components/Modal/index'
import { getObjectivUserList } from '../../../../../api/services/employee.services'
import Toast from '../../../../../common/toast'
import { meetingMilestoneUpdate } from '../../../../../api/services/meetingTopic.services'
import InPageLoader from '../../../../../components/InPageLoader/index'
import { useTranslation } from 'react-i18next'
import SelectField from '../../../../../components/CustomMultipleSelect/singleOption'
import { ModalSaveButtonFitted } from '../../../../../components/ModalSubmitButton'
import { postHttpRequest } from '../../../../../api/query/dynamicAPI'

const initialValues = {
  assign: [],
}

const AssigneeActivity = ({
  objectiveId,
  view,
  setModelView,
  milestoneId,
  assigneeList,
  loadAgain = () => {},
  refreshBGUScorecardFn = () => {},
  meetingId,
  cleanup = () => {},
  placeHolderOnly = false,
}) => {
  const { t } = useTranslation(['Common'])
  // const { state, dispatch } = useDemandIntakeContext()

  const [formValues, setFormValues] = useState(initialValues)

  const [userList, setUserList] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const [error, setError] = useState('')

  useEffect(() => {
    if (objectiveId) {
      getUserList()
    }
  }, [objectiveId])

  useEffect(() => {
    if (assigneeList) {
      let tempAssigneeList = [...assigneeList]
      tempAssigneeList = tempAssigneeList.filter((x) => x.eId !== 0) //removes non assignee with eId 0 issue coming from backend
      const obj = {
        assign: tempAssigneeList,
      }
      setFormValues(obj)
    }
  }, [assigneeList])

  async function getUserList() {
    setIsLoading(true)
    const result = await getObjectivUserList(objectiveId)
    setIsLoading(false)
    if (result?.employees) setUserList(result.employees)
  }

  function inputChangedHandler(e, data) {
    // Get name of changed input, and its corresponding value
    let name
    let value
    if (data) {
      if (data.name == 'assign') {
        name = data.name
        value = e
      }
    }
    updateFormHandler(name, value)
  }

  function updateFormHandler(name, value) {
    setFormValues((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  async function onCustomSubmit(data, type) {
    if (!formValues.assign && !formValues.assign.eId) {
      setError('Assign Required !')
      return
    }

    setIsLoading(true)
    let result
    if (!placeHolderOnly) {
      if (meetingId === undefined || meetingId === null) {
        result = await meetingMilestoneUpdate(milestoneId, {
          eIds: [...formValues.assign.map((x) => x.eId)],
        })
      } else {
        let response = await postHttpRequest(`/update_meeting_managers/${meetingId}`, {
          eIds: [...formValues.assign.map((x) => x.eId)],
        })
        result = response.success
      }
    } else {
      result = true
      cleanup([...formValues.assign])
    }

    setIsLoading(false)
    if (result) {
      setModelView(false)
      setUserList([])
      setFormValues(initialValues)
      loadAgain()
      refreshBGUScorecardFn()
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong try again later!',
      })
    }
  }

  return (
    <div className="kanban-modal">
      <Modal
        title={t('assigneeActivityModal.selectAssignee')}
        isModalOpen={view}
        data-testid="Assigne"
        closeModal={() => setModelView(false)}
        width={'35rem'}
      >
        {isLoading ? <InPageLoader /> : ''}

        {!isLoading > 0 && (
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div
              className="column-input-objective"
              style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            >
              <div className="row-kanban-inputs">
                <div className="form-field action-field form-field action-field--kanban">
                  <label>{t('assigneeActivityModal.assignTo')}</label>
                  <div style={{ width: '100%' }}>
                    <SelectField
                      isMulti={true}
                      placeholder={t('formPlaceHolder.select')}
                      name="assign"
                      value={formValues.assign}
                      options={userList}
                      onChange={inputChangedHandler}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.eId}
                      isClearable={true}
                      menuShouldScrollIntoView={false}
                      menuPosition="fixed"
                    />
                    {error ? <span className="error error-add-commnet">{error}</span> : null}
                  </div>
                </div>
              </div>
              <div className="modal-dual-btn">
                <ModalSaveButtonFitted
                  isLoading={isLoading}
                  tag={t('Common:commonButtons.updateUpperCase')}
                  onClick={onCustomSubmit}
                />
                {/* Bring back these buttons when we decide to change buttons again all at once in a few days */}
                {/* <button className="dual-btn positive-btn" type="button"
                    onClick={onCustomSubmit}>
                     {t("commonButtons.updateUpperCase")}
                    </button> */}
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}
export default AssigneeActivity
