import React from 'react'
import './styles.css'
import { useGovernanceContext } from '../../context/governanceContext'
import TeamLeaders from './TeamLeaders'
import AreaLeaders from './AreaLeaders'
import { useState } from 'react'
import AddEditEmployeeModal from './AddEditEmployeeForm'
import { CardContainer, CardHeader } from './styles'
import CsuiteLeaders from './CsuiteLeaders'
import ManageTeamMembers from '../PeopleManagment'
import { useLocation } from 'react-router-dom'
import { governanceSubRoutes } from '../../../../utils/routes'
import ALConfigModal from './ConfigModal/ALConfigModal'
import TLConfigModal from './ConfigModal/TLConfigModal'
import { Link } from 'react-router-dom'
import { getHttpRequest, postHttpRequest } from '../../../../api/query/dynamicAPI'
import Toast from '../../../../common/toast'
import UserLicenseModal from '../../../../components/UserLicenseModal'
import { hasRole } from '../../../../utils/auth'
import { AdminDropdownMenu } from '../../../../components/SooDropdownMenu'
import InPageLoader from '../../../../components/InPageLoader'
import { useTranslation } from 'react-i18next'
import ReportingLineModal from '../../../../components/ReportingLineModal'
import MeiCriteriaModal from './MeiDynamicCriteria'

const ManageLeaders = (props) => {
  let { state, dispatch } = useGovernanceContext()
  const { t } = useTranslation(['Dashboard'])

  let currentLocation = useLocation()

  const [ALConfigModalOpen, setALConfigModalOpen] = useState(false)
  const [TLConfigModalOpen, setTLConfigModalOpen] = useState(false)
  const [userLicenseModalOpen, setUserLicenseModalOpen] = useState(false)
  const [TLReportingModalOpen, setTLReportingModalOpen] = useState(false)
  const [MEIModalOpen, setMEIModalOpen] = useState(false)

  const [totalLicense, setTotalLicense] = useState(null)

  const [refetchData, setRefetchData] = useState(false)
  const [allAreaLeadersList, setAllAreaLeadersList] = useState([])
  const [allTeamLeadersList, setAllTeamLeadersList] = useState([])
  const [allCsuiteLeadersList, setAllCsuiteLeadersList] = useState([])
  const [allTeamMembersList, setAllTeamMembersList] = useState([])

  const [licenses, setLicenses] = useState([])
  const [loading, setLoading] = useState(false)
  const [isFromAl, setIsFromAl] = useState(false)

  const licenseTitles = {
    execs: 'Executives',
    areaLeaders: 'Area Leaders',
    teamLeaders: 'Team Leaders',
    teamMembers: 'Team Members',
    '': '',
  }

  const getEmployeesData = async () => {
    setLoading(true)
    const allAreaLeadersList = await getHttpRequest('/all_area_leaders')
    const allTeamLeadersList = await getHttpRequest('/all_team_leaders')
    const allCsuiteLeadersList = await getHttpRequest('/all_csuite_leaders')
    const allTeamMembersList = await getHttpRequest('/all_team_members')

    setAllAreaLeadersList(allAreaLeadersList?.employees ?? [])
    setAllTeamLeadersList(allTeamLeadersList?.employees ?? [])
    setAllCsuiteLeadersList(allCsuiteLeadersList?.employees ?? [])
    setAllTeamMembersList(allTeamMembersList?.employees ?? [])
    setLoading(false)
  }

  const getLicense = async () => {
    const response = await getHttpRequest('/get_user_licenses')
    let licenses_temp = []
    Object.keys(response).forEach((key) => {
      let license = licenses.find((license) => license.name === key)
      if (license) {
        licenses_temp.push({ name: key, value: response[key], used: license.used })
        return
      }
      licenses_temp.push({ name: key, value: response[key], used: 0 })
    })
    setLicenses(licenses_temp)
  }

  React.useEffect(() => {
    getLicense()
  }, [])

  React.useEffect(() => {
    getEmployeesData()
  }, [refetchData])

  React.useEffect(() => {
    // whenever the employees list changes, update the license display
    let licenseMapping = {
      execs: allCsuiteLeadersList.filter((csuite) => csuite.isDeactivated === false).length,
      areaLeaders: allAreaLeadersList.filter((al) => al.isDeactivated === false).length,
      teamLeaders: allTeamLeadersList.filter((tl) => tl.isDeactivated === false).length,
      teamMembers: allTeamMembersList.filter((tm) => tm.isDeactivated === false).length,
    }

    let licenses_temp = []
    Object.keys(licenseMapping).forEach((key) => {
      // find the key, and update the new value (used)
      let license = licenses.find((license) => license.name === key)
      licenses_temp.push({ name: key, value: license?.value ?? 0, used: licenseMapping[key] })
    })
    setLicenses(licenses_temp)
  }, [allTeamLeadersList, allAreaLeadersList, allCsuiteLeadersList, allTeamMembersList])

  const OnSelectedAreaLeaderChanged = (e) => {
    dispatch({
      type: 'AREA_LEADER_SELECTED',
      isAreaLeaderSelected: true,
      selectedAreaLeaderEId: e.target.value,
      isTeamLeaderListUpdated: false,
    })
  }

  const OnSelectedCsuiteLeaderChanged = (e) => {
    dispatch({
      type: 'CSUITE_LEADER_SELECTED',
      isCsuiteLeaderSelected: true,
      selectedCsuiteLeaderEId: e.target.value,
      isAreaLeaderListUpdated: false,
    })
  }

  const OnSelectedCeoLeaderChanged = (e) => {
    dispatch({
      type: 'CEO_LEADER_SELECTED',
      isCeoLeaderSelected: true,
      selectedCeoLeaderEId: e.target.value,
      isCsuiteLeaderListUpdated: false,
    })
  }

  const updateTotalAvailableLicense = async (newValue, license) => {
    let newLicenses = licenses.map((prevLicense) => {
      if (prevLicense.name === license.name) {
        return { name: license.name, value: newValue, used: license.used }
      }
      return prevLicense
    })

    try {
      await postHttpRequest('/update_user_license', {
        newLicenses: newLicenses.reduce((acc, curr) => {
          acc[curr.name] = curr.value
          return acc
        }, {}),
      })

      setLicenses(newLicenses)
      setUserLicenseModalOpen(false)
      setTotalLicense(null)
      Toast.fire({
        icon: 'success',
        title: 'License updated successfully',
      })
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  if (loading) {
    return <InPageLoader inComponent={true} />
  }

  return (
    <>
      <AddEditEmployeeModal setRefetchData={setRefetchData} />
      {ALConfigModalOpen && (
        <ALConfigModal
          isModalOpen={ALConfigModalOpen}
          closeModal={() => {
            setALConfigModalOpen(false)
          }}
          selectedCsuiteLeader={
            allCsuiteLeadersList.filter(
              (employee) => employee.eId == state.selectedCsuiteLeaderEId,
            )[0]
          }
        />
      )}
      {TLConfigModalOpen && (
        <TLConfigModal
          isModalOpen={TLConfigModalOpen}
          closeModal={() => {
            setTLConfigModalOpen(false)
          }}
          selectedAreaLeader={
            allAreaLeadersList.filter((employee) => employee.eId == state.selectedAreaLeaderEId)[0]
          }
        />
      )}
      {userLicenseModalOpen && totalLicense && (
        <UserLicenseModal
          isModalOpen={userLicenseModalOpen}
          closeModal={() => {
            setUserLicenseModalOpen(false)
            setTotalLicense(null)
          }}
          handleSubmit={updateTotalAvailableLicense}
          license={totalLicense}
        />
      )}
      {TLReportingModalOpen && (
        <ReportingLineModal
          isModalOpen={TLReportingModalOpen}
          closeModal={() => {
            setIsFromAl(false)
            setTLReportingModalOpen(false)
          }}
          allTeamLeadersList={allTeamLeadersList}
          allAreaLeadersList={allAreaLeadersList}
          isFromAl={isFromAl}
        />
      )}
      {hasRole('enterprise') && MEIModalOpen && (
        <MeiCriteriaModal
          cxoList={allCsuiteLeadersList}
          isModalOpen={MEIModalOpen}
          closeModal={() => {
            setMEIModalOpen(false)
          }}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
          width: '90%',
          wordBreak: 'break-word',
        }}
      >
        <CardContainer>
          <CardHeader>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '1rem',
                width: '100%',
                position: 'relative',
              }}
            >
              <div className="underline-admin"></div>
            </div>
          </CardHeader>
          <div className="table-wrapper" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div
              className={hasRole('enterprise') ? 'widget-grid-4' : 'widget-grid-3'}
              style={{ width: '100%', marginLeft: '5rem' }}
            >
              <div
                style={{
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                  width: '100%',
                }}
                className="widget-grid-title"
              ></div>
              <div
                style={{
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                  width: '100%',
                }}
                className="widget-grid-title"
              >
                {t('adminDashboard.totalAvailable')}
              </div>
              <div
                style={{
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                  width: '100%',
                }}
                className="widget-grid-title"
              >
                {t('adminDashboard.used')}
              </div>
              {hasRole('enterprise') && (
                <div
                  style={{ margin: '0.5rem 0.5rem', width: '100%' }}
                  className="widget-grid-title"
                ></div>
              )}

              {licenses.map((license, index) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      style={{
                        margin: '0.5rem 0.5rem',
                        textAlign: 'left',
                        alignItems: 'normal',
                        justifyContent: 'normal',
                        width: '90%',
                        wordBreak: 'break-word',
                        wordWrap: 'break-word',
                      }}
                      className="widget-grid-item"
                    >
                      {licenseTitles[license?.name ?? '']}
                    </div>
                    <div
                      style={{
                        margin: '0.5rem 0.5rem',
                        textAlign: 'left',
                        alignItems: 'normal',
                        justifyContent: 'normal',
                        width: '90%',
                        wordBreak: 'break-word',
                        wordWrap: 'break-word',
                      }}
                      className="widget-grid-item"
                    >
                      {license?.value}
                    </div>
                    <div
                      style={{
                        margin: '0.5rem 0.5rem',
                        textAlign: 'left',
                        alignItems: 'normal',
                        justifyContent: 'normal',
                        width: '90%',
                        wordBreak: 'break-word',
                        wordWrap: 'break-word',
                      }}
                      className="widget-grid-item"
                    >
                      {license?.used}
                    </div>

                    {hasRole('enterprise') && (
                      <div
                        style={{
                          margin: '0.5rem 0.5rem',
                          textAlign: 'left',
                          alignItems: 'normal',
                          justifyContent: 'normal',
                        }}
                        className="widget-grid-item"
                      >
                        <div
                          class="fitted-button blue"
                          onClick={() => {
                            setUserLicenseModalOpen(true)
                            setTotalLicense(license)
                          }}
                        >
                          <span class="material-symbols-outlined icon-size">edit</span>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        </CardContainer>

        {hasRole('enterprise') && (
          <CardContainer>
            <CardHeader>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '1rem',
                  width: '100%',
                  position: 'relative',
                }}
              >
                <div style={{ display: 'flex' }}>
                  {t('adminDashboard.ceo')}
                  <div style={{ marginLeft: 'auto' }}>
                    <button
                      type="button"
                      className="fitted-button blue"
                      style={{ background: 'white', padding: '0.2rem', fontWeight: 'bold' }}
                      onClick={() => {
                        dispatch({
                          type: 'OPEN_CEO_LEADER_MODAL',
                          editCeoLeaderIndex: null,
                          editTeamLeaderIndex: null,
                          editAreaLeaderIndex: null,
                          editCsuiteLeaderIndex: null,
                          editTeamMemberIndex: null,
                          ceoLeadersList: state.data.ceoLeadersList,
                          role: 'ceo',
                        })
                      }}
                    >
                      <span className="material-symbols-outlined icon-size">
                        <span
                          class="material-symbols-outlined"
                          style={{ fontWeight: 'bold', fontSize: '20px' }}
                        >
                          add
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
                <div className="underline-admin"></div>
              </div>
            </CardHeader>
            <div
              className="table-wrapper"
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <div className="widget-grid-5" style={{ width: '100%', marginLeft: '5rem' }}>
                <div
                  style={{
                    margin: '0.5rem 0.5rem',
                    textAlign: 'left',
                    alignItems: 'normal',
                    justifyContent: 'normal',
                    width: '100%',
                  }}
                  className="widget-grid-title"
                >
                  {t('adminDashboard.name')}
                </div>
                <div
                  style={{
                    margin: '0.5rem 0.5rem',
                    textAlign: 'left',
                    alignItems: 'normal',
                    justifyContent: 'normal',
                    width: '100%',
                  }}
                  className="widget-grid-title"
                >
                  {t('adminDashboard.title')}
                </div>
                <div
                  style={{
                    margin: '0.5rem 0.5rem',
                    textAlign: 'left',
                    alignItems: 'normal',
                    justifyContent: 'normal',
                    width: '100%',
                  }}
                  className="widget-grid-title"
                >
                  {t('adminDashboard.email')}
                </div>
                <div
                  style={{
                    margin: '0.5rem 0.5rem',
                    textAlign: 'left',
                    alignItems: 'normal',
                    justifyContent: 'normal',
                    width: '100%',
                  }}
                  className="widget-grid-title"
                >
                  {t('adminDashboard.function')}
                </div>
                <div
                  style={{ margin: '0.5rem 0.5rem', width: '100%' }}
                  className="widget-grid-title"
                ></div>

                {state?.data?.ceoLeadersList?.map((ceo, index) => (
                  <React.Fragment key={`ceo-${ceo?.eId}-${ceo?.email}`}>
                    <div
                      style={{
                        margin: '0.5rem 0.5rem',
                        textAlign: 'left',
                        alignItems: 'normal',
                        justifyContent: 'normal',
                        width: '90%',
                        wordBreak: 'break-word',
                        wordWrap: 'break-word',
                      }}
                      className="widget-grid-item"
                    >
                      {ceo?.name}
                    </div>
                    <div
                      style={{
                        margin: '0.5rem 0.5rem',
                        textAlign: 'left',
                        alignItems: 'normal',
                        justifyContent: 'normal',
                        width: '90%',
                        wordBreak: 'break-word',
                        wordWrap: 'break-word',
                      }}
                      className="widget-grid-item"
                    >
                      {ceo?.title}
                    </div>
                    <div
                      style={{
                        margin: '0.5rem 0.5rem',
                        textAlign: 'left',
                        alignItems: 'normal',
                        justifyContent: 'normal',
                        width: '90%',
                        wordBreak: 'break-word',
                        wordWrap: 'break-word',
                      }}
                      className="widget-grid-item"
                    >
                      {ceo?.email}
                    </div>
                    <div
                      style={{
                        margin: '0.5rem 0.5rem',
                        textAlign: 'left',
                        alignItems: 'normal',
                        justifyContent: 'normal',
                        width: '90%',
                        wordBreak: 'break-word',
                        wordWrap: 'break-word',
                      }}
                      className="widget-grid-item"
                    >
                      {ceo?.function}
                    </div>

                    <div
                      style={{
                        margin: '0.5rem 0.5rem',
                        textAlign: 'left',
                        alignItems: 'normal',
                        justifyContent: 'normal',
                      }}
                      className="widget-grid-item"
                    >
                      <AdminDropdownMenu
                        isCeo={true}
                        onClickEdit={() => {
                          dispatch({
                            type: 'OPEN_CEO_LEADER_MODAL',
                            editCeoLeaderIndex: index,
                            editCsuiteLeaderIndex: null,
                            editAreaLeaderIndex: null,
                            editTeamLeaderIndex: null,
                            editTeamMemberIndex: null,
                            ceoLeadersList: state.data.ceoLeadersList,
                          })
                        }}
                      />
                      {/* <div
                      class="fitted-button blue"
                      onClick={() => {
                        dispatch({
                          type: 'OPEN_CEO_LEADER_MODAL',
                          editCeoLeaderIndex: index,
                          editCsuiteLeaderIndex: null,
                          editAreaLeaderIndex: null,
                          editTeamLeaderIndex: null,
                          ceoLeadersList: state.data.ceoLeadersList,
                        })
                      }}
                    >
                      <span class="material-symbols-outlined icon-size" >edit</span>
                    </div> */}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </CardContainer>
        )}

        {hasRole('enterprise') && (
          <CardContainer>
            <CardHeader>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '1rem',
                  width: '100%',
                  position: 'relative',
                }}
              >
                <div style={{ display: 'flex' }}>
                  {t('adminDashboard.csuiteLeaderFor')}
                  <div style={{ marginLeft: 'auto' }}>
                    {/* Allow adding CXO with no CEO */}
                    {/* {(state.selectedCeoLeaderEId!=='000000' && state.selectedCeoLeaderEId!=0)&&  */}
                    <button
                      type="button"
                      className="fitted-button blue"
                      style={{ background: 'white', padding: '0.2rem', fontWeight: 'bold' }}
                      onClick={() => {
                        let license = licenses.find((license) => license.name === 'execs')
                        if (license && license.value <= license.used) {
                          Toast.fire({
                            icon: 'error',
                            title:
                              'You do not currently have available licenses to add another user',
                          })
                          return
                        }
                        dispatch({
                          type: 'OPEN_C_SUITE_LEADER_MODAL',
                          editCeoLeaderIndex: null,
                          editTeamLeaderIndex: null,
                          editAreaLeaderIndex: null,
                          editCsuiteLeaderIndex: null,
                          editTeamMemberIndex: null,
                          csuiteLeadersList: state.data.csuiteLeadersList,
                          allCsuiteLeadersList: state.data.allCsuiteLeadersList,
                          role: 'csuite',
                        })
                      }}
                    >
                      <span className="material-symbols-outlined icon-size">
                        <span
                          class="material-symbols-outlined"
                          style={{ fontWeight: 'bold', fontSize: '20px' }}
                        >
                          add
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <select
                    className="accountable-select"
                    onChange={OnSelectedCeoLeaderChanged}
                    value={state.selectedCeoLeaderEId}
                    style={{ width: '30%', height: '2rem' }}
                  >
                    <option value="000000" key="000000">
                      {t('adminDashboard.selectCEO')}
                    </option>
                    {state.data?.ceoLeadersList?.map((employee, index) => (
                      <option value={employee?.eId} key={`${employee?.eId}`}>
                        {employee?.name}
                      </option>
                    ))}
                  </select>
                  <div style={{ marginLeft: 'auto' }}>
                    <div
                      style={{
                        marginLeft: 'auto',
                        color: '#0926D5',
                        textDecoration: 'underline',
                      }}
                    >
                      <span
                        style={{ cursor: 'pointer', fontSize: '15px' }}
                        onClick={() => {
                          setMEIModalOpen(true)
                        }}
                      >
                        {t('adminDashboard.dynamicMei')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="underline-admin"></div>
              </div>
            </CardHeader>
            <CsuiteLeaders setRefetchData={setRefetchData} />
          </CardContainer>
        )}

        <CardContainer>
          <CardHeader>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '1rem',
                width: '100%',
                position: 'relative',
              }}
            >
              <div style={{ display: 'flex' }}>
                {t('adminDashboard.areaLeaderFor')}
                <div style={{ marginLeft: 'auto' }}>
                  {state.selectedCsuiteLeaderEId !== '000' &&
                    state.selectedCsuiteLeaderEId != 0 && (
                      <button
                        type="button"
                        className="fitted-button blue"
                        style={{ background: 'white', padding: '0.2rem', fontWeight: 'bold' }}
                        onClick={() => {
                          let license = licenses.find((license) => license.name === 'areaLeaders')
                          if (license && license.value <= license.used) {
                            Toast.fire({
                              icon: 'error',
                              title:
                                'You do not currently have available licenses to add another user',
                            })
                            return
                          }
                          dispatch({
                            type: 'OPEN_AREA_LEADER_MODAL',
                            editTeamLeaderIndex: null,
                            editAreaLeaderIndex: null,
                            editCsuiteLeaderIndex: null,
                            editCeoLeaderIndex: null,
                            editTeamMemberIndex: null,
                            areaLeadersList: state.data.areaLeadersList,
                            allAreaLeadersList: state.data.allAreaLeadersList,
                            role: 'areaLeader',
                          })
                        }}
                      >
                        <span className="material-symbols-outlined icon-size">
                          <span
                            class="material-symbols-outlined"
                            style={{ fontWeight: 'bold', fontSize: '20px' }}
                          >
                            add
                          </span>
                        </span>
                      </button>
                    )}
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <select
                  className="accountable-select"
                  onChange={OnSelectedCsuiteLeaderChanged}
                  value={state.selectedCsuiteLeaderEId}
                  style={{ width: '30%', height: '2rem' }}
                >
                  <option value="000" key="000">
                    {t('adminDashboard.selectCxo')}
                  </option>
                  {allCsuiteLeadersList?.map((employee, index) => (
                    <option value={employee?.eId} key={`${employee?.eId}`}>
                      {employee?.name}
                    </option>
                  ))}
                </select>
                {hasRole('enterprise') &&
                  state.selectedCsuiteLeaderEId !== '000' &&
                  state.selectedCsuiteLeaderEId != 0 && (
                    <div style={{ marginLeft: 'auto' }}>
                      <div
                        style={{
                          marginLeft: 'auto',
                          color: '#0926D5',
                          textDecoration: 'underline',
                        }}
                      >
                        <span
                          style={{ cursor: 'pointer', fontSize: '15px' }}
                          onClick={() => {
                            setIsFromAl(true)
                            setTLReportingModalOpen(true)
                          }}
                        >
                          {t('adminDashboard.reporting')}
                        </span>
                      </div>
                      <div
                        style={{
                          marginLeft: 'auto',
                          color: '#0926D5',
                          textDecoration: 'underline',
                        }}
                      >
                        <span
                          style={{ cursor: 'pointer', fontSize: '15px' }}
                          onClick={() => {
                            // setInitModalOpen(true)
                            setTLConfigModalOpen(false)
                            setALConfigModalOpen(true)
                          }}
                        >
                          {t('adminDashboard.configurations')}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
              <div className="underline-admin"></div>
            </div>
          </CardHeader>
          <AreaLeaders setRefetchData={setRefetchData} />
        </CardContainer>

        <CardContainer>
          <CardHeader>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '1rem',
                width: '100%',
                position: 'relative',
              }}
            >
              <div style={{ display: 'flex' }}>
                {t('adminDashboard.teamLeaderFor')}
                <div style={{ marginLeft: 'auto' }}>
                  {state.selectedAreaLeaderEId !== '0000' && state.selectedAreaLeaderEId != 0 && (
                    <button
                      type="button"
                      className="fitted-button blue"
                      style={{ background: 'white', padding: '0.2rem', fontWeight: 'bold' }}
                      onClick={() => {
                        let license = licenses.find((license) => license.name === 'teamLeaders')
                        if (license && license.value <= license.used) {
                          Toast.fire({
                            icon: 'error',
                            title:
                              'You do not currently have available licenses to add another user',
                          })
                          return
                        }
                        dispatch({
                          type: 'OPEN_TEAM_LEADER_MODAL',
                          editTeamLeaderIndex: null,
                          teamLeadersList: state.data.teamLeadersList,
                          allTeamLeadersList: state.data.allTeamLeadersList,
                          editAreaLeaderIndex: null,
                          editCsuiteLeaderIndex: null,
                          editCeoLeaderIndex: null,
                          editTeamMemberIndex: null,
                          role: 'teamLeader',
                        })
                      }}
                    >
                      <span className="material-symbols-outlined icon-size">
                        <span
                          class="material-symbols-outlined"
                          style={{ fontWeight: 'bold', fontSize: '20px' }}
                        >
                          add
                        </span>
                      </span>
                    </button>
                  )}
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <select
                  className="accountable-select"
                  onChange={OnSelectedAreaLeaderChanged}
                  value={state.selectedAreaLeaderEId}
                  style={{ width: '30%', height: '2rem' }}
                >
                  <option value="0000" key="0000">
                    {t('adminDashboard.selectArea')}
                  </option>
                  {allAreaLeadersList?.map((employee, index) => (
                    <option value={employee?.eId} key={`${employee?.eId}`}>
                      {employee?.name}
                    </option>
                  ))}
                </select>
                {state.selectedAreaLeaderEId !== '0000' && state.selectedAreaLeaderEId != 0 && (
                  <div style={{ marginLeft: 'auto' }}>
                    <div
                      style={{
                        marginLeft: 'auto',
                        color: '#0926D5',
                        textDecoration: 'underline',
                      }}
                    >
                      {/* <Link
                        style={{color:'#0926D5'}}
                        to={`/${governanceSubRoutes.manageTeamConfiguration}`}
                        state={{
                          path: currentLocation.pathname,
                          selectedAreaLeader:allAreaLeadersList.filter((employee)=>employee.eId==state.selectedAreaLeaderEId)
                        }}
                      >
                        <span style={{ cursor: 'pointer', fontSize:'15px' }}>{t('adminDashboard.reporting')}</span>

                      </Link> */}
                      <span
                        style={{ cursor: 'pointer', fontSize: '15px' }}
                        onClick={() => {
                          setTLReportingModalOpen(true)
                        }}
                      >
                        {t('adminDashboard.reporting')}
                      </span>
                    </div>
                    <div
                      style={{
                        marginLeft: 'auto',
                        color: '#0926D5',
                        textDecoration: 'underline',
                      }}
                    >
                      <span
                        style={{ cursor: 'pointer', fontSize: '15px' }}
                        onClick={() => {
                          // setInitModalOpen(true)
                          //setALconfig
                          setALConfigModalOpen(false)
                          setTLConfigModalOpen(true)
                        }}
                      >
                        {t('adminDashboard.configurations')}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="underline-admin"></div>
            </div>
          </CardHeader>
          <TeamLeaders setRefetchData={setRefetchData} />
        </CardContainer>
        <ManageTeamMembers
          licenses={licenses}
          allTeamLeadersList={allTeamLeadersList}
          setRefetchData={setRefetchData}
        />
      </div>
    </>
  )
}

export default ManageLeaders
