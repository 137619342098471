import React from 'react'
import { Outlet } from 'react-router-dom'
import ApplicationLayout from '../../components/ApplicationLayout'
import Loading from '../../components/Loading'
import MeetingViewHeaders from './components/Headers'
import ScoreCard from './components/ScoreCard'
import useTeamMeetingViewData from './hooks/useTeamMeetingViewData'
import { useState, useEffect } from 'react'
import '../MeetingView/styles.css'
import '../MeetingView/responsive.css'
import { useLocation } from 'react-router-dom'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import { routes, teamMeetingViewSubRoutes } from '../../utils/routes'
import BGUScoreCard from '../MeetingMultiView/components/BGUScoreCard'
import { useFeatureFlagsContext } from '../../utils/featureFlagsContext'
import BGUTopicAgeTableModal from '../MeetingMultiView/components/BGUScoreCard/component/BGUTopicAgeTableModal'
import BGUOpenCloseActionTableModal from '../MeetingMultiView/components/BGUScoreCard/component/BGUOpenCloseActionTableModal'
import BGUActivityDuesTableModal from '../MeetingMultiView/components/BGUScoreCard/component/BGUActivityDuesTableModal'
import { hasRole, getConsolidatedConfigSettings, getCompanyConfigSettings } from '../../utils/auth'
import { useNavbarContext } from '../../utils/navBarContext'
import JoyrideComponent from '../MeetingMultiView/components/JoyRide'
import Toast from '../../common/toast'
import { t } from 'i18next'
import { checkForPendingUW } from '../../common/checkForPendingUwOnLogin'
import { useTranslation } from 'react-i18next'
import { meetingViewModesEnum } from '../../utils/enumValues'
import { checkForBrowser } from '../../common/checkForBrowser'
import MeetingTranscription from '../../components/MeetingTranscription'
import { useMeetingRecording } from '../../utils/meetingRecordingContext/meetingRecordingContext'

const TeamMeetingViewPage = () => {
  const { t } = useTranslation(['MeetingHub', 'Common'])
  const { state } = useMeetingRecording()

  const [refreshIndex, setRefreshIndex] = useState(0)
  const [refreshBGUScorecard, setRefreshBGUScorecard] = useState(false)
  const [BGUScoreCardData, setBGUScoreCardData] = useState(null)
  const [isActivityModalOpen, setIsActivityModalOpen] = React.useState(false)
  const [isActionModalOpen, setIsActionModalOpen] = React.useState(false)
  const [isTopicModalOpen, setIsTopicModalOpen] = React.useState(false)
  const [selectedBar, setSelectedBar] = React.useState(null)
  const [isOpen, setIsOpen] = React.useState(null)
  const [activitiesDuesCategory, setActivitiesDuesCategory] = React.useState(null)
  const [reload, setReload] = React.useState(0)
  const [eIdsToLoadBGU, setEIdsToLoadBGU] = React.useState([])
  const [isScoreCardCollapsed, setIsScoreCardCollapsed] = React.useState(
    !state?.hasSummarizationStarted,
  )
  const { isNavbarCollapsed, handleNavbarStateChange } = useNavbarContext()
  const meetingHubContainerRef = React.useRef(null)
  const [childComponentLoadingState, setchildComponentLoadingState] = React.useState([])
  const [runJoyRide, setRunJoyRide] = React.useState(false)
  const [meetingTopicExist, setMeetingTopicExist] = React.useState(false)
  const [mhTutorialOff, setMhTutorialOff] = React.useState(false)

  const { flags } = useFeatureFlagsContext()
  let location = useLocation()
  const { pathname } = location

  const queryParams = new URLSearchParams(location.search)
  const isInitialLoad = queryParams.get('initialLoad')
  const [isCheckForPendingUwComplete, setIsCheckForPendingUwComplete] = React.useState(false)

  const isProductSettingsEnabled = getCompanyConfigSettings('productSettings')
  const viewMode =
    isProductSettingsEnabled && hasRole('ic')
      ? meetingViewModesEnum.ACTION_VIEW
      : meetingViewModesEnum.PEOPLE_VIEW

  const [selectedViewMode, setSelectedViewMode] = React.useState(viewMode)

  const canUseMeetingSummarization =
    getConsolidatedConfigSettings('can_use_meeting_summarization') === true

  const {
    isLoading,
    tradeoffs,
    employees,
    scoreCard,
    meetingData,
    F2WData,
    selectedCardIndexHook,
    setSelectedCardIndexHook,
    updateScoreCard,
    addOption,
  } = useTeamMeetingViewData(refreshIndex)

  const hasF2WCapacity = F2WData?.f2wTeamCapacities.length > 0 ? true : false

  const resetTradeOffandScorecardData = () => {}

  React.useEffect(() => {
    // if isInitialLoad is true, then we need to check for pending UW
    // This happens when the user logs in for the first time
    console.log('isInitialLoad', isInitialLoad)
    if (isInitialLoad) {
      checkForBrowser(t).then(() => {
        checkForPendingUW(t).then((result) => {
          setIsCheckForPendingUwComplete(result)
        })
      })
    } else {
      setIsCheckForPendingUwComplete(true)
    }
  }, [])

  useEffect(() => {
    setIsScoreCardCollapsed(!state?.hasSummarizationStarted)
  }, [state?.hasSummarizationStarted])

  // React.useEffect(() => {
  //   // Function to handle screen size change
  //   const handleResize = () => {
  //     setIsScoreCardCollapsed(window.innerWidth <= 1400);
  //   };

  //   // Initial check for screen size
  //   setIsScoreCardCollapsed(window.innerWidth <= 1400);

  //   // Add listener for screen size changes
  //   window.addEventListener('resize', handleResize);

  //   // Cleanup function to remove listener when component unmounts
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []); // Empty dependency array ensures the effect runs once on mount
  React.useEffect(() => {
    ;(async () => {
      try {
        let response = await getHttpRequest('/get_employees_user_preferences')
        setMhTutorialOff(response.mhTutorialOff)
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    })()
  }, [mhTutorialOff])

  React.useEffect(() => {
    resetTradeOffandScorecardData()
  }, [window.location])

  const refreshBGUScorecardFn = () => {
    setRefreshBGUScorecard((refreshBGUScorecard) => !refreshBGUScorecard)
  }

  const loadAgain = () => {
    setReload(reload + 1)
  }

  function getBGUScoreCard() {
    let url = ''
    url = eIdsToLoadBGU.length > 0 ? url + '/' + eIdsToLoadBGU.join(',') : url

    getHttpRequest(`/bgu_scorecard_response${url}`, {}).then((response) => {
      const data = response
      if (data) {
        setBGUScoreCardData(data)
      } else {
      }
    })
  }

  const getLoadingStateFromChildComponent = (componentName, isLoaded) => {
    setchildComponentLoadingState({ ...childComponentLoadingState, [componentName]: isLoaded })
  }

  useEffect(() => {
    getBGUScoreCard()
  }, [refreshBGUScorecard, eIdsToLoadBGU])

  // React.useEffect(() => {
  //   setIsScoreCardCollapsed(
  //     !(
  //       pathname.endsWith(teamMeetingViewSubRoutes.meetingTopics) ||
  //       pathname.endsWith(routes.teamMeetingview)
  //     )
  //   )
  // }, [pathname])

  useEffect(() => {
    if (BGUScoreCardData != null) {
      getLoadingStateFromChildComponent('BGUScoreCard', true)
    }
  }, [BGUScoreCardData])

  useEffect(() => {
    if (
      childComponentLoadingState.MeetingTopic == true &&
      childComponentLoadingState.BGUScoreCard &&
      !mhTutorialOff
    ) {
      setTimeout(() => {
        setRunJoyRide(true)
        console.log('timeout')
        console.log(meetingTopicExist)
      }, 3000)
    }
  }, [childComponentLoadingState])

  const toggleScoreCardCollapse = () => {
    setIsScoreCardCollapsed(!isScoreCardCollapsed)
  }

  if (isLoading) {
    return <Loading />
  }

  const closeTestModal = () => {
    setIsActionModalOpen(false)
    setIsTopicModalOpen(false)
    setIsActivityModalOpen(false)
  }

  const loading = (isLoading) => {
    const tradeOffOverlay = document.querySelector('.meeting-view-overlay')
    const tradeOffSpinner = tradeOffOverlay.querySelector('.meeting-view-spinner')

    if (isLoading) {
      tradeOffOverlay.classList.remove('hidden')
      tradeOffSpinner.classList.remove('hidden')
    } else {
      tradeOffOverlay.classList.add('hidden')
      tradeOffSpinner.classList.add('hidden')
    }
  }
  const overlayClickHandler = (e) => {
    const tradeOffOverlay = document.querySelector('.meeting-view-overlay')

    if (e.target === tradeOffOverlay) {
      showMeetingViewModal(false)
    }
  }

  const showMeetingViewModal = (shouldShow) => {
    const tradeOffOverlay = document.querySelector('.meeting-view-overlay')
    const tradeOffModal = tradeOffOverlay.querySelector('.meeting-view-modal')

    if (shouldShow) {
      tradeOffOverlay.classList.remove('hidden')
      tradeOffModal.classList.remove('hidden')
    } else {
      tradeOffOverlay.classList.add('hidden')
      tradeOffModal.classList.add('hidden')
      tradeOffModal.innerHTML = ''
    }
  }

  const determineDivWidthPercentage = () => {
    if (meetingHubContainerRef.current) {
      const containerWidth = meetingHubContainerRef.current.clientWidth

      if (containerWidth >= 1600) {
        if (isNavbarCollapsed) {
          return '96%'
        } else {
          return '88%'
        }
      } else if (isNavbarCollapsed) {
        return '95%'
      } else {
        if (canUseMeetingSummarization) {
          return '83%'
        }
        return '85%'
      }
    }
  }
  // Check if the current URL is in Decision Log "/TeamMeetingView/LeadershipDecisions"
  const isInDecisionLogTab = location.pathname.includes('LeadershipDecisions')

  // Check if the current URL is in Summary of Objectives "/TeamMeetingView/LeadershipDecisions"
  const isInSummaryOfObjectiveTab = location.pathname.includes('MilestonesOverview')

  const isInKpiReportByLeader = location.pathname.includes('KpiReportByLeader')

  const getIfMeetingTopicExists = (meetingTopic) => {
    setMeetingTopicExist(meetingTopic)
  }

  return (
    <ApplicationLayout>
      <div
        ref={meetingHubContainerRef}
        className="meeting-hub-container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: determineDivWidthPercentage(),
          position: 'absolute',
        }}
      >
        {isTopicModalOpen === true && selectedBar !== null && (
          <BGUTopicAgeTableModal
            data={selectedBar}
            isModalOpen={isTopicModalOpen}
            closeModal={closeTestModal}
            refreshBGUScorecardFn={refreshBGUScorecardFn}
            loadAgain={loadAgain}
          />
        )}

        {isActionModalOpen === true && selectedBar !== null && isOpen !== null && (
          <BGUOpenCloseActionTableModal
            data={selectedBar}
            isModalOpen={isActionModalOpen}
            closeModal={closeTestModal}
            refreshBGUScorecardFn={refreshBGUScorecardFn}
            loadAgain={loadAgain}
            isOpen={isOpen}
          />
        )}

        {isActivityModalOpen === true &&
          selectedBar !== null &&
          activitiesDuesCategory !== null && (
            <BGUActivityDuesTableModal
              data={selectedBar}
              isModalOpen={isActivityModalOpen}
              closeModal={closeTestModal}
              loadAgain={loadAgain}
              refreshBGUScorecardFn={refreshBGUScorecardFn}
              activitiesDuesCategory={activitiesDuesCategory}
            />
          )}
        <div className="meeting-view-overlay hidden" onClick={(e) => overlayClickHandler(e)}>
          <div className="meeting-view-modal hidden"></div>
          <div className="meeting-view-spinner hidden"></div>
        </div>

        <div className="meeting-view" style={{ paddingTop: '3.5rem' }}>
          <div className="page_title">{t('meetingHubPageTitle')}</div>
          <MeetingViewHeaders hasF2WCapacity={hasF2WCapacity} />
          <div className="meetingview-view-body" style={{ justifyContent: 'space-between' }}>
            <div
              className={`meeting-view-container ${
                isScoreCardCollapsed ? 'expanded' : 'collapsed'
              } ${canUseMeetingSummarization ? '' : 'no-meeting-summarization'}`}
            >
              <Outlet
                context={{
                  tradeoffs,
                  employees,
                  meetingData,
                  F2WData,
                  refreshIndex,
                  updateScoreCard,
                  loading,
                  showMeetingViewModal,
                  setRefreshIndex,
                  setSelectedCardIndexHook,
                  selectedCardIndexHook,
                  refreshBGUScorecardFn,
                  loadAgain,
                  reload,
                  setEIdsToLoadBGU,
                  isTeamMeetingView: true,
                  getLoadingStateFromChildComponent,
                  getIfMeetingTopicExists,
                  setSelectedViewMode,
                  selectedViewMode,
                  isScoreCardCollapsed,
                }}
              />
            </div>
            {canUseMeetingSummarization && !hasRole('ic') && (
              <div className={`scorecard-menu ${isScoreCardCollapsed ? ' collapsed' : 'expanded'}`}>
                {isScoreCardCollapsed ? (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div class="fitted-button" onClick={toggleScoreCardCollapse}>
                      <span class="material-symbols-outlined">right_panel_open</span>
                    </div>
                  </div>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div class="fitted-button" onClick={toggleScoreCardCollapse}>
                        <span class="material-symbols-outlined" id="meetingHubTutorial4">
                          chevron_right
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          paddingRight: '1rem',
                          paddingTop: '0.3rem',
                          paddingLeft: '0.5rem',
                          fontSize: '13px',
                          color: '#949494',
                        }}
                      >
                        {state.hasSummarizationStarted
                          ? t('transcript.summarizingMeetingInProgress')
                          : t('transcript.startSummarizingMeeting')}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        // paddingLeft:'1rem',
                        textAlign: 'left',
                        marginLeft: '1rem',
                        marginTop: '1rem',
                        marginBottom: '0.3rem',
                        color: '#5780ca',
                      }}
                    >
                      {t('transcript.title')}
                    </div>
                    <div className="divider"></div>
                  </div>
                )}
                <div
                  class="bgu-container"
                  style={{
                    visibility: isScoreCardCollapsed ? 'hidden' : 'visible',
                    overflow: 'auto',
                  }}
                >
                  <MeetingTranscription />
                  {/* {BGUScoreCardData && (
                    <BGUScoreCard
                      actionChartData={BGUScoreCardData}
                      setIsActionModalOpen={setIsActionModalOpen}
                      setIsActivityModalOpen={setIsActivityModalOpen}
                      setIsTopicModalOpen={setIsTopicModalOpen}
                      setSelectedBar={setSelectedBar}
                      setIsOpen={setIsOpen}
                      setActivitiesDuesCategory={setActivitiesDuesCategory}
                      refreshBGUScorecardFn={refreshBGUScorecardFn}
                      loadAgain={loadAgain}
                      visibility={isScoreCardCollapsed ? 'hidden' : 'visible'}
                      getLoadingStateFromChildComponent = {getLoadingStateFromChildComponent} 
                    />
                  )} */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <JoyrideComponent run={runJoyRide} meetingTopicExist={meetingTopicExist} />
    </ApplicationLayout>
  )
}

export default TeamMeetingViewPage
