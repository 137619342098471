import { getHttpRequest, postHttpRequest } from '../query/dynamicAPI'
import {
  API_ERROR_MESSAGE,
  GET_LIST_OBJECTIVES,
  OBJECTIVE_CANVAS_DATA_V2,
  TEAM_LEADER_OBJECTIVES,
  EMPLOYEES_ALLOC_T,
  EMP_OBJ_ALLOC,
  AREA_LEADER_OBJECTIVES,
  GANTT_CHART_DATA,
  TEAM_CAPACITY,
  GET_KANBAN_BOARD_OBJECTIVE_DATA,
  PICASSO_PROCESS,
  SAVE_PICASSO_OBJECTIVES,
  GET_KANBAN_OBJECTIVES_LABEL_MODE,
  PICASSO_PROCESS_TeamLeader,
  Employee_Matching,
  SAVE_PICASSO_OBJECTIVES_TL,
} from '../../api/constant/route'
import Toast from '../../common/toast'

export async function getListOfObjectives(tid) {
  try {
    let url = tid !== undefined ? `${GET_LIST_OBJECTIVES}/${tid}` : `${GET_LIST_OBJECTIVES}`
    const data = await getHttpRequest(url)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getAllObjectivesInCompany(level = null) {
  try {
    let params = { level: level }
    const data = await getHttpRequest(`/get_all_objectives_for_company`, { params: params })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getKanbanObjectivesList(teamLeaderId = null, objIds = [], tagIds = []) {
  try {
    let params = { teamLeaderId: teamLeaderId }
    if (objIds && objIds.length > 0) {
      params.objIds = objIds.join(',')
    }
    if (tagIds && tagIds.length > 0) {
      params.tagIds = tagIds.join(',')
    }
    const data = await getHttpRequest(`${GET_KANBAN_BOARD_OBJECTIVE_DATA}`, { params: params })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getKanbanObjectivesListCustomMode(
  actionLabel = null,
  objIds = [],
  tagIds = [],
) {
  try {
    let params = {}
    if (objIds && objIds.length > 0) {
      params.objIds = objIds.join(',')
    }
    if (tagIds && tagIds.length > 0) {
      params.tagIds = tagIds.join(',')
    }
    params.milestoneLabelId = actionLabel
    const data = await getHttpRequest(`${GET_KANBAN_OBJECTIVES_LABEL_MODE}`, { params: params })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getListOfObjectivesFlow(ic, date) {
  try {
    const data = await getHttpRequest(`${OBJECTIVE_CANVAS_DATA_V2}?ic=${ic}&date=${date}`)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getTeamObjectives() {
  try {
    const data = await getHttpRequest(`${TEAM_LEADER_OBJECTIVES}`)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getAreaObjectives(empId) {
  try {
    let url =
      empId !== undefined ? `${AREA_LEADER_OBJECTIVES}/${empId}` : `${AREA_LEADER_OBJECTIVES}`
    const data = await getHttpRequest(url)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getTeamMembers() {
  try {
    const data = await getHttpRequest(`${TEAM_CAPACITY}`)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getEmployeeMainObjective(get_additional_capacity = false) {
  try {
    let params = { get_additional_capacity: get_additional_capacity }
    const data = await getHttpRequest(`${EMP_OBJ_ALLOC}`, { params: params })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getGanttChartData(
  objectiveId,
  employeeId = null,
  isL2Gantt = false,
  showIndividualGantt = false,
) {
  try {
    let url =
      objectiveId !== undefined ? `${GANTT_CHART_DATA}/${objectiveId}` : `${GANTT_CHART_DATA}`
    let param = isL2Gantt && employeeId != null ? { isL2Gantt: true, employeeId: employeeId } : {}
    if (showIndividualGantt) {
      param.showIndividualGantt = true
    }
    const data = await getHttpRequest(url, { params: { ...param } })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getCustomGantt(objId = null, eId = null, l2ObjId = null) {
  try {
    const data = await getHttpRequest(`/get_custom_gantt`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params: { objId: objId, eId: eId, l2ObjId: l2ObjId },
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function saveCustomGantt(formData) {
  try {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    const data = await postHttpRequest(`/save_custom_gantt`, formData, config)

    return data
  } catch (e) {
    return e.response
  }
}

export async function getGanttChartDataV2(objId = null, eId = null, l2ObjId = null) {
  try {
    const data = await getHttpRequest(`/get_gantt_objectives_v2`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params: { objId: objId, eId: eId, l2ObjId: l2ObjId },
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getGoalsByIds(goalsIds) {
  try {
    const data = await getHttpRequest(`/get_goals_by_ids`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      // Sending the array of IDs as query parameters instead of in the request body
      // Ensure goalsIds is an array, otherwise, convert it to a string or handle it appropriately
      params: { ids: goalsIds.join(',') },
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getPicaasoProcess(
  answer,
  firstRequest = false,
  nextQuestion,
  messageId = null,
  logId = null,
  isObjectiveMapFinished = false,
) {
  try {
    let postData = {}
    postData['answer'] = answer
    postData['firstRequest'] = firstRequest
    postData['messageId'] = messageId
    postData['logId'] = logId
    postData['nextQuestion'] = nextQuestion
    postData['isObjectiveMapFinished'] = isObjectiveMapFinished
    const data = await postHttpRequest(`${PICASSO_PROCESS}`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function savePicassoObjectives(
  objectiveHierarchy,
  newEmployees,
  newEmployeeWithId,
  isMatchingMode = false,
) {
  try {
    let postData = {}
    postData['objectiveHierarchy'] = objectiveHierarchy
    postData['newEmployees'] = newEmployees
    postData['newEmployeeWithId'] = newEmployeeWithId
    postData['isMatchingMode'] = isMatchingMode
    const data = await postHttpRequest(`${SAVE_PICASSO_OBJECTIVES}`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function savePicassoObjectivesForTeamLeader(objectiveHierarchy) {
  try {
    let postData = {}
    postData['objectiveHierarchy'] = objectiveHierarchy
    const data = await postHttpRequest(`${SAVE_PICASSO_OBJECTIVES_TL}`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getPicaasoProcessForTeamLeader(
  answer,
  firstRequest = false,
  nextQuestion,
  messageId = null,
  logId = null,
  isObjectiveMapFinished = false,
) {
  let retryCount = 0
  const maxRetries = 2
  while (retryCount < maxRetries) {
    try {
      let postData = {}
      postData['answer'] = answer
      postData['firstRequest'] = firstRequest
      postData['messageId'] = messageId
      postData['logId'] = logId
      postData['nextQuestion'] = nextQuestion
      postData['isObjectiveMapFinished'] = isObjectiveMapFinished
      const data = await postHttpRequest(`${PICASSO_PROCESS_TeamLeader}`, postData)
      return data
    } catch (e) {
      retryCount++
      Toast.fire({
        icon: 'error',
        title: API_ERROR_MESSAGE,
      })
    }
  }
}

export async function employeeMatching(employeesName) {
  try {
    let postData = {}
    postData['employeesName'] = employeesName
    const data = await postHttpRequest(`${Employee_Matching}`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}
