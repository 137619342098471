import moment from 'moment'

const dateTimeFilter = (timestamp) => {
  return moment.unix(timestamp).format('MM/DD/YYYY HH:mm')
}

const dateFilter = (timestamp) => {
  return moment.unix(timestamp).format('MM/DD/YY')
}

const dayMonthFilter = (timestamp) => {
  return moment.unix(timestamp).format('MM/DD')
}

const dateToUnix = (date) => {
  return moment(date).unix()
}

const yyyymmddNow = () => {
  return moment(new Date()).format('YYYY-MM-DD')
}

const dateToString = (timestamp) => {
  return moment(timestamp).format('MMMM D, YYYY')
}

const isDateInPast = (unixTimestamp) => {
  if (unixTimestamp === 0) {
    return false
  }
  const currentUnixTimestamp = Math.floor(Date.now() / 1000)

  return unixTimestamp < currentUnixTimestamp
}

const mmddyyyyToStringmmdd = (dateString) => {
  const [month, day] = dateString.split('/').slice(0, 2)
  return `${month}/${day}`
}
function formatDateToMMDD(inputDate) {
  var dateParts = inputDate.split('-')
  var formattedDate = dateParts[1] + '/' + dateParts[2]
  return formattedDate
}

function convertTimestampToDate(timestamp) {
  const date = new Date(timestamp * 1000)
  return date.toISOString().slice(0, 10)
}

const convertTimeStamToMMDDYYYY = (timestamp) => {
  return moment.unix(timestamp).format('MM/DD/YYYY')
}

export {
  dateTimeFilter,
  dayMonthFilter,
  dateFilter,
  yyyymmddNow,
  dateToUnix,
  dateToString,
  isDateInPast,
  mmddyyyyToStringmmdd,
  formatDateToMMDD,
  convertTimestampToDate,
  convertTimeStamToMMDDYYYY,
}
