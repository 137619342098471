import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const editActionF2W = async (actionData) => {
  const { data } = await axios.post(`${BASE_URL}/action_f2w`, actionData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useEditActionF2w() {
  const queryClient = useQueryClient()
  return useMutation(['edit-action-f2w'], (data) => editActionF2W(data), {
    onSuccess: () => {
      void queryClient.invalidateQueries('multi-meeting')
      void queryClient.invalidateQueries('f2w')
    },
  })
}
