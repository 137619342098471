import React from 'react'
import deleteCross from '../../../../../../assets/delete-cross.svg'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import ErrorModal from '../../../../../../components/ErrorModal'
import useValidateGoal from '../../../../../../api/mutation/useValidateGoal'
import useVerbSuggestions from '../../../../../../utils/useVerbSuggestions'
import useAddTeamLeaderActivity from '../../../../../../api/mutation/addTLActivity'
import Modal from '../../../../../../components/Modal'
import ModalSaveButton from '../../../../../../components/ModalSubmitButton'
import ActivityExampleText from '../../../../../../components/ActivityExampleText'
import { tlActivityProgress } from '../../../../../../utils/enumValues'
import useEditTeamLeaderActivity from '../../../../../../api/mutation/editTLActivity'
import { useTranslation } from 'react-i18next'

const ActivityModal = ({ isActivityModalOpen, handleClose, activity }) => {
  const mutationValidateGoal = useValidateGoal()
  const mutationAddTLActivity = useAddTeamLeaderActivity()
  const mutationEditTeamLeaderActivity = useEditTeamLeaderActivity()

  const [verbOptions, handleVerbSuggestions] = useVerbSuggestions()

  const [errorMessage, setErrorMessage] = React.useState(null)
  const [activityModalErrorMessage, setActivityModalErrorMessage] = React.useState(null)
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const { t } = useTranslation(['Dashboard', 'Common'])

  const openErrorModal = () => {
    setIsErrorModalOpen(true)
  }
  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
  }

  const errorCheck = async (values, resetForm) => {
    await mutationValidateGoal.mutateAsync(`${values.statement}`, {
      onSuccess: (response) => {
        if (response.is_valid === true) {
          values.isValid = true
          setErrorMessage(null)
          handleSubmit(values, resetForm)
        } else {
          setErrorMessage(t('teamLeadersActivityModal.activitySyntaxErrorMsg'))
        }
      },
      onError: () => {
        openErrorModal()
      },
    })
  }

  const handleSubmit = async (values, resetForm) => {
    if (values.activityId != null && values.activityId != undefined && values.activityId != '') {
      let objSubmit = {
        statement: `${values.statement}`,
        progress: parseInt(values.progress),
        activityId: values.activityId,
      }
      await mutationEditTeamLeaderActivity.mutateAsync(objSubmit, {
        onSuccess: () => {
          handleClose()
          resetForm()
        },
        onError: () => {
          handleClose()
          openErrorModal()
          resetForm()
        },
      })
    } else {
      let objSubmit = { statement: `${values.statement}`, progress: parseInt(values.progress) }
      await mutationAddTLActivity.mutateAsync(objSubmit, {
        onSuccess: () => {
          handleClose()
          resetForm()
        },
        onError: () => {
          handleClose()
          openErrorModal()
          resetForm()
        },
      })
    }
  }

  const [resetForm, setResetForm] = React.useState(null)

  const formikRef = React.useRef()

  React.useEffect(() => {
    if (formikRef.current !== undefined) {
      setResetForm(() => formikRef.current.resetForm)
    }
  }, [formikRef])

  return (
    <>
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={closeErrorModal}
        errorMessage={activityModalErrorMessage}
      ></ErrorModal>

      {isActivityModalOpen === true && (
        <Modal
          title={t('teamLeadersActivityModal.title')}
          closeModal={() => {
            resetForm()
            handleClose()
            setErrorMessage(null)
          }}
          width={'35rem'}
        >
          <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={{
              statement: activity ? activity.statement : '',
              progress: activity ? tlActivityProgress(activity.progress) : '5',
              activityId: activity ? activity.activityId : '',
            }}
            validationSchema={Yup.object().shape({
              statement: Yup.string().required(t('Common:required')),
              progress: Yup.string().required(t('Common:required')),
            })}
            onSubmit={(values, { resetForm }) => {
              errorCheck(values, resetForm)
            }}
          >
            {({ errors, touched, resetForm }) => (
              <Form
                className="objective-form"
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <div>
                  <div className="form-field action-field">
                    <label>{t('teamLeadersActivityModal.activityDescription')}</label>
                    <Field
                      list="obj_verb_list"
                      id="statement"
                      name="statement"
                      placeholder={t('teamLeadersActivityModal.statementPlaceholder')}
                      onKeyUp={handleVerbSuggestions}
                      onClick={handleVerbSuggestions}
                      autoComplete="off"
                    />
                    {errors.statement && touched.statement && (
                      <div className="validation-error">{errors.statement}</div>
                    )}
                    <datalist id="obj_verb_list">
                      {verbOptions.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </datalist>
                  </div>

                  <div className="form-field">
                    <label>{t('Common:status.status')}</label>
                    <Field as="select" id="progress" name="progress">
                      <option value="">{t('Common:status.selectStatus')}</option>
                      <option value="5">{t('Common:status.notStarted')}</option>
                      <option value="2">{t('Common:status.onTrack')}</option>
                      <option value="3">{t('Common:status.atRisk')}</option>
                      <option value="1">{t('Common:status.completed')}</option>
                      <option value="4">{t('Common:status.deprioritized')}</option>
                    </Field>
                    {errors.progress && touched.progress && (
                      <div className="validation-error">{errors.progress}</div>
                    )}
                  </div>
                </div>
                {<div className="validation-error">{errorMessage}</div>}
                <div className="modal-dual-btn">
                  <button
                    className="dual-btn negative-btn"
                    onClick={() => {
                      resetForm()
                      handleClose()
                      setErrorMessage(null)
                    }}
                    type="button"
                  >
                    {t('Common:commonButtons.cancel')}
                  </button>
                  <ModalSaveButton
                    isLoading={mutationValidateGoal.isLoading || mutationAddTLActivity.isLoading}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  )
}
export default ActivityModal
