import React, { useRef } from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import { useTranslation } from 'react-i18next'

function BGUDropdownMenu(props) {
  const { t } = useTranslation(['UpdateWizard'])
  const fileInputRef = useRef(null)

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  console.log('props.selectedMeetingTopic: ', props.selectedMeetingTopic)

  return (
    <div style={{ margin: 'auto 0' }}>
      <Menu
        menuClassName="soo-menu"
        menuButton={
          <MenuButton style={{ backgroundColor: 'white', border: 'none', padding: '0' }}>
            <span style={{ alignSelf: 'center' }} className="material-symbols-outlined">
              more_vert
            </span>
          </MenuButton>
        }
      >
        <MenuItem style={{ textAlign: 'left' }} onClick={props.onClickEdit}>
          {t('dropdown.edit')}
        </MenuItem>
        {props.showMeetingDeck && (
          <MenuItem
            style={{ textAlign: 'left' }}
            onClick={() => {
              handleUploadClick()
              props.onClickUpload(props.meetingTopic)
            }}
          >
            {t('dropdown.uploadDocument')}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept=".ppt, .pptx, .pdf"
              onChange={(e) => props.onChangePpt(e, props.meetingTopic)}
              title={
                props?.selectedMeetingTopic?.hasMeetingDeck
                  ? t('meetingTopicWizStep.meetingDeckAlreadyPresent')
                  : ''
              }
            />
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}

export default BGUDropdownMenu
