import React, { useState } from 'react'

import { Handle, Position } from 'reactflow'
import './customNode.scss'
import ActivityIcon from '../../../assets/activity.svg'
import MessageIcon from '../../../assets/message.svg'
import ZoomIcon from '../../../assets/zoom.svg'
import ZoomOutIcon from '../../../assets/zoomOut.svg'

const handleStyle = { left: 10 }

function TopicNode({ data }) {
  return (
    <div className="nodrag text-updater-node">
      <div className={`custom-box-node topic-box`}>
        <ul className="node-text-list">
          <li className="single-node">
            <div className="topic-blocker">Topic</div>
            <p>{data.description}</p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default TopicNode
