import React, { useState, useEffect, useRef } from 'react'
import Toast from '../../../common/toast'
import InPageLoader from '../../../components/InPageLoader'
import { useLocation, useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getHttpRequest } from '../../../api/query/dynamicAPI'
import { dateFilter } from '../../../utils/time'
import { shortenName } from '../../../utils/general'
import ChangesReportDropdownMenu from '../changesReportDropdownMenu'
import CardModal from '../../../components/CardModal'
import useEditKanbanBoardChangeActivityStatus from '../../../api/mutation/useEditKanbanBoardChangeActivityStatus'
import '../styles.scss'

const ChangesReportActions = () => {
  const { t } = useTranslation(['ChangesReport', 'Common', 'ObjectiveMap'])
  const { setIsLoading, isLoading, loadAgain, reload } = useOutletContext()

  const [actionList, setActionList] = useState([])
  const [selectedDays, setSelectedDays] = useState(7)
  const [isTopicModalOpen, setIsTopicModalOpen] = useState(false)
  const [topicId, setTopicId] = useState(0)
  const [topicType, setTopicType] = useState(0)
  const changeActivityStatusMutation = useEditKanbanBoardChangeActivityStatus()

  React.useEffect(() => {
    getActions()
  }, [selectedDays])

  const getActions = async () => {
    setIsLoading(true)
    // fetch actions
    try {
      let args = {
        days: selectedDays,
      }
      let response = await getHttpRequest('/get_changes_report_actions', { params: args })
      setActionList(response.milestones)
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
    setIsLoading(false)
  }

  const handleFilter = (days) => {
    setSelectedDays(days)
  }

  const handleCompleteClick = (objectiveId, milestoneId, isClose) => async (e) => {
    e.preventDefault() // Prevent default behavior
    e.stopPropagation()

    if (isClose) {
      await changeActivityStatus(objectiveId, milestoneId, 'ON_TRACK')
    } else {
      await changeActivityStatus(objectiveId, milestoneId, 'COMPLETED')
    }

    //Do not reload but change data locally, so this row doesn't dissapear from table
    let tempActionList = [...actionList]
    tempActionList.map((action) => {
      if (action.objectiveId === objectiveId && action.milestoneId === milestoneId) {
        action.isOpen = !action.isOpen
      }
    })
    setActionList(tempActionList)
  }

  const changeActivityStatus = async (objectiveId, milestoneId, newStatus) => {
    const postObject = {
      objectiveId,
      milestoneId,
      newStatus,
    }

    await changeActivityStatusMutation.mutateAsync(postObject, {
      onSuccess: (response) => {
        Toast.fire({
          icon: 'success',
          title: t('ObjectiveMap:notificationType.actionCompleted'),
        })
      },
      onError: () => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      },
    })
  }

  const processMentions = (inputText) => {
    // Regular expression to match the pattern @[Name](user:X)
    const pattern = /@\[([^\]]+)\]\(user:\d+\)/g

    // Split the text into parts: mentions and non-mentions
    const parts = inputText.split(pattern)

    // Process each part
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        // Odd indices are matches (mentions)
        return (
          <span key={index} className="mention">
            @{part}
          </span>
        )
      } else {
        // Even indices are non-matches (regular text)
        return part
      }
    })
  }

  return (
    <>
      {isTopicModalOpen && (
        <CardModal
          isModalOpen={isTopicModalOpen}
          closeModal={() => {
            setIsTopicModalOpen(false)
            setTopicId(0)
            setTopicType(0)
          }}
          umtId={topicId}
          topicType={topicType}
        />
      )}
      {isLoading && <InPageLoader />}

      {!isLoading && (
        <div>
          <div
            className="button-container"
            style={{ paddingLeft: '2rem', marginTop: '1rem', marginBottom: '2rem' }}
          >
            <div
              className="button"
              onClick={() => handleFilter(7)}
              style={{ backgroundColor: selectedDays == 7 ? '#4472c4' : '#a5a5a5' }}
            >
              {t('ChangesReport:past7Days')}
            </div>
            <div
              className="button"
              onClick={() => handleFilter(14)}
              style={{ backgroundColor: selectedDays == 14 ? '#4472c4' : '#a5a5a5' }}
            >
              {t('ChangesReport:past14Days')}
            </div>
            <div
              className="button"
              onClick={() => handleFilter(30)}
              style={{ backgroundColor: selectedDays == 30 ? '#4472c4' : '#a5a5a5' }}
            >
              {t('ChangesReport:past30Days')}
            </div>
          </div>

          {actionList.length === 0 && (
            <div style={{ paddingLeft: '1rem' }}>{t('ChangesReport:thereAreNoActions')}</div>
          )}
          {actionList.length > 0 && (
            <div
              className="modal-gray-box"
              style={{
                borderRadius: '10px',
                padding: '1rem',
                margin: '0 2rem 0 1rem',
                width: 'fit-content',
              }}
            >
              <div
                className="changes-report-actions_table"
                style={{ paddingBottom: '2rem', wordBreak: 'break-word' }}
              >
                <div className="changes-report-header">{t('ChangesReport:objective')}</div>
                <div className="changes-report-header">{t('ChangesReport:topic')}</div>
                <div className="changes-report-header">{t('ChangesReport:action')}</div>
                <div className="changes-report-header">{t('ChangesReport:dateCompleted')}</div>
                <div className="changes-report-header">{t('ChangesReport:completedBy')}</div>
                <div className="changes-report-header" style={{ textAlign: 'center' }}>
                  {t('ChangesReport:completed')}
                </div>
                <div className="changes-report-header"></div>

                {actionList.map((action, index) => {
                  return (
                    <React.Fragment key={`${action.completedTs}` + index}>
                      <div>{action.objDescription}</div>
                      <div>{processMentions(action.meetingTopicDescription)}</div>
                      <div>{processMentions(action.description)}</div>
                      <div>{action.completedTs === 0 ? '--' : dateFilter(action.completedTs)}</div>
                      <div>{shortenName(action?.completedBy?.name)}</div>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div
                          class={`fitted-button checklist tooltip`}
                          onClick={handleCompleteClick(
                            action.objectiveId,
                            action.milestoneId,
                            !action.isOpen,
                          )}
                          style={{
                            color: action.isOpen === false ? '#00CC00' : 'grey',
                            fontSize: '1.25rem',
                            cursor: 'pointer',
                            padding: '0',
                            marginLeft: '0.5rem',
                          }}
                        >
                          <span class="material-symbols-outlined ">done</span>
                          <span class="tooltiptext" style={{ fontSize: '16px' }}>
                            {' '}
                            {t('Common:tooltip.completeAction')}{' '}
                          </span>
                        </div>
                      </div>
                      <div>
                        <ChangesReportDropdownMenu
                          type="action"
                          seeDetails={() => {
                            setIsTopicModalOpen(true)
                            setTopicId(action.umtId)
                            setTopicType(action.topicType)
                          }}
                        />
                      </div>
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}
export default ChangesReportActions
