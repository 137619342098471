const ActivityExampleText = () => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', gap: '1rem' }}>
        <div>
          <b>Every activity should be phrased with two main components:</b>
        </div>
        <ul>
          <li> An action to be performed + a description of the activity</li>
          <li>
            {' '}
            An example could be “Design application architecture” or “Plan phase 1 of project X”
          </li>
          {/* <li>
              Feel free to write the activity in past tense if you’d like. Something like “Designed{' '}
              <i>application architecture”</i>
            </li> */}
        </ul>
      </div>
    </>
  )
}

export default ActivityExampleText
