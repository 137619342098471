import React from 'react'
import './styles.css'
import { useOutletContext } from 'react-router-dom'
import SummaryOfObjectives from '../../../../components/SummaryOfObjectives'

const MilestonesOverview = () => {
  const { eIdsOfSelectedTls } = useOutletContext()

  return (
    <>
      <SummaryOfObjectives
        eIdsOfSelectedTls={eIdsOfSelectedTls}
        isMultiMeetingView={true}
      ></SummaryOfObjectives>
    </>
  )
}

export default MilestonesOverview
