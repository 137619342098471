import { useState, useEffect } from 'react'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import './style.css'
import { addMultipleMeetingTopics } from '../../api/services/meetingTopic.services'
import InPageLoader from '../InPageLoader'
import TextArea from 'rc-textarea'
import { getAllObjectivesInCompany } from '../../api/services/objectives.services'
import Select from 'react-select'
import { getReportingEmployeesList } from '../../api/services/employee.services'
import { getAllEmployeeList } from '../../api/services/employee.services'

const SearchForObjectivesModal = ({
  isModalOpen,
  closeModal,
  setObjectiveFilterValue,
  objectivesListFilter,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation(['Common'])
  const [objectives, setObjectives] = useState([])
  const [employees, setEmployees] = useState([])
  const [selectedL1Objective, setSelectedL1Objective] = useState(null)
  const [selectedL2Objective, setSelectedL2Objective] = useState(null)
  const [selectedL3Objectives, setSelectedL3Objectives] = useState([])
  const [selectedLeader, setSelectedLeader] = useState(null)
  const [selectedL3ObjectivesLeader, setSelectedL3ObjectivesLeader] = useState(null)
  const [leaderL3ObjectivesOptions, setLeaderL3ObjectivesOptions] = useState([])
  const [showError, setShowError] = useState(false)
  const [isObjetiveModeEnabled, setIsObjectiveModeEnabled] = useState(false)

  useEffect(() => {
    getAllObjectives()
    getEmployees()
  }, [])

  async function getAllObjectives() {
    const result = await getAllObjectivesInCompany()
    if (result) setObjectives(result.objectives)
  }

  async function getEmployees() {
    const result = await getAllEmployeeList(true)
    let tempEmployees = result.employees.filter(
      (employee) => employee.level === 1 || employee.level === 2 || employee.level === 3,
    )
    if (result) setEmployees(tempEmployees)
  }

  async function handleLeaderChange(selectedOption) {
    setSelectedLeader(selectedOption)
    if (!selectedOption) {
      setShowError(true)
      return
    }
    setShowError(false)
    setSelectedL3ObjectivesLeader(null)

    let result = await getReportingEmployeesList(selectedOption.eId)
    let eIds = []
    if (result?.employees) {
      eIds = result?.employees.map((employee) => employee.eId)
    }

    let choosenObjectives = objectivesListFilter.filter((obj) =>
      eIds.includes(parseInt(obj.ownerId)),
    )
    choosenObjectives = choosenObjectives.filter((obj) => obj.isGeneralObjective === false)

    setLeaderL3ObjectivesOptions(choosenObjectives)
  }

  const handleL3ObjectivesLeaderChange = (selectedOption) => {
    setSelectedL3ObjectivesLeader(selectedOption)
  }

  const handleL1ObjectiveChange = (selectedOption) => {
    setSelectedL1Objective(selectedOption)
    setSelectedL2Objective(null)
    setSelectedL3Objectives([])
  }

  const handleL2ObjectiveChange = (selectedOption) => {
    setSelectedL2Objective(selectedOption)
  }

  const handleL3ObjectiveChange = (values) => {
    setSelectedL3Objectives(values || [])
  }

  const handleApplyObjective = () => {
    if (!selectedL1Objective || !selectedL2Objective || selectedL3Objectives.length === 0) {
      setShowError(true)
      return
    }
    setShowError(false)

    let newIds = []
    let tempSelectedFilterTopic = []
    if (selectedL3Objectives.some((obj) => obj.id === '0')) {
      const filteredObjectives = objectives.filter((obj) => obj.parentId == selectedL2Objective.id)
      newIds = filteredObjectives.map((obj) => parseInt(obj.id))
      tempSelectedFilterTopic = filteredObjectives.map(({ id, statement }) => ({ id, statement }))
    } else {
      newIds = selectedL3Objectives.map((obj) => parseInt(obj.id))
      tempSelectedFilterTopic = selectedL3Objectives.map(({ id, statement }) => ({ id, statement }))
    }

    setObjectiveFilterValue((prevFilter) => {
      const uniqueValues = tempSelectedFilterTopic.filter(
        ({ id }) => !prevFilter.some((item) => item.id === id),
      )
      return [...prevFilter, ...uniqueValues]
    })
    closeModal()
  }

  async function handleApplyLeader() {
    if (!selectedLeader || !selectedL3ObjectivesLeader) {
      setShowError(true)
      return
    }
    setShowError(false)
    let choosenObjectives = []
    if (selectedL3ObjectivesLeader.id === '0') {
      choosenObjectives = [...leaderL3ObjectivesOptions]
    } else {
      choosenObjectives = [selectedL3ObjectivesLeader]
    }

    setObjectiveFilterValue((prevFilter) => {
      const uniqueValues = choosenObjectives.filter(
        ({ id }) => !prevFilter.some((item) => item.id === id),
      )
      return [...prevFilter, ...uniqueValues]
    })

    closeModal()
  }

  return (
    <div className="modal-outer-container">
      <Modal closeModal={closeModal} width={'37rem'}>
        {isLoading && <InPageLoader inComponent={true} />}
        {!isLoading && (
          <>
            <div className="modal-gray-box">
              <div className="modal-inner-content">
                <div className="modal-inner-title">
                  {t('Common:searchForObjectivesModal.searchForObjectives')}
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '2rem',
                    marginBottom: '1rem',
                  }}
                >
                  <div
                    className="option-button"
                    style={{ backgroundColor: !isObjetiveModeEnabled ? '#4472c4' : '#a6a6a6' }}
                    onClick={() => {
                      setIsObjectiveModeEnabled(false)
                    }}
                  >
                    <span>{t('Kanban:leader')}</span>
                  </div>
                  <div
                    className="option-button"
                    style={{ backgroundColor: isObjetiveModeEnabled ? '#4472c4' : '#a6a6a6' }}
                    onClick={() => {
                      setIsObjectiveModeEnabled(true)
                    }}
                  >
                    <span>{t('Kanban:objective')}</span>
                  </div>
                </div>

                {!isObjetiveModeEnabled && (
                  <div className="search-objectives-modal-outer-container">
                    <div className="search-objectives-dropdown-container">
                      <label for="enterpriseObjective">
                        <span className="search-objectives-dropdown-label">
                          {t('Common:searchForObjectivesModal.searchForLeader')}:
                        </span>
                      </label>
                      <Select
                        placeholder={t('Common:filter.select') + '...'}
                        className="basic-single"
                        value={selectedLeader}
                        onChange={handleLeaderChange}
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option?.eId}
                        options={employees}
                        menuPosition="fixed"
                        isMulti={false}
                      />
                    </div>
                    <div style={{ height: '1rem' }}>
                      <span
                        className="validation-error"
                        style={{
                          fontSize: '14px',
                          visibility: showError && !selectedLeader ? 'visible' : 'hidden',
                        }}
                      >
                        {t('Common:searchForObjectivesModal.selectLeaderError')}
                      </span>
                    </div>

                    <div className="search-objectives-dropdown-container">
                      <label for="enterpriseObjective">
                        <span className="search-objectives-dropdown-label">
                          {t('Common:searchForObjectivesModal.searchForLeader')}:
                        </span>
                      </label>
                      <Select
                        placeholder={t('Common:filter.select') + '...'}
                        className="basic-single"
                        value={selectedL3ObjectivesLeader}
                        onChange={handleL3ObjectivesLeaderChange}
                        getOptionLabel={(option) => option?.statement}
                        getOptionValue={(option) => option?.eId}
                        options={[
                          { id: '0', statement: t('Common:filter.selectAll') },
                          ...leaderL3ObjectivesOptions,
                        ]}
                        menuPosition="fixed"
                        isMulti={false}
                      />
                    </div>
                    <div style={{ height: '1rem' }}>
                      <span
                        className="validation-error"
                        style={{
                          fontSize: '14px',
                          visibility:
                            showError && !selectedL3ObjectivesLeader ? 'visible' : 'hidden',
                        }}
                      >
                        {t('Common:searchForObjectivesModal.selectTeamObjectiveError')}
                      </span>
                    </div>
                  </div>
                )}

                {isObjetiveModeEnabled && (
                  <div className="search-objectives-modal-outer-container">
                    <div className="search-objectives-dropdown-container">
                      <label for="enterpriseObjective">
                        <span className="search-objectives-dropdown-label">
                          {t('Common:searchForObjectivesModal.enterpriseObjective')}:
                        </span>
                      </label>
                      <Select
                        placeholder={t('Common:filter.select') + '...'}
                        className="basic-single"
                        value={selectedL1Objective}
                        onChange={handleL1ObjectiveChange}
                        getOptionLabel={(option) => option?.statement}
                        getOptionValue={(option) => option?.id}
                        options={objectives.filter((obj) => obj.level === 1)}
                        menuPosition="fixed"
                      />
                    </div>
                    <div style={{ height: '1rem' }}>
                      <span
                        className="validation-error"
                        style={{
                          fontSize: '14px',
                          visibility: showError && !selectedL1Objective ? 'visible' : 'hidden',
                        }}
                      >
                        {t('Common:searchForObjectivesModal.selectEnterpriseObjectiveError')}
                      </span>
                    </div>
                    <div className="search-objectives-dropdown-container">
                      <label for="areaObjective">
                        <span className="search-objectives-dropdown-label">
                          {t('Common:searchForObjectivesModal.areaObjective')}:
                        </span>
                      </label>
                      <Select
                        placeholder={t('Common:filter.select') + '...'}
                        className="basic-single"
                        value={selectedL2Objective}
                        onChange={handleL2ObjectiveChange}
                        getOptionLabel={(option) => option?.statement}
                        getOptionValue={(option) => option?.id}
                        options={objectives.filter(
                          (obj) => obj.level === 2 && obj.parentId === selectedL1Objective?.id,
                        )}
                        menuPosition="fixed"
                        isDisabled={selectedL1Objective != null ? false : true}
                      />
                    </div>
                    <div style={{ height: '1rem' }}>
                      <span
                        className="validation-error"
                        style={{
                          fontSize: '14px',
                          visibility: showError && !selectedL2Objective ? 'visible' : 'hidden',
                        }}
                      >
                        {t('Common:searchForObjectivesModal.selectAreaObjectiveError')}
                      </span>
                    </div>
                    <div className="search-objectives-dropdown-container">
                      <label for="teamObjective">
                        <span className="search-objectives-dropdown-label">
                          {t('Common:searchForObjectivesModal.teamObjective')}:
                        </span>
                      </label>
                      <Select
                        placeholder={t('Common:filter.select') + '...'}
                        isMulti
                        value={selectedL3Objectives}
                        onChange={handleL3ObjectiveChange}
                        getOptionLabel={(option) => option?.statement}
                        getOptionValue={(option) => option?.id}
                        options={[
                          { id: '0', statement: t('Common:filter.selectAll') },
                          ...objectives.filter(
                            (obj) => obj.level === 3 && obj.parentId === selectedL2Objective?.id,
                          ),
                        ]}
                        menuPosition="fixed"
                        isDisabled={
                          selectedL2Objective != null && selectedL1Objective != null ? false : true
                        }
                      />
                    </div>
                    <div style={{ height: '1rem' }}>
                      <span
                        className="validation-error"
                        style={{
                          fontSize: '14px',
                          visibility:
                            showError && selectedL3Objectives.length === 0 ? 'visible' : 'hidden',
                        }}
                      >
                        {t('Common:searchForObjectivesModal.selectTeamObjectiveError')}
                      </span>
                    </div>
                  </div>
                )}
                <div className="search-objectives-buttons-container">
                  <div
                    className="sleek-button sleek-blue"
                    style={{ cursor: 'pointer', height: '1.5rem' }}
                    onClick={() => {
                      if (isObjetiveModeEnabled) {
                        handleApplyObjective()
                      } else {
                        handleApplyLeader()
                      }
                    }}
                  >
                    {t('commonButtons.apply')}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>
    </div>
  )
}

export default SearchForObjectivesModal
