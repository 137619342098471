import { useState, useEffect } from 'react'
import React, { useCallback } from 'react'
import ApplicationLayout from '../../components/ApplicationLayout'
import ObjectiveMapByLeader from './leaderMode'
import ObjectiveMapByObjective from './objectiveMode'
import { getTeamLeaderList } from '../../api/services/employee.services'
import { getListOfObjectivesFlow } from '../../api/services/objectives.services'
import InPageLoader from '../../components/InPageLoader/index'
import ObjMapActivitiesModal from './modelComponent/activitiesModal'
import { getCompanyConfigSettings, isAreaLeader, isCEO, isCXO } from '../../utils/auth'
import ObjectiveDetailsModal from '../MeetingMultiView/components/MeetingTopics/common/component/OjbectiveDetailsModal'
import AddEditObjectiveModal from '../../components/AddEditObjectiveModal'
import L1L2ObjectiveModal from '../../components/L1L2ObjectiveModal'
import { useTranslation } from 'react-i18next'
import { PendingActivitiesModal } from '../EmailReminderTab'
import NotificationsModal from './modelComponent/notificationsModal'
import RequestUpdateModal from './modelComponent/requestUpdateModal'
import { useLocation } from 'react-router-dom'
import KPIModal from '../../components/KPI/KPIModal'
import FilingCabinetModal from '../../components/FilingCabinetModal'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../utils/routes'
import { hasRole } from '../../utils/auth'
import Toast from '../../common/toast'
import useDeleteObjective_v2 from '../../api/mutation/deleteObjectiveHome_v2'
import useEditObjectiveComplete_v2 from '../../api/mutation/editObjectiveCompleteHome_v2'
import Swal from 'sweetalert2'
import CompletedObjectiveReportModal from '../../components/ObjectiveReportModal'
import GanttChartModal from '../../components/GanttChartModal/ganttChartModal'

export default function ObjectiveMap() {
  const { t } = useTranslation(['Common', 'ObjectiveMap'])
  const [objMapMode, setObjMapMode] = useState('leader')
  const [objectiveList, setObjectiveList] = useState([])
  const [isLoadingObjectives, setIsLoadingObjectives] = useState(false)
  const [showLoaderAfterFirstLoad, setShowLoaderAfterFirstLoad] = useState(false)
  const [isActivitiesModalOpen, setIsActivitiesModalOpen] = useState(false)
  const [objId, setObjId] = useState(null)
  const [isObjHistoryOpen, setIsObjHistoryOpen] = React.useState(false)
  const [isAddEditObjModalOpen, setIsAddEditObjModalOpen] = React.useState(false)
  const [isL1L2ModalOpen, setIsL1L2ModalOpen] = React.useState(false)
  const [objModalMode, setObjModalMode] = React.useState(null)
  const [lastClickedLevel, setLastClickedLevel] = React.useState(0)
  const productSettings = getCompanyConfigSettings('productSettings')
  const [isEmailReminderModalOpen, setIsEmailReminderModalOpen] = React.useState(false)
  const [isObjectiveReportModalOpen, setIsObjectiveReportModalOpen] = React.useState(false)
  const [editedObj, setEditedObj] = React.useState(null)
  const [objData, setObjData] = React.useState({})
  const [addedObj, setAddedObj] = React.useState(0)
  const [activitiesModalMode, setActivitiesModalMode] = React.useState('')
  const [isNotificationModalOpen, setIsNotificationModalOpen] = React.useState(false)
  const [isRequestUpdateModalOpen, setIsRequestModalOpen] = React.useState(false)
  const [isKPIModalOpen, setIsKPIModalOpen] = React.useState(false)
  const [isViewFilesModalOpen, setIsViewFilesModalOpen] = React.useState(false)
  const [openOnSpecificKpi, setOpenOnSpecificKpi] = React.useState(false)
  const [isGanttChartModalOpen, setIsGanttChartModalOpen] = React.useState(false)
  const location = useLocation()
  const [singleObjId, setSingleObjId] = React.useState(null)
  const navigate = useNavigate()
  const canViewKpiReport =
    hasRole('cos') || hasRole('ceo') || hasRole('cxo') || hasRole('area') || hasRole('team')

  const deleteObjective_v2 = useDeleteObjective_v2()
  const mutationEditObjectiveComplete_v2 = useEditObjectiveComplete_v2()
  const [fromSetUpAgent, setFromSetUpAgent] = useState(false)

  async function getObjList() {
    if (addedObj === 0) {
      setIsLoadingObjectives(true)
    } else {
      setShowLoaderAfterFirstLoad(true)
    }
    const result = await getListOfObjectivesFlow('', '')
    if (addedObj === 0) {
      setIsLoadingObjectives(false)
    } else {
      setShowLoaderAfterFirstLoad(false)
    }
    setObjectiveList(result)
  }

  const ShowDeleteObjectiveModal = async (id, level, numChildren) => {
    if (level === 2 && numChildren > 0) {
      console.log('numChildren ShowDeleteObjectiveModal: ', numChildren)
      const hasChildrenConfirm = await Swal.fire({
        title: t('ObjectiveMap:confirmationMsgs.objectiveHasChildrenDeletion'),
        icon: 'info',
        confirmButtonText: t('Common:commonButtons.ok'),
      })
      return
    }
    const resultConfirm = await Swal.fire({
      title: t('ObjectiveMap:confirmationMsgs.confirmDeleteObjective'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (resultConfirm.isConfirmed) {
      deleteObj(id, id)
    }
  }

  const deleteObj = async (objId, originalId, lastCopy = false) => {
    let postObj = { objId: objId, lastCopy: lastCopy, originalId: originalId }
    await deleteObjective_v2.mutateAsync(postObj, {
      onSuccess: () => {
        loadAgain()
      },
      onError: () => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      },
    })
  }

  const completeObjective = async (objId, originalId) => {
    let postObj = { objId: objId, originalId: originalId }
    await mutationEditObjectiveComplete_v2.mutateAsync(postObj, {
      onSuccess: (response) => {
        loadAgain()
      },
      onError: (response) => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      },
    })
  }

  const ShowCompleteObjectiveModal = async (id, level, numChildren) => {
    if (level === 2 && numChildren > 0) {
      console.log('numChildren ShowCompleteObjectiveModal: ', numChildren)
      const hasChildrenConfirm = await Swal.fire({
        title: t('ObjectiveMap:confirmationMsgs.objectiveHasChildrenCompletion'),
        icon: 'info',
        confirmButtonText: t('Common:commonButtons.ok'),
      })
      return
    }
    const resultConfirm = await Swal.fire({
      title: t('ObjectiveMap:confirmationMsgs.confirmCompleteObjective'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (resultConfirm.isConfirmed) {
      completeObjective(id, id)
    }
  }

  useEffect(() => {
    if (location.state && !location.state.fromSetUpAgent) {
      setObjMapMode('objective')
      setSingleObjId(location.state.objId)
    }
    if (location?.state?.fromSetUpAgent) {
      setFromSetUpAgent(true)
    }
  }, [])

  useEffect(() => {
    getObjList()
  }, [addedObj])

  const loadAgain = () => {
    setAddedObj((prev) => prev + 1)
  }

  if (isLoadingObjectives) {
    return (
      <ApplicationLayout>
        <InPageLoader />
      </ApplicationLayout>
    )
  }

  return (
    <>
      <ApplicationLayout>
        {isRequestUpdateModalOpen && (
          <RequestUpdateModal closeModal={() => setIsRequestModalOpen(false)} objectiveId={objId} />
        )}

        {isNotificationModalOpen && (
          <NotificationsModal
            closeModal={() => setIsNotificationModalOpen(false)}
            objectiveId={objId}
          />
        )}
        {isActivitiesModalOpen && (
          <ObjMapActivitiesModal
            closeModal={() => setIsActivitiesModalOpen(false)}
            objectiveId={objId}
            mode={activitiesModalMode}
          />
        )}

        {isObjHistoryOpen && (
          <ObjectiveDetailsModal
            isModalOpen={isObjHistoryOpen}
            closeModal={() => {
              setIsObjHistoryOpen(false)
            }}
            objIdIn={objId}
            showInitiativesId={productSettings}
          />
        )}

        {isAddEditObjModalOpen && (
          <AddEditObjectiveModal
            objId={parseInt(objId)}
            closeModal={() => {
              setIsAddEditObjModalOpen(false)
            }}
            isAddEditObjectiveModalOpen={isAddEditObjModalOpen}
            mode={objModalMode}
            setEditedObj={setEditedObj}
            objData={objData}
            setObjData={setObjData}
            loadAgain={loadAgain}
            isFromObjectiveMap={true}
          />
        )}

        {isL1L2ModalOpen && (
          <L1L2ObjectiveModal
            isOpen={isL1L2ModalOpen}
            mode={objModalMode}
            handleClose={() => {
              setIsL1L2ModalOpen(false)
            }}
            objId={objId}
            objectiveType={lastClickedLevel === 1 ? 'cxo' : 'area'}
            leaders={[]}
            enterpriseObjectives={[]}
            isAreaDashboard={true}
            isCeo={isCEO || isCXO ? true : false}
            setEditedObj={setEditedObj}
            loadAgain={loadAgain}
            objData={objData}
            setObjData={setObjData}
          />
        )}

        {isObjectiveReportModalOpen && (
          <CompletedObjectiveReportModal
            isObjectiveReportModalOpen={isObjectiveReportModalOpen}
            closeModal={() => {
              setIsObjectiveReportModalOpen(false)
            }}
          />
        )}

        {isEmailReminderModalOpen && (
          <PendingActivitiesModal
            isEmailReminderModalOpen={isEmailReminderModalOpen}
            closeModal={() => {
              setIsEmailReminderModalOpen(false)
            }}
          />
        )}

        {isKPIModalOpen && (
          <KPIModal
            objId={objId}
            handleCancel={() => {
              setIsKPIModalOpen(false)
            }}
            openOnSpecificKpi={openOnSpecificKpi}
            reloadBackground={loadAgain}
          />
        )}

        {isViewFilesModalOpen && (
          <FilingCabinetModal
            objId={parseInt(objId)}
            closeModal={() => {
              setIsViewFilesModalOpen(false)
            }}
          />
        )}

        {isGanttChartModalOpen === true && (
          <GanttChartModal
            isModalOpen={isGanttChartModalOpen}
            closeModal={() => {
              setIsGanttChartModalOpen(false)
            }}
            objId={objId}
          />
        )}

        <div className="container">
          {/* <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem', borderBottom: '1px solid #eee', paddingBottom:'10px' }}> */}
          <div className="page_title">{t('navigation.objectiveMap')}</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '1rem',
              border: '1px solid #eee',
              padding: '10px',
              borderRadius: '10px',
            }}
          >
            {t('ObjectiveMap:menu.deepDiveBy')}:
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                width: 'fitContent',
                height: 'fitContent',
                borderRadius: '4px',
                color: 'white',
                padding: '0.3rem 1rem 0.3rem 1rem',
                backgroundColor: objMapMode === 'leader' ? '#4472c4' : '#a6a6a6',
              }}
              onClick={() => setObjMapMode('leader')}
            >
              {t('ObjectiveMap:menu.leaders')}
            </div>
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                width: 'fitContent',
                height: 'fitContent',
                borderRadius: '4px',
                color: 'white',
                padding: '0.3rem 1rem 0.3rem 1rem',
                backgroundColor: objMapMode === 'objective' ? '#4472c4' : '#a6a6a6',
              }}
              onClick={() => setObjMapMode('objective')}
            >
              {t('ObjectiveMap:menu.objectives')}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
              {canViewKpiReport && (
                <div
                  style={{
                    color: '#0926D5',
                    textDecoration: 'underline',
                    marginRight: '1rem',
                  }}
                >
                  <span
                    style={{ cursor: 'pointer', fontSize: '14px' }}
                    onClick={() => {
                      navigate(`/${routes.kpiReportByLeader}`)
                    }}
                  >
                    {t('objectiveMap.kpiByLeader')}
                  </span>
                </div>
              )}
              <div
                style={{
                  color: '#0926D5',
                  textDecoration: 'underline',
                  marginRight: '1rem',
                }}
              >
                <span
                  style={{ cursor: 'pointer', fontSize: '14px' }}
                  onClick={() => {
                    setIsObjectiveReportModalOpen(true)
                  }}
                >
                  {t('objectiveMap.seeObjectiveReport')}
                </span>
              </div>
              <div
                style={{
                  color: '#0926D5',
                  textDecoration: 'underline',
                  marginRight: '1rem',
                }}
              >
                <span
                  style={{ cursor: 'pointer', fontSize: '14px' }}
                  onClick={() => {
                    setIsEmailReminderModalOpen(true)
                  }}
                >
                  {t('objectiveMap.seeFullList')}
                </span>
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '1rem',
              border: '1px solid #fefefe',
              padding: '10px',
              borderRadius: '10px',
              justifyContent: 'flex-end',
            }}
          >
            <div class="fitted-button red-notification" style={{ cursor: 'grab' }}>
              <span class="material-symbols-outlined icon-size" style={{ backgroundColor: '#fff' }}>
                hourglass_disabled
              </span>
            </div>{' '}
            <span style={{ fontSize: '0.75rem' }}> {t('ObjectiveMap:legend.noActivity')}</span>
            <div class="fitted-button green-notification">
              <span class="material-symbols-outlined icon-size" style={{ backgroundColor: '#fff' }}>
                notifications
              </span>
            </div>
            <span style={{ fontSize: '0.75rem' }}> {t('ObjectiveMap:legend.recentActivity')}</span>
            <div class="fitted-button yellow-notification">
              <span class="material-symbols-outlined icon-size" style={{ backgroundColor: '#fff' }}>
                warning
              </span>
            </div>
            <span style={{ fontSize: '0.75rem' }}>{t('ObjectiveMap:legend.overdueActions')}</span>
          </div>

          {/* We will toggle these components by div visibility, rather than mounting/unmounting the 
          components. This is because we don't want to rerender them on toggle. */}

          <div style={{ display: objMapMode === 'objective' ? 'block' : 'none' }}>
            <ObjectiveMapByObjective
              objectiveList={objectiveList}
              activitiesModalClickHander={setIsActivitiesModalOpen}
              setIsObjHistoryOpen={setIsObjHistoryOpen}
              setObjId={setObjId}
              setIsAddEditObjModalOpen={setIsAddEditObjModalOpen}
              setIsL1L2ModalOpen={setIsL1L2ModalOpen}
              setLastClickedLevel={setLastClickedLevel}
              editedObj={editedObj}
              lastClickedLevel={lastClickedLevel}
              setObjModalMode={setObjModalMode}
              setObjData={setObjData}
              setActivitiesModalMode={setActivitiesModalMode}
              setIsNotificationModalOpen={setIsNotificationModalOpen}
              setIsRequestModalOpen={setIsRequestModalOpen}
              singleObjId={singleObjId}
              setSingleObjId={setSingleObjId}
              setIsKPIModalOpen={setIsKPIModalOpen}
              setIsViewFilesModalOpen={setIsViewFilesModalOpen}
              setOpenOnSpecificKpi={setOpenOnSpecificKpi}
              loadAgain={loadAgain}
              ShowDeleteObjectiveModal={ShowDeleteObjectiveModal}
              ShowCompleteObjectiveModal={ShowCompleteObjectiveModal}
              setIsGanttChartModalOpen={setIsGanttChartModalOpen}
            />
          </div>

          <div style={{ display: objMapMode === 'leader' ? 'block' : 'none' }}>
            <ObjectiveMapByLeader
              objectiveList={objectiveList}
              activitiesModalClickHander={setIsActivitiesModalOpen}
              setIsObjHistoryOpen={setIsObjHistoryOpen}
              setObjId={setObjId}
              setIsAddEditObjModalOpen={setIsAddEditObjModalOpen}
              setIsL1L2ModalOpen={setIsL1L2ModalOpen}
              setLastClickedLevel={setLastClickedLevel}
              editedObj={editedObj}
              lastClickedLevel={lastClickedLevel}
              setObjModalMode={setObjModalMode}
              setObjData={setObjData}
              setActivitiesModalMode={setActivitiesModalMode}
              setIsNotificationModalOpen={setIsNotificationModalOpen}
              setIsRequestModalOpen={setIsRequestModalOpen}
              singleObjId={singleObjId}
              setIsKPIModalOpen={setIsKPIModalOpen}
              setIsViewFilesModalOpen={setIsViewFilesModalOpen}
              setOpenOnSpecificKpi={setOpenOnSpecificKpi}
              ShowDeleteObjectiveModal={ShowDeleteObjectiveModal}
              ShowCompleteObjectiveModal={ShowCompleteObjectiveModal}
              fromSetUpAgent={fromSetUpAgent}
              setIsGanttChartModalOpen={setIsGanttChartModalOpen}
            />
          </div>
        </div>
      </ApplicationLayout>
    </>
  )
}
