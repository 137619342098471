import React from 'react'
import Modal from '../Modal'
import { Formik, Field, Form } from 'formik'
import TradeoffCapacitySchema from '../../utils/validationSchemas/TradeoffCapacityNeededSchema'
import ModalSaveButton from '../ModalSubmitButton'
import { useTranslation } from 'react-i18next'

const EditCapacityNeeded = ({ tradeoff, closeModal, handleSubmit }) => {
  const { t } = useTranslation(['MeetingHub'])
  return (
    <>
      <Modal
        title={t('editCapacityNeededModal.title')}
        closeModal={() => {
          closeModal()
        }}
        width={'40rem'}
      >
        <Formik
          enableReinitialize
          initialValues={{
            capacityNeeded: parseInt((tradeoff.requiredFte - tradeoff.allocatedFte) * 100) ?? '',
            // only use for Yup error validation
            capacityNeededCopy: parseFloat(tradeoff?.requiredFte).toFixed(2) ?? '',
            allocatedFte: parseFloat(tradeoff?.allocatedFte).toFixed(2) ?? '',
          }}
          validationSchema={TradeoffCapacitySchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(tradeoff.orginalObjId, values.capacityNeeded)
            setTimeout(() => {
              resetForm()
            }, 2000)
          }}
        >
          {(errors, touched, resetForm, values, setFieldValue, validateForm) => (
            <Form className="form">
              <div className="form-field">
                <label>{t('editCapacityNeededModal.additionalCapacityNeeded')}</label>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Field
                    type="number"
                    id="capacityNeeded"
                    name="capacityNeeded"
                    autoComplete="off"
                  />
                  %
                </div>

                {errors?.errors?.capacityNeeded && (
                  <div
                    className="validation-error"
                    style={{ textAlign: 'left', maxWidth: '16rem' }}
                  >
                    {errors?.errors?.capacityNeeded}
                  </div>
                )}
              </div>

              <div className="modal-dual-btn">
                <button
                  className="dual-btn negative-btn"
                  onClick={() => {
                    closeModal()
                    resetForm()
                  }}
                  type="button"
                >
                  {t('Common:commonButtons.cancel')}
                </button>
                <ModalSaveButton></ModalSaveButton>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
export default EditCapacityNeeded
