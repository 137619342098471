import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const editOverAllocation = async (allocData) => {
  const { data } = await axios.post(`${BASE_URL}/adjust_over_alloc`, allocData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useEditOverAllocation() {
  const client = useQueryClient()
  return useMutation((data) => editOverAllocation(data), {
    onSuccess: () => {
      void client.invalidateQueries('over-allocated-employees')
    },
  })
}
