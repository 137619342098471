import React from 'react'
import '../../../MeetingView/components/MilestonesOverview/styles.css'
import Activities from '../../../../components/Activities'

const Tasks = () => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="shadow" style={{ width: '80%', marginBottom: '3rem', padding: '2rem' }}>
          <Activities view="DecisionMeeting"></Activities>
        </div>
      </div>
    </>
  )
}

export default Tasks
