import React, { useState } from 'react'
import deleteCross from '../../../../../../assets/delete-cross.svg'
import './styles.css'
import Modal from '../../../../../../components/Modal'

const AddOptionModal = ({
  showAddOptionModal,
  handleClose,
  tradeoffs,
  setTradeoffs,
  meetingData,
  tradeOffIndex,
}) => {
  let selectedOptionIds = []

  const toggleSelectedOption = (e) => {
    const idInSelectedOptions = selectedOptionIds.find((id) => id === e.target.value)

    if (!idInSelectedOptions) {
      selectedOptionIds.push(e.target.value)
    } else {
      selectedOptionIds.splice(selectedOptionIds.indexOf(idInSelectedOptions), 1)
    }
  }

  const renderOptionsHandler = () => {
    // if selected empty
    const newTradeoffs = [...tradeoffs]

    for (const selectedId of selectedOptionIds) {
      const targetObjective = meetingData?.execAllObjectives?.find(
        (objective) => objective.objId.toString() === selectedId,
      )
      // let newOptionObject = {
      //   fteLost: targetObjective.fteLost,
      //   statement: targetObjective.statement,

      //   allocatedFte: targetObjective.allocatedFte,
      //   impactMeasure: targetObjective.impactMeasure,
      //   objId: targetObjective.objId,
      //   ownerName: targetObjective.ownerName || 'Unknown',
      //   ownerId: employees.find((employee) => employee.name === targetObjective.ownerName).eId,
      // }

      let newOptionObject = JSON.parse(JSON.stringify(targetObjective))

      newTradeoffs[tradeOffIndex].options.push(newOptionObject)
    }

    setTradeoffs([...newTradeoffs])
    //setShowAddOptionModal(false)
    handleClose()
    selectedOptionIds = []
  }

  return (
    <>
      {showAddOptionModal === true && (
        <Modal title={`Choose which options you'd like to add`} closeModal={handleClose}>
          <table className="options-table">
            <tbody>
              <tr>
                <th>Objective</th>
                <th>Owner</th>
                <th>Capacity</th>
                <th>Preview trade off</th>
              </tr>
              {meetingData?.execAllObjectives
                .filter(
                  (objective) =>
                    tradeoffs.length > 0 &&
                    !tradeoffs[tradeOffIndex].options.find(
                      (option) => option.objId === objective.objId,
                    ) &&
                    objective.fteLost > 0,
                )
                .map((option, index) => {
                  return (
                    <tr key={`option-addition-${index}`}>
                      <td>{option.statement}</td>
                      <td>{option.ownerName}</td>
                      <td>{option.fteLost}</td>
                      <td>
                        <input
                          className="add-option-checkbox"
                          value={option.objId}
                          type="checkbox"
                          defaultChecked={false}
                          onChange={(e) => toggleSelectedOption(e)}
                        />
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: '2rem',
            }}
          >
            <button onClick={renderOptionsHandler} className="big-btn">
              ADD OPTIONS
            </button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default AddOptionModal
