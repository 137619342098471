import React from 'react'
import { Outlet } from 'react-router-dom'
import ApplicationLayout from '../../components/ApplicationLayout'
import Loading from '../../components/Loading'
import MeetingViewHeaders from './components/Headers'
import ScoreCard from './components/ScoreCard'
import useMeetingViewData from './hooks/useMeetingViewData'
import { useState, useEffect } from 'react'
import './styles.css'
import './responsive.css'

const MeetingViewPage = () => {
  const [refreshIndex, setRefreshIndex] = useState(0)
  const {
    isLoading,
    tradeoffs,
    employees,
    scoreCard,
    meetingData,
    F2WData,
    selectedCardIndexHook,
    setSelectedCardIndexHook,
    updateScoreCard,
    addOption,
  } = useMeetingViewData(refreshIndex)

  const hasF2WCapacity = F2WData?.f2wTeamCapacities.length > 0 ? true : false

  const ownerName = meetingData ? meetingData.displayTitle : ''

  if (isLoading) {
    return <Loading />
  }

  const loading = (isLoading) => {
    const tradeOffOverlay = document.querySelector('.meeting-view-overlay')
    const tradeOffSpinner = tradeOffOverlay.querySelector('.meeting-view-spinner')

    if (isLoading) {
      tradeOffOverlay.classList.remove('hidden')
      tradeOffSpinner.classList.remove('hidden')
    } else {
      tradeOffOverlay.classList.add('hidden')
      tradeOffSpinner.classList.add('hidden')
    }
  }
  const overlayClickHandler = (e) => {
    const tradeOffOverlay = document.querySelector('.meeting-view-overlay')

    if (e.target === tradeOffOverlay) {
      showMeetingViewModal(false)
    }
  }

  const showMeetingViewModal = (shouldShow) => {
    const tradeOffOverlay = document.querySelector('.meeting-view-overlay')
    const tradeOffModal = tradeOffOverlay.querySelector('.meeting-view-modal')

    if (shouldShow) {
      tradeOffOverlay.classList.remove('hidden')
      tradeOffModal.classList.remove('hidden')
    } else {
      tradeOffOverlay.classList.add('hidden')
      tradeOffModal.classList.add('hidden')
      tradeOffModal.innerHTML = ''
    }
  }

  return (
    <ApplicationLayout>
      <div className="meeting-view-overlay hidden" onClick={(e) => overlayClickHandler(e)}>
        <div className="meeting-view-modal hidden"></div>
        <div className="meeting-view-spinner hidden"></div>
      </div>

      <div className="meeting-view">
        <h2 className="meetingViewTitle">{ownerName}</h2>
        <MeetingViewHeaders hasF2WCapacity={hasF2WCapacity} />
        <div className="meetingview-view-body">
          <div className="meeting-view-container">
            <Outlet
              context={{
                tradeoffs,
                employees,
                meetingData,
                F2WData,
                updateScoreCard,
                loading,
                showMeetingViewModal,
                setRefreshIndex,
                setSelectedCardIndexHook,
              }}
            />
          </div>
          {scoreCard && (
            <ScoreCard scoreCardData={scoreCard} selectedCardIndexHook={selectedCardIndexHook} />
          )}
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default MeetingViewPage
