import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getMeetingData = async (refreshIndex) => {
  const { data } = await axios.get(`${BASE_URL}/meeting_view_response`)

  return data
}

// Takes a `refreshIndex` so that we can trigger a refetch automatically. useQuery automatically
//does this when the vars in the cache key change. The value is irrelevant for our purpose.
export default function useMeetingData(refreshIndex) {
  return useQuery(['meeting', refreshIndex], (refreshIndex) => getMeetingData(refreshIndex), {
    refetchOnWindowFocus: false,
  })
}
