import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getTeamLeaderActivities = async () => {
  const { data } = await axios.get(`${BASE_URL}/tl_activities`)

  return data
}

// Takes a `refreshIndex` so that we can trigger a refetch automatically. useQuery automatically
//does this when the vars in the cache key change. The value is irrelevant for our purpose.
export default function useTeamLeaderActivities() {
  return useQuery(['team-leader-activities'], () => getTeamLeaderActivities(), {
    refetchOnWindowFocus: false,
  })
}
