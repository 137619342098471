import React from 'react'
import { Link } from 'react-router-dom'
import { meetingViewSubRoutes, routes } from '../../../../utils/routes'
import '../../styles.css'
import { useLocation } from 'react-router-dom'

const MeetingViewHeaders = ({ hasF2WCapacity }) => {
  const location = useLocation()
  const showTrendAndAnalysis = localStorage.getItem('dMd') === 'true'

  return (
    <div className="meetingview-nav-container">
      {/*TODO Swetha: Commenting Overallocation for now */}
      {/* <Link
          to={`/${routes.meetingview}/${meetingViewSubRoutes.overAllocation}`}
          className={`nav-option ${
            location.pathname.endsWith(meetingViewSubRoutes.overAllocation) ? 'active' : ''
          }`}
        >
          <span className="nav-option-text">Overallocations</span>
        </Link> */}

      {!hasF2WCapacity ? (
        ''
      ) : (
        <Link
          to={`/${routes.meetingview}/${meetingViewSubRoutes.F2WAssignment}`}
          className={`nav-option ${
            location.pathname.endsWith(meetingViewSubRoutes.F2WAssignment) ? 'active' : ''
          }`}
        >
          <span className="nav-option-text">Flow-to-work Allocations</span>
        </Link>
      )}

      <Link
        to={`/${routes.meetingview}/${meetingViewSubRoutes.tradeOffs}`}
        className={`nav-option ${
          location.pathname.endsWith(meetingViewSubRoutes.tradeOffs) ? 'active' : ''
        }`}
      >
        <span className="nav-option-text">Trade-offs</span>
      </Link>
      {/* <Link
        to={`/${routes.meetingview}/${meetingViewSubRoutes.dependencyResolution}`}
        className={`nav-option ${
          location.pathname.endsWith(meetingViewSubRoutes.dependencyResolution) ? 'active' : ''
        }`}
      >
        <span className="nav-option-text">Dependency Resolution</span>
      </Link> */}

      <Link
        to={`/${routes.meetingview}/${meetingViewSubRoutes.overview}`}
        className={`nav-option ${
          location.pathname.endsWith(meetingViewSubRoutes.overview) ? 'active' : ''
        }`}
      >
        <span className="nav-option-text">Summary of Objectives</span>
      </Link>
      {showTrendAndAnalysis && (
        <Link
          to={`/${routes.meetingview}/${meetingViewSubRoutes.trendsAnalysis}`}
          className={`nav-option ${
            location.pathname.endsWith(meetingViewSubRoutes.trendsAnalysis) ? 'active' : ''
          }`}
        >
          <span className="nav-option-text">Trends and Analysis</span>
        </Link>
      )}
    </div>
  )
}

export default MeetingViewHeaders
