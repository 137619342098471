import React from 'react'
import '../styles.css'
import { useGovernanceContext } from '../../../context/governanceContext'
import useAreaLeadersForCsuiteLeader from '../../../../../api/query/useAreaLeadersForCsuiteLeader'
import InPageLoader from '../../../../../components/InPageLoader'
import { AdminDropdownMenu } from '../../../../../components/SooDropdownMenu'
import { useTranslation } from 'react-i18next'
import { postHttpRequest } from '../../../../../api/query/dynamicAPI'
import Swal from 'sweetalert2'
import Toast from '../../../../../common/toast'

const AreaLeaders = ({ setRefetchData }) => {
  let { state, dispatch } = useGovernanceContext()
  const { t } = useTranslation(['Dashboard'])
  const { data, isLoading, isFetching } = useAreaLeadersForCsuiteLeader(
    state.selectedCsuiteLeaderEId,
    state?.data?.allAreaLeadersList?.length ?? 0,
  )
  //Swetha TODO: This is a temporary hack. Changes need to be made so the state is updated automatically and not manually as below
  if (!state.isAreaLeaderListUpdated) {
    state = {
      ...state,
      data: {
        ...state.data,
        // data = undefined || null is when users select 'select c-suite leader' option or..
        areaLeadersList: !data ? [] : [...data.employees.filter((e) => !e.isDeleted)],
      },
    }
  }

  return (
    <div className="table-wrapper" style={{ justifyContent: 'center', alignItems: 'center' }}>
      {isLoading && isFetching && (
        <div>
          <InPageLoader inComponent={true} />
        </div>
      )}
      {!(isLoading && isFetching) && (
        <div className="widget-grid-5" style={{ width: '100%', marginLeft: '5rem' }}>
          <div
            style={{
              margin: '0.5rem 0.5rem',
              textAlign: 'left',
              alignItems: 'normal',
              justifyContent: 'normal',
              width: '100%',
            }}
            className="widget-grid-title"
          >
            {t('adminDashboard.name')}
          </div>
          <div
            style={{
              margin: '0.5rem 0.5rem',
              textAlign: 'left',
              alignItems: 'normal',
              justifyContent: 'normal',
              width: '100%',
            }}
            className="widget-grid-title"
          >
            {t('adminDashboard.title')}
          </div>
          <div
            style={{
              margin: '0.5rem 0.5rem',
              textAlign: 'left',
              alignItems: 'normal',
              justifyContent: 'normal',
              width: '100%',
            }}
            className="widget-grid-title"
          >
            {t('adminDashboard.email')}
          </div>
          <div
            style={{
              margin: '0.5rem 0.5rem',
              textAlign: 'left',
              alignItems: 'normal',
              justifyContent: 'normal',
              width: '100%',
            }}
            className="widget-grid-title"
          >
            {t('adminDashboard.function')}
          </div>
          <div
            style={{ margin: '0.5rem 0.5rem', width: '8rem' }}
            className="widget-grid-title"
          ></div>

          {state?.data?.areaLeadersList?.map((areaLeader, index) => (
            <React.Fragment key={`TL-${areaLeader?.eId}-${areaLeader.email}`}>
              <div
                style={{
                  color: areaLeader?.isDeactivated ? '#BBBCBC' : 'black',
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                  width: '90%',
                  wordBreak: 'break-word',
                  wordWrap: 'break-word',
                }}
                className="widget-grid-item"
              >
                {areaLeader?.name}
              </div>
              <div
                style={{
                  color: areaLeader?.isDeactivated ? '#BBBCBC' : 'black',
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                  width: '90%',
                  wordBreak: 'break-word',
                  wordWrap: 'break-word',
                }}
                className="widget-grid-item"
              >
                {areaLeader?.title}
              </div>
              <div
                style={{
                  color: areaLeader?.isDeactivated ? '#BBBCBC' : 'black',
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                  width: '90%',
                  wordBreak: 'break-word',
                  wordWrap: 'break-word',
                }}
                className="widget-grid-item"
              >
                {areaLeader?.email}
              </div>
              <div
                style={{
                  color: areaLeader?.isDeactivated ? '#BBBCBC' : 'black',
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                  width: '90%',
                  wordBreak: 'break-word',
                  wordWrap: 'break-word',
                }}
                className="widget-grid-item"
              >
                {areaLeader?.function}
              </div>
              <div
                style={{
                  margin: '0.5rem 0.5rem',
                  textAlign: 'left',
                  alignItems: 'normal',
                  justifyContent: 'normal',
                }}
                className="widget-grid-item"
              >
                <AdminDropdownMenu
                  hasDeactivate={!areaLeader?.isDeactivated}
                  hasDelete={true}
                  onClickEdit={() => {
                    dispatch({
                      type: 'OPEN_AREA_LEADER_MODAL',
                      editAreaLeaderIndex: index,
                      editTeamLeaderIndex: null,
                      editCsuiteLeaderIndex: null,
                      editCeoLeaderIndex: null,
                      editTeamMemberIndex: null,
                      areaLeadersList: state.data.areaLeadersList,
                    })
                  }}
                  onClickDelete={async () => {
                    const result = await Swal.fire({
                      title: t('Common:modalMessages.areYouSureDeleteThis'),
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonText: t('Common:commonButtons.yes'),
                      cancelButtonText: t('Common:commonButtons.no'),
                    })
                    if (result.isConfirmed) {
                      try {
                        const res = await postHttpRequest(`/delete_employee/${areaLeader.eId}`)
                        if (res.success) {
                          setRefetchData((prev) => !prev)
                          Toast.fire({
                            icon: 'success',
                            title: t('Common:modalMessages.deletedSuccessfully'),
                          })
                        } else {
                          Toast.fire({
                            icon: 'error',
                            title: t('Common:modalMessages.youCannotDeleteThis'),
                          })
                        }
                      } catch (error) {
                        Toast.fire({
                          icon: 'error',
                          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
                        })
                      }
                    }
                  }}
                  onClickChangeEmployeeState={async () => {
                    const result = await Swal.fire({
                      title: areaLeader?.isDeactivated
                        ? t('Common:modalMessages.areYouSureReactivate')
                        : t('Common:modalMessages.areYouSureDeactivate'),
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonText: t('Common:commonButtons.yes'),
                      cancelButtonText: t('Common:commonButtons.no'),
                    })
                    if (result.isConfirmed) {
                      try {
                        await postHttpRequest(`/edit_employee_state/${areaLeader.eId}`, {
                          isDeactivated: areaLeader.isDeactivated,
                        })
                        setRefetchData((prev) => !prev)
                        Toast.fire({
                          icon: 'success',
                          title: t('Common:modalMessages.updatedSuccessfully'),
                        })
                      } catch (error) {
                        Toast.fire({
                          icon: 'error',
                          title: t('Common:modalMessages.error'),
                        })
                      }
                    }
                  }}
                />
                {/* <div
                      class="fitted-button blue"
                      onClick={() => {
                        dispatch({
                          type: 'OPEN_AREA_LEADER_MODAL',
                          editAreaLeaderIndex: index,
                          editTeamLeaderIndex: null,
                          editCsuiteLeaderIndex: null,
                          editCeoLeaderIndex: null,
                          areaLeadersList: state.data.areaLeadersList
                        })
                      }}
                    >
                      <span class="material-symbols-outlined icon-size" >edit</span>
                  </div> */}
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  )
}
export default AreaLeaders
