import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getOverAllocatedEmployeeDataForArea = async (areaLeaderId) => {
  const queryString =
    areaLeaderId === null ? '/overallocated_ics_area' : `/overallocated_ics_area/${areaLeaderId}`

  const { data } = await axios.get(`${BASE_URL}${queryString}`)
  return data
}

export default function useOverAllocatedEmployeeDataForArea(areaLeaderId = null) {
  return useQuery(
    ['over-allocated-employees', areaLeaderId],
    () => getOverAllocatedEmployeeDataForArea(areaLeaderId),
    {
      refetchOnWindowFocus: false,
    },
  )
}
