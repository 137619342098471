import React from 'react'
import Modal from '../../../../../components/Modal'

import { useTranslation } from 'react-i18next'
import { dateFilter } from '../../../../../utils/time'
import './style.css'
import { getCompanyConfigSettings } from '../../../../../utils/auth'
import useEditKanbanBoardChangeActivityStatus from '../../../../../api/mutation/useEditKanbanBoardChangeActivityStatus'
import SooDropdownMenu from '../../../../../components/SooDropdownMenu'
import AddEditActivityModal from '../../../../../components/AddEditActivityModal'
import { getHttpRequest } from '../../../../../api/query/dynamicAPI'
import Select from 'react-select'
import Toast from '../../../../../common/toast'
import CommentsModal2 from '../../../../../components/CommentsModal2'
import { markActivityAsPriority } from '../../../../EmailReminderTab/components/utils.js'
import { removeMentionMarkupFromText } from '../../../../../utils/parseMarkup'

const BGUActivityDuesTableModal = ({
  isActivityModalOpen,
  closeModal,
  data,
  refreshBGUScorecardFn = () => {},
  loadAgain = () => {},
  setRefreshIndex = () => {},
  eIdsOfSelectedTls,
  activitiesDuesCategory,
}) => {
  const { t } = useTranslation(['MeetingHub', 'Common'])
  const productSettings = getCompanyConfigSettings('productSettings')

  const [allData, setAllData] = React.useState(data)
  const [dataToShow, setDataToShow] = React.useState(data)

  const [isActivityEditModalOpen, setIsActivityEditModalOpen] = React.useState(false)
  const [objectiveIdSelected, setObjectiveIdSelected] = React.useState(null)
  const [taskIdSelected, setTaskIdSelected] = React.useState(null)
  const [isCompletedBtns, setIsCompletedBtns] = React.useState({})
  const changeActivityStatusMutation = useEditKanbanBoardChangeActivityStatus()

  const [peopleFilterValue, setPeopleFilterValue] = React.useState([])
  const [allAssignees, setAssignees] = React.useState([])

  const [isCommentModalOpen, setIsCommentModalOpen] = React.useState(false)
  const [objective, setObjective] = React.useState({})

  const [modalTitle, setModalTitle] = React.useState('')

  const handleChangePeopleFilter = (value) => {
    setPeopleFilterValue(value)
  }

  React.useEffect(() => {
    let tempModalTitle = ''
    if (activitiesDuesCategory === 0) {
      tempModalTitle = t('MeetingHub:scorecard.overdue')
    } else if (activitiesDuesCategory === 1) {
      tempModalTitle = t('MeetingHub:scorecard.dueNextWeek')
    } else if (activitiesDuesCategory === 2) {
      tempModalTitle = t('MeetingHub:scorecard.noDueDate')
    } else if (activitiesDuesCategory === 3) {
      tempModalTitle = t('MeetingHub:scorecard.dueLater')
    }
    setModalTitle(tempModalTitle)
  }, [activitiesDuesCategory])

  React.useEffect(() => {
    if (allData) {
      let people = []
      allData?.forEach((milestoneObj) => {
        if (milestoneObj.activity?.assigneeList?.length > 0) {
          people.push(...milestoneObj.activity.assigneeList)
        }
      })
      // remove duplicate persons
      people = people?.filter(
        (person, index, arr) => arr.findIndex((val) => val.eId === person.eId) === index,
      )
      setAssignees(people)

      // First sort by priority, then by objective, then activity.
      allData.sort((a, b) => {
        if (a.activity.isPriority === b.activity.isPriority) {
          // Sort by Objective, then activity
          return (
            a.objDesc.localeCompare(b.objDesc) ||
            a.activity.description.localeCompare(b.activity.description)
          )
        } else if (a.activity.isPriority === true) return -1
        return 1
      })

      setDataToShow(allData)
    }
  }, [allData])

  const peopleFilterFn = (activity) =>
    peopleFilterValue.every((person) => {
      return activity?.assigneeList.some((assignee) => assignee.eId == person.eId)
    })

  const applyFilters = () => {
    let tempData = allData?.filter((milestoneObj) => {
      let allMatched = peopleFilterFn(milestoneObj?.activity)

      return allMatched
    })

    setDataToShow(tempData)
  }

  const handleCompleteClick = (objectiveId, milestoneId, index, isClose) => async (e) => {
    if (isCompletedBtns[e.target.id] === undefined || isCompletedBtns[e.target.id] === false) {
      if (isClose) {
        await changeActivityStatus(objectiveId, milestoneId, 'ON_TRACK', index, isClose)
      } else {
        await changeActivityStatus(objectiveId, milestoneId, 'COMPLETED', index, isClose)
      }
    } else {
      if (isCompletedBtns[e.target.id] && isClose) {
        await changeActivityStatus(objectiveId, milestoneId, 'ON_TRACK', index, isClose)
      } else {
        await changeActivityStatus(objectiveId, milestoneId, 'COMPLETED', index, isClose)
      }
    }
    refreshBGUScorecardFn()
  }

  const changeActivityStatus = async (objectiveId, milestoneId, newStatus, index, isClose) => {
    const postObject = {
      objectiveId,
      milestoneId,
      newStatus,
    }

    await changeActivityStatusMutation.mutateAsync(postObject, {
      onSuccess: (response) => {
        setIsCompletedBtns((prev) => ({
          ...prev,
          [milestoneId]: prev[milestoneId] === undefined ? true : !prev[milestoneId],
        }))
        loadAgain()
        if (isClose) {
          dataToShow[index].activity.isClose = false
        } else {
          dataToShow[index].activity.isClose = true
        }
      },
      onError: () => {},
    })
  }

  const refreshBGUActivitiesFn = async () => {
    let url = ''
    if (eIdsOfSelectedTls && eIdsOfSelectedTls.length !== 0) {
      url = url + '/' + eIdsOfSelectedTls.join(',')
    }
    try {
      const response = await getHttpRequest(`/bgu_activities_dues_scorecard_response${url}`, {})

      setAllData(
        response?.milestoneObj?.filter((milestone) => milestone.category == activitiesDuesCategory),
      )
      setPeopleFilterValue([])
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const updateDatasetWithFlagVal = (newFlagVal, milestoneId) => {
    let newData = dataToShow.map((item) => {
      if (item.activity.milestoneId == milestoneId) {
        item.activity.isPriority = newFlagVal
      }
      return item
    })
    setDataToShow(newData)
  }

  return (
    <>
      <Modal
        isModalOpen={isActivityModalOpen}
        closeModal={() => {
          closeModal()
        }}
        width={'90%'}
        style={{ padding: '0rem 0rem 1rem 0rem' }}
        title={modalTitle}
      >
        <>
          {isActivityEditModalOpen === true && objectiveIdSelected && taskIdSelected && (
            <AddEditActivityModal
              isModalOpen={isActivityEditModalOpen}
              closeModal={() => {
                setIsActivityEditModalOpen(false)
                setObjectiveIdSelected(null)
                setTaskIdSelected(null)
              }}
              objectiveId={objectiveIdSelected}
              activityId={taskIdSelected}
              setIsRefreshObjective={() => {
                setRefreshIndex((prev) => prev + 1)
              }}
              refreshBGUScorecardFn={refreshBGUScorecardFn}
              refreshBGUActionsFn={refreshBGUActivitiesFn}
              fromModal={true}
            />
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              wordBreak: 'break-word',
            }}
          >
            <div style={{ width: '100%', marginBottom: '3rem', wordBreak: 'break-all' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '1rem',
                  marginBottom: '3rem',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '0.5rem',
                    textAlign: 'left',
                  }}
                >
                  <label for="teamLeadersFilter">
                    <b>{t('Common:filter.activityOwner')}:</b>
                  </label>
                  <div data-testid="multi-options-1" style={{ width: '18vw' }}>
                    <Select
                      value={peopleFilterValue}
                      isMulti
                      onChange={handleChangePeopleFilter}
                      maxMenuHeight={100}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.eId}
                      options={allAssignees}
                    />
                  </div>
                </div>
                <div
                  class="fitted-button blue"
                  onClick={() => {
                    applyFilters()
                  }}
                  style={{ alignSelf: 'end' }}
                >
                  <span>{t('Common:filter.applyFilter')}</span>
                </div>
              </div>
              {dataToShow && dataToShow.length > 0 ? (
                <div className="chart-due-log-meeting-action-table">
                  {/* <div className="chart-log-meeting-header"></div> */}
                  <div className="chart-log-meeting-header title-border">
                    {t('scorecard.objectiveTitle')}
                  </div>
                  <div className="chart-log-meeting-header title-border">
                    {t('scorecard.activityStatement')}
                  </div>
                  <div className="chart-log-meeting-header title-border">
                    {t('scorecard.createdBy')}
                  </div>
                  <div className="chart-log-meeting-header title-border">
                    {t('scorecard.assignedTo')}
                  </div>
                  <div className="chart-log-meeting-header title-border">
                    {t('scorecard.dueDate')}
                  </div>
                  <div className="chart-log-meeting-header title-border">
                    {t('scorecard.complete')}
                  </div>
                  <div className="chart-log-meeting-header title-border">
                    {t('scorecard.priority')}
                  </div>
                  <div className="chart-log-meeting-header title-border">{t('scorecard.more')}</div>

                  {dataToShow?.map((value, index) => {
                    return (
                      <React.Fragment key={`objective-${value?.activity?.milestoneId}`}>
                        {/* <div></div> */}
                        <div className="chart-log-meeting-description"></div>
                        <>
                          {/* <div></div> */}
                          <>
                            <div style={{ textAlign: 'left' }}>
                              {value.isObjCompleted ? (
                                <div style={{ color: 'grey' }}>
                                  <b>{t('Common:completedCaps')}</b>
                                </div>
                              ) : (
                                <></>
                              )}
                              <div>
                                {productSettings && value?.initiativeId !== 0 ? (
                                  <div>
                                    <span>{value?.initiativeId.toString()?.padStart(5, '0')}</span>
                                    <span style={{ padding: '0rem 0.2rem 0rem 0.2rem' }}> -</span>
                                    <span>{value.objDesc}</span>
                                  </div>
                                ) : (
                                  <span>{value.objDesc}</span>
                                )}
                              </div>
                            </div>
                            <div style={{ textAlign: 'left', paddingLeft: '0.5rem' }}>
                              {removeMentionMarkupFromText(value?.activity.description)}
                            </div>
                            <div style={{ textAlign: 'left' }}>
                              <span key={value?.activity?.createdByAssignee?.name}>
                                {value?.activity?.createdByAssignee?.name ? (
                                  value.activity.createdByAssignee.name.replace(
                                    /(\b\w+\b)\s(\b\w)\w*\b/,
                                    '$1 $2',
                                  )
                                ) : (
                                  <>---</>
                                )}
                              </span>
                            </div>

                            <div style={{ textAlign: 'left' }}>
                              {value?.activity?.assigneeList?.length > 1 ? (
                                value?.activity?.assigneeList?.map((assignee, ind) => (
                                  <>
                                    <span key={assignee.name}>
                                      {assignee.name.replace(/(\b\w+\b)\s(\b\w)\w*\b/, '$1 $2')}
                                    </span>
                                    {ind !== value?.activity?.assigneeList?.length - 1 && (
                                      <span>, </span>
                                    )}
                                  </>
                                ))
                              ) : value?.activity?.assigneeList?.length > 0 ? (
                                <span>{value?.activity?.assigneeList[0].name}</span>
                              ) : (
                                <span>--</span>
                              )}
                            </div>
                            <div style={{ textAlign: 'left' }}>
                              {value?.activity.dueDate == 0
                                ? '--'
                                : dateFilter(value?.activity.dueDate)}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              {!value?.activity.isClose && (
                                <div
                                  class="fitted-button checklist"
                                  onClick={(e) => {
                                    handleCompleteClick(
                                      value.objId,
                                      value?.activity.milestoneId,
                                      index,
                                      value?.activity.isClose,
                                    )(e)
                                  }}
                                >
                                  <span class="material-symbols-outlined grey-check"> done</span>
                                </div>
                              )}

                              {value?.activity.isClose && (
                                <div
                                  class="fitted-button checklist"
                                  onClick={(e) => {
                                    handleCompleteClick(
                                      value.objId,
                                      value?.activity.milestoneId,
                                      index,
                                      value?.activity.isClose,
                                    )(e)
                                  }}
                                >
                                  <span class="material-symbols-outlined green-check"> done</span>
                                </div>
                              )}
                            </div>
                            <div>
                              {!value?.activity.isPriority && (
                                <button
                                  class="icon-button-no-border"
                                  style={{ display: 'inline-block' }}
                                >
                                  <span
                                    class="material-symbols-outlined icon-size"
                                    onClick={() => {
                                      markActivityAsPriority(true, value?.activity.milestoneId)
                                      updateDatasetWithFlagVal(true, value?.activity.milestoneId)
                                    }}
                                  >
                                    flag
                                  </span>
                                </button>
                              )}
                              {value?.activity.isPriority && (
                                <button
                                  class="icon-button-no-border"
                                  style={{ display: 'inline-block' }}
                                >
                                  <span
                                    class="material-symbols-outlined icon-size"
                                    style={{ color: 'red' }}
                                    onClick={() => {
                                      markActivityAsPriority(false, value?.activity.milestoneId)
                                      updateDatasetWithFlagVal(false, value?.activity.milestoneId)
                                    }}
                                  >
                                    flag
                                  </span>
                                </button>
                              )}
                            </div>
                            <div>
                              <SooDropdownMenu
                                fromModal={true}
                                onClickEdit={() => {
                                  setObjectiveIdSelected(value.objId)
                                  setTaskIdSelected(value?.activity.milestoneId)
                                  setIsActivityEditModalOpen(true)
                                }}
                                onClickComment={() => {
                                  setObjectiveIdSelected(value.objId)
                                  setTaskIdSelected(value?.activity.milestoneId)
                                  setIsCommentModalOpen(true)
                                }}
                              />
                            </div>
                            <div class="row-border" style={{ gridColumn: '1 / 9' }}></div>
                            {/* <div>
                          </div> */}
                          </>
                        </>{' '}
                      </React.Fragment>
                    )
                  })}
                </div>
              ) : (
                <div style={{ padding: '2rem' }}>{t('decisionLog.thereAreNoLogsAtThisTime')}</div>
              )}
            </div>
          </div>
        </>
      </Modal>
      {isCommentModalOpen && (
        <CommentsModal2
          objective={{ id: objectiveIdSelected, ...objective }}
          taskId={taskIdSelected}
          handleCancel={() => {
            setIsCommentModalOpen(false)
          }}
          setIsRefreshObjective={() => {}}
        />
      )}
    </>
  )
}

export default BGUActivityDuesTableModal
