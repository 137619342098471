import React from 'react'
import { Formik, Field, Form, FieldArray } from 'formik'
import * as Yup from 'yup'
import employee from '../../../../../../assets/employee.svg'
import useObjectiveDataWithTeamLeaderId from '../../../../../../api/query/getObjectiveDataWithTeamLeaderId'
import useEditOverAllocation from '../../../../../../api/mutation/editOverAllocation'
import Modal from '../../../../../../components/Modal'

const EditOverAllocationModal = ({
  isEditOverAllocationModalOpen,
  setIsEditOverAllocationModalOpen,
  icOtherManagerMappings,
  teamAlloc,
  tlId,
  eId,
  bigproto,
}) => {
  const [errorMessage, setErrorMessage] = React.useState(null)
  const [teamAllocation, setTeamAllocation] = React.useState(null)
  const { data: objectivesData, isLoading: isObjectivesLoading } =
    useObjectiveDataWithTeamLeaderId(tlId)

  const mutationAllocation = useEditOverAllocation()

  React.useEffect(() => {
    if (isEditOverAllocationModalOpen === true) {
      setErrorMessage(null)
      setTeamAllocation(teamAlloc)
    }
  }, [isEditOverAllocationModalOpen])

  const submit = async (values, resetForm) => {
    let submitData = {
      eId: eId,
      ownTeamAlloc: values.teamAlloc,
      objectiveAllocs: values.icOtherManagerMappings.map((obj) => {
        const { objId, allocation } = obj
        return { objId, allocation }
      }),
      icTotalAllocProto: bigproto,
    }
    if (values.isCriticalObj === 'true' || values.isCriticalObj === 'false') {
      submitData = {
        ...submitData,
        isCriticalObj: values.isCriticalObj,
        criticalObjId: parseInt(values.criticalObjId),
      }
    }

    await mutationAllocation.mutateAsync(submitData, {
      onSuccess: (response) => {
        if (response) {
        } else {
        }
      },
      onError: () => {},
    })

    resetForm()
    setIsEditOverAllocationModalOpen(false)
  }

  const checkValidity = (values, resetForm) => {
    let totalSumObj = 0
    for (let obj of values.icOtherManagerMappings) {
      totalSumObj += obj.allocation
    }
    if (totalSumObj + values.teamAlloc > 100) {
      setErrorMessage(
        'The total allocation between the objectives and the team they are part of cannot exceed 100%',
      )
    } else {
      setErrorMessage(null)
      submit(values, resetForm)
    }
  }

  const [resetForm, setResetForm] = React.useState(null)

  const formikRef = React.useRef()

  React.useEffect(() => {
    if (formikRef.current !== undefined) {
      setResetForm(() => formikRef.current.resetForm)
    }
  }, [formikRef])

  return (
    <>
      {isEditOverAllocationModalOpen === true && (
        <Modal
          title={'Edit Allocation'}
          closeModal={() => {
            setIsEditOverAllocationModalOpen(false)
            resetForm()
            setErrorMessage(null)
          }}
        >
          <div className="table-wrapper">
            <Formik
              innerRef={formikRef}
              enableReinitialize
              initialValues={{
                icOtherManagerMappings: icOtherManagerMappings
                  ?.sort((a, b) => (a.objId < b.objId ? -1 : 1))
                  .map((obj) => ({
                    ...obj,
                  })),
                teamAlloc: teamAlloc,
                displayCriticalObjQuestion: false, //To-do: check more correct way to use var to display
                //condional form inputs, when this was done through a hook, it ocassioned whole modal to re-render
                isCriticalObj: '',
                criticalObjId: '',
              }}
              validationSchema={Yup.object().shape({
                teamAlloc: Yup.number()
                  .test(
                    'Is positive?',
                    'Has to be greater or equal than 0%!',
                    (value) => value > -1,
                  )
                  .test(
                    'Is not greater than 100?',
                    'Cannot be greater than 100%!',
                    (value) => value <= 100 || value === undefined,
                  ),
                displayCriticalObjQuestion: Yup.boolean(),
                isCriticalObj: Yup.string().when('displayCriticalObjQuestion', {
                  is: true,
                  then: Yup.string().required('Required'),
                  otherwise: Yup.string().nullable(),
                }),
                criticalObjId: Yup.string().when('isCriticalObj', {
                  is: (isCriticalObj) => isCriticalObj === 'true' || isCriticalObj === 'false',
                  then: Yup.string().required('Required'),
                  otherwise: Yup.string().nullable(),
                }),
                icOtherManagerMappings: Yup.array().of(
                  Yup.object().shape({
                    allocation: Yup.number()
                      .test(
                        'Is positive?',
                        'Has to be greater or equal than 0%!',
                        (value) => value > -1,
                      )
                      .test(
                        'Is not greater than 100?',
                        'Cannot be greater than 100%!',
                        (value) => value <= 100 || value === undefined,
                      ),
                  }),
                ),
              })}
              onSubmit={(values, { resetForm }) => {
                checkValidity(values, resetForm)
              }}
            >
              {({ errors, touched, setFieldValue, values, resetForm, validateForm }) => (
                <Form className="edit-overallocation-form">
                  <div className="widget-grid-2">
                    <div className="widget-grid-title" style={{ justifyContent: 'flex-start' }}>
                      Objectives in other teams:
                    </div>
                    <div className="widget-grid-title">Allocation</div>
                  </div>
                  <FieldArray
                    name="icOtherManagerMappings"
                    render={(arrayHelpers) => (
                      <React.Fragment>
                        {values?.icOtherManagerMappings?.map((obj, index) => (
                          <div key={`objreallocation-${index}`} className="widget-grid-2">
                            <div
                              className="widget-grid-title"
                              style={{
                                fontWeight: 'normal',
                                justifyContent: 'flex-start',
                                alignContent: 'flex-start',
                                textAlign: 'initial',
                              }}
                            >
                              {obj?.objDesc}
                            </div>

                            <div className="widget-grid-title" style={{ fontWeight: 'normal' }}>
                              <div
                                style={{
                                  displa: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <Field
                                  style={{ minWidth: '5vw' }}
                                  className="time-input"
                                  type="number"
                                  min="0"
                                  step=".5"
                                  id="allocation"
                                  name={`icOtherManagerMappings[${index}].allocation`}
                                  autoComplete="off"
                                  onKeyUp={(e) => {
                                    setTimeout(() => {
                                      if (e.target.value.length > 0) {
                                        setFieldValue(
                                          `icOtherManagerMappings[${index}].allocation`,
                                          parseInt(e.target.value),
                                        )
                                      } else {
                                        setFieldValue(
                                          `icOtherManagerMappings[${index}].allocation`,
                                          '',
                                        )
                                      }
                                    }, 0)
                                    setTimeout(() => {}, 200)
                                  }}
                                />
                                %
                              </div>
                            </div>

                            <div className="validation-error"></div>
                            {errors?.icOtherManagerMappings?.[index]?.allocation &&
                              touched?.icOtherManagerMappings?.[index]?.allocation && (
                                <div className="validation-error">
                                  {errors?.icOtherManagerMappings?.[index]?.allocation}
                                </div>
                              )}
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  />

                  <div className="widget-grid-2">
                    <div className="widget-grid-title" style={{ justifyContent: 'flex-start' }}>
                      <br />
                      <br />
                    </div>
                    <div className="widget-grid-title" style={{ justifyContent: 'flex-start' }}>
                      <br />
                      <br />
                    </div>
                    <div
                      className="widget-grid-title"
                      style={{
                        fontWeight: 'normal',
                        justifyContent: 'flex-start',
                        alignContent: 'flex-start',
                        textAlign: 'initial',
                      }}
                    >
                      <b>Allocation to own team:</b>
                    </div>

                    <div className="widget-grid-title" style={{ fontWeight: 'normal' }}>
                      <div
                        style={{
                          displa: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Field
                          style={{ minWidth: '5vw' }}
                          className="time-input"
                          type="number"
                          min="0"
                          step=".5"
                          id="teamAlloc"
                          name="teamAlloc"
                          autoComplete="off"
                          onKeyUp={(e) => {
                            setFieldValue('teamAlloc', parseInt(e.target.value))
                            if (
                              parseInt(teamAllocation) > parseInt(e.target.value) ||
                              e.target.value === undefined
                            ) {
                              setFieldValue('displayCriticalObjQuestion', true)
                            } else {
                              setFieldValue('displayCriticalObjQuestion', false)
                              setFieldValue('isCriticalObj', '')
                            }
                          }}
                        />
                        %
                      </div>
                    </div>

                    <div className="validation-error"></div>
                    {errors?.teamAlloc && touched?.teamAlloc && (
                      <div className="validation-error">{errors?.teamAlloc}</div>
                    )}
                  </div>

                  <br />
                  <br />
                  <div
                    className={` ${values.displayCriticalObjQuestion === true ? '' : ' hidden'}`}
                  >
                    <div className="divider"></div>
                    <br />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <b>
                          Is there any critical objective in your team that can't be slowed down?
                        </b>
                      </div>
                      <br />
                      <div style={{ display: 'flex', displayDirection: 'row' }}>
                        <div className="custom-control">
                          <input
                            id="no"
                            type="radio"
                            value="false"
                            name="isCriticalObj"
                            onClick={(e) => {
                              setFieldValue('isCriticalObj', e.target.value)
                            }}
                            checked={values.isCriticalObj === 'false'}
                          />
                          <label className="custom-control-label" htmlFor="no">
                            No
                          </label>
                        </div>
                        <div className="custom-control">
                          <input
                            id={true}
                            type="radio"
                            value="true"
                            name="isCriticalObj"
                            onClick={(e) => {
                              setFieldValue('isCriticalObj', e.target.value)
                            }}
                            checked={values.isCriticalObj === 'true'}
                          />
                          <label className="custom-control-label" htmlFor="yes">
                            Yes
                          </label>
                        </div>
                      </div>

                      {errors?.isCriticalObj && touched?.isCriticalObj && (
                        <div className="validation-error">{errors?.isCriticalObj}</div>
                      )}
                      <br />
                      <br />
                    </div>
                  </div>

                  <div
                    className={` ${
                      (values.isCriticalObj === 'true' || values.isCriticalObj === 'false') &&
                      values.displayCriticalObjQuestion === true
                        ? ''
                        : ' hidden'
                    }`}
                  >
                    <div className="divider"></div>

                    <br />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <b>Which objective is impacted by this reduction? </b>
                      </div>
                      <br />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        {objectivesData?.objectives?.map((obj, index) => (
                          <React.Fragment>
                            <div className="custom-control">
                              <input
                                id={`obj[${index}].criticalObjId`}
                                type="radio"
                                value={obj.id}
                                name="criticalObjId"
                                onClick={(e) => {
                                  setFieldValue('criticalObjId', e.target.value)
                                }}
                                checked={values.criticalObjId === obj.id}
                              />
                              <label className="custom-control-label">{obj.statement}</label>
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                      {errors?.criticalObjId && touched?.criticalObjId && (
                        <div className="validation-error">{errors?.criticalObjId}</div>
                      )}
                    </div>
                  </div>

                  <div className="validation-error">{errorMessage}</div>

                  <div className="modal-dual-btn">
                    <button
                      className="dual-btn negative-btn"
                      onClick={() => {
                        setIsEditOverAllocationModalOpen(false)
                        resetForm()
                        setErrorMessage(null)
                      }}
                      type="button"
                    >
                      CANCEL
                    </button>
                    <button className="dual-btn positive-btn" type="submit">
                      SAVE
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </>
  )
}

export default EditOverAllocationModal
