import React, { useEffect, useState } from 'react'
// import { useDemandIntakeContext } from '../../context/demandIntakeContext'
import { shortenName } from '../../../utils/general'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { initial } from 'lodash'

const TeamCapacityAllocationTable = ({ contextHandler }) => {
  const { t } = useTranslation(['Common'])
  const { state, dispatch } = contextHandler()
  const [selectedRow, setSelectedRow] = useState(false)
  const [selectedCol, setSelectedCol] = useState(false)
  const [displayCol, setDisplayCol] = useState(
    state?.data?.objectives?.map((obj, index) => {
      return { id: obj.id, check: obj?.team?.length > 0 ? true : false, team: obj?.team } //return obj.team ? true : false
    }),
  )
  const [chosenEmp, setChosenEmp] = useState(
    state?.data?.employees.map((emp, index) => {
      return { eId: emp.eId, check: true, objectives: [] }
    }),
  )

  React.useEffect(() => {
    if (state.allocSubWiz.triggerSelectAllEmp === true) {
      setAllChosenEmp(true)
    }
    dispatch({ type: 'ALLOC_SUB_WIZ_SET_SELECT_ALL_EMP', value: false })
  }, [state.allocSubWiz.triggerSelectAllEmp])

  React.useEffect(() => {
    setAllDisplayColFalse()
    let temp = [...displayCol]

    for (let [indexDis, discol] of temp.entries()) {
      if (discol.team) {
        for (let [indexMember, member] of discol.team.entries()) {
          for (let [indexEmp, chemp] of chosenEmp.entries()) {
            if (member.eId === chemp.eId && chemp.check === true) {
              discol.check = true
            }
          }
        }
      }
    }
    setDisplayCol(temp)
  }, [chosenEmp])

  React.useEffect(() => {
    setChosenEmp(
      state?.data?.employees.map((emp, index) => {
        return { eId: emp.eId, check: true, objectives: [] }
      }),
    )
  }, [state?.data?.employees])

  const handleChange = (e, emp, obj) => {
    e.preventDefault()

    emp.touched1 = true

    let inputValue = Number(e.target.value)

    let totalAllocatedPct = state.data.objectives
      .map((objective) => {
        if (objective.id === obj.id) {
          return null
        }
        return objective?.team?.filter((member) => member.eId === emp.eId)[0]
      })
      .reduce((total, obj) => {
        if (obj) {
          return parseInt(total) + parseInt(obj.value)
        }
        return parseInt(total)
      }, 0)
    totalAllocatedPct = totalAllocatedPct ? totalAllocatedPct : 0
    if (parseInt(e.target.value) + parseInt(totalAllocatedPct) > emp.teamAllocPct) {
      const capacityAllocation = {
        ...emp,
        value: parseInt(e.target.value),
        // error: true,
      }

      dispatch({
        type: 'ALLOC_SUB_WIZ__SET_ALLOCATED_CAPACITY_ERROR',
        objectives: state.data.objectives.map((objective) => {
          if (objective.id === obj.id) {
            const updatedTeamData = objective.team.map((member) => {
              if (member.eId === emp.eId) {
                return {
                  ...capacityAllocation,
                }
              }
              return member
            })
            return {
              ...objective,
              team: updatedTeamData,
            }
          }
          return objective
        }),
      })
      // dispatch({ type: "ALLOC_SUB_WIZ__DISABLE_NEXT" })
      dispatch({ type: 'ALLOC_SUB_WIZ__HAS_ERROR__TEAM_CAP_ALLOC_TABLE' })
    } else {
      const capacityAllocation = {
        ...emp,
        value: parseInt(e.target.value),
        // error: false,
      }
      dispatch({
        type: 'ALLOC_SUB_WIZ__SET_ALLOCATED_CAPACITY',
        objectives: state.data.objectives.map((objective) => {
          if (objective.id === obj.id) {
            const updatedTeamData = objective.team.map((member) => {
              if (member.eId === emp.eId) {
                return {
                  ...capacityAllocation,
                }
              }
              return member
            })
            return {
              ...objective,
              team: updatedTeamData,
            }
          }
          return objective
        }),
      })
      // dispatch({ type: "ALLOC_SUB_WIZ__ENABLE_NEXT" })
    }

    if (checkAllError() === false) {
      dispatch({ type: 'ALLOC_SUB_WIZ__NOT_HAS_ERROR__TEAM_CAP_ALLOC_TABLE' })
    }
  }

  const onNameClick = (index) => {
    setSelectedRow(index)
  }
  const onObjectiveClick = (index) => {
    setSelectedCol(index)
  }

  const checkAllError = () => {
    return state.data?.objectives?.some((obj) => obj.team?.some((mem) => mem.error === true))
  }

  const displayErrors = (emp) => {
    // For now comment this out. This is because if we  assign a person to an existing objective
    // during "Edit Capacity", then their value is NaN but we do not show an error.
    // if (!emp.touched1) {
    //   return
    // }

    let arrayDisplayedColumns = [...state.data.objectives]
    for (let [indexObj, obj] of state.data.objectives.entries()) {
      for (let [indexDis, discol] of displayCol.entries()) {
        if (obj.id === discol.id) {
          obj.check = discol.check
        }
      }
    }

    const negativeErrTrue = arrayDisplayedColumns
      .map(
        (objective) =>
          objective.check === true &&
          objective?.team?.filter((member) => member.eId === emp.eId)[0],
      )
      .filter((item) => {
        if (item) {
          return item.value < 0
        }
      })

    const negativeErrFalse = arrayDisplayedColumns
      .map(
        (objective) =>
          objective.check === false &&
          objective?.team?.filter((member) => member.eId === emp.eId)[0],
      )
      .filter((item) => {
        if (item) {
          return item.value < 0
        }
      })

    const nanErrDisplayTrue = arrayDisplayedColumns
      .map(
        (objective) =>
          objective.check === true &&
          objective?.team?.filter((member) => member.eId === emp.eId)[0],
      )
      .filter((item) => {
        if (item) {
          return isNaN(item.value)
        }
      })

    const nanErrDisplayFalse = arrayDisplayedColumns
      .map(
        (objective) =>
          objective.check === false &&
          objective?.team?.filter((member) => member.eId === emp.eId)[0],
      )
      .filter((item) => {
        if (item) {
          return isNaN(item.value)
        }
      })

    // We don't throw error for empty cells now
    // if (nanErrDisplayTrue.length > 0) {
    //   return (<p className="error-message">
    //     {t("teamCapacityAllocationTable.errorMessage1")}
    //   </p>)
    // }

    if (nanErrDisplayFalse.length > 0) {
      return <p className="error-message">{t('teamCapacityAllocationTable.errorMessage2')}</p>
    }

    if (negativeErrTrue.length > 0) {
      return <p className="error-message">{t('teamCapacityAllocationTable.errorMessage3')}</p>
    }

    if (negativeErrFalse.length > 0) {
      return <p className="error-message">{t('teamCapacityAllocationTable.errorMessage2')}</p>
    }

    if (checkTotalAllocError(emp)) {
      return (
        <p className="error-message" data-testid={`error-message-${emp.eId}`}>
          {t('teamCapacityAllocationTable.errorMessage4')}
        </p>
      )
    }

    return null
  }
  const distrubuteTeamCapacityAllocationEvenly = (emp) => {
    const totalTeamCapacityPct = 100 - emp.sharedAlloc

    const totalObjectivesThisEmployeeIsAssignedTo = state?.data?.objectives?.filter((objective) =>
      objective?.team?.find((member) => member.eId === emp.eId),
    ).length
    const capacityPerObjective = Math.floor(
      totalTeamCapacityPct / totalObjectivesThisEmployeeIsAssignedTo,
    )
    const cloneObjectives = JSON.parse(JSON.stringify(state?.data?.objectives))

    cloneObjectives.map((objective) => {
      if (!objective.team) {
        return objective
      }

      return objective.team.map((member) => {
        if (member.eId === emp.eId) {
          member.value = capacityPerObjective
        }
        return member
      })
    })

    dispatch({
      type: 'ALLOC_SUB_WIZ__SET_ALLOCATED_CAPACITY',
      objectives: cloneObjectives,
    })
  }
  const checkTotalAllocError = (emp) => {
    return emp.currentAlloc + emp.sharedAlloc > 100
  }

  const calculateTotalFTEPerObjective = () => {
    let arrayDisplayedColumns = []
    for (let [indexObj, obj] of state.data.objectives.entries()) {
      for (let [indexDis, discol] of displayCol.entries()) {
        if (obj.id === discol.id && discol.check === true) {
          arrayDisplayedColumns.push(obj)
        }
      }
    }

    const arrayOfAllocation = arrayDisplayedColumns.map(
      (objective) =>
        shouldDisplayCol(objective.id) &&
        objective?.team?.reduce((total, obj) => {
          if (obj.value) {
            return total + obj.value
          }
          return total
        }, 0),
    )
    return arrayOfAllocation
  }
  const calcMemberTotal = (emp) => {
    let x = state.data.objectives
      .map((objective) => objective?.team?.filter((member) => member.eId === emp.eId)[0])
      .filter(Boolean)
    let totalAllocatedPct = x.reduce(
      (total, obj) => parseInt(total) + (isNaN(obj.value) ? 0 : parseInt(obj.value)),
      0,
    )

    return totalAllocatedPct
  }

  const getInputValue = (emp, obj) => {
    return obj?.team?.filter((member) => member.eId === emp.eId)[0]?.value || null
  }

  const errorChecker = () => {
    const error = state?.data?.employees?.some((emp) => {
      return checkTotalAllocError(emp)
    })

    const hasNegatives = state.data?.objectives?.some((obj) =>
      obj.team?.some((mem) => mem.value < 0),
    )

    const hasNaNs = state.data?.objectives?.some((obj) => obj.team?.some((mem) => isNaN(mem.value)))

    if (error || hasNegatives) {
      dispatch({ type: 'ALLOC_SUB_WIZ__HAS_ERROR__TEAM_CAP_ALLOC_TABLE' })
    } else {
      dispatch({ type: 'ALLOC_SUB_WIZ__NOT_HAS_ERROR__TEAM_CAP_ALLOC_TABLE' })
    }
  }

  useEffect(() => {
    updateCurrentTeamAlloc()
    errorChecker()
  }, [state.data.objectives])

  useEffect(() => {
    updateCurrentTeamAlloc(true)
    errorChecker()
  }, [])

  useEffect(() => {
    errorChecker()
  }, [state.data.employees])

  const updateCurrentTeamAlloc = (initialLoad = false) => {
    const updatedEmployees = state.data.employees.map((emp) => {
      // Filter objectives for the current employee
      let objectivesForEmployee = state.data.objectives
        .map((objective) => objective?.team?.find((member) => member.eId === emp.eId))
        .filter(Boolean)

      // Calculate totalAlloc for the current employee
      let currentAlloc = objectivesForEmployee.reduce(
        (total, obj) => total + (isNaN(obj.value) ? 0 : parseInt(obj.value)),
        0,
      )

      if (initialLoad) {
        return { ...emp, currentAlloc: currentAlloc, sharedAlloc: emp.totalAlloc - currentAlloc }
      } else {
        return { ...emp, currentAlloc: currentAlloc }
      }
    })

    dispatch({ type: 'UPDATE_EMPLOYEES', employees: updatedEmployees })
  }

  const getCheck = (id) => {
    let obj = chosenEmp.find((e) => e?.eId === id)
    return obj?.check
  }

  const handleCheck = (id) => {
    let temp = [...chosenEmp]
    for (let [index, chemp] of chosenEmp.entries()) {
      if (chemp.eId === id) {
        chosenEmp[index].check = !chemp.check
      }
    }
    setChosenEmp(temp)
  }

  const shouldDisplayCol = (id) => {
    let obj = displayCol.find((o) => o?.id === id)
    return obj?.check === true
  }

  const shouldCellBeInvalid = (id) => {
    let obj = chosenEmp.find((e) => e?.eId === id)
    return obj?.check === false
  }

  const setAllChosenEmp = (value) => {
    let temp = [...chosenEmp]
    for (let chemp of temp) {
      chemp.check = value
    }
    setChosenEmp(temp)
  }

  const setAllDisplayColFalse = () => {
    let temp = [...displayCol]
    for (let discol of temp) {
      discol.check = false
    }
    setDisplayCol(temp)
  }

  return (
    <>
      {/*  Leaving this commented here in case we want the old style back.
      <div className="capacity-step"  style={{overflowX: 'auto', width: '100%'}}>
        <table className="capacity-table"> */}

      <div className="" style={{ overflow: 'auto', width: '100%' }}>
        <table className="team-capacity-table">
          <tbody>
            <tr
              className="table-objective-mapping-head"
              style={{
                alignItems: 'stretch',
                position: 'sticky',
                top: '0',
                zIndex: '1001',
                background: 'white',
              }}
            >
              <th
                className="team-cap-allocation-generic-cell"
                style={{
                  position: 'sticky',
                  left: '0',
                  zIndex: '1000',
                  background: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <span>{t('teamCapacityAllocationTable.teamMembers')}</span>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <span
                    style={{
                      color: 'rgb(82 119 255)',
                      cursor: 'pointer',
                      fontWeight: 'normal',
                      fontSize: '16px',
                      whiteSpace: 'nowrap',
                    }}
                    onClick={() => {
                      setAllChosenEmp(true)
                    }}
                  >
                    {t('filter.selectAll')}
                  </span>
                  <span
                    style={{
                      color: 'rgb(82 119 255)',
                      cursor: 'pointer',
                      fontWeight: 'normal',
                      fontSize: '16px',
                      display: 'flex',
                      flexDirection: 'column',
                      whiteSpace: 'nowrap',
                    }}
                    onClick={() => {
                      setAllChosenEmp(false)
                    }}
                  >
                    {t('filter.clear')}
                  </span>
                  &nbsp;
                </div>
              </th>
              <th
                className="team-cap-allocation-total-cell-th"
                style={{
                  position: 'sticky',
                  left: '10vw',
                  top: '0',
                  zIndex: '2001',
                  background: 'white',
                }}
              >
                <span>{t('teamCapacityAllocationTable.available')}</span>
              </th>
              <th
                className="team-cap-allocation-total-cell-th"
                style={{
                  position: 'sticky',
                  left: '16.7vw',
                  top: '0',
                  zIndex: '2000',
                  background: 'white',
                }}
              >
                <span>{t('teamCapacityAllocationTable.total')}</span>
              </th>

              {/* className="active-column"*/}
              {state?.data?.objectives?.map((obj, objIndex) => (
                <>
                  {shouldDisplayCol(obj.id) && (
                    <th className={objIndex === selectedCol ? 'active-column' : ''}>
                      <span onClick={() => onObjectiveClick(objIndex)}>{obj.statement}</span>
                    </th>
                  )}
                </>
              ))}
            </tr>
            {chosenEmp.length > 0 &&
              state?.data?.employees?.map((emp, empIndex) => (
                <>
                  <tr
                    key={empIndex}
                    className={empIndex === selectedRow ? 'objective-mapping-tbody-row' : ''}
                  >
                    <td
                      className="team-cap-allocation-generic-cell"
                      style={{ position: 'sticky', left: '0', background: 'white' }}
                    >
                      <span
                        className="member-name"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}
                        onClick={() => onNameClick(empIndex)}
                      >
                        <input
                          style={{ marginRight: '1rem' }}
                          data-testid={emp.name}
                          id={empIndex}
                          type="checkbox"
                          checked={getCheck(emp.eId)}
                          onClick={() => {
                            handleCheck(emp.eId)
                          }}
                        ></input>
                        {shortenName(emp.name)}
                      </span>
                      <div
                        class="fitted-button blue"
                        style={{ fontSize: '12px' }}
                        onClick={() => distrubuteTeamCapacityAllocationEvenly(emp)}
                        disabled={getCheck(emp.eId) === false}
                      >
                        {t('teamCapacityAllocationTable.distributeEvenly')}
                      </div>
                    </td>

                    <td
                      className="team-cap-allocation-total-cell-td"
                      style={{
                        position: 'sticky',
                        left: '10vw',
                        zIndex: '2001',
                        background: 'white',
                      }}
                    >
                      {100 - calcMemberTotal(emp) - (emp.sharedAlloc ? emp.sharedAlloc : 0)}%
                    </td>
                    <td
                      className="team-cap-allocation-total-cell-td"
                      style={{
                        position: 'sticky',
                        left: '16.7vw',
                        zIndex: '2000',
                        background: 'white',
                      }}
                    >
                      {calcMemberTotal(emp) + emp.sharedAlloc}%
                    </td>
                    {state?.data?.objectives?.map((obj, objectiveIndex) => (
                      <>
                        {shouldDisplayCol(obj.id) && (
                          <td className={obj.id === selectedCol ? 'active-column' : ''}>
                            <span>
                              <input
                                style={{ zIndex: '500' }}
                                onChange={(e) => handleChange(e, emp, obj)}
                                key={objectiveIndex}
                                className="fte-input custom-input border-change"
                                type="number"
                                step="10"
                                min="0"
                                value={getInputValue(emp, obj)}
                                disabled={
                                  !obj?.team?.some((item) => item.eId === emp.eId) ||
                                  shouldCellBeInvalid(emp.eId)
                                }
                              />
                              {obj?.team?.some((item) => item.eId === emp.eId) && <span>%</span>}
                            </span>
                          </td>
                        )}
                      </>
                    ))}
                  </tr>
                  <div className="error-wrapper">
                    <div style={{ width: '20vw', height: '100%', background: 'white' }}></div>
                    {displayErrors(emp)}
                  </div>
                </>
              ))}
            <tr className="total-row">
              <td style={{ position: 'sticky', left: '0', zIndex: '1000', background: 'white' }}>
                Total
              </td>
              <td
                className="team-cap-allocation-total-cell-td"
                style={{ position: 'sticky', left: '10vw', zIndex: '2000', background: 'white' }}
              ></td>
              <td
                className="team-cap-allocation-total-cell-td"
                style={{ position: 'sticky', left: '16.7vw', zIndex: '2001', background: 'white' }}
              ></td>
              {calculateTotalFTEPerObjective().map((total) => (
                <td className="goal-cell">{total ? total : 0}%</td>
              ))}
            </tr>
          </tbody>
        </table>
        <br />
      </div>
    </>
  )
}

export default TeamCapacityAllocationTable
