import * as Yup from 'yup'

const TradeoffCapacitySchema = Yup.object().shape({
  capacityNeededCopy: Yup.number(),
  allocatedFte: Yup.number(),

  capacityNeeded: Yup.number()
    .required('Required field. Use Deprioritize button to remove this trade-off.')
    .test(
      'Range that cannot be set to',
      'Value needs to be greater than 0.',
      (value, context) => 0 <= parseFloat(value).toFixed(2),
    ),
})
export default TradeoffCapacitySchema
