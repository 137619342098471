import React from 'react'
import { Formik, Field, Form } from 'formik'
import VerbDataList from '../../utils/VerbDataList'
import * as Yup from 'yup'
import useValidateGoal from '../../api/mutation/useValidateGoal'
import ErrorModal from '../ErrorModal'
import ObjectiveExampleText from '../ObjectiveExampleText'
import ModalSaveButton from '../ModalSubmitButton'
import { useTranslation } from 'react-i18next'
import SelectField from '../CustomMultipleSelect/customSelect'
import useTeamLeadersList from '../../api/query/useTeamLeadersList'

const AddEditForm = ({
  handleSubmit,
  objective,
  handleClose,
  leaders,
  objectiveType,
  enterpriseObjectives,
  isEdit,
  setResetForm,
  isLoading,
  isAreaDashboard = false,
  isCeo = false,
}) => {
  const [verbOptions, setVerbOptions] = React.useState([])
  const [errorMessage, setErrorMessage] = React.useState(null)
  const mutationValidateGoal = useValidateGoal()
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const { t } = useTranslation(['Dashboard', 'Common'])
  const [teamLeaders, setTeamLeaders] = React.useState([])

  const loggedInUserEmail = localStorage.getItem('tfmu')

  const { data: teamLeaderData } = useTeamLeadersList(false)

  React.useEffect(() => {
    setTeamLeaders(teamLeaderData?.employees)
  }, [teamLeaderData])

  const handleVerbSuggestions = ({ target }) => {
    if (target.value) {
      const filteredOptions = VerbDataList.filter((option) =>
        option.toLowerCase().startsWith(target.value.toLowerCase()),
      )
      setVerbOptions(filteredOptions)
    } else {
      setVerbOptions([])
    }
  }

  const errorCheck = async (values, resetForm) => {
    // if(allowCxoUsersSeeL3Objs){
    //   values.leader = leaders?.employees[0].eId
    // }
    values.statement = values.statement.charAt(0).toUpperCase() + values.statement.slice(1)
    await mutationValidateGoal.mutateAsync(`${values.statement}`, {
      onSuccess: (response) => {
        if (response.is_valid === true) {
          values.isValid = true
          setErrorMessage(null)
          handleSubmit(values, resetForm)
        } else {
          setErrorMessage(t('Common:areaObjective.objectiveSyntaxErrorMsg'))
        }
      },
      onError: () => {
        openErrorModal()
      },
    })
  }

  const openErrorModal = () => {
    setIsErrorModalOpen(true)
  }

  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
  }

  const formikRef = React.useRef()

  React.useEffect(() => {
    if (formikRef.current !== undefined) {
      setResetForm(() => formikRef.current.resetForm)
    }
  }, [formikRef])

  let validationSchema = Yup.object().shape({
    statement: Yup.string().required('Required'),
    objectiveType: Yup.string(),
  })
  // ceo doesn;t need parentId
  if (!isCeo) {
    validationSchema = validationSchema.concat(
      Yup.object().shape({
        parentId: Yup.number().when('objectiveType', {
          is: (value) => value && (value === 'area' || value === 'cxo'),
          then: Yup.number().required('Required'),
          otherwise: Yup.number().nullable().notRequired(),
        }),
      }),
    )
  }

  if (isEdit === false) {
    validationSchema = validationSchema.concat(
      Yup.object().shape({
        leader: Yup.number().required('Required'),
      }),
    )
  }

  return (
    <>
      <ErrorModal isOpen={isErrorModalOpen} handleClose={closeErrorModal} errorMessage={null} />
      <div>
        {!isAreaDashboard && <ObjectiveExampleText />}
        <Formik
          innerRef={formikRef}
          enableReinitialize
          initialValues={{
            statement: objective?.statement ?? '',
            leader: isEdit
              ? (objective?.ownerId ?? '')
              : (leaders?.employees?.find((employee) => employee.email === loggedInUserEmail)
                  ?.eId ?? ''),
            objectiveType: objectiveType,
            parentId: objective?.parentId ?? '',
            rights: objective?.rights ?? [],
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            errorCheck(values, resetForm)
          }}
        >
          {({ errors, touched, resetForm }) => (
            <Form
              className="objective-form"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <div>
                {
                  <div className="form-field">
                    <label>{t('Common:areaObjective.ownerName')}</label>
                    <Field as="select" id="leader" name="leader">
                      <option value="">{t('Common:areaObjective.selectOwner')}</option>

                      {leaders?.employees?.map((leader, leaderIndex) => {
                        return (
                          <option key={`leader-${leaderIndex}`} id={leader.eId} value={leader.eId}>
                            {leader.name}
                          </option>
                        )
                      })}
                    </Field>
                    {errors.leader && touched.leader && (
                      <div className="validation-error">{errors.leader}</div>
                    )}
                  </div>
                }

                {objectiveType === 'area' && (
                  <div className="form-field">
                    <label>{t('Common:areaObjective.enterpriseLink')} </label>
                    <Field as="select" id="parentId" name="parentId">
                      <option value="">{t('Common:areaObjective.selectEnterprise')}</option>
                      <option value="0">{t('Common:areaObjective.notLinked')}</option>

                      {enterpriseObjectives?.map((obj, objIndex) => {
                        return (
                          <option key={`obj-${objIndex}`} id={obj.objId} value={obj.objId}>
                            {obj.statement}
                          </option>
                        )
                      })}
                    </Field>
                    {errors.parentId && touched.parentId && (
                      <div className="validation-error">{errors.parentId}</div>
                    )}
                  </div>
                )}

                <div className="form-field action-field">
                  <label>{t('Common:areaObjective.statement')}</label>
                  <Field
                    list="obj_verb_list"
                    id="statement"
                    name="statement"
                    placeholder={t('Common:areaObjective.statementPlaceholder')}
                    onKeyUp={handleVerbSuggestions}
                    onClick={handleVerbSuggestions}
                    autoComplete="off"
                  />
                  {errors.statement && touched.statement && (
                    <div className="validation-error">{errors.statement}</div>
                  )}
                  <datalist id="obj_verb_list">
                    {verbOptions.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </datalist>
                </div>

                {objectiveType === 'area' && (
                  <div
                    className="form-field action-field form-field action-field--kanban"
                    style={{ textAlign: 'left' }}
                  >
                    <label>
                      {' '}
                      {t('addObjectivesModal.shared')}{' '}
                      <div style={{ fontWeight: 'normal' }}>
                        {' '}
                        {t('addObjectivesModal.sharedDescription')}{' '}
                      </div>
                    </label>

                    <div data-testid="multi-options-2">
                      <Field {...SelectField} name="rights">
                        {({ field }) => (
                          <SelectField
                            field={field}
                            getOptionLabel={(option) => option?.name}
                            getOptionValue={(option) => option?.eId}
                            options={teamLeaders}
                            isMulti={true}
                          />
                        )}
                      </Field>
                    </div>
                    {errors.rights && touched.rights && (
                      <div className="validation-error">{errors.rights}</div>
                    )}
                  </div>
                )}
              </div>
              {errorMessage && <div className="validation-error">{errorMessage}</div>}
              <div className="modal-dual-btn">
                <button
                  className="dual-btn negative-btn"
                  onClick={() => {
                    resetForm()
                    handleClose()
                    setErrorMessage(null)
                  }}
                  type="button"
                >
                  {t('Common:areaObjective.cancel')}
                </button>
                <ModalSaveButton isLoading={isLoading} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default AddEditForm
