import React, { useState } from 'react'
import styled from 'styled-components'

import 'react-quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import './styles.css'
import { GET_MEETING_NOTES, POST_MEETING_NOTES } from '../../api/constant/route'
import { getHttpRequest, postHttpRequest } from '../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'

const Statement = styled.div`
  font-size: 16px;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 0.5rem;
`

const TextEditor = ({ lang }) => {
  const { t } = useTranslation(['MeetingHub'])

  const [meetingNotes, setMeetingNotes] = useState('')
  const [displayPrompt, setDisplayPrompt] = useState(false)
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  }

  const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet']
  React.useEffect(() => {
    getMeetingNotes()
  }, [])
  const handleChange = (html) => {
    setMeetingNotes(html)
  }
  async function saveMeetingNotes() {
    const postObj = {}
    postObj.meeting_notes = meetingNotes
    postHttpRequest(POST_MEETING_NOTES, postObj)
      .then((response) => {
        if (response) {
          setDisplayPrompt(true)
          setTimeout(() => {
            setDisplayPrompt(false)
          }, 3000)
          return
        } else {
        }
      })
      .catch((error) => {})
  }
  function getMeetingNotes() {
    getHttpRequest(`${GET_MEETING_NOTES}`)
      .then((response) => {
        setMeetingNotes(response.meeting_notes[0])
        if (!response) {
          return
        }
      })
      .catch((error) => {})
  }
  return (
    <>
      <ReactQuill
        theme="snow"
        value={meetingNotes}
        modules={modules}
        formats={formats}
        onChange={handleChange}
      />
      <div>
        <div class="fitted-button blue" onClick={saveMeetingNotes} style={{ marginTop: '0.8rem' }}>
          <span style={{ margin: '0px 0.5rem' }}> {t('Common:commonButtons.save')}</span>
        </div>
        {displayPrompt && (
          <span style={{ color: 'gray', margin: '1rem' }}>Changes saved succesfully</span>
        )}
      </div>
    </>
  )
}

export default TextEditor
