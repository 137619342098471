import React, { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import '../../../MeetingView/components/TradeOffs/styles.css'
import { Fragment } from 'react'
import next from '../../../../assets/next-white.svg'
import team from '../../../../assets/team.svg'
import plus from '../../../../assets/plus.svg'
import useEditRequiredFte from '../../../../api/mutation/useEditRequiredFte'
import ErrorModal from '../../../../components/ErrorModal'
import Modal from '../../../../components/Modal'
import EditCapacityNeeded from '../../../../components/CapacityNeededModal/capacityNeededModal'
import CoOwnedObjectiveAllocModal from '../../../../components/CoOwnedObjectiveAllocModal'
import { useTranslation } from 'react-i18next'

const TradeOffs = () => {}

export default TradeOffs
