import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const editTeamLeaderActivity = async (activityData) => {
  const { data } = await axios.post(`${BASE_URL}/update_tl_activity`, activityData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useEditTeamLeaderActivity() {
  const client = useQueryClient()

  return useMutation((data) => editTeamLeaderActivity(data), {
    onSuccess: () => {
      void client.invalidateQueries('team-leader-activities')
    },
  })
}
