import React, { useState } from 'react'
import Textarea from 'rc-textarea'
import AssigneeActivity from './assigneeActivity'
import {
  meetingCommentUpdate,
  meetingMilestoneUpdate,
} from '../../../../../api/services/meetingTopic.services'
import Toast from '../../../../../common/toast'
import { useTranslation } from 'react-i18next'
import { dayMonthFilter } from '../../../../../utils/time'
import useEditKanbanBoardChangeActivityStatus from '../../../../../api/mutation/useEditKanbanBoardChangeActivityStatus'
import 'react-tooltip/dist/react-tooltip.css'
import Swal from 'sweetalert2'
import SooDropdownMenu from '../../../../../components/SooDropdownMenu'
import AddEditActivityModal from '../../../../../components/AddEditActivityModal'
import CommentsModal from '../../../../../components/CommentsModal'
import CommentsModal2 from '../../../../../components/CommentsModal2'
import { postHttpRequest } from '../../../../../api/query/dynamicAPI'
import { POST_KANBAN_MARK_ACTIVITY_AS_PRIORITY } from '../../../../../api/constant/route'
import { shortenName, linkifyString } from '../../../../../utils/general'
import { MentionsInput, Mention } from 'react-mentions'
import styled from 'styled-components'
import { removeMentionMarkupFromText } from '../../../../../utils/parseMarkup'
import { formatName } from '../../../../../utils/name'
import AiMemoQuestionsModal from './component/AiMemoQuestionsModal'
import AiMemoModal from './component/AiMemoModal'
import { getCompanyConfigSettings } from '../../../../../utils/auth'
import ActionTopicUpdateModal from './component/ActionTopicUpdateModal'

const Wrapper = styled.div`
  div > textarea {
    border-color: transparent !important;
  }
  width: 100%;
  font-family: 'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif;
`

const CommentActivity = ({
  commentActivityData,
  setCommentActivityData,
  loadAgain,
  reload,
  refreshBGUScorecardFn = () => {},
  viewOnlyMode = false,
  index,
  cardButtonTypes,
  userList = [],
  umtId,
  isPresentationMode = false,
}) => {
  const { t } = useTranslation(['MeetingHub', 'Common'])
  const [isCreateModel, setIsCreateModel] = useState(false)
  const [objectiveId, setObjectiveId] = useState('')
  const [milestoneId, setMilestoneId] = useState('')
  const [assigneeList, setAssigneeList] = useState([])
  const [isCompletedBtns, setIsCompletedBtns] = useState({})
  const [showTooltips, setShowTooltips] = useState({})
  const [isLoading, setIsLoading] = React.useState({})
  const [isActivityEditModalOpen, setIsActivityEditModalOpen] = React.useState(false)
  const [isCommentModalOpen, setIsCommentModalOpen] = React.useState(false)
  const [objective, setObjective] = React.useState({})
  const [objectiveIdSelected, setObjectiveIdSelected] = React.useState(null)
  const [taskIdSelected, setTaskIdSelected] = React.useState(null)
  const [showDisabledComments, setShowDisabledComments] = useState({})
  const changeActivityStatusMutation = useEditKanbanBoardChangeActivityStatus()
  const [data, setData] = React.useState()
  const [CommentsForActionisHidden, setIsCommentsForActionisHidden] = React.useState(
    new Array(commentActivityData.filter((item) => item.isDecision === false).length).fill(false),
  )
  const [mentionState, setMentionState] = React.useState([])
  const [memoAction, setMemoAction] = React.useState(null)
  const [isAiMemoQuestionsModalOpen, setIsAiMemoQuestionsModalOpen] = React.useState(false)
  const [isFromAiMemoQuestionsModal, setIsFromAiMemoQuestionsModal] = React.useState(false)
  const [isAiMemoDraftModalOpen, setIsAiMemoDraftModalOpen] = React.useState(false)

  const [memoMaterial, setMemoMaterial] = React.useState(null)
  const [showEng, setShowEng] = React.useState(
    getCompanyConfigSettings('languageSettings') !== 'es',
  )

  const [isTopicSelectionModalOpen, setIsTopicSelectionModalOpen] = React.useState(false)

  React.useEffect(() => {
    if (commentActivityData) {
      let tempShowDisabledComments = [...commentActivityData]
      if (cardButtonTypes.includes('OpenOnly')) {
        const filteredData = tempShowDisabledComments.filter((item) => {
          // const isDecisionTrue = item.isDecision === true
          const isInCommentTable = item.commentId !== 0
          const isMilestoneCompleted = isCompleted(item.milestoneId) === true

          // Filter out items where either are comments or isMilestoneCompleted is true
          return !isInCommentTable && !isMilestoneCompleted
        })
        setData(filteredData)
      } else {
        setData(tempShowDisabledComments)
      }
    }
  }, [cardButtonTypes])

  async function saveComment(value, index) {
    const dataArray = [...data]
    const obj = dataArray[index]

    if (obj.milestoneId != 0) {
      obj.description = value
    } else if (obj.commentId != 0) {
      obj.commentText = value
    }

    dataArray[index] = obj
    setData(dataArray)
    // refreshBGUScorecardFn()
  }

  //TODO Swetha: See if we can create this from the backend or optimize this
  React.useEffect(() => {
    let dict = {}
    commentActivityData?.forEach((element) => {
      if (element.milestoneId !== 0) {
        setIsCompletedBtns((prev) => ({
          ...prev,
          [element.milestoneId]: element.progress == 1 ? true : false,
        }))
      }

      dict[element.milestoneId] = element.commentsCount === 0 ? false : true
    })

    setData(commentActivityData)

    setShowDisabledComments({ ...dict })

    const commentsDictionary = {}
    commentActivityData.forEach((item) => {
      if (item.isDecision === false) {
        commentsDictionary[item.milestoneId] = false // You can set any default value you want
      }
      setIsCommentsForActionisHidden(commentsDictionary)
    })
  }, [commentActivityData])

  //TODO Swetha: We can pass a property from the backend
  const updateCommentsIconStatus = (milestoneId) => {
    let tempShowDisabledComments = { ...showDisabledComments }

    for (const [key, value] of Object.entries(tempShowDisabledComments)) {
      if (parseInt(key) === parseInt(milestoneId)) {
        if (value === false) {
          tempShowDisabledComments[key] = true
        } else {
          return
        }
      }
    }

    setShowDisabledComments({ ...tempShowDisabledComments })
  }

  async function saveText(data) {
    if (data.description) {
      let postData = {
        milestoneDesc: data.description,
      }
      let mentions =
        mentionState
          .find((mention) => mention.id === data.milestoneId && mention.type === 'action')
          ?.mentions.map((m) => m.id) ?? null
      // it means that user has triggered the onChange event of the textarea..
      // this is to prevent user click on the text then click outside of the textarea immediately
      // in which case mentions are not recorded yet
      if (mentions) {
        postData.mentions = mentions
      }
      const result = await meetingMilestoneUpdate(data.milestoneId, postData)
      if (result) {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.updatedSuccessfully'),
        })
        refreshBGUScorecardFn()
        if (mentions) {
          loadAgain((old) => !old)
        }
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    } else if (data.commentText) {
      let postData = {
        commentText: data.commentText,
        umtId: umtId,
        isDecision: data.isDecision,
      }
      let mentions =
        mentionState
          .find((mention) => mention.id === data.commentId && mention.type === 'comment')
          ?.mentions.map((m) => m.id) ?? null
      if (mentions) {
        postData.mentions = mentions
      }
      const result = await meetingCommentUpdate(data.commentId, postData)

      if (result) {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.updatedSuccessfully'),
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    }
  }

  async function deleteText(data) {
    const result = await Swal.fire({
      title: t('Common:modalMessages.areYouSureDeleteThis'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (data.description && result.isConfirmed) {
      const result = await meetingMilestoneUpdate(data.milestoneId, { isDeleted: 'true' })
      if (result) {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.deletedSuccessfully'),
        })
        refreshBGUScorecardFn()
        loadAgain()
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    } else if (data.commentText && result.isConfirmed) {
      const result = await meetingCommentUpdate(data.commentId, { isDeleted: 'true' })
      if (result) {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.deletedSuccessfully'),
        })
        loadAgain()
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    }
  }

  const handleClick = (objectiveId, milestoneId) => async (e) => {
    setIsLoading((prevLoading) => ({
      ...prevLoading,
      [milestoneId]: true,
    }))
    if (isCompletedBtns[milestoneId] === undefined || isCompletedBtns[milestoneId] === false) {
      await changeActivityStatus(objectiveId, milestoneId, 'COMPLETED')
    } else {
      if (isCompletedBtns[milestoneId]) {
        await changeActivityStatus(objectiveId, milestoneId, 'ON_TRACK')
      } else {
        await changeActivityStatus(objectiveId, milestoneId, 'COMPLETED')
      }
    }
    refreshBGUScorecardFn()
    loadAgain()
  }

  const changeActivityStatus = async (objectiveId, milestoneId, newStatus) => {
    const postObject = {
      objectiveId,
      milestoneId,
      newStatus,
    }

    await changeActivityStatusMutation.mutateAsync(postObject, {
      onSuccess: (response) => {
        setIsLoading((prevLoading) => ({
          ...prevLoading,
          [milestoneId]: false,
        }))
        setIsCompletedBtns((prev) => ({
          ...prev,
          [milestoneId]: prev[milestoneId] === undefined ? true : !prev[milestoneId],
        }))
        if (newStatus === 'COMPLETED') {
          Toast.fire({
            icon: 'success',
            title:
              "<div style='color:green'>" + t('meetingTopics.completedAnActionMessage') + '</div>',
          })
        }
      },
      onError: () => {},
    })
  }

  async function assignActivity(data) {
    setMilestoneId(data.milestoneId)
    setObjectiveId(data.objectiveId)
    setIsCreateModel(true)
    setAssigneeList(data?.assigneeList)
  }

  // TODO Swetha: See the possibility of moving this to backend
  const isCompleted = (milestoneId) =>
    isCompletedBtns[milestoneId] !== undefined && isCompletedBtns[milestoneId] === true

  const canBeDisabledNoComments = (data) =>
    showDisabledComments[data.milestoneId] ? '' : 'inactive-blue'

  async function markActicityAsPriority(value, milestoneId) {
    const postObject = {
      milestoneId: milestoneId,
      newStatus: value,
    }
    postHttpRequest(POST_KANBAN_MARK_ACTIVITY_AS_PRIORITY, postObject)
      .then((response) => {
        if (response) {
          loadAgain()
        } else {
        }
      })
      .catch((error) => {})
  }

  const toggleContentForCommentsAction = (index) => {
    let tempIsHidden = { ...CommentsForActionisHidden }
    tempIsHidden[index] = !tempIsHidden[index]
    setIsCommentsForActionisHidden(tempIsHidden)
  }

  const openAiMemoGeneration = (data) => {
    setMemoAction(data)
    setIsAiMemoQuestionsModalOpen(true)
    setIsFromAiMemoQuestionsModal(true)
  }

  const openAiMemoDraft = (data) => {
    setIsFromAiMemoQuestionsModal(false)
    setMemoAction(data)
    setIsAiMemoDraftModalOpen(true)
  }

  const updateActivityStatus = async (activity, index, isAccepted = true) => {
    const dataArray = [...data]
    const obj = dataArray[index]
    let result
    let postData = {
      aiGenPendingApproval: false,
      isDeleted: isAccepted ? 'false' : 'true',
    }
    if (activity.milestoneId != 0) {
      result = await meetingMilestoneUpdate(activity.milestoneId, postData)
    } else {
      result = await meetingCommentUpdate(activity.commentId, postData)
    }

    if (result) {
      Toast.fire({
        icon: 'success',
        title: t('Common:modalMessages.updatedSuccessfully'),
      })

      obj.aiGenPendingApproval = false
      dataArray[index] = obj
      setData(dataArray)
      if (!isAccepted) {
        loadAgain()
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
    // refreshBGUScorecardFn()
  }

  const moveTopic = (data) => {
    console.log(data)
    setTaskIdSelected(data.milestoneId)
    setIsTopicSelectionModalOpen(true)
  }

  return (
    <>
      {isActivityEditModalOpen === true && objectiveIdSelected && taskIdSelected && (
        <AddEditActivityModal
          isModalOpen={isActivityEditModalOpen}
          closeModal={() => {
            setIsActivityEditModalOpen(false)
            setObjectiveIdSelected(null)
            setTaskIdSelected(null)
          }}
          objectiveId={objectiveIdSelected}
          activityId={taskIdSelected}
          setIsRefreshObjective={loadAgain}
          refreshBGUScorecardFn={refreshBGUScorecardFn}
          fromModal={true}
        />
      )}
      {isCommentModalOpen && (
        <CommentsModal2
          objective={{ id: objectiveIdSelected, ...objective }}
          taskId={taskIdSelected}
          handleCancel={() => {
            setIsCommentModalOpen(false)
          }}
          setIsRefreshObjective={() => {
            loadAgain()
            updateCommentsIconStatus(taskIdSelected)
          }}
          callbackFn={() => {
            updateCommentsIconStatus(taskIdSelected)
          }}
        />
      )}
      {isAiMemoQuestionsModalOpen && memoAction && (
        <AiMemoQuestionsModal
          isModalOpen={isAiMemoQuestionsModalOpen}
          closeModal={(hasDraftGeneratedInQAModal = false) => {
            // only open draft modal if draft has been generated in QA modal
            if (hasDraftGeneratedInQAModal) {
              setIsAiMemoDraftModalOpen(true)
            }
            setIsAiMemoQuestionsModalOpen(false)
          }}
          memoAction={memoAction}
          setMemoMaterial={setMemoMaterial}
          setIsAiMemoDraftModalOpen={setIsAiMemoDraftModalOpen}
          setShowEng={setShowEng}
          showEng={showEng}
        />
      )}
      {isAiMemoDraftModalOpen && memoAction && (
        <AiMemoModal
          isModalOpen={isAiMemoDraftModalOpen}
          closeModal={(hasError) => {
            setIsAiMemoDraftModalOpen(false)
            if (!hasError) {
              setMemoAction(null)
              setMemoMaterial(null)
              // setIsAiMemoQuestionsModalOpen(false)
              loadAgain((old) => !old)
            }
          }}
          memoAction={memoAction}
          memoMaterial={memoMaterial}
          isFromAiMemoQuestionsModal={isFromAiMemoQuestionsModal}
          setIsAiMemoQuestionsModalOpen={setIsAiMemoQuestionsModalOpen}
          setShowEng={setShowEng}
          showEng={showEng}
        />
      )}
      {isTopicSelectionModalOpen && taskIdSelected && (
        <ActionTopicUpdateModal
          umtId={umtId}
          milstoneId={taskIdSelected}
          closeModal={() => {
            setTaskIdSelected(null)
            setIsTopicSelectionModalOpen(false)
          }}
          loadAgain={loadAgain}
        />
      )}

      {/* TODO Swetha: Can we handle the sort in the backend */}
      <div
        style={
          isPresentationMode ? { maxHeight: '13rem', overflowY: 'auto', overflowX: 'hidden' } : {}
        }
      >
        {data
          ?.sort((a, b) => b.createdAt - a.createdAt)
          .map((data, index) => {
            return (
              <React.Fragment key={index}>
                <div className="divider" style={{ height: '1px', marginBottom: '0.5rem' }}></div>
                <div
                  id="meetingHubTutorial11"
                  className={`card_body ${
                    data?.aiGenPendingApproval ? 'card_body_unreviewed' : ''
                  }`}
                  style={{
                    marginBottom: '0rem',
                    gap: '0rem',
                    fontSize: '12px',
                    fontWeight: '600',
                    padding: '4px',
                  }}
                >
                  {data?.aiGenPendingApproval && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '0.5rem',
                        padding: '3px',
                      }}
                    >
                      <span style={{ margin: 'auto 0', fontWeight: 'normal', color: '#5780ca' }}>
                        {t('meetingTopics.fromRecording')} &nbsp;
                      </span>

                      <div
                        style={{
                          marginLeft: 'auto',
                          marginTop: 'auto',
                          marginBottom: 'auto',

                          fontWeight: 'normal',
                        }}
                      >
                        <button
                          type="button"
                          className="btn-card text-box-grey-btn"
                          style={{ padding: '0', width: '4.2rem', backgroundColor: '#a6a6a6' }}
                          onClick={() => updateActivityStatus(data, index, false)}
                        >
                          {t('meetingTopics.reject')}
                        </button>
                      </div>
                      <div
                        style={{
                          marginLeft: '0.2rem',
                          marginTop: 'auto',
                          marginBottom: 'auto',

                          fontWeight: 'normal',
                        }}
                      >
                        <button
                          type="button"
                          className="btn-card text-box-grey-btn"
                          style={{ padding: '0', width: '4.2rem', backgroundColor: '#4472c4' }}
                          onClick={() => updateActivityStatus(data, index, true)}
                        >
                          {t('meetingTopics.accept')}
                        </button>
                      </div>
                    </div>
                  )}
                  {data.milestoneId != 0 ? (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex' }}>
                          <label style={{ margin: 'auto 0', fontWeight: 'normal', color: 'gray' }}>
                            {t('meetingTopics.action')} &nbsp;
                          </label>

                          {data.description && (
                            <div
                              style={{ marginLeft: '0.6rem' }}
                              class="fitted-button blue tooltip"
                              onClick={(e) => assignActivity(data)}
                            >
                              <span
                                class="material-symbols-outlined icon-size"
                                style={{ fontSize: '15px' }}
                              >
                                person_add
                              </span>
                              <span class="tooltiptext" style={{ fontSize: '16px' }}>
                                {' '}
                                {t('Common:tooltip.assignToOthers')}{' '}
                              </span>
                            </div>
                          )}
                          <div
                            style={{ marginLeft: '0.6rem' }}
                            className={'fitted-button blue ' + ' tooltip'}
                            onClick={(e) => {
                              setObjectiveIdSelected(data.objectiveId)
                              setTaskIdSelected(data.milestoneId)
                              setIsCommentModalOpen(true)
                            }}
                          >
                            <span
                              class="material-symbols-outlined icon-size"
                              style={{ fontSize: '15px' }}
                            >
                              comment
                            </span>
                            <span class="tooltiptext" style={{ fontSize: '16px' }}>
                              {' '}
                              {t('Common:tooltip.addComment')}{' '}
                            </span>
                          </div>
                          {!data.isPriority && (
                            <div
                              style={{ marginLeft: '0.6rem' }}
                              class="fitted-button blue tooltip"
                              type="button"
                              onClick={(e) => markActicityAsPriority(true, data.milestoneId)}
                              onKeyDown={(e) => markActicityAsPriority(true, data.milestoneId)}
                            >
                              <span class="tooltiptext" style={{ fontSize: '16px' }}>
                                {' '}
                                {t('Common:tooltip.flagForPriority')}{' '}
                              </span>
                              <span class="material-symbols-outlined" style={{ fontSize: '15px' }}>
                                flag
                              </span>
                            </div>
                          )}
                          {data.isPriority && (
                            <div
                              style={{ marginLeft: '0.6rem' }}
                              class="fitted-button blue tooltip"
                              type="button"
                              onClick={(e) => markActicityAsPriority(false, data.milestoneId)}
                              onKeyDown={(e) => markActicityAsPriority(false, data.milestoneId)}
                            >
                              <span class="tooltiptext" style={{ fontSize: '16px' }}>
                                {' '}
                                {t('Common:tooltip.flagForPriority')}{' '}
                              </span>
                              <span
                                class="material-symbols-outlined"
                                style={{ color: 'red', fontSize: '15px' }}
                              >
                                flag
                              </span>
                            </div>
                          )}

                          {data.milestoneId != 0 ? (
                            <>
                              {!viewOnlyMode && (
                                <>
                                  {isLoading[data.milestoneId] ? (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexFlow: 'column',
                                        height: '100%',
                                      }}
                                    >
                                      <div
                                        className="loading-spinner"
                                        style={{
                                          width: '0.5rem',
                                          height: '0.5rem',
                                          marginLeft: '0.5rem',
                                        }}
                                      ></div>
                                    </div>
                                  ) : (
                                    <div
                                      class={`fitted-button ${isCompleted(data.milestoneId) ? 'checklist-green' : 'checklist'} tooltip`}
                                      //data-tooltip-id="my-tooltip"
                                      //data-tooltip-content={t('meetingTopics.isCompletedTooltip')}
                                      id={data.milestoneId}
                                      onClick={(e) =>
                                        handleClick(data.objectiveId, data.milestoneId)(e)
                                      }
                                      style={{
                                        color: isCompleted(data.milestoneId) ? 'white' : 'grey',
                                        fontSize: '1.25rem',
                                        cursor: 'pointer',
                                        padding: '0',
                                        marginLeft: '0.5rem',
                                        backgroundColor: isCompleted(data.milestoneId)
                                          ? '#77BF72'
                                          : data.aiGenPendingApproval
                                            ? '#f2f2f2'
                                            : 'white',
                                      }}
                                      onMouseOver={(e) => {
                                        if (
                                          showTooltips[e.target.id] == undefined ||
                                          !showTooltips[e.target.id]
                                        ) {
                                          setShowTooltips((prev) => ({
                                            ...prev,
                                            [e.target.id]: true,
                                          }))
                                        }
                                      }}
                                      onMouseOut={(e) => {
                                        if (showTooltips[e.target.id]) {
                                          setShowTooltips((prev) => ({
                                            ...prev,
                                            [e.target.id]: false,
                                          }))
                                        }
                                      }}
                                    >
                                      <span class="material-symbols-outlined icon-size">done</span>
                                      <span class="tooltiptext" style={{ fontSize: '16px' }}>
                                        {' '}
                                        {t('Common:tooltip.completeAction')}{' '}
                                      </span>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              margin: 'auto 0',
                              color: 'grey',
                              fontWeight: 'normal',
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            {data?.actionCreatorName && (
                              <span className="tooltip">
                                <span>
                                  <b>C</b>:&nbsp;<i>{shortenName(data.actionCreatorName)}</i>
                                  &nbsp;&nbsp;
                                </span>
                                <span
                                  class="tooltiptext"
                                  style={{
                                    width: '7rem',
                                    paddingRight: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    fontSize: '14px',
                                    marginLeft: '-45px',
                                  }}
                                >
                                  {' '}
                                  {t('Common:tooltip.creator')}{' '}
                                </span>
                              </span>
                            )}
                            {data?.assigneeList?.length > 0 && (
                              <span className="tooltip">
                                <span>
                                  <b>A</b>:&nbsp;
                                </span>
                                <span
                                  class="tooltiptext"
                                  style={{
                                    width: '7rem',
                                    paddingRight: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    fontSize: '14px',
                                  }}
                                >
                                  {' '}
                                  {t('Common:tooltip.assigned')}{' '}
                                </span>

                                {/* TODO Swetha: See if we can do sort and slice in the backend */}
                                {data?.assigneeList
                                  ?.sort((a, b) => a.name.localeCompare(b.name))
                                  .slice(0, 1)
                                  .map((assignee, assigneeIndex) => (
                                    <React.Fragment key={assigneeIndex}>
                                      <i>{shortenName(assignee.name)}</i>
                                      {data?.assigneeList?.length > 1 && <span>, </span>}
                                    </React.Fragment>
                                  ))}
                                {data?.assigneeList?.length > 1 && (
                                  <>
                                    <span>&nbsp; ...+{data?.assigneeList?.length - 1}</span>
                                  </>
                                )}
                              </span>
                            )}
                          </div>

                          <i
                            style={{
                              marginLeft: 'auto',
                              marginTop: 'auto',
                              marginBottom: 'auto',
                              color: 'grey',
                              fontWeight: 'normal',
                            }}
                          >
                            {/* TODO Swetha: Move this to backend */}
                            {dayMonthFilter(data?.createdAt)}
                          </i>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div
                        style={{
                          display: 'flex',
                          height: '1.3rem',
                          fontWeight: 'normal',
                          color: 'gray',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ margin: 'auto 0', color: 'grey', fontWeight: 'normal' }}>
                          <label>
                            {data?.isDecision
                              ? `${t('meetingTopics.comment')}\u00A0`
                              : `${t('meetingTopics.commentMeeting')}\u00A0`}
                          </label>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ margin: 'auto 0', color: 'grey', fontWeight: 'normal' }}>
                          {/* TODO Swetha: Move this to backend */}
                          <i> {shortenName(data?.commentOwner)} </i>
                        </div>
                        <i
                          style={{
                            marginLeft: 'auto',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            color: 'grey',
                            fontWeight: 'normal',
                          }}
                        >
                          {/* TODO Swetha: Move this to backend */}
                          {dayMonthFilter(data?.commentTs)}
                        </i>
                      </div>
                    </div>
                  )}
                  <div className="card_body_bottomBox" style={{ marginLeft: '0.5rem' }}>
                    {data.milestoneId != 0 ? (
                      // TODO Swetha: See if we can do something here
                      <Wrapper>
                        <MentionsInput
                          className="body_bottom_text"
                          value={data.description}
                          onChange={(e, v, nv, mentions) => {
                            let mention = mentionState.find(
                              (mention) =>
                                mention.id === data.milestoneId && mention.type === 'action',
                            )
                            if (mention) {
                              setMentionState((prev) =>
                                prev.map((item) =>
                                  item.id === data.milestoneId && item.type === 'action'
                                    ? { ...item, mentions: mentions }
                                    : item,
                                ),
                              )
                            } else {
                              setMentionState([
                                ...mentionState,
                                { id: data.milestoneId, type: 'action', mentions: mentions },
                              ])
                            }
                            saveComment(e.target.value, index)
                          }}
                          onBlur={(e, clickedSuggestion) => {
                            if (!clickedSuggestion) saveText(data)
                          }}
                          allowSuggestionsAboveCursor={true}
                          style={{
                            padding: '0',
                            suggestions: {
                              list: {
                                maxHeight: 80,
                                overflowY: 'auto',
                              },
                              item: {
                                '&focused': {
                                  backgroundColor: '#d3d3d3',
                                },
                              },
                            },
                            highlighter: {
                              border: '4px solid transparent',
                            },
                          }}
                          disabled={viewOnlyMode}
                          spellCheck={false}
                        >
                          <Mention
                            trigger="@"
                            data={userList}
                            // renderSuggestion={this.renderUserSuggestion}
                            markup="@[__display__](user:__id__)"
                            displayTransform={(id, display) => `@${display}`}
                            renderSuggestion={(suggestion, search, highlightedDisplay) => (
                              <div className="user">{highlightedDisplay}</div>
                            )}
                            appendSpaceOnAdd={true}
                            // style={{backgroundColor:'rgb(206, 228, 229)'}}
                          />
                        </MentionsInput>
                      </Wrapper>
                    ) : (
                      <Wrapper>
                        <MentionsInput
                          className="body_bottom_text"
                          value={data.commentText}
                          onChange={(e, v, nv, mentions) => {
                            let mention = mentionState.find(
                              (mention) =>
                                mention.id === data.commentId && mention.type === 'comment',
                            )
                            if (mention) {
                              setMentionState((prev) =>
                                prev.map((item) =>
                                  item.id === data.commentId && item.type === 'comment'
                                    ? { ...item, mentions: mentions }
                                    : item,
                                ),
                              )
                            } else {
                              setMentionState([
                                ...mentionState,
                                { id: data.commentId, type: 'comment', mentions: mentions },
                              ])
                            }
                            saveComment(e.target.value, index)
                          }}
                          onBlur={(e, clickedSuggestion) => {
                            if (!clickedSuggestion) saveText(data)
                          }}
                          allowSuggestionsAboveCursor={true}
                          style={{
                            padding: '0',
                            suggestions: {
                              list: {
                                maxHeight: 80,
                                overflowY: 'auto',
                              },
                              item: {
                                '&focused': {
                                  backgroundColor: '#d3d3d3',
                                },
                              },
                            },
                            highlighter: {
                              border: '4px solid transparent',
                            },
                          }}
                          disabled={viewOnlyMode}
                          spellCheck={false}
                        >
                          <Mention
                            trigger="@"
                            data={userList}
                            // renderSuggestion={this.renderUserSuggestion}
                            markup="@[__display__](user:__id__)"
                            displayTransform={(id, display) => `@${display}`}
                            renderSuggestion={(suggestion, search, highlightedDisplay) => (
                              <div className="user">{highlightedDisplay}</div>
                            )}
                            appendSpaceOnAdd={true}
                            // style={{backgroundColor:'rgb(206, 228, 229)'}}
                          />
                        </MentionsInput>
                      </Wrapper>
                    )}
                    {!viewOnlyMode && (
                      <div className="card_footer">
                        <div className="card_footer_rightside" style={{ gap: '2px' }}>
                          {/* <button type="button" className="btn-common blue-btn" onClick={(e) => saveText(data)}>Save</button> */}
                          {/* <button
                      className="icon-button comment-icon-button"
                      style={{ height: '1.8rem', backgroundColor: '#ff5349' }}
                      onClick={(e) => deleteText(data)}
                    >
                      <span class="material-symbols-outlined">close</span>
                    </button> */}

                          {data.description && (
                            <div
                              style={{ marginLeft: '1rem', fontWeight: 'normal', fontSize: '16px' }}
                            >
                              <SooDropdownMenu
                                isFromMeetingHub={true}
                                isAction={true}
                                aiMemoCount={data.aiMemoCount}
                                onClickEdit={() => {
                                  setObjectiveIdSelected(data.objectiveId)
                                  setTaskIdSelected(data.milestoneId)
                                  setIsActivityEditModalOpen(true)
                                }}
                                onClickDelete={() => deleteText(data)}
                                onClickAiMemo={() => openAiMemoGeneration(data)}
                                onClickDraftMemo={() => openAiMemoDraft(data)}
                                aiGenPendingApproval={data?.aiGenPendingApproval}
                                onMoveTopic={() => moveTopic(data)}
                                // onClickComment={()=>{
                                //   //setObjective({})
                                //   setObjectiveIdSelected(data.objectiveId)
                                //   setTaskIdSelected(data.milestoneId)
                                //   setIsCommentModalOpen(true)}}
                              />
                            </div>
                          )}
                          {data.milestoneId == 0 && (
                            <div
                              class="fitted-button red icon-size"
                              onClick={(e) => deleteText(data)}
                            >
                              <span
                                class="material-symbols-outlined icon-size"
                                style={{ fontSize: '15px' }}
                              >
                                close
                              </span>
                            </div>
                          )}

                          {data.isDecision === false && data.commentsCount > 0 && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginLeft: '1rem',
                              }}
                            >
                              {!CommentsForActionisHidden[data.milestoneId] && (
                                <span
                                  style={{ fontSize: '30px', cursor: 'pointer' }}
                                  class="material-symbols-outlined"
                                  onClick={() => {
                                    toggleContentForCommentsAction(data.milestoneId)
                                  }}
                                >
                                  arrow_drop_up
                                </span>
                              )}

                              {CommentsForActionisHidden[data.milestoneId] && (
                                <span
                                  class="material-symbols-outlined"
                                  onClick={() => {
                                    toggleContentForCommentsAction(data.milestoneId)
                                  }}
                                  style={{ fontSize: '30px', cursor: 'pointer' }}
                                >
                                  arrow_drop_down
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div style={{ fontWeight: 'normal', margin: '0 0.5rem 0 1rem' }}>
                    {!CommentsForActionisHidden[data.milestoneId] &&
                      CommentsForActionisHidden.hasOwnProperty(data.milestoneId) &&
                      commentActivityData
                        .filter((item) => item.isDecision === false)
                        .map(
                          (action, actionIndex) =>
                            action.commentsList.length > 0 &&
                            action.milestoneId === data.milestoneId && (
                              <div
                                key={actionIndex}
                                style={{ display: 'flex', flexDirection: 'column' }}
                              >
                                {action.commentsList
                                  .sort((a, b) => b.timestamp - a.timestamp)
                                  .map((comment, commentIndex) => (
                                    <div
                                      key={commentIndex}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginBottom: '0.5rem',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                          color: 'grey',
                                          //fontSize:"11px"
                                        }}
                                      >
                                        <i>{shortenName(comment.eName)}</i>
                                        <i>{dayMonthFilter(comment.timestamp)}</i>
                                      </div>
                                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <span>
                                          {linkifyString(removeMentionMarkupFromText(comment.text))}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            ),
                        )}
                  </div>
                </div>
              </React.Fragment>
            )
          })}
      </div>

      {isCreateModel && (
        <AssigneeActivity
          refreshBGUScorecardFn={refreshBGUScorecardFn}
          setModelView={setIsCreateModel}
          view={isCreateModel}
          objectiveId={objectiveId}
          milestoneId={milestoneId}
          assigneeList={assigneeList}
          loadAgain={loadAgain}
        />
      )}
    </>
  )
}
export default CommentActivity
