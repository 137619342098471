import * as Yup from 'yup'

const F2wCardSchema = Yup.object().shape({
  requiredCapacityInput: Yup.number()
    .test(
      'Is positive?',
      'The Required Capacity input has to be greater than 0.',
      (value) => value > 0 || value === undefined || value === null || isNaN(value) === true,
    )
    .required('The Required Capacity input has to be greater than 0.'),
})

export default F2wCardSchema
