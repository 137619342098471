import React, { useState } from 'react'
import './styles.css'
import useGetObjectiveMilestones from '../../../../api/query/useGetObjectiveMilestones'
import styled from 'styled-components'
import { milestoneStatus } from '../../../../utils/enumValues'
import { useOutletContext } from 'react-router-dom'
import InPageLoader from '../../../../components/InPageLoader'

const ObjectiveStatement = styled.div`
  font-size: 16px;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 0.5rem;
`
const MilestonesColumnTitle = styled.div`
  font-size: 14px;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid rgb(9, 38, 213);
`

const MilestonesOverview = () => {
  const { meetingData, isLoading } = useOutletContext()

  const [milestonesOverviewData, setMilestonesOverviewData] = React.useState({
    milestones: [],
    blockers: meetingData.blockedObjectives ?? [],
    objectives: meetingData.execAllObjectives ?? [],
  })
  const { data: milestoneData, isLoading: isMilestonesLoading } = useGetObjectiveMilestones(
    meetingData.execAllObjectives.map((obj) => parseInt(obj.objId)) ?? [],
  )

  React.useEffect(() => {
    setMilestonesOverviewData({
      milestones: milestoneData?.milestones ?? [],
      blockers: meetingData.blockedObjectives ?? [],
      objectives: meetingData.execAllObjectives ?? [],
    })
  }, [milestoneData?.milestones, isMilestonesLoading])

  if (isMilestonesLoading) {
    return <InPageLoader />
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!isLoading &&
          milestonesOverviewData.objectives &&
          milestonesOverviewData.objectives.map((obj, objIndex) => (
            <div className="shadow" style={{ width: '80%', marginBottom: '3rem', padding: '2rem' }}>
              <div className="milestones-container">
                <React.Fragment key={`obj-${objIndex}`}>
                  <b>
                    <ObjectiveStatement>{obj.statement}</ObjectiveStatement>
                  </b>
                  <div className="divider"></div>
                  {milestonesOverviewData.milestones.filter(
                    (mls) => parseInt(obj.objId) === parseInt(mls.objectiveId),
                  )?.length > 0 && (
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                      <h3>
                        <MilestonesColumnTitle class="scorecard-subtitle">
                          Activities
                        </MilestonesColumnTitle>
                      </h3>
                      <h3>
                        <MilestonesColumnTitle class="scorecard-subtitle">
                          Progress
                        </MilestonesColumnTitle>
                      </h3>

                      {/* TODO(Ceci): Add messages for empty milestones and empty blockers */}
                      <div>
                        {milestonesOverviewData.milestones &&
                          milestonesOverviewData.milestones
                            .filter((mls) => parseInt(obj.objId) === parseInt(mls.objectiveId))
                            ?.map((mls) => {
                              return (
                                <div>
                                  <ul style={{ padding: '0' }}>
                                    <li style={{ listStyle: 'none' }}>{mls.description}</li>
                                  </ul>
                                </div>
                              )
                            })}
                      </div>

                      <div>
                        {milestonesOverviewData.milestones &&
                          milestonesOverviewData.milestones.map((mls, mlsIndex) => {
                            if (parseInt(obj.objId) !== parseInt(mls.objectiveId)) {
                              return null
                            }

                            return (
                              <div>
                                <ul style={{ padding: '0' }}>
                                  <li style={{ listStyle: 'none' }}>
                                    {milestoneStatus(mls.progress)}
                                  </li>
                                </ul>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  )}
                  {milestonesOverviewData.milestones.filter(
                    (mls) => parseInt(obj.objId) === parseInt(mls.objectiveId),
                  )?.length == 0 && (
                    <div style={{ color: '#555' }}>No activities have been added.</div>
                  )}

                  <div>
                    {milestonesOverviewData.blockers &&
                      milestonesOverviewData.blockers.filter(
                        (blocker, blkIndx) => blocker.objectiveId === obj.objId,
                      ).length > 0 && (
                        <>
                          <h3>
                            <MilestonesColumnTitle class="scorecard-subtitle">
                              Blockers
                            </MilestonesColumnTitle>
                          </h3>
                          {milestonesOverviewData.blockers &&
                            milestonesOverviewData.blockers.map((blocker, blockerIndex) => {
                              if (parseInt(obj.objId) !== parseInt(blocker.objectiveId)) {
                                return null
                              }

                              return (
                                <div>
                                  <ul style={{ padding: '0' }}>
                                    <li style={{ listStyle: 'none' }}>{blocker.actionNeeded}</li>
                                  </ul>
                                </div>
                              )
                            })}
                        </>
                      )}

                    {!milestonesOverviewData.blockers ||
                      (milestonesOverviewData.blockers.filter(
                        (blocker, blkIndx) => blocker.objectiveId === obj.objId,
                      ).length == 0 && (
                        <div style={{ color: '#555' }}>Currently there are no blockers.</div>
                      ))}
                  </div>
                </React.Fragment>
              </div>
            </div>
          ))}
        {/* <div className="atrade-offs">
    <div>
      test overview
    </div>

    {

    milestonesOverviewData.objectives.map((obj) => (
      <>
    <div className='overviewObjective'>
      {obj.statement}
    </div>

    <div className='overviewMilestones'>
      {milestonesOverviewData?.milestones.filter((milestone, mIndx) => milestone.objectiveId === obj.objId) .length > 0 &&
      (<div>Milestone | Progress</div>)}
      {milestonesOverviewData.milestones.filter((milestone, mIndx) => milestone.objectiveId === obj.objId).map((milestone) => (
        <div>
          Milestone: {milestone.description} | {milestone.progress}
        </div>
      ))}

    </div>

    <div className='overviewBlocker'>
      {milestonesOverviewData.blockers.filter((blocker, blkIndx) => blocker.objectiveId === obj.objId).map((blocker) => (
        <>
        Blocker: {blocker.actionNeeded}
        </>
      )) }
    </div>
    </>
    ))}
    </div> */}
      </div>
    </>
  )
}

export default MilestonesOverview
