import { useState, useRef, React, useEffect } from 'react'
import AudioWaveVisualizer from '../AudioWaveVisualizer'
//import './styles.css'

const AudioRecorderV3 = ({
  handleSubmit,
  isNewDesign = false,
  start = true,
  setIsStarting = () => {},
  userInputEnd = false,
  setUserInputEnd = () => {},
  fromSetUpAgent = false,
  setIsRecording,
}) => {
  const [recording, setRecording] = useState(false)
  const mediaRecorder = useRef(null)
  const [seconds, setSeconds] = useState(0)
  const [audioStream, setAudioStream] = useState(null)
  const [showMic, setShowMic] = useState(true)

  const startRecording = () => {
    setSeconds(0)
    setIsStarting(true)
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      setAudioStream(stream)
      const options = {
        audioBitsPerSecond: 64000,
        mimeType: 'audio/webm',
      }
      mediaRecorder.current = new MediaRecorder(stream, options)
      mediaRecorder.current.start()
      setRecording(true)
    })
  }

  const stopRecording = async () => {
    setIsStarting(false)
    mediaRecorder.current.stop()
    setRecording(false)
    mediaRecorder.current.ondataavailable = (e) => {
      const audioBlob = new Blob([e.data], { type: 'audio/webm' })
      handleSubmit(audioBlob)
    }
    setSeconds(0)
    setUserInputEnd(false)
    if (audioStream) {
      audioStream.getTracks().forEach((track) => track.stop())
      setAudioStream(null)
    }
  }

  useEffect(() => {
    if (userInputEnd) {
      stopRecording()
    }
  }, [userInputEnd])

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds < 60) {
        setSeconds(seconds + 1)
      } else {
        clearInterval(interval)
        if (recording) {
          stopRecording()
        }
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  })

  useEffect(() => {
    if (start) {
      startRecording()
    }
  }, [])

  const formatTime = (secs) => {
    let hours = Math.floor(secs / 3600)
    let minutes = Math.floor(secs / 60) % 60
    let seconds = secs % 60
    return [hours, minutes, seconds]
      .map((v) => ('' + v).padStart(2, '0'))
      .filter((v, i) => v !== '00' || i > 0)
      .join(':')
  }

  const handleClickMiC = () => {
    if (!recording) {
      setShowMic(false)
      setIsRecording(true)
      startRecording()
    } else {
      setShowMic(true)
      setIsRecording(false)
      stopRecording()
    }
  }

  if (fromSetUpAgent) {
    return (
      <>
        {!recording && showMic && (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
            <div className="" onClick={handleClickMiC}>
              <span className="material-symbols-outlined icon-size-xl">mic</span>
            </div>
          </div>
        )}
        {recording === true && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'end',
              width: '100%',
            }}
          >
            <AudioWaveVisualizer audioStream={audioStream} />
            <div className="" onClick={handleClickMiC}>
              <span
                className="material-symbols-outlined icon-size-xl"
                style={{
                  paddingRight: recording === true ? '1.5rem' : '0',
                  fontSize: '30px',
                  color: 'red',
                }}
              >
                stop_circle
              </span>
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      {isNewDesign ? (
        <>
          {!recording ? (
            <div
              class="sleek-button sleek-blue"
              style={{ cursor: 'pointer', height: '1.5rem' }}
              onClick={() => {
                startRecording()
              }}
            >
              <span class="material-symbols-outlined icon-size" title="Start Recording">
                mic
              </span>
            </div>
          ) : (
            <div class="sleek-button sleek-blue recording">
              <span class="audio-recorder-timer">{formatTime(seconds)}</span>
              <span class="audio-recorder-status  ">
                <span class="audio-recorder-status-dot"></span>Recording
              </span>
              <span
                class="material-symbols-outlined icon-size"
                onClick={stopRecording}
                title="Stop Recording"
              >
                stop_circle
              </span>
            </div>
          )}
        </>
      ) : (
        <>
          {!recording ? (
            <div class="audio-recorder">
              <span
                class="material-symbols-outlined icon-size"
                onClick={startRecording}
                title="Start Recording"
              >
                mic
              </span>
            </div>
          ) : (
            <div class="audio-recorder recording">
              <span class="audio-recorder-timer">{formatTime(seconds)}</span>
              <span class="audio-recorder-status  ">
                <span class="audio-recorder-status-dot"></span>Recording
              </span>
              <span
                class="material-symbols-outlined icon-size"
                onClick={stopRecording}
                title="Stop Recording"
              >
                stop_circle
              </span>
            </div>
          )}
        </>
      )}
    </>
  )
}
export default AudioRecorderV3
