import React from 'react'
import useGetEmployeesUserConfigs from '../../../../../../api/query/useGetEmployeesUserConfig'
import useEditEmployeesUserConfig from '../../../../../../api/mutation/useEditEmployeesUserConfig'
import { userConfigALEnum } from '../../../../../../utils/enumValues'
import Modal from '../../../../../../components/Modal'
import Toast from '../../../../../../common/toast'
import InPageLoader from '../../../../../../components/InPageLoader'
import { getAreaUserConfigFlagMetadata } from '../../../../../../api/services/employee.services.jsx'
import { useTranslation } from 'react-i18next'

const ALConfigModal = ({ isModalOpen, closeModal, selectedCsuiteLeader }) => {
  const csuiteLeaderId = selectedCsuiteLeader?.eId ?? '000'
  const { data: areaLeadersData, isLoading: isAreaLeadersDataLoading } =
    useGetEmployeesUserConfigs(csuiteLeaderId)
  const [checkboxes, setCheckboxes] = React.useState({})
  const [flagMetadata, setFlagMetadata] = React.useState(null)
  const [empFlagValueMapping, setEmpFlagValueMapping] = React.useState(null)
  const [usersTouched, setUsersTouched] = React.useState([])

  const { t } = useTranslation(['Common'])

  const getUserConfigFlagMetadata2 = async () => {
    const data = await getAreaUserConfigFlagMetadata()
    if (data) {
      setFlagMetadata(data)
    }
  }

  React.useEffect(() => {
    getUserConfigFlagMetadata2()
  }, [])

  const [als, setALUsers] = React.useState(areaLeadersData ? areaLeadersData?.employees : [])
  React.useEffect(() => {
    if (areaLeadersData?.employees && flagMetadata) {
      setALUsers(areaLeadersData?.employees)

      let newEmpFlagValueMapping = {}

      for (let employee of areaLeadersData?.employees ?? []) {
        newEmpFlagValueMapping[employee.e_id] = {}

        for (let flag of flagMetadata.flag_metadata_list) {
          newEmpFlagValueMapping[employee.e_id][flag.name] =
            employee.user_config && employee.user_config[flag.name] !== undefined
              ? employee.user_config[flag.name]
              : false
        }
      }

      setEmpFlagValueMapping(newEmpFlagValueMapping)
    }
  }, [areaLeadersData, flagMetadata])

  const handleALCheckboxChange = (e, eId, flag) => {
    let tmpEmpFlagValueMapping = { ...empFlagValueMapping }

    tmpEmpFlagValueMapping[eId][flag.name] = !tmpEmpFlagValueMapping[eId][flag.name]
    setEmpFlagValueMapping(tmpEmpFlagValueMapping)
    setUsersTouched([...usersTouched, eId])
  }

  const handleTLRadioChange = (e, eId, flag, groupId) => {
    let tmpEmpFlagValueMapping = { ...empFlagValueMapping }

    // Find all flags in this group and set them to false
    for (let flagMxGroup of flagMetadata.flag_mx_groups) {
      if (flagMxGroup.group_id === groupId) {
        for (let flagName of flagMxGroup.flag_names) {
          tmpEmpFlagValueMapping[eId][flagName] = false
        }
      }
    }

    tmpEmpFlagValueMapping[eId][flag.name] = true
    setEmpFlagValueMapping(tmpEmpFlagValueMapping)
    setUsersTouched([...usersTouched, eId])
  }

  const editUserConfigsMutation = useEditEmployeesUserConfig()

  const saveData = async () => {
    // Only send users who were touched.
    let empFlagValueMappingCopy = {}
    for (let user of als) {
      if (usersTouched.includes(user.e_id)) {
        empFlagValueMappingCopy[user.e_id] = empFlagValueMapping[user.e_id]
      }
    }

    await editUserConfigsMutation.mutateAsync(
      { flagData: empFlagValueMappingCopy },
      {
        onSuccess: (response) => {
          Toast.fire({
            icon: 'success',
            title: 'Data saved successfully',
          })
        },
        onError: () => {
          Toast.fire({
            icon: 'error',
            title: 'Something went wrong',
          })
        },
      },
    )
  }

  let flagIdToFlag = {}
  if (flagMetadata) {
    for (let flag of flagMetadata.flag_metadata_list) {
      flagIdToFlag[flag.name] = flag
    }
  }

  // First we will render all mutually exclusive groups
  let flagsNamesCoveredByMxGroups = []
  let mxGroups = []
  if (flagMetadata) {
    for (let mxGroup of flagMetadata.flag_mx_groups) {
      let flags = []
      for (let flag_name of mxGroup.flag_names) {
        flags.push(flagIdToFlag[flag_name])
        flagsNamesCoveredByMxGroups.push(flag_name)
      }
      mxGroups.push({ flags: flags, groupId: mxGroup.group_id })
    }
  }

  let flagsNotCoveredByMxGroups = []
  if (flagMetadata) {
    for (let flag of flagMetadata.flag_metadata_list) {
      if (!flagsNamesCoveredByMxGroups.includes(flag.name)) {
        flagsNotCoveredByMxGroups.push(flag)
      }
    }
  }

  return (
    <div className="tlconfig-modal">
      <Modal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        width={'90%'}
        style={{ padding: '0rem 0rem 1rem 0rem' }}
        fixHorizontalScroll={true}
        includeCloseIcon={areaLeadersData?.employees?.length > 0 ? false : true}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '2rem',
              width: '65%',
              // overflowX:'auto'
            }}
          >
            <div style={{ alignSelf: 'start', marginLeft: '3rem' }}>
              Area Leaders for
              <select className="accountable-select" defaultValue={csuiteLeaderId}>
                <option value={selectedCsuiteLeader?.eId} key={`${selectedCsuiteLeader?.eId}`}>
                  {selectedCsuiteLeader?.name}
                </option>
              </select>
            </div>
            {isAreaLeadersDataLoading && (
              <div>
                <InPageLoader inComponent={true} />
              </div>
            )}
            {!isAreaLeadersDataLoading &&
              areaLeadersData &&
              areaLeadersData?.employees?.length > 0 && (
                <>
                  <div style={{ width: '90vw', overflowX: 'scroll' }}>
                    <table style={{ marginBottom: '1rem' }}>
                      <thead>
                        <tr>
                          <th>Name</th>
                          {mxGroups.map((mxGroup, mxGroupIndex) =>
                            mxGroup.flags.map((flag) => (
                              <th key={flag.name}>{flag.description}</th>
                            )),
                          )}

                          {flagsNotCoveredByMxGroups.map((flag) => (
                            <th key={flag.name}>{flag.description}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody style={{ width: '100%' }}>
                        {als?.map((user, userIndex) => (
                          <tr key={user.e_id}>
                            <td>{user.name}</td>

                            {mxGroups.map((mxGroup, mxGroupIndex) =>
                              mxGroup.flags.map((flag) => (
                                <td>
                                  <input
                                    id={`${user.e_id}-${flag.name}`}
                                    type="radio"
                                    checked={
                                      empFlagValueMapping &&
                                      empFlagValueMapping[user?.e_id] &&
                                      empFlagValueMapping[user?.e_id][flag.name]
                                    }
                                    onChange={(e) =>
                                      handleTLRadioChange(e, user.e_id, flag, mxGroup.groupId)
                                    }
                                  />
                                </td>
                              )),
                            )}

                            {flagsNotCoveredByMxGroups.map((flag) => (
                              <td key={flag.name}>
                                <input
                                  id={`${user.e_id}-${flag.name}`}
                                  type="checkbox"
                                  checked={
                                    empFlagValueMapping &&
                                    empFlagValueMapping[user?.e_id] &&
                                    empFlagValueMapping[user?.e_id][flag.name]
                                  }
                                  onChange={(e) => handleALCheckboxChange(e, user.e_id, flag)}
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
          </div>
          {!isAreaLeadersDataLoading &&
            areaLeadersData &&
            areaLeadersData?.employees?.length > 0 && (
              <div>
                <div className="modal-dual-btn" style={{ marginTop: '0rem' }}>
                  <button
                    className="fitted-button blue"
                    style={{
                      background: 'white',
                      padding: '0.4rem',
                      height: '35px',
                      fontWeight: 'bold',
                      marginRight: '1rem',
                    }}
                    onClick={() => {
                      closeModal()
                    }}
                    type="button"
                  >
                    {t('commonButtons.cancelSmall')}
                  </button>
                  <button
                    type="button"
                    className="fitted-button blue"
                    style={{
                      background: 'white',
                      padding: '0.4rem',
                      height: '35px',
                      fontWeight: 'bold',
                    }}
                    onClick={() => saveData(als)}
                  >
                    {t('commonButtons.saveData')}
                  </button>
                </div>
              </div>
            )}
        </div>
      </Modal>
    </div>
  )
}

export default ALConfigModal
