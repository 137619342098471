import React, { useState } from 'react'
import useTeamLeaderActivities from '../../api/query/team_meeting/useTLActivities'
import useDeleteTeamLeaderActivity from '../../api/mutation/deleteTLActivity'
import useEditTeamLeaderActivity from '../../api/mutation/editTLActivity'
import ErrorModal from '../ErrorModal'
import deleteCross from '../../assets/delete-cross.svg'
import plus from '../../assets/plus.svg'
import edit from '../../assets/edit.svg'
import { tlActivityProgress } from '../../utils/enumValues'
import ActivityModal from '../../pages/TeamMeetingView/components/MilestonesOverview/components/ActivityModal'
import styled from 'styled-components'
import { CardContainer, CardHeader } from '../../pages/Home/styles'
import InPageLoader from '../InPageLoader'
import { useTranslation } from 'react-i18next'

const ObjectiveStatement = styled.div`
  font-size: 16px;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 0.5rem;
`

const Activities = (view) => {
  const {
    data: tlActivities,
    isLoading: isLoading,
    isFetching: isFetching,
  } = useTeamLeaderActivities()
  const mutationDeleteTeamLeaderActivity = useDeleteTeamLeaderActivity()
  const mutationEditTeamLeaderActivity = useEditTeamLeaderActivity()
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const [activityErrorMessage, setActivityErrorMessage] = React.useState(null)
  const [isActivityModalOpen, setIsActivityModalOpen] = React.useState(false)
  const [isLoadingChangeAct, setIsLoadingChangeAct] = React.useState(false)
  const [loadingIndex, setLoadingIndex] = React.useState(null)
  const [actStatusArray, setActStatusArray] = React.useState(new Array(20).fill(0))
  const [currentActivity, setCurrentActivity] = React.useState(null)
  const { t } = useTranslation(['Dashboard'])

  React.useEffect(() => {
    if (tlActivities?.teamLeaderActivities) {
      tlActivities?.teamLeaderActivities.sort((a, b) => a.statement.localeCompare(b.statement))
      setActStatusArray(tlActivities?.teamLeaderActivities?.map((act, index) => act.progress))
    }
  }, [tlActivities?.teamLeaderActivities])

  const openErrorModal = () => {
    setIsErrorModalOpen(true)
  }

  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
    setActivityErrorMessage(null)
  }

  const deleteActivity = async (activityId) => {
    if (window.confirm(t('teamLeadersActivitiesCard.deleteActivityConfirmMsg'))) {
      let objSubmit = { activityId: parseInt(activityId) }
      await mutationDeleteTeamLeaderActivity.mutateAsync(objSubmit, {
        onSuccess: (response) => {},
        onError: () => {
          openErrorModal()
        },
      })
    }
  }

  const changeActivityProgress = async (progressType, activityId, index) => {
    let copyActStatusArray = [...actStatusArray]
    let previousProgressVal = copyActStatusArray[index]

    setIsLoadingChangeAct(true)
    setLoadingIndex(index)
    let objSubmit = { activityId: activityId, progress: parseInt(progressType) }
    await mutationEditTeamLeaderActivity.mutateAsync(objSubmit, {
      onSuccess: () => {
        setIsLoadingChangeAct(false)
        setLoadingIndex(null)
      },
      onError: () => {
        openErrorModal()
        copyActStatusArray[index] = previousProgressVal
        setActStatusArray([...copyActStatusArray])
        setIsLoadingChangeAct(false)
        setLoadingIndex(null)
      },
    })
    setIsLoadingChangeAct(false)
  }

  const toCamelCase = (str) => {
    return str
      .split('_')
      .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
      .join(' ')
  }

  const openActivityModal = () => {
    setIsActivityModalOpen(true)
  }

  const closeActivityModal = () => {
    setIsActivityModalOpen(false)
  }
  if (view.view === 'dashboard' && (isLoading || isFetching)) {
    return (
      <CardContainer>
        <CardHeader>{t('teamLeadersActivitiesCard.title')}</CardHeader>

        <div style={{ height: '10vw' }}>
          <InPageLoader inComponent={true} />
        </div>
      </CardContainer>
    )
  }
  return (
    <>
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={closeErrorModal}
        errorMessage={activityErrorMessage}
      />
      {isActivityModalOpen && (
        <ActivityModal
          isActivityModalOpen={isActivityModalOpen}
          handleClose={closeActivityModal}
          activity={currentActivity}
        ></ActivityModal>
      )}
      <div>
        <div>
          <div className="milestones-container">
            {view.view === 'Dashboard' && (
              <CardHeader>{t('teamLeadersActivitiesCard.title')}</CardHeader>
            )}
            {view.view === 'DecisionMeeting' && (
              <div>
                <b>
                  <ObjectiveStatement>{t('teamLeadersActivitiesCard.title')}</ObjectiveStatement>
                </b>
                <div className="divider"></div>
              </div>
            )}
            {tlActivities?.teamLeaderActivities?.map((act, index) => (
              <div
                className="widget-grid-item"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
              >
                <div style={{ width: '10rem' }}>{act.statement}</div>
                <div style={{ width: '10rem' }}>{toCamelCase(act.progress)}</div>

                {/* <select
                  value={tlActivityProgress(actStatusArray[index]) || "0"}
                  onChange={(e) => {
                    changeActivityProgress(e.target.value, act.activityId, index)
                  }}
                >
                  <option value="5">Not started</option>
                  <option value="2">On track</option>
                  <option value="3">At risk</option>
                  <option value="1">Completed</option>
                  <option value="4">Deprioritized</option>
             
                </select> */}

                {isLoadingChangeAct && loadingIndex === index ? (
                  <div className="loading-spinner"></div>
                ) : (
                  <div></div>
                )}
                <div
                  className="delete-button"
                  onClick={() => {
                    deleteActivity(act.activityId)
                  }}
                >
                  <img className="delete-btn" src={deleteCross} />
                </div>
                <div
                  className="edit-button"
                  onClick={() => {
                    setCurrentActivity(act)
                    openActivityModal()
                  }}
                >
                  <img className="edit-btn" src={edit} />
                </div>
              </div>
            ))}

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '4rem',
              }}
            >
              <div
                className="add-btn"
                onClick={() => {
                  setCurrentActivity(null)
                  openActivityModal()
                }}
              >
                <img className="plus" src={plus} alt="TransforML Logo" />
                <span>{t('teamLeadersActivitiesCard.addActivities')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Activities
