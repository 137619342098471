import React from 'react'
import { GridItem, ItemRow } from '../../styles'
import { milestoneStatus } from '../../../../utils/enumValues'
import { useTranslation } from 'react-i18next'
// import { getCompanyConfigSettings } from '../../../../utils/auth'
// import Button from '../../../../components/Button'
// import { colors } from '../../../../utils/colors'
// import { dateFilter } from '../../../../utils/time'
// import moment from 'moment';
import useEditKanbanBoardChangeActivityStatus from '../../../../api/mutation/useEditKanbanBoardChangeActivityStatus'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import { shortenName } from '../../../../utils/general'
import { getCompanyConfigSettings } from '../../../../utils/auth'
import { postHttpRequest } from '../../../../api/query/dynamicAPI'
import Toast from '../../../../common/toast'

const ObjectiveRow = ({
  objective,
  openModal,
  milestones = [],
  // kpis = [],
  // handleOpenKPIModal,
  // deleteKpi,
  deleteActivity,
  // handleOpenSuggestedActivityModal,
  // handleOpenMultiActivitiesModal,
  setActivity,
  setIsAddEditActivityModalOpen,
  setObjective,
}) => {
  //const disableAiConfig = getCompanyConfigSettings('disableAi')
  const { t } = useTranslation(['UpdateWizard'])
  const productSettings = getCompanyConfigSettings('productSettings')
  const changeActivityStatusMutation = useEditKanbanBoardChangeActivityStatus()
  const displayDate = (dueDate) => {
    if (dueDate !== 0) {
      let newDate = new Date(dueDate * 1000)
      return (dueDate = `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate
        .getFullYear()
        .toString()
        .slice(-2)}`)
    } else {
      return (dueDate = null)
    }
  }

  const [milestonesOnly, setMilestonesOnly] = React.useState([])
  const [activitiesOnly, setActivitiesOnly] = React.useState([])

  const [showTooltips, setShowTooltips] = React.useState({})
  const [isCompletedBtns, setIsCompletedBtns] = React.useState({})
  const loggedInUser = localStorage.getItem('tfmnm')

  const [dueDate, setDueDate] = React.useState(objective.dueDateStr)
  const [startDate, setStartDate] = React.useState(objective.startDateStr)
  const firstRender = React.useRef(true)

  React.useEffect(() => {
    ;(async () => {
      if (!firstRender.current) {
        const postObj = {
          objectiveId: objective.id,
          startDate: startDate,
          dueDate: dueDate,
        }
        await postHttpRequest('/update_objective_date', postObj)
      } else {
        firstRender.current = false
      }
    })()
  }, [startDate, dueDate])

  React.useEffect(() => {
    let tempMilestonesOnly = []
    let tempActiviesOnly = []

    for (const item of milestones) {
      if (item.umtId === 0 && item.progress !== 'COMPLETED') {
        tempMilestonesOnly.push(item)
      } else if (item.progress !== 'COMPLETED') {
        tempActiviesOnly.push(item)
      }
    }

    // Sort milestones alphabetically by description
    tempMilestonesOnly.sort((a, b) => a.description.localeCompare(b.description))

    // Sort activities alphabetically by description
    tempActiviesOnly.sort((a, b) => a.description.localeCompare(b.description))

    setMilestonesOnly([...tempMilestonesOnly])
    setActivitiesOnly([...tempActiviesOnly])
  }, [milestones])

  const handleCompleteClick = (objectiveId, milestoneId) => async (e) => {
    const result = await Swal.fire({
      title: t('activitiesStep.areYouSureCompleteActivity'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('activitiesStep.yes'),
      cancelButtonText: t('activitiesStep.no'),
    })

    if (result.isConfirmed && isCompletedBtns[e.target.id] === undefined) {
      await changeActivityStatus(objectiveId, milestoneId, 'COMPLETED')
    }
  }

  const changeActivityStatus = async (objectiveId, milestoneId, newStatus) => {
    const postObject = {
      objectiveId,
      milestoneId,
      newStatus,
    }

    await changeActivityStatusMutation.mutateAsync(postObject, {
      onSuccess: (response) => {
        setIsCompletedBtns((prev) => ({
          ...prev,
          [milestoneId]: true,
        }))
      },
    })
  }

  const validateStartEndDate = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'startDate') {
      if (dueDate && value > dueDate) {
        Toast.fire({
          icon: 'error',
          title: t('activitiesStep.startAfterDueToastMsg'),
        })
      } else {
        setStartDate(value)
      }
    } else if (name === 'dueDate') {
      if (startDate && value < startDate) {
        Toast.fire({
          icon: 'error',
          title: t('activitiesStep.dueDateBeforeStartToastMsg'),
        })
      } else {
        setDueDate(value)
      }
    }
  }

  return (
    <>
      <GridItem>
        <h4
          style={{
            marginBottom: '0',
            color: objective.typeId === 3 ? 'rgb(110, 110, 110)' : '#000',
          }}
        >
          {productSettings && objective.isGeneralObjective === false
            ? `${(objective?.initiativeId ?? 0).toString().padStart(5, '0')} - ${
                objective.statement
              }`
            : `${objective.statement}`}
        </h4>
        {(objective.level === 3 || objective.level === 2) && !objective.isGeneralObjective && (
          <>
            <div style={{ marginTop: '0.2rem', marginBottom: '0.1rem' }}>
              <div>
                <span>{t('activitiesStep.startDate')}:</span>
                <input
                  type="date"
                  value={startDate}
                  name="startDate"
                  onKeyDown={(e) => {
                    e.preventDefault()
                  }}
                  onChange={validateStartEndDate}
                />
              </div>
              <div style={{ marginTop: '0.1rem' }}>
                <span>{t('activitiesStep.dueDate')}: </span>
                <input
                  type="date"
                  value={dueDate}
                  name="dueDate"
                  onKeyDown={(e) => {
                    e.preventDefault()
                  }}
                  onChange={validateStartEndDate}
                />
              </div>
            </div>
            <div class="material-symbols-outlined fitted-button blue bolder">
              <span onClick={() => openModal(objective, null)}>add</span>
            </div>
          </>
        )}
        {objective.typeId === 3 && (
          <span style={{ fontSize: '14px', color: 'rgb(85, 85, 85)' }}>
            {t('activitiesStep.businessAsUsual')}
          </span>
        )}
        <br />
        <br />
      </GridItem>
      <GridItem>
        {milestonesOnly.map((milestone, index) => {
          if (parseInt(objective.id) !== parseInt(milestone.objectiveId)) {
            return null
          }
          // We cannot use a `filter` because it messes up indexes, which are required for deleting
          // and editing.
          if (milestone.mode == 'deleted') return null

          return (
            <>
              {(objective.showAssignedActivitiesOnly == false ||
                (objective.showAssignedActivitiesOnly == true &&
                  milestone.assigneeList.some((person) => person.name === loggedInUser))) && (
                <div
                  key={`tl-${milestone.milestoneId}`}
                  style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}
                >
                  {/* Commented out for now to see if we really want to keep or not the M */}
                  {/* <span class="icon-size" style={{ color: '#7A9980' }}>
                M
              </span> */}

                  {/* Span is used so that milestones align to Actions which use an icon  */}
                  {/* <span style={{ width: "12.648px" }}></span> */}
                  <ItemRow style={{ width: '100%' }}>
                    <div
                      style={{
                        display: 'grid',
                        columnGap: '1rem',
                        gridTemplateColumns: '2fr 2fr 1fr 1fr 1fr 1fr 1fr',
                        width: '100%',
                      }}
                    >
                      <div data-testid={`activityDescription-${index}`}>
                        {' '}
                        {milestone.meetingTopicDescription}{' '}
                      </div>
                      <div data-testid={`activityDescription-${index}`} style={{ display: 'flex' }}>
                        <span style={{ width: '12.648px', marginRight: '1rem' }}></span>
                        <span>{milestone.description} </span>
                      </div>
                      <div data-testid={`activityAssignee-${index}`}>
                        {' '}
                        {milestone.name}
                        {milestone?.assigneeList?.map((person, personIndex) => (
                          <>
                            <span key={personIndex}>
                              {shortenName(person.name)}
                              {personIndex < milestone.assigneeList.length - 1 ? ', ' : ''}
                            </span>
                          </>
                        ))}
                      </div>
                      <div data-testid={`activityStartDate-${index}`}>
                        {' '}
                        {displayDate(milestone.startDate)}
                      </div>
                      <div data-testid={`activityDueDate-${index}`}>
                        {' '}
                        {displayDate(milestone.dueDate)}
                      </div>
                      <div data-testid={`activityStatus-${index}`}>
                        {' '}
                        {milestoneStatus(milestone.progress)}{' '}
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', columnGap: '0.5rem' }}>
                        <div class="fitted-button blue">
                          <span
                            class="material-symbols-outlined"
                            onClick={() => {
                              setObjective(objective)
                              setActivity(milestone)
                              setIsAddEditActivityModalOpen(true)
                            }}
                          >
                            edit
                          </span>
                        </div>
                        <Tooltip
                          id="my-tooltip"
                          style={{ backgroundColor: '#f2f2f2', color: 'black' }}
                        />
                        <div class="fitted-button blue">
                          <span
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={t('activitiesStep.isCompletedTooltip')}
                            id={milestone.milestoneId}
                            class="material-symbols-outlined"
                            onClick={(e) =>
                              handleCompleteClick(milestone.objectiveId, milestone.milestoneId)(e)
                            }
                            style={{ color: 'grey', cursor: 'pointer' }}
                            onMouseOver={(e) => {
                              if (
                                showTooltips[e.target.id] == undefined ||
                                !showTooltips[e.target.id]
                              ) {
                                setShowTooltips((prev) => ({ ...prev, [e.target.id]: true }))
                              }
                            }}
                            onMouseOut={(e) => {
                              if (showTooltips[e.target.id]) {
                                setShowTooltips((prev) => ({ ...prev, [e.target.id]: false }))
                              }
                            }}
                          >
                            done
                          </span>
                        </div>
                        {/* <div class="fitted-button blue" >
                      <span class="material-symbols-outlined"
                        onClick={() => {
                          deleteActivity(index)
                        }}>done</span>
                    </div> */}
                      </div>
                    </div>
                  </ItemRow>
                </div>
              )}
            </>
          )
        })}

        {activitiesOnly.map((milestone, index) => {
          if (parseInt(objective.id) !== parseInt(milestone.objectiveId)) {
            return null
          }
          // We cannot use a `filter` because it messes up indexes, which are required for deleting
          // and editing.
          if (milestone.mode == 'deleted') return null

          return (
            <>
              {(objective.showAssignedActivitiesOnly == false ||
                (objective.showAssignedActivitiesOnly == true &&
                  milestone.assigneeList.some((person) => person.name === loggedInUser))) && (
                <div
                  key={`activities-${milestone.milestoneId}`}
                  style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}
                >
                  <ItemRow style={{ width: '100%' }}>
                    <div
                      style={{
                        display: 'grid',
                        columnGap: '1rem',
                        gridTemplateColumns: '2fr 2fr 1fr 1fr 1fr 1fr 1fr',
                        width: '100%',
                      }}
                    >
                      <div data-testid={`activityDescription-${index}`}>
                        {' '}
                        {milestone.meetingTopicDescription}{' '}
                      </div>
                      <div data-testid={`activityDescription-${index}`} style={{ display: 'flex' }}>
                        <span class="icon-size" style={{ color: '#BC7063', marginRight: '1rem' }}>
                          A
                        </span>
                        {milestone.description}
                      </div>
                      <div data-testid={`activityAssignee-${index}`}>
                        {' '}
                        {milestone.name}
                        {milestone?.assigneeList?.map((person, personIndex) => (
                          <span key={personIndex}>
                            {shortenName(person.name)}
                            {personIndex < milestone.assigneeList.length - 1 ? ', ' : ''}
                          </span>
                        ))}
                      </div>
                      <div data-testid={`activityStartDate-${index}`}>
                        {' '}
                        {displayDate(milestone.startDate)}
                      </div>
                      <div data-testid={`activityDueDate-${index}`}>
                        {' '}
                        {displayDate(milestone.dueDate)}
                      </div>
                      <div data-testid={`activityStatus-${index}`}>
                        {' '}
                        {milestoneStatus(milestone.progress)}{' '}
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', columnGap: '0.2rem' }}>
                        <div class="fitted-button blue">
                          <span
                            class="material-symbols-outlined"
                            onClick={() => {
                              setObjective(objective)
                              setActivity(milestone)
                              setIsAddEditActivityModalOpen(true)
                            }}
                          >
                            edit
                          </span>
                        </div>
                        <Tooltip
                          id="my-tooltip"
                          style={{ backgroundColor: '#f2f2f2', color: 'black' }}
                        />
                        <div class="fitted-button blue">
                          <span
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={t('activitiesStep.isCompletedTooltip')}
                            id={milestone.milestoneId}
                            class="material-symbols-outlined"
                            onClick={(e) =>
                              handleCompleteClick(milestone.objectiveId, milestone.milestoneId)(e)
                            }
                            style={{ color: 'grey', cursor: 'pointer' }}
                            onMouseOver={(e) => {
                              if (
                                showTooltips[e.target.id] == undefined ||
                                !showTooltips[e.target.id]
                              ) {
                                setShowTooltips((prev) => ({ ...prev, [e.target.id]: true }))
                              }
                            }}
                            onMouseOut={(e) => {
                              if (showTooltips[e.target.id]) {
                                setShowTooltips((prev) => ({ ...prev, [e.target.id]: false }))
                              }
                            }}
                          >
                            done
                          </span>
                        </div>

                        {/* <div class="fitted-button blue tooltip">
                          <span
                            class="material-symbols-outlined"
                          >
                            info
                          </span>

                          <span class="tooltiptext"> Action associated with Meeting Topic: {milestone.meetingTopicDescription}</span>

                        </div> */}

                        {/* <div class="fitted-button blue" >
                      <span class="material-symbols-outlined"
                        onClick={() => {
                          deleteActivity(index)
                        }}>done</span>
                    </div> */}

                        {/* {!disableAiConfig && (
                <>
                  <Button
                    color={colors.blue}
                    onClick={() => handleOpenSuggestedActivityModal(objective, null)}
                  >
                    <b>+</b> {t('activitiesStep.suggestActivities')}
                  </Button>

                  <Button
                    color={colors.blue}
                    onClick={() => handleOpenMultiActivitiesModal(objective)}
                  >
                    <b>+</b> {t('activitiesStep.addMultipleActivities')}
                  </Button>
                </>
              )} */}
                      </div>
                    </div>
                  </ItemRow>
                </div>
              )}
            </>
          )
        })}
      </GridItem>
    </>
  )
}

export default ObjectiveRow
