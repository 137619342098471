import React from 'react'
import '../../../MeetingView/components/MilestonesOverview/styles.css'
import TextEditor from '../../../../components/QuillTextEditor'
import { hasRole } from '../../../../utils/auth'

const Takeaways = () => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {(hasRole('team') || hasRole('area')) && (
          <div
            className="shadow"
            style={{
              marginTop: '1rem',
              width: '90%',
              marginBottom: '3rem',
              padding: '2rem',
              height: '38rem',
            }}
          >
            <TextEditor />
          </div>
        )}
      </div>
    </>
  )
}

export default Takeaways
