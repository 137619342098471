import React, { useState, useRef, useEffect } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import Task from '../task'
import { ColumnContainer, Title, TaskList } from './style'
import SeeMoreActivitiesModal from '../../../../components/SeeMoreActivitiesModal'
import { colors } from '../../../../utils/colors'
import useSaveSpreadsheetData from '../../../../api/mutation/useSaveSpreadsheetData'
import Toast from '../../../../common/toast'
import {
  API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE,
  API_ERROR_MESSAGE,
} from '../../../../api/constant/route'
import { tlActivityProgress, backLogActivityProgress } from '../../../../utils/enumValues'
import { useGetSingleActivity } from '../../../../api/services/spreadsheet.srvices'
import { useTranslation } from 'react-i18next'
import Textarea from 'rc-textarea'
import { MentionsInput, Mention } from 'react-mentions'

function Column({ backlogActivity = false, setIsKanbanBacklogOpen = () => {}, ...props }) {
  const { t } = useTranslation(['Common', 'Kanban'])
  const [displayViewMore, setDisplayViewMore] = React.useState(true)
  const [isSeeMoreActivitiesModalOpen, setIsSeeMoreActivitiesModalOpen] = React.useState(false)
  const [activityType, setActivityType] = React.useState(null)
  const [displayViewMoreBacklog, setDisplayViewMoreBacklog] = React.useState(false)
  const [mentionsList, setMentionsList] = useState([])

  const openSeeMoreActivitiesModal = () => {
    setIsSeeMoreActivitiesModalOpen(true)
  }
  const closeSeeMoreActivitiesModal = () => {
    setIsSeeMoreActivitiesModalOpen(false)
  }

  const moreOrEqualThanLimitActType = (limit, type) => {
    let amount = props.tasks.filter((task, index) => task.progress === type).length
    return amount >= limit
  }

  // weird function
  function findCount() {
    const result = props.tasks.map((item) => {
      if (item.progress == 'NOT_STARTED') {
        return item
      }
    })
    return result.length
  }

  React.useEffect(() => {
    // setDisplayViewMore(moreOrEqualThanLimitActType(10, 'COMPLETED'))
    setDisplayViewMoreBacklog(moreOrEqualThanLimitActType(10, 'BACKLOG'))
  }, [])
  const [inputDescription, setInputDescription] = useState('')

  const { data: activityData, isLoading: isActivityDataLoading } = useGetSingleActivity(
    parseInt(props?.objectiveId),
    null, //activiyid
  )

  const mutationSaveSpreadsheetData = useSaveSpreadsheetData()

  const submitForm = async (description) => {
    let actdata = [
      {
        mId__: null,
        description: description,
        progress: backlogActivity ? 6 : tlActivityProgress(props.column?.statusId),
      },
    ]

    if (backlogActivity) {
      actdata[0].criticalityScore = backLogActivityProgress(props.column?.statusId)
    }

    let setChangesObj = {
      createdRowIds: Array.from([]),
      updatedRowIds: Array.from([]),
      deletedRowIds: Array.from([]),
      activityData: [],
      idMapping: {},
      // even if it's added from backlog modal, it's still an activity, so let's use the same columnMetadata as other act
      columnMetadata: activityData?.columnMetadata,
      objectiveId: parseInt(props?.objectiveId),
    }
    setChangesObj.createdRowIds = [0]
    actdata[0].id = 0
    setChangesObj.activityData = actdata

    // This line will take care that if kanba view is personalized if we add an activity it will automatically
    // add the label from the view to the new activity
    if (props.milestoneLabelName !== null) {
      setChangesObj['milestoneLabels'] = [{ labelMilestoneName: props.milestoneLabelName }]
    }

    setChangesObj['mentions'] = mentionsList.map((mention) => mention.id)

    await mutationSaveSpreadsheetData.mutateAsync([parseInt(props?.objectiveId), setChangesObj], {
      onSuccess: (response) => {
        setInputDescription('')
        if (!backlogActivity) {
          Toast.fire({
            icon: 'success',
            title: API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE,
          })
        }
      },
      onError: (e) => {
        Toast.fire({
          icon: 'error',
          title: API_ERROR_MESSAGE,
        })
      },
    })

    props.setIsRefreshObjective((old) => !old)
  }

  const mentionStyle = {
    width: '100%',
    padding: '0',
    marginTop: '9px',
    height: '3rem',
    marginRight: '0.5rem',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: 'none',
    '&focused': {
      borderColor: '#40a9ff',
    },
    input: {
      overflow: 'auto',
    },
    highlighter: {
      boxSizing: 'border-box',
      overflow: 'hidden',
      height: 70,
      border: '4px solid transparent',
    },
    suggestions: {
      list: {
        maxHeight: 190,
        overflowY: 'auto',
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#d3d3d3',
        },
      },
    },
  }

  return (
    <>
      {isSeeMoreActivitiesModalOpen && activityType && (
        <SeeMoreActivitiesModal
          isModalOpen={isSeeMoreActivitiesModalOpen}
          closeModal={closeSeeMoreActivitiesModal}
          statement={props.statement}
          objectiveId={props.objectiveId}
          activityType={activityType}
          setIsRefreshObjective={props.setIsRefreshObjective}
          employeesWithColors={props.employeesWithColors}
        />
      )}

      <ColumnContainer>
        <Title
          onClick={() => {
            if (props.column?.statusId === 'BACKLOG') {
              setIsKanbanBacklogOpen(true)
            } else if (props.column?.statusId === 'COMPLETED') {
              setActivityType(props.column.title)
              openSeeMoreActivitiesModal()
            }
          }}
          className={`box_header_wrapper ${
            !backlogActivity ? `${props.column?.statusId}_box` : 'BACKLOG_box'
          } ${props.column?.statusId === 'COMPLETED' ? 'tooltip' : ''}`}
          style={{
            cursor:
              props.column?.statusId === 'BACKLOG' || props.column?.statusId === 'COMPLETED'
                ? 'pointer'
                : 'default',
          }}
        >
          {props.column?.statusId === 'COMPLETED' && (
            <span
              class="tooltiptext"
              style={{
                width: 'fit-content',
                paddingRight: '1rem',
                bottom: '65%',
                left: '0%',
                paddingTop: '1rem',
              }}
            >
              {' '}
              {t('Common:tooltip.completedHeader')}{' '}
            </span>
          )}
          <span className="box_header">
            <span className="title_symbol"></span> {props.column?.title.replace('-', ' ')}
          </span>
          <span class="title_count">{findCount()}</span>
        </Title>
        {((!backlogActivity &&
          (props.column?.statusId === 'NOT_STARTED' ||
            props.column?.statusId === 'ON_TRACK' ||
            props.column?.statusId === 'BACKLOG')) ||
          backlogActivity) && (
          <div
            style={{
              padding: '8px',
            }}
          >
            {props?.showAssignedActivitiesOnly === false && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '10px',
                  boxShadow: 'rgb(204, 204, 204) 0px 0px 6px',
                  borderRadius: '4px',
                  backgroundColor: 'white',
                  alignItems: 'center',
                }}
              >
                <MentionsInput
                  id="new_activity"
                  value={inputDescription}
                  onChange={(e, v, nv, mentions) => {
                    setMentionsList(mentions)
                    setInputDescription(e.target.value)
                  }}
                  allowSuggestionsAboveCursor={true}
                  style={mentionStyle}
                  spellCheck={false}
                >
                  <Mention
                    trigger="@"
                    data={props.userList}
                    markup="@[__display__](user:__id__)"
                    displayTransform={(id, display) => `@${display}`}
                    appendSpaceOnAdd={true}
                  />
                </MentionsInput>
                <div
                  class="fitted-button blue"
                  onClick={() => {
                    if (inputDescription !== '') {
                      submitForm(inputDescription)
                    }
                  }}
                >
                  <span class="material-symbols-outlined">add</span>
                </div>
              </div>
            )}
          </div>
        )}
        <Droppable droppableId={props.column?.statusId}>
          {(provided, snapshot) => (
            <TaskList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
              style={{
                backgroundColor: snapshot.isDraggingOver ? 'rgb(242, 242, 242)' : '#FCFCFC',
              }}
            >
              {props.tasks.map((task, index) => (
                <>
                  {props.buttonTypes.includes('Flagged') && task.isPriority && (
                    <Task
                      key={task.id}
                      task={task}
                      index={index}
                      objectiveId={props.objectiveId}
                      setIsRefreshObjective={props.setIsRefreshObjective}
                      employeesWithColors={props.employeesWithColors}
                      backlogActivity={backlogActivity}
                    />
                  )}
                </>
              ))}

              {props.tasks.map((task, index) => (
                <>
                  {!props.buttonTypes.includes('Flagged') && (
                    <Task
                      key={task.id}
                      task={task}
                      index={index}
                      objectiveId={props.objectiveId}
                      setIsRefreshObjective={props.setIsRefreshObjective}
                      employeesWithColors={props.employeesWithColors}
                      backlogActivity={backlogActivity}
                    />
                  )}
                </>
              ))}

              {provided.placeholder}

              {!backlogActivity &&
                (props.column?.statusId === 'COMPLETED' ||
                  props.column?.statusId === 'DEPRIORITIZED') && (
                  <div style={{ textAlign: 'center', color: colors.blue, cursor: 'pointer' }}>
                    <span
                      onClick={() => {
                        setActivityType(props.column.title)
                        openSeeMoreActivitiesModal()
                      }}
                    >
                      {t('Kanban:viewAll')}
                    </span>
                  </div>
                )}
              {!backlogActivity && props.column?.statusId === 'BACKLOG' && (
                <div style={{ textAlign: 'center', color: colors.blue, cursor: 'pointer' }}>
                  <span
                    onClick={() => {
                      setIsKanbanBacklogOpen(true)
                    }}
                  >
                    {t('Kanban:goToFullBacklog')}
                  </span>
                </div>
              )}
            </TaskList>
          )}
        </Droppable>
      </ColumnContainer>
    </>
  )
}

export default Column
