import React from 'react'
import '../../../MeetingView/components/MilestonesOverview/styles.css'
import SummaryOfObjectives from '../../../../components/SummaryOfObjectives'

const MilestonesOverview = () => {
  let eIdsOfSelectedTls = []
  return (
    <>
      <SummaryOfObjectives eIdsOfSelectedTls={eIdsOfSelectedTls}></SummaryOfObjectives>
    </>
  )
}

export default MilestonesOverview
