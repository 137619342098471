import { useState, useEffect, useRef, Fragment } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import Column from '../common/column'
import { throttle } from 'lodash'

import { getHttpRequest, postHttpRequest } from '../../../api/query/dynamicAPI'
import { POST_KANBAN_BOARD_CHANGE_ACTIVITY_STATUS } from '../../../api/constant/route'

import { Container, KanbanHeader, KanbanTitle, CreateTaskButton } from '../style'
import useAddManySuggestedActivities from '../../../api/mutation/addManySuggestedActivities'
import ErrorModal from '../../../components/ErrorModal'
import { useFeatureFlagsContext } from '../../../utils/featureFlagsContext'

import AddEditActivityModal from '../../../components/AddEditActivityModal'
import { useTranslation } from 'react-i18next'

import BlockerModal from '../common/blockerModel/index'

import Modal from '../../../components/Modal'

function KanbanMainBacklog({ isModalOpen, closeModal, ...props }) {
  const { t } = useTranslation(['Kanban'])
  const [state, setState] = useState('')
  const [objectiveId, setObjectiveId] = useState(props?.objective?.id)

  const [tasks, setTasks] = useState([])
  const [isSingleActivityModalOpen, setIsSingleActivityModalOpen] = useState(false)

  const [isRefreshObjective, setIsRefreshObjective] = useState(false)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

  const [viewblocker, setViewblocker] = useState(false)
  const [suffelTaskId, setSuffelTaskId] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { flags } = useFeatureFlagsContext()

  const [isProces, setIsProces] = useState(false)
  const [showPriority, setShowPriority] = useState(true)

  const BacklogViewMode = {
    BACKLOG_VIEW_MODE_PRIORITY: 0,
    BACKLOG_VIEW_MODE_NUMBER: 1,
  }

  useEffect(() => {
    ;(async () => {
      let userPreference = await getHttpRequest('/get_employees_user_preferences')
      let kanbanBacklogViewMode = userPreference?.kanbanBacklogViewMode?.find(
        (pref) => pref.objId == objectiveId,
      )
      if (kanbanBacklogViewMode) {
        setShowPriority(BacklogViewMode[kanbanBacklogViewMode.viewMode] === 0)
      }
    })()
  }, [])

  useEffect(() => {
    getObjectiveTasks(
      objectiveId,
      props?.objective.isViewerOnly,
      props?.objective.showAssignedActivitiesOnly,
      showPriority,
    )
  }, [objectiveId, isRefreshObjective, props?.filterObj, showPriority])

  function getObjectiveTasks(objectiveId, isViewerOnly, showAssignedActivitiesOnly, showPriority) {
    setIsLoading(true)
    setTimeout(() => {
      getHttpRequest(`/kanban_board_activity_data_with_limit/${objectiveId}/${5}`, {
        params: {
          is_shared: isViewerOnly,
          show_assigned_activities_only: showAssignedActivitiesOnly,
          is_backlog: true,
          show_priority: showPriority,
        },
      })
        .then((response) => {
          const data = response
          if (data) {
            const tasks = data.milestones
            const columns = data.criticalityScoreStatusList
            const columnOrder = data.columnOrder
            const finalData = { tasks, columns, columnOrder }
            setState(finalData)
          } else {
          }

          setIsLoading(false)
        })
        .catch((error) => {})
    }, props.delay * 1000)
  }

  // function onDragEnd(result) {
  // {(result) =>
  const onDragEnd = throttle((result) => {
    const { destination, source, draggableId } = result

    // setIsProces(true);
    if (isProces) return

    // TODO:  Recorder our column
    if (!destination) {
      return
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    const new_column_status = destination.droppableId
    const taskId = Math.floor(draggableId)

    // Start Column source
    const start = state.columns[source.droppableId]

    // Start Column destination
    const finish = state.columns[destination.droppableId]

    if (start === finish) {
      const newTaskIds = Array.from(start.activityIds)
      ///

      newTaskIds.splice(source.index, 1)

      const drag = Math.floor(draggableId)

      newTaskIds.splice(destination.index, 0, drag)

      const newColumn = {
        ...start,
        activityIds: newTaskIds,
      }

      const cloneState = { ...state }
      cloneState.columns[source.droppableId] = newColumn
      setState(cloneState)

      return
    } else {
      // Moving From One List to Other

      const startTaskIds = Array.from(start.activityIds)
      startTaskIds.splice(source.index, 1)
      const newStart = {
        ...start,
        activityIds: startTaskIds,
      }

      const finishTaskIds = Array.from(finish?.activityIds ?? [])

      const drag = Math.floor(draggableId)
      finishTaskIds.splice(destination.index, 0, drag)

      const newFinish = {
        ...finish,
        activityIds: finishTaskIds,
      }

      const cloneState = { ...state }

      cloneState.columns[source.droppableId] = newStart
      cloneState.columns[destination.droppableId] = newFinish

      const postObject = {
        objectiveId: objectiveId,
        milestoneId: taskId,
        newStatus: new_column_status,
        isBacklog: true,
      }
      setState(cloneState)

      shuffleTaskBetweenColumn(postObject)
    }
  }, 100)

  function onDragStart() {}

  function onDragUpdate() {}

  async function shuffleTaskBetweenColumn(postObject) {
    postHttpRequest(POST_KANBAN_BOARD_CHANGE_ACTIVITY_STATUS, postObject)
      .then((response) => {
        if (response) {
          // setIsRefreshObjective((old) => !old)
          return
        } else {
        }
      })
      .catch((error) => {})
  }

  const mutationAddManyActivities = useAddManySuggestedActivities()

  const addSuggestedActivities = async (activities) => {
    await mutationAddManyActivities.mutateAsync(
      { milestones: activities },
      {
        onSuccess: () => {
          setIsRefreshObjective((old) => !old)
        },
        onError: () => {
          alert(t('Common:errorModal.text5'))
        },
      },
    )
  }

  useEffect(() => {
    let activities = []
    state?.columnOrder?.length > 0 &&
      state?.columnOrder?.map((columnId, index) => {
        const column = state.columns[columnId]

        if (column?.activityIds && column.activityIds.length > 0) {
          activities = column.activityIds.map((activityIds) => state.tasks[activityIds])
        }
      })
    setTasks(activities)
  }, [state])

  const hasAnyActivities = (id) => {
    if (tasks.length === 0) {
      return false
    }
    tasks?.map((task, index) => {
      if (task.objectiveId === parseInt(id)) {
        return true
      }
      return false
    })
  }

  const updateUserPreference = async (newShowPriority) => {
    const preference = {
      objId: objectiveId,
      showPriority: newShowPriority,
    }
    await postHttpRequest('/edit_employees_user_preferences', preference)
  }

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={() => {
        closeModal()
      }}
      width={'95%'}
      modalContainerStyle={{ padding: '0px', width: '100%' }}
    >
      <div style={{ width: '100%' }}>
        {flags.enable_combined_objs === 'false' &&
          props?.objective?.isViewerOnly === true &&
          props?.objective?.ownerName != undefined && (
            <div
              style={{
                color: 'rgb(85, 85, 85)',
                display: 'flex',
                paddingLeft: '12px',
                textAlign: 'left',
              }}
            >
              {t('Kanban:owner')}: {props?.objective?.ownerName}
            </div>
          )}
        <KanbanHeader>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <KanbanTitle style={{ textAlign: 'left' }}>
                {' '}
                {props?.objective?.statement}{' '}
              </KanbanTitle>
              {props?.objective?.isGeneralObjective && <div>{t('Kanban:generalObjectiveMsg')}</div>}
              {flags.enable_combined_objs === 'true' &&
                (props?.objective?.originalId != 0 || props?.objective.isViewerOnly) &&
                (props?.objective?.isCopy ? (
                  <span style={{ textAlign: 'left' }}> {props?.objective?.ownerName} </span>
                ) : (
                  <span style={{ textAlign: 'left' }}>
                    {t('Kanban:owner')}: {props?.objective?.ownerName}
                  </span>
                ))}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
              <span
                style={{
                  textAlign: 'left',
                  width: '100%',
                  paddingRight: '1rem',
                  alignSelf: 'center',
                }}
              >
                {t('Kanban:backlogLabel')}
              </span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',

                  columnGap: '1rem',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div
                  className="option-button"
                  style={{ backgroundColor: showPriority === true ? '#4472c4' : '#a6a6a6' }}
                  onClick={() => {
                    setShowPriority(true)
                    updateUserPreference(true)
                  }}
                >
                  {t('Common:commonButtons.priority')}
                </div>
                <div
                  className="option-button"
                  style={{ backgroundColor: showPriority === false ? '#4472c4' : '#a6a6a6' }}
                  onClick={() => {
                    setShowPriority(false)
                    updateUserPreference(false)
                  }}
                >
                  {t('Common:commonButtons.number')}
                </div>
              </div>
            </div>
          </div>
        </KanbanHeader>
        {
          <>
            {isLoading && (
              <>
                <br />
                <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
                  <div
                    className="loading-spinner"
                    style={{ width: '1rem', height: '1rem', marginLeft: '5rem' }}
                  ></div>
                </div>
              </>
            )}

            <Container style={{ overflowX: 'none', textAlign: 'left' }}>
              <DragDropContext
                onDragStart={onDragStart}
                onDragUpdate={onDragUpdate}
                onDragEnd={onDragEnd}
              >
                {state !== '' &&
                  state?.columnOrder.length > 0 &&
                  state?.columnOrder.map((columnId, index) => {
                    const column = state.columns[columnId]
                    let tasks = []
                    if (column?.activityIds && column.activityIds.length > 0) {
                      tasks = column.activityIds.map((activityIds) => state.tasks[activityIds])
                    }
                    return (
                      <Column
                        key={column.statusId}
                        column={column}
                        tasks={tasks}
                        index={index}
                        statement={props?.objective?.statement}
                        objectiveId={objectiveId}
                        setIsRefreshObjective={setIsRefreshObjective}
                        employeesWithColors={props?.employeesWithColors}
                        buttonTypes={props.buttonTypes}
                        showAssignedActivitiesOnly={props?.objective?.showAssignedActivitiesOnly}
                        backlogActivity={true}
                      />
                    )
                  })}
              </DragDropContext>
            </Container>
            <br />
          </>
        }
        {viewblocker && (
          <BlockerModal
            view={viewblocker}
            setModelView={setViewblocker}
            objectiveId={props?.objective?.id}
            suffelTaskId={suffelTaskId}
          />
        )}

        {isSingleActivityModalOpen && (
          <AddEditActivityModal
            isModalOpen={isSingleActivityModalOpen}
            closeModal={() => {
              setIsSingleActivityModalOpen(false)
            }}
            objectiveId={objectiveId}
            activityId={null}
            setIsRefreshObjective={setIsRefreshObjective}
          />
        )}
        <ErrorModal
          isOpen={isErrorModalOpen}
          handleClose={() => {
            setIsErrorModalOpen(false)
          }}
          errorMessage={t('Common:errorModal.text4')}
        />
      </div>
    </Modal>
  )
}

export default KanbanMainBacklog
