import React from 'react'
import useEmployeeData from '../../../api/query/useEmployeeData'
import useTeamMeetingData from '../../../api/query/team_meeting/useTeamMeetingData'
import useF2WData from '../../../api/query/useF2WData'

function addOrInitToDict(dictName, key, value) {
  if (key in dictName) dictName[key] += value
  else dictName[key] = value
}

export default function useTeamMeetingViewData(refreshIndex) {
  // const { data: meetingData, isLoading: isMeetingViewLoading } = useTeamMeetingData(refreshIndex)
  const { data: employeeData, isLoading: isEmployeeLoading } = useEmployeeData()
  // const { data: F2WData, isLoading: isF2WLoading } = useF2WData()

  const [scoreCard, setScoreCard] = React.useState(null)
  const [selectedCardIndexHook, setSelectedCardIndexHook] = React.useState(0)

  let origScoreCard = null

  // React.useEffect(() => {
  //   if (!isMeetingViewLoading) {
  //   }
  // }, [isMeetingViewLoading])

  const updateScoreCard = (e, tradeOffIndex) => {}

  const addOption = (e) => {}

  let F2WData = null
  let meetingData = null

  return {
    isLoading: isEmployeeLoading,
    meetingData,
    scoreCard,
    employees: employeeData?.employees ?? [],
    F2WData,
    updateScoreCard,
    addOption,
    selectedCardIndexHook,
    setSelectedCardIndexHook,
  }
}
