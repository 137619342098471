import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const addTeamLeaderActivity = async (activityData) => {
  const { data } = await axios.post(`${BASE_URL}/tl_activity_add`, activityData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data
}

export default function useAddTeamLeaderActivity() {
  const client = useQueryClient()

  return useMutation((data) => addTeamLeaderActivity(data), {
    onSuccess: (response) => {
      if (response.ok) {
        void client.invalidateQueries('team-leader-activities')
      }
    },
  })
}
