import React from 'react'
import { Formik, Field, Form } from 'formik'
import SelectField from '../../../../components/CustomMultipleSelect/customSelect'
import VerbDataList from '../../../../utils/VerbDataList'
import { useDemandIntakeContext } from '../../context/demandIntakeContext'
import ErrorModal from '../../../../components/ErrorModal'
import useValidateGoal from '../../../../api/mutation/useValidateGoal'
import { useNavigate } from 'react-router-dom'
import { ModalSaveButtonFitted } from '../../../../components/ModalSubmitButton'
import useGetAreaEmployeesMinusSelfAndCopyOwners from '../../../../api/query/useGetAreaEmployeesMinusSelfAndCopyOwners'
import { useFeatureFlagsContext } from '../../../../utils/featureFlagsContext'
import { getCompanyConfigSettings } from '../../../../utils/auth'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { getConsolidatedConfigSettings, isAreaLeader } from '../../../../utils/auth'
import useEmployeeData_2_3 from '../../../../api/query/useEmployeeDataLevel2&3'
import SelectSingleField from '../../../../components/CustomMultipleSelect/singleOption.jsx'
import TagInput from '../../../../components/TagInput'
import { routes } from '../../../../utils/routes'

const ObjectiveForm = ({
  objectives,
  objectiveType,
  handleNext,
  handleNextSkipOneStep,
  handleSubmit,
  action,
}) => {
  const { state, dispatch } = useDemandIntakeContext()
  // debugger

  const [tags, setTags] = React.useState(state?.objectiveAssociation?.tags ?? [])
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = React.useState(null)
  const [objectiveWidgetErrorMessage, setObjectiveWidgetErrorMessage] = React.useState(null)
  const [showMore, setShowMore] = React.useState(false)
  const mutationValidateGoal = useValidateGoal()
  const { flags } = useFeatureFlagsContext()
  const productSettings = getCompanyConfigSettings('productSettings')
  const { t } = useTranslation(['Dashboard', 'Common'])
  const canChangeObjStatus =
    getConsolidatedConfigSettings('can_change_obj_status') || isAreaLeader()
  const [tlId, setTlId] = React.useState(state?.objectiveAssociation?.ownerId ?? null)
  const [teamLeaders, setTeamLeaders] = React.useState([])
  const [rightsInitialValues, setRightsInitialValues] = React.useState([])

  const statusOptions = [
    { value: 'NEW', label: 'Pre-PD0' },
    { value: 'NOT_STARTED', label: 'PD0' },
    { value: 'IN_PROGRESS', label: 'PD1' },
    { value: 'COMPLETE', label: 'PDf' },
    { value: 'DEPRIORITIZED', label: 'PDx' },
  ]

  const { data: dataEmpLevel23 } = useEmployeeData_2_3()

  const [isClearable, setIsClearable] = React.useState(true)

  React.useEffect(() => {
    // only set default tld as loggedin user if ownerId doesn't exist
    if (!state?.objectiveAssociation?.ownerId) {
      let leader = dataEmpLevel23?.employees.filter(
        (emp) => emp.name == localStorage.getItem('tfmnm'),
      )[0]
      setTlId(parseInt(leader?.eId))
    }
    setTeamLeaders(
      dataEmpLevel23?.employees.filter(
        (emp) =>
          emp.eId !== parseInt(state?.objectiveAssociation?.ownerId) && emp.isDeactivated === false,
      ),
    )
  }, [dataEmpLevel23])

  React.useEffect(() => {
    const l2UserCanSeeL2InKanban = getConsolidatedConfigSettings('l2_user_can_see_l2_in_kanban')
    if (l2UserCanSeeL2InKanban && teamLeaders) {
      setRightsInitialValues(teamLeaders)
    }
  }, [teamLeaders])

  React.useEffect(() => {
    if (state?.objectiveAssociation?.coOwners) {
      state?.objectiveAssociation?.coOwners.map((owner, ownerIndex) => {
        owner.isFixed = true
      })
    }
  }, [state?.objectiveAssociation?.coOwners])

  React.useEffect(() => {
    if (action) {
      if (action === 'createNewObj') {
        setIsClearable(true)
      } else if (action === 'editObj') {
        setIsClearable(false)
      }
    }
  }, [action])

  React.useEffect(() => {
    if (!state.isObjFormModalOpen) {
      // Reset custom validation on modal close
      setErrorMessage(null)
    }
  }, [state.isObjFormModalOpen])

  const openErrorModal = () => {
    dispatch({ type: 'OPEN_ERROR_MODAL' })
  }

  const closeErrorModal = () => {
    dispatch({ type: 'CLOSE_ERROR_MODAL' })
  }

  const [verbOptions, setVerbOptions] = React.useState([])

  const handleVerbSuggestions = ({ target }) => {
    if (target.value) {
      const filteredOptions = VerbDataList.filter((option) =>
        option.toLowerCase().startsWith(target.value.toLowerCase()),
      )
      setVerbOptions(filteredOptions)
    } else {
      setVerbOptions([])
    }
  }

  const goals = React.useMemo(() => {
    const returnGoals = []

    if (state.data.linkageData.higherObjectives) {
      state.data.linkageData.higherObjectives.forEach((goal) => {
        if (goal.level === 1) {
          returnGoals.push({
            levelOneGoal: goal,
            levelTwoGoals:
              state.data.linkageData.higherObjectives.filter(
                (test) => test.parentId === goal.id && test.level === 2,
              ) ?? [],
          })
        }
      })

      // This handles orphan level 2 objectives. We create a dummy level 1 objecitve with the ID 0,
      // which can be used to represent the dropdown option for "Not linked to an enterprise objective".
      returnGoals.push({
        levelOneGoal: {
          id: '0', // This should be a string because others are strings too (why??)
          statement: 'Not linked to an enterprise objective',
        },
        levelTwoGoals:
          state.data.linkageData.higherObjectives.filter(
            (test) =>
              (test.parentId === undefined || parseInt(test.parentId) === 0) && test.level === 2,
          ) ?? [],
      })

      return returnGoals
    }
  }, [state.data.linkageData.higherObjectives])

  const prepareObj = async (values, resetForm) => {
    let objectiveToSubmit = {
      typeId: values.isBAU
        ? 3
        : parseInt(values.topLevelObjectiveId) === 0 ||
            parseInt(values.topLevelObjectiveId) === null
          ? 2
          : 1,
      durationWeeks: values.durationWeeks,
      //objectiveDuration: values.objectiveDuration.toUpperCase(),
      isBlocked: state?.objectiveAssociation?.isBlocked ?? false,
      blockedReason: state?.objectiveAssociation?.blockedReason ?? null,
      statement: values.statement,
      topLevelObjectiveId: values.topLevelObjectiveId,
      parentId: values.parentId,
      impactMeasureId: values.impactMeasureId,
      lackingCapacity: state?.objectiveAssociation?.lackingCapacity ?? false,
      objectiveType: objectiveType,
      tags: [...tags],
      coOwners: [...values.coOwners],
      originalId:
        action === 'editObj'
          ? (state?.objectiveAssociation?.originalId ?? 0)
          : state?.objectiveAssociation?.originalId,
      rights: [...values.rights],
      status: values?.status,
      dueDate: values?.dueDateFilter,
      startDate: values?.startDateFilter,
      bizRationale: productSettings ? values.bizRationale : null,
    }

    if (productSettings && action === 'createNewObj') {
      objectiveToSubmit.objectiveOwner = tlId
    }

    if (values.isBAU) {
      objectiveToSubmit = {
        ...objectiveToSubmit,
        topLevelObjectiveId: null,
        parentId: null,
        impactMeasureId: null,
      }
    } else {
      objectiveToSubmit = {
        ...objectiveToSubmit,
        topLevelObjectiveId: values.topLevelObjectiveId,
        parentId: values.parentId,
        impactMeasureId: parseInt(values.impactMeasureId),
      }
    }

    if (action === 'editObj' && showMore === true && values.transferOwnership !== '') {
      objectiveToSubmit = {
        ...objectiveToSubmit,
        transferOwnership: parseInt(values.transferOwnership),
      }

      if (window.confirm(t('addObjectivesModal.confirmTransferObjectiveMsg'))) {
      } else {
        return
      }
    }

    dispatch({ type: 'SET_OBJ_ASSOCIATION', objectiveAssociation: objectiveToSubmit })
    dispatch({ type: 'CLOSE_OBJ_FORM_MODAL' })
    resetForm()

    handleSubmit(objectiveToSubmit, false)
  }

  const errorCheck = async (values, resetForm) => {
    let errorCount = 0
    values.statement = values.statement.charAt(0).toUpperCase() + values.statement.slice(1)
    await mutationValidateGoal.mutateAsync(`${values.statement}`, {
      onSuccess: (response) => {
        if (response.is_valid === true) {
          values.isValid = true
          setErrorMessage(null)
        } else {
          errorCount++
          setErrorMessage(t('addObjectivesModal.objectiveSyntaxErrorMsg'))
        }
      },
      onError: () => {
        openErrorModal()
      },
    })
    // Commenting the condition for now
    // if (flags.enable_combined_objs === 'true') {
    //   if (errorCount === 0) {
    //     if (values.rights.length > 0 && values.coOwners.length > 0) {
    //       values.rights.forEach(item1 => {
    //         values.coOwners.forEach(item2 => {
    //           if (item1.eId === item2.eId) {
    //             errorCount++
    //             setErrorMessage(t('addObjectivesModal.sameSharedCoownerErrorMsg'))
    //           }
    //         });
    //       });
    //     }
    //   }
    // }
    if (errorCount === 0) {
      prepareObj(values, resetForm)
    }
  }

  function onKeyDown(keyEvent) {
    if (keyEvent.key === 'Enter') {
      keyEvent.preventDefault()
    }
  }

  const isCopy = () => {
    if (
      state?.objectiveAssociation?.originalId > 0 &&
      state?.objectiveAssociation?.originalId !== parseInt(state?.objectiveAssociation?.id)
    ) {
      return true
    }
    return false
  }

  const styles = React.useMemo(
    () => ({
      multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base
      },
    }),
    [],
  )

  const {
    data: employeesListData,
    isLoading: isEmployeesListDataLoading,
    error: errorEmployeesListData,
  } = useGetAreaEmployeesMinusSelfAndCopyOwners(parseInt(state?.objectiveAssociation?.id))

  const getObjectiveFormModalSchema = (requiredMessage) => {
    return Yup.object().shape({
      statement: Yup.string().required(requiredMessage),
      isBAU: Yup.boolean(),
      topLevelObjectiveId: Yup.string().when('isBAU', {
        is: false,
        then: Yup.string().required(requiredMessage),
        otherwise: Yup.string().nullable(),
      }),
      parentId: Yup.string().when('isBAU', {
        is: false,
        then: Yup.string().required(requiredMessage),
        otherwise: Yup.string().nullable(),
      }),
      dueDateFilter: Yup.string().when('productSettings', {
        is: true,
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
    })
  }

  let validationSchema = getObjectiveFormModalSchema(t('Common:required'))

  const inputChangedHandler = (e) => {
    setTlId(parseInt(e.eId))
  }

  return (
    <>
      <ErrorModal
        isOpen={state.isErrorModalOpen}
        handleClose={closeErrorModal}
        errorMessage={objectiveWidgetErrorMessage}
      />
      <Formik
        enableReinitialize
        initialValues={{
          statement:
            state?.objectiveAssociation?.statement ??
            (objectiveType === 'General' && state.suggestedObjective
              ? state.suggestedObjective
              : ''),
          isBAU: state?.objectiveAssociation?.typeId
            ? state?.objectiveAssociation?.typeId === 3
              ? true
              : false
            : objectiveType === 'BAU'
              ? true
              : false,
          topLevelObjectiveId: state?.objectiveAssociation?.topLevelObjectiveId
            ? parseInt(state?.objectiveAssociation?.topLevelObjectiveId)
            : '',
          parentId: state?.objectiveAssociation?.parentId
            ? parseInt(state?.objectiveAssociation?.parentId)
            : '',
          impactMeasureId: state?.objectiveAssociation?.impactMeasureId
            ? parseInt(state?.objectiveAssociation?.impactMeasureId)
            : '',
          durationWeeks:
            state?.objectiveAssociation?.durationWeeks ?? (objectiveType === 'BAU' ? 999999 : 1),

          coOwners: state?.objectiveAssociation?.coOwners ?? [],
          rights: state?.objectiveAssociation?.rights ?? rightsInitialValues,
          transferOwnership: '',
          status: state?.objectiveAssociation?.status ?? statusOptions[0].value,
          dueDateFilter: state?.objectiveAssociation?.dueDateStr ?? '',
          startDateFilter: state?.objectiveAssociation?.startDateStr ?? '',
          productSettings: productSettings ?? '',
          bizRationale: state?.objectiveAssociation?.bizRationale ?? '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          errorCheck(values, resetForm)
        }}
      >
        {({ errors, touched, resetForm, values, setFieldValue, validateForm }) => (
          <Form className="objective-form" onKeyDown={onKeyDown}>
            {isCopy() && (
              <span className="error-message">
                {' '}
                {t('addObjectivesModal.nonEditableFieldsWarningMsg')}{' '}
              </span>
            )}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                className="column-input-objective"
                style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}
              >
                {productSettings && action === 'createNewObj' && (
                  <div
                    className="form-field action-field form-field action-field--kanban"
                    style={{ textAlign: 'left' }}
                  >
                    <label>
                      {' '}
                      {t('addObjectivesModal.objectiveInitiativeOwner')}{' '}
                      <div style={{ fontWeight: 'normal' }}> </div>
                    </label>

                    <div data-testid="single-options-2">
                      <Field name="objectiveOwner">
                        {({ field }) => (
                          <SelectSingleField
                            // field={field}
                            value={
                              dataEmpLevel23?.employees?.filter(
                                (emp) => parseInt(emp.eId) === parseInt(tlId),
                              )[0]
                            }
                            getOptionLabel={(option) => option?.name}
                            getOptionValue={(option) => option?.eId}
                            options={dataEmpLevel23?.employees.filter(
                              (emp) => emp.isDeactivated === false,
                            )}
                            onChange={inputChangedHandler}
                            width={'100%'}
                          />
                        )}
                      </Field>
                    </div>
                    {errors.rights && touched.rights && (
                      <div className="validation-error">{errors.rights}</div>
                    )}
                  </div>
                )}
                <div className="form-field action-field">
                  <label>{t('addObjectivesModal.statement')}</label>
                  <Field
                    list="obj_verb_list"
                    id="statement"
                    name="statement"
                    placeholder={t('addObjectivesModal.statementPlaceholder')}
                    onKeyUp={handleVerbSuggestions}
                    onClick={handleVerbSuggestions}
                    autoComplete="off"
                    disabled={isCopy()}
                    as="textarea"
                    style={{ width: '85%' }}
                  />

                  {errors.statement && touched.statement && (
                    <div className="validation-error">{errors.statement}</div>
                  )}
                  <datalist id="obj_verb_list">
                    {verbOptions.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </datalist>
                </div>

                {productSettings && (
                  <div className="form-field action-field">
                    <label>Business Rationale and/or Key Customers</label>
                    <Field
                      id="bizRationale"
                      name="bizRationale"
                      placeholder="Start typing..."
                      onKeyUp={handleVerbSuggestions}
                      onClick={handleVerbSuggestions}
                      autoComplete="off"
                      as="textarea"
                      style={{ width: '60%' }}
                    />

                    {errors.bizRationale && touched.bizRationale && (
                      <div className="validation-error">{errors.bizRationale}</div>
                    )}
                  </div>
                )}

                {objectiveType !== 'BAU' && (
                  <>
                    <div className="form-field">
                      <label>{t('addObjectivesModal.bau')}</label>
                      <Field
                        type="checkbox"
                        id="isBAU"
                        name="isBAU"
                        checked={values.isBAU}
                        disabled={isCopy()}
                        onChange={(e) => {
                          const { checked } = e.target
                          setFieldValue('isBAU', checked)
                          setTimeout(() => {
                            setFieldValue('topLevelObjectiveId', '')
                            setFieldValue('parentId', '')
                            setFieldValue('impactMeasureId', '')
                            // setFieldValue('durationWeeks', 999999)
                            //setFieldValue('objectiveDuration', 'full_cycle')
                          }, 0)
                          validateForm(
                            'topLevelObjectiveId',
                            'parentId',
                            'impactMeasureId',
                            'durationWeeks',
                            //'objectiveDuration'
                          )
                        }}
                      />
                    </div>

                    <div className="form-field">
                      <label>{t('addObjectivesModal.enterpriseObjective')}</label>
                      <Field
                        as="select"
                        id="topLevelObjectiveId"
                        name="topLevelObjectiveId"
                        disabled={values.isBAU || isCopy()}
                      >
                        <option value="">
                          {t('addObjectivesModal.selectEnterpriseObjective')}
                        </option>

                        {goals.map((goal, goalIndex) => {
                          return goal.levelTwoGoals.length > 0 ? (
                            <option
                              key={`level1-${goalIndex}`}
                              id={goal.levelOneGoal.id}
                              value={goal.levelOneGoal.id}
                            >
                              {goal.levelOneGoal.statement}
                            </option>
                          ) : (
                            ''
                          )
                        })}
                      </Field>
                      {errors.topLevelObjectiveId && touched.topLevelObjectiveId && (
                        <div className="validation-error">{errors.topLevelObjectiveId}</div>
                      )}
                    </div>

                    <div className="form-field">
                      <label>{t('addObjectivesModal.areaObjective')}</label>
                      <Field
                        as="select"
                        id="parentId"
                        name="parentId"
                        disabled={values.isBAU || !Boolean(values.topLevelObjectiveId) || isCopy()}
                      >
                        <option value="">{t('addObjectivesModal.selectAreaObjective')}</option>
                        {goals &&
                          goals
                            .find(
                              (goal) =>
                                parseInt(goal.levelOneGoal.id) ===
                                parseInt(values?.topLevelObjectiveId),
                            )
                            ?.levelTwoGoals.map((level2Goal, l2Index) => (
                              <option
                                value={level2Goal.id}
                                key={`${state.editObjectiveIndex}-level2-${l2Index}`}
                                id={level2Goal.id}
                              >
                                {level2Goal.statement}
                              </option>
                            ))}
                      </Field>
                      {errors.parentId && touched.parentId && (
                        <div className="validation-error">{errors.parentId}</div>
                      )}
                    </div>

                    {productSettings && !isCopy() && (
                      <div className="form-field">
                        <label>{t('addObjectivesModal.status')}</label>
                        <Field
                          as="select"
                          name="status"
                          className="custom-select"
                          disabled={!canChangeObjStatus}
                        >
                          {statusOptions.map((data, index) => (
                            <option
                              key={data.value}
                              value={data.value}
                              label={data.label}
                              disabled={
                                action === 'createNewObj' &&
                                (data.value === 'COMPLETE' || data.value === 'DEPRIORITIZED')
                              }
                            />
                          ))}
                        </Field>
                      </div>
                    )}
                    {!isCopy() && (
                      <div className="form-field">
                        <label>{t('addObjectivesModal.startDate')}</label>
                        <Field name="startDateFilter" type="date"></Field>
                      </div>
                    )}
                    {!isCopy() && (
                      <div className="form-field">
                        <label>{t('addObjectivesModal.dueDate')}</label>
                        <Field name="dueDateFilter" type="date"></Field>
                        {errors.dueDateFilter && touched.dueDateFilter && (
                          <div className="validation-error">{errors.dueDateFilter}</div>
                        )}
                      </div>
                    )}
                    <div className="form-field">
                      <label>{t('addObjectivesModal.tags')}</label>
                      <TagInput existingTags={tags} setTagsExternally={setTags} objective={true} />
                    </div>

                    {
                      <div
                        className="form-field action-field form-field action-field--kanban"
                        style={{ textAlign: 'left' }}
                      >
                        <label>
                          {' '}
                          {t('addObjectivesModal.shared')}{' '}
                          <div style={{ fontWeight: 'normal' }}>
                            {' '}
                            {t('addObjectivesModal.sharedDescription')}{' '}
                          </div>
                        </label>

                        <div data-testid="multi-options-2">
                          <Field {...SelectField} name="rights">
                            {({ field }) => (
                              <SelectField
                                field={field}
                                getOptionLabel={(option) => option?.name}
                                getOptionValue={(option) => option?.eId}
                                options={teamLeaders}
                                isMulti={true}
                              />
                            )}
                          </Field>
                        </div>
                        {errors.rights && touched.rights && (
                          <div className="validation-error">{errors.rights}</div>
                        )}
                      </div>
                    }
                    {/* TODO Swetha: Commenting for now as we are not using co-owners. We can remove this as part of cleanup */}
                    {/* {productSettings && (
                    <div className="form-field action-field form-field action-field--kanban"
                      style={{ textAlign: "left" }}
                    >


                      <label> {t('addObjectivesModal.coOwned')} <div style={{ fontWeight: "normal" }}> {t("addObjectivesModal.coOwnedDescription")} </div>
                      </label>
                      <div data-testid="multi-options-1">
                        <Field {...SelectField} name="coOwners"

                        >
                          {({ field }) => (
                            <SelectField
                              field={field}
                              getOptionLabel={(option) => option?.name}
                              getOptionValue={(option) => option?.eId}
                              options={teamLeaders}
                              isDisabled={isCopy()}
                              isClearable={action === 'createNewObj' ? true : false}
                              styles={styles}
                              isMulti={true}
                            />
                          )}
                        </Field>
                        {errors.coOwners && touched.coOwners && (
                          <div className="validation-error">{errors.coOwners}</div>
                        )}
                      </div>
                    </div>
                    )} */}

                    {action === 'editObj' && (
                      <div
                        style={{
                          marginTop: '2rem',
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          rowGap: '1rem',
                        }}
                      >
                        <span
                          onClick={() => {
                            setShowMore(!showMore)
                          }}
                          style={{
                            color: 'blue',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            width: '100%',
                            cursor: 'pointer',
                          }}
                        >
                          <b>
                            {showMore === false
                              ? t('addObjectivesModal.showMore')
                              : t('addObjectivesModal.showLess')}
                          </b>
                        </span>
                        {showMore === true && (
                          <div className="form-field">
                            <label>{t('addObjectivesModal.transferObjective')}</label>
                            <Field as="select" id="transferOwnership" name="transferOwnership">
                              <option value="">{t('addObjectivesModal.selectPerson')}</option>
                              {employeesListData?.employees?.map((person, personIndex) => (
                                <option
                                  value={person.eId}
                                  key={`employee-list-${personIndex}`}
                                  id={person.name}
                                >
                                  {person.name}
                                </option>
                              ))}
                            </Field>
                            {errors.transferOwnership && touched.transferOwnership && (
                              <div className="validation-error">{errors.transferOwnership}</div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            {errorMessage && <div className="validation-error">{errorMessage}</div>}
            <div className="modal-dual-btn" style={{ columnGap: '2rem' }}>
              <div
                class="fitted-button blue"
                onClick={() => {
                  resetForm()
                  if (
                    state.demandIntakeAction === 'createNewObj' ||
                    state.demandIntakeAction === 'editObj'
                  ) {
                    if (state.locationState?.path !== undefined) {
                      if (state.locationState?.path === '/KPI/ListOfObjectives') {
                        navigate(`/${routes.kpiListOfObjectives}`, {
                          state: { showObjOnly: true },
                        })
                      } else {
                        window.location.href = state.locationState?.path
                      }
                    } else {
                      window.location.href = '/Objectives'
                    }
                  } else {
                    dispatch({ type: 'CLOSE_OBJ_FORM_MODAL' })
                  }
                }}
              >
                <span>{t('Common:commonButtons.cancel')}</span>
              </div>
              <ModalSaveButtonFitted isLoading={mutationValidateGoal.isLoading} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ObjectiveForm
